import { Observable } from 'rxjs'
import { PreQualifierProduct } from '../../../domain/pre-qualifier-product/pre-qualifier-product.interface'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { BffService } from '../bff.service'

export class ProductService extends BffService {
  public fetchProductDetails(
    vendorId: string,
    sku: string
  ): Observable<PreQualifierProduct> {
    return super.createAuthorizedRequest({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/product/vendor/${vendorId}/sku/${sku}`
    })
  }
}
