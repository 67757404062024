import { Reducer } from 'redux'
import { createConsumerAutoAddressState } from '../types/consumer-auto-address-state.functions'
import { ConsumerAutoAddressState } from '../types/consumer-auto-address-state.interface'
import {
  ConsumerAutoAddressAction,
  ConsumerAutoAddressActionType
} from './consumer-auto-address.actions'

export const consumerAutoAddressReducer: Reducer<
  ConsumerAutoAddressState,
  ConsumerAutoAddressActionType
> = (
  state: ConsumerAutoAddressState = createConsumerAutoAddressState(),
  action: ConsumerAutoAddressActionType
): ConsumerAutoAddressState => {
  switch (action.type) {
    case ConsumerAutoAddressAction.LookupAddress:
      return {
        ...state,
        lookupAddresses: [],
        lookupAddressesFailure: null,
        lookupAddressesLoading: true
      }

    case ConsumerAutoAddressAction.LookupAddressFailure:
      return {
        ...state,
        lookupAddressesFailure: action.payload,
        lookupAddressesLoading: false
      }

    case ConsumerAutoAddressAction.LookupAddressSuccess:
      return {
        ...state,
        lookupAddresses: action.payload,
        lookupAddressesLoading: false
      }

    case ConsumerAutoAddressAction.RetrieveAddress:
      return {
        ...state,
        retrieveAddressFailure: null,
        retrieveAddressLoading: true
      }

    case ConsumerAutoAddressAction.RetrieveAddressFailure:
      return {
        ...state,
        retrieveAddressFailure: action.payload,
        retrieveAddressLoading: false
      }

    case ConsumerAutoAddressAction.RetrieveAddressSuccess:
      return {
        ...state,
        retrieveAddress: action.payload,
        retrieveAddressLoading: false
      }

    case ConsumerAutoAddressAction.SaveAutoAddress:
      return {
        ...state,
        saveAddressLoading: true,
        saveAddressFailure: null
      }

    case ConsumerAutoAddressAction.SaveAutoAddressFailure:
      return {
        ...state,
        saveAddressLoading: false,
        saveAddressFailure: action.payload
      }

    case ConsumerAutoAddressAction.SaveAutoAddressSuccess:
      return {
        ...state,
        isWorkflowStepComplete: true,
        saveAddressLoading: false
      }
    case ConsumerAutoAddressAction.Reset:
      return createConsumerAutoAddressState()
    case ConsumerAutoAddressAction.ResetSaveAddressFailure:
      return {
        ...state,
        saveAddressFailure: null
      }
    default:
      return state
  }
}
