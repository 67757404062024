import { MarkerIconProp } from './marker.icon.prop'

export const MarkerIcon = (props: MarkerIconProp) => (
  <svg
    {...props.uat}
    className={props.className}
    width="31"
    height="31"
    viewBox="0 0 31 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8809 0.665039C19.8907 0.665039 23.7363 2.2866 26.5717 5.173C29.4071 8.0594 31 11.9742 31 16.0562C31 23.4106 26.1241 30.1801 16.5562 36.3776C16.3543 36.5081 16.12 36.5774 15.8809 36.5774C15.6417 36.5774 15.4075 36.5081 15.2055 36.3776C5.63764 30.1801 0.761719 23.4106 0.761719 16.0562C0.761719 11.9742 2.35462 8.0594 5.19001 5.173C8.0254 2.2866 11.871 0.665039 15.8809 0.665039ZM15.8809 10.9258C14.5442 10.9258 13.2624 11.4663 12.3172 12.4285C11.3721 13.3906 10.8411 14.6955 10.8411 16.0562C10.8411 17.4169 11.3721 18.7218 12.3172 19.6839C13.2624 20.6461 14.5442 21.1866 15.8809 21.1866C17.2175 21.1866 18.4993 20.6461 19.4445 19.6839C20.3896 18.7218 20.9206 17.4169 20.9206 16.0562C20.9206 14.6955 20.3896 13.3906 19.4445 12.4285C18.4993 11.4663 17.2175 10.9258 15.8809 10.9258Z"
      fill="#B82C21"
    />
  </svg>
)
