import { Reducer } from 'redux'
import { createConsumerDobStepState } from '../types/consumer-dob-step-state.functions'
import { ConsumerDobStepState } from '../types/consumer-dob-step-state.interface'
import {
  ConsumerDobAction,
  ConsumerDobStepActionType
} from './consumer-dob-step.actions'

export const consumerDobStepReducer: Reducer<
  ConsumerDobStepState,
  ConsumerDobStepActionType
> = (
  state: ConsumerDobStepState = createConsumerDobStepState(),
  action: ConsumerDobStepActionType
): ConsumerDobStepState => {
  switch (action.type) {
    case ConsumerDobAction.SaveConsumerDob:
      return {
        ...state,
        error: null,
        loading: true
      }
    case ConsumerDobAction.SaveConsumerDobFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case ConsumerDobAction.SaveConsumerDobSuccess:
      return {
        ...state,
        dateOfBirth: action.payload,
        isWorkflowStepComplete: true,
        loading: false
      }
    case ConsumerDobAction.ResetConsumerDobState:
      return createConsumerDobStepState()
    default:
      return state
  }
}
