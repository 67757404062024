import { Epic } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  ignoreElements,
  tap,
  map
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { MerchantSelectionActions } from './merchant-selection.actions'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { Action } from '../../../../../store/root.actions'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { ConsumerSessionStore } from '../../consumer-session/store/consumer-session.store'
import { MerchantService } from '../../../../../api/bff/services/merchant.service'
import { MerchantsResult } from '../types/merchants-result.interface'
import { history } from '../../../../../store/types/root-store.functions'
import { ConsumerProfileDto } from '../../../../../api/bff/dto/consumer-profile/consumer-profile.dto'
import { Coordinates } from '../../../../../domain/coordinate/coordinate.interface'
import { MapViewService } from '../../../../../shared/components/map-view/services/map-view.service'
import { ConsumerProfile } from '../../../../../domain/consumer/consumer-profile/consumer-profile.interface'
import { ConsumerRoute } from '../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { ConsumerProfileSelectedMerchantsService } from '../../../../../api/bff/services/consumer-profile-selected-merchants.service'
import { Merchants } from '../../../../../domain/merchant/merchants.interface'
import { addVendorIdToPath } from '../../../../../shared/helpers/path-helper'
import { ConsumerProfileStore } from '../../consumer-profile/store/consumer-profile.store'
import { MerchantSelectionStore } from './merchant-selection.store'

const fetchMerchantsEpic: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(MerchantSelectionActions.fetchMerchants)),
    switchMap((action) =>
      new MerchantService(ConsumerSessionStore.getSessionToken(state$.value))
        .fetchMerchants(
          action.payload.page,
          action.payload.assetSubtypeId,
          MerchantSelectionStore.getMerchantSelectionForm(state$.value).merchantName,
        )
        .pipe(
          mergeMap((result: MerchantsResult) =>
            of(MerchantSelectionActions.fetchMerchantsSuccess(result))
          ),
          catchError((err: HttpError) =>
            of(MerchantSelectionActions.fetchMerchantsFailure(err))
          )
        )
    )
  )

const fetchConsumerProfileAddressCoordinatesEpic: Epic<
  Action,
  Action,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf(
        MerchantSelectionActions.fetchConsumerProfileAddressCoordinates
      )
    ),
    map(() => state$.value.consumerState.consumerProfileState.address),
    switchMap((address) =>
      new MapViewService().getAddressCoordinates(address).pipe(
        mergeMap((result: Coordinates) =>
          of(
            MerchantSelectionActions.fetchConsumerProfileAddressCoordinatesSuccess(
              result
            )
          )
        ),
        catchError((err: HttpError) =>
          of(
            MerchantSelectionActions.fetchConsumerProfileAddressCoordinatesFailure(
              err
            )
          )
        )
      )
    )
  )

const reassignConsumerProfileApplicationMerchantEpic: Epic<
  Action,
  Action,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf(
        MerchantSelectionActions.reassignConsumerProfileApplicationMerchant
      )
    ),
    switchMap((action) =>
      new MerchantService(ConsumerSessionStore.getSessionToken(state$.value))
        .reassignConsumerProfileApplicationMerchant(
          action.payload.merchant,
          action.payload.assetSubtypeId
        )
        .pipe(
          mergeMap((result: ConsumerProfile) =>
            of(
              MerchantSelectionActions.reassignConsumerProfileApplicationMerchantSuccess(
                result
              )
            )
          ),
          catchError((err: HttpError) =>
            of(
              MerchantSelectionActions.reassignConsumerProfileApplicationMerchantFailure(
                err
              )
            )
          )
        )
    )
  )

const reassignConsumerProfileApplicationMerchantSuccessEpic: Epic<
  Action,
  Action,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf(
        MerchantSelectionActions.reassignConsumerProfileApplicationMerchantSuccess
      )
    ),
    tap(() =>
      history.push(
        addVendorIdToPath(
          ConsumerProfileStore.getVendorId(state$.value),
          ConsumerRoute.SelectMerchantSuccess
        )
      )
    ),
    ignoreElements()
  )

const cloneConsumerProfileApplicationMerchantEpic: Epic<
  Action,
  Action,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf(
        MerchantSelectionActions.cloneConsumerProfileApplicationMerchant
      )
    ),
    switchMap((action) =>
      new MerchantService(ConsumerSessionStore.getSessionToken(state$.value))
        .cloneConsumerProfileApplicationMerchant(
          action.payload.merchant,
          action.payload.assetSubtypeId
        )
        .pipe(
          mergeMap((result: ConsumerProfileDto) =>
            of(
              MerchantSelectionActions.cloneConsumerProfileApplicationMerchantSuccess(
                result
              )
            )
          ),
          catchError((err: HttpError) =>
            of(
              MerchantSelectionActions.cloneConsumerProfileApplicationMerchantFailure(
                err
              )
            )
          )
        )
    )
  )

const cloneConsumerProfileApplicationMerchantSuccessEpic: Epic<
  Action,
  Action,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf(
        MerchantSelectionActions.cloneConsumerProfileApplicationMerchantSuccess
      )
    ),
    tap(() =>
      history.push(
        addVendorIdToPath(
          ConsumerProfileStore.getVendorId(state$.value),
          ConsumerRoute.SelectMerchantSuccess
        )
      )
    ),
    ignoreElements()
  )

const fetchConsumerProfileSelectedMerchantsEpic: Epic<
  Action,
  Action,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf(MerchantSelectionActions.fetchConsumerProfileSelectedMerchants)
    ),
    switchMap(() =>
      new ConsumerProfileSelectedMerchantsService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .getSelectedMerchants()
        .pipe(
          mergeMap((result: Merchants) =>
            of(
              MerchantSelectionActions.fetchConsumerProfileSelectedMerchantsSuccess(
                result
              )
            )
          ),
          catchError((err: HttpError) =>
            of(
              MerchantSelectionActions.fetchConsumerProfileSelectedMerchantsFailure(
                err
              )
            )
          )
        )
    )
  )

export const merchantSelectionEpics = [
  fetchMerchantsEpic,
  fetchConsumerProfileAddressCoordinatesEpic,
  reassignConsumerProfileApplicationMerchantEpic,
  reassignConsumerProfileApplicationMerchantSuccessEpic,
  cloneConsumerProfileApplicationMerchantEpic,
  cloneConsumerProfileApplicationMerchantSuccessEpic,
  fetchConsumerProfileSelectedMerchantsEpic
]
