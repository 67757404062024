import mapboxGeocodingService from '@mapbox/mapbox-sdk/services/geocoding'
import { Coordinates } from '../../../domain/coordinate/coordinate.interface'
import { GeocodeProvider } from '../../../domain/geolocation/geocode-provider.interface'

export class MapLibraryGeocodeService implements GeocodeProvider {
  private geoCodingService = mapboxGeocodingService({
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? ''
  })

  public getCoordinates(address: string): Promise<Coordinates> {
    return this.geoCodingService
      .forwardGeocode({
        query: address,
        autocomplete: false,
        limit: 1
      })
      .send()
      .then((response) => {
        if (response.body?.features[0]) {
          return {
            longitude: response.body.features[0].center[0],
            latitude: response.body.features[0].center[1]
          }
        }
        throw new Error('failed to find consumer address coordinates')
      })
  }
}
