import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerAssetSubtypeStepState } from '../types/consumer-asset-subtype-step-interface'

export enum ConsumerAssetSubtypeStepAction {
  SaveConsumerAssetSubtypeId = 'ConsumerAssetSubtype/SaveConsumerAssetSubtypeId',
  SaveConsumerAssetSubtypeIdSuccess = 'ConsumerAssetSubtype/SaveConsumerAssetSubtypeIdSuccess',
  SaveConsumerAssetSubtypeIdFailure = 'ConsumerAssetSubtype/SaveConsumerAssetSubtypeIdFailure'
}

const saveConsumerAssetSubtypeId = createAction(
  ConsumerAssetSubtypeStepAction.SaveConsumerAssetSubtypeId,
  (action) =>
    (
      assetSubtypeId: NonNullable<
        ConsumerAssetSubtypeStepState['assetSubtypeId']
      >
    ) =>
      action(assetSubtypeId)
)

const saveConsumerAssetSubtypeIdSuccess = createAction(
  ConsumerAssetSubtypeStepAction.SaveConsumerAssetSubtypeIdSuccess,
  (action) =>
    (
      assetSubtypeId: NonNullable<
        ConsumerAssetSubtypeStepState['assetSubtypeId']
      >
    ) =>
      action(assetSubtypeId)
)

const saveConsumerAssetSubtypeIdFailure = createAction(
  ConsumerAssetSubtypeStepAction.SaveConsumerAssetSubtypeIdFailure,
  (action) => (error: NonNullable<ConsumerAssetSubtypeStepState['error']>) =>
    action(error)
)

export const ConsumerAssetSubtypeStepActions = {
  saveConsumerAssetSubtypeId,
  saveConsumerAssetSubtypeIdSuccess,
  saveConsumerAssetSubtypeIdFailure
}

export type ConsumerAssetSubtypeStepActionType = ActionType<
  typeof ConsumerAssetSubtypeStepActions
>
