export const ConsumerPostSubmissionApprovedViewMessages = {
  header: {
    id: 'Pages.PostSubmission.Approved.View.header',
    description: 'Post Submission Approved View Header'
  },
  title: {
    id: 'Pages.PostSubmission.Approved.View.title',
    description: 'Post Submission Approved View Title'
  },
  approvedAmountMessageHeader: {
    id: 'Pages.PostSubmission.Approved.View.approvedAmountMessageHeader',
    description: 'Post Submission Approved View Approved Amount Message'
  },
  approvedAmountMessage: {
    id: 'Pages.PostSubmission.Approved.View.approvedAmountMessage',
    description: 'Post Submission Approved View Approved Amount Message'
  },
  approvedAmountLabel: {
    id: 'Pages.PostSubmission.Approved.View.approvedAmountLabel',
    description: 'Post Submission Approved View Approved Amount Label'
  },
  selectMerchantLabel: {
    id: 'Pages.PostSubmission.Approved.View.selectMerchantLabel',
    description: 'Post Submission Approved View Select Merchant Label'
  },
  selectMerchantConfirmButton: {
    id: 'Pages.PostSubmission.Approved.View.selectMerchantConfirmButton',
    description: 'Post Submission Approved View Select Merchant Confirm Button'
  },
  redirectApplicantPortalLabel: {
    id: 'Pages.PostSubmission.Approved.View.redirectApplicantPortalLabel',
    description:
      'Post Submission Approved View Redirect To Applicant Portal Label'
  },
  redirectApplicantPortalButton: {
    id: 'Pages.PostSubmission.Approved.View.redirectApplicantPortalButton',
    description:
      'Post Submission Approved View Redirect To Applicant Portal Confirm Button'
  },
  aboutMessage: {
    id: 'Pages.PostSubmission.Approved.View.aboutMessage',
    description: 'About LendCare Paragraph'
  },
  helpText: {
    id: 'Pages.PostSubmission.Approved.View.helpText',
    description: 'LendCare Helper Text',
  },
  helpEmail: {
    id: 'Pages.PostSubmission.Approved.View.helpEmail',
    description: 'LendCare Helper Text',
  },
  helpPhoneNumber: {
    id: 'Pages.PostSubmission.Approved.View.helpPhoneNumber',
    description: 'LendCare Helper Text',
  },
  getStartedLabel: {
    id: 'Pages.PostSubmission.Approved.View.getStartedLabel',
    description: 'Get Started Label',
  }
}
