import * as yup from 'yup'
import { FormSchemaDefinition } from './types/form-schema-definition.type'
import { FormSchemaMap } from './types/form-schema-map.type'
import { FormFieldSchemaOptions } from './form-field-schema/types/form-field-schema-options/form-field-schema-options.type'

export class FormSchema<T> {
  private formSchema: yup.AnyObjectSchema

  constructor(private schemaDefinition: FormSchemaMap<T>) {
    this.formSchema = yup.object().shape(this.buildFormSchemaDefinition())
  }

  private buildFormSchemaDefinition(): FormSchemaDefinition {
    const formSchemaDefinition: FormSchemaDefinition = {}
    for (const key in this.schemaDefinition) {
      formSchemaDefinition[key] = this.schemaDefinition[key].getSchema()
    }
    return formSchemaDefinition
  }

  public isValid(values: T): Promise<boolean> {
    return this.formSchema.isValid(values)
  }

  public isValidSync(values: T): boolean {
    return this.formSchema.isValidSync(values)
  }

  public isValidOrReject(values: T): Promise<boolean> {
    return this.formSchema.validate(values).then(() => true)
  }

  public isValidOrThrow(values: T): boolean {
    try {
      this.formSchema.validateSync(values)
      return true
    } catch (err) {
      throw err
    }
  }

  public getSchemaOptions<R extends FormFieldSchemaOptions>(field: keyof T): R {
    return this.schemaDefinition[field].getSchemaOptions() as R
  }
}
