import { FormattedMessage } from 'react-intl'
import { QuestionsDialogForm } from '../questions-dialog-form/questions-dialog-form.component'
import { QuestionsDialogContentStyles } from './questions-dialog-content.styles'
import { QuestionsDialogContentMessages } from './questions-dialog-content.messages'
import { QuestionsDialogContentProps } from './questions-dialog-content.props'
import { LanguageSelector } from '../../../../../../../../shared/components/language-selector/language-selector.component'

export const QuestionsDialogContent = (props: QuestionsDialogContentProps) => {
  return (
    <div className={QuestionsDialogContentStyles.container}>
      <div className={QuestionsDialogContentStyles.header}>
        <h2 className={QuestionsDialogContentStyles.title}>
          <FormattedMessage {...QuestionsDialogContentMessages.dialogTitle} />
        </h2>
        <div className={QuestionsDialogContentStyles.languageSelectorContainer}>
          <LanguageSelector
            changeLocale={props.changeLocale}
            currentLocale={props.currentLocale}
          />
        </div>
      </div>
      <p className={QuestionsDialogContentStyles.paragraph}>
        <FormattedMessage
          {...QuestionsDialogContentMessages.dialogDescription}
        />
      </p>
      <QuestionsDialogForm
        securityQuestions={props.securityQuestions}
        onSubmit={props.onSubmit}
        attemptsRemaining={props.attemptsRemaining}
        loading={props.loading}
      />
    </div>
  )
}
