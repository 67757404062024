import { ConsumerPostSubmissionOffersState } from './consumer-post-submission-offers-state.interface'

export function createConsumerPostSubmissionOffersState(): ConsumerPostSubmissionOffersState {
  return {
    loading: false,
    offers: null,
    fetchError: null,
    hasViewedPage: false
  }
}
