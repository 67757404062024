import { useCallback } from 'react'
import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component'
import { ConsumerPhoneNumberView } from './components/consumer-phone-number-view/consumer-phone-number-view.component'
import { ConsumerPhoneNumberStepProps } from './consumer-phone-number-step.props'

export const ConsumerPhoneNumberStep = (
  props: ConsumerPhoneNumberStepProps
) => {
  const onComplete = useCallback(
    () => props.onComplete(props.step),
    [props.onComplete, props.step]
  )

  const goBack = useCallback(
    () => props.goBack(props.step),
    [props.step, props.goBack]
  )

  return (
    <MainLayout step={props.step} hideFooter>
      <ConsumerPhoneNumberView onComplete={onComplete} goBack={goBack} />
    </MainLayout>
  )
}
