import { withShowIf } from '../../../../../../helpers/with-show-if/with-show-if.component'
import { EmbeddedIconProps } from './embedded-icon.props'
import { EmbeddedIconStyles } from './embedded-icon.styles'

export const EmbeddedIcon = withShowIf((props: EmbeddedIconProps) => {
  if (!props.icon) {
    return null
  }

  return <div className={props.end ? EmbeddedIconStyles.containerEnd : EmbeddedIconStyles.containerStart}>{props.icon}</div>
})
