import { Reducer } from 'redux'
import { createPreQualifierAssetTypeSelectionState } from '../types/pre-qualifier-asset-type-selection-state.functions'
import { PreQualifierAssetTypeSelectionState } from '../types/pre-qualifier-asset-type-selection-state.interface'
import {
  PreQualifierAssetTypeSelectionAction,
  PreQualifierAssetTypeSelectionActionType
} from './pre-qualifier-asset-type-selection.actions'

export const preQualifierAssetTypeSelectionReducer: Reducer<
  PreQualifierAssetTypeSelectionState,
  PreQualifierAssetTypeSelectionActionType
> = (
  state: PreQualifierAssetTypeSelectionState = createPreQualifierAssetTypeSelectionState(),
  action: PreQualifierAssetTypeSelectionActionType
): PreQualifierAssetTypeSelectionState => {
  switch (action.type) {
    case PreQualifierAssetTypeSelectionAction.FetchAssetTypes:
      return {
        ...state,
        fetchError: null,
        loading: true
      }
    case PreQualifierAssetTypeSelectionAction.FetchAssetTypesFailure:
      return {
        ...state,
        fetchError: action.payload,
        loading: false
      }
    case PreQualifierAssetTypeSelectionAction.FetchAssetTypesSuccess:
      return {
        ...state,
        assetTypes: action.payload,
        fetchError: null,
        loading: false
      }
    case PreQualifierAssetTypeSelectionAction.SetSelectedAssetType:
      return {
        ...state,
        selectedAssetType: action.payload
      }
    case PreQualifierAssetTypeSelectionAction.ResetPreQualifierAssetTypeState:
      return createPreQualifierAssetTypeSelectionState()
    default:
      return state
  }
}
