import { Reducer } from 'redux'
import {
  ConsumerPostSubmissionOffersAction,
  ConsumerPostSubmissionOffersActionType
} from './consumer-post-submission-offers.actions'
import { ConsumerPostSubmissionOffersState } from '../types/consumer-post-submission-offers-state.interface'
import { createConsumerPostSubmissionOffersState } from '../types/consumer-post-submission-offers-state.functions'

export const consumerPostSubmissionOffersReducer: Reducer<
  ConsumerPostSubmissionOffersState,
  ConsumerPostSubmissionOffersActionType
> = (
  state: ConsumerPostSubmissionOffersState = createConsumerPostSubmissionOffersState(),
  action: ConsumerPostSubmissionOffersActionType
): ConsumerPostSubmissionOffersState => {
  switch (action.type) {
    case ConsumerPostSubmissionOffersAction.FetchOffers:
      return {
        ...state,
        loading: true,
        fetchError: null,
        offers: null
      }
    case ConsumerPostSubmissionOffersAction.FetchOffersSuccess:
      return {
        ...state,
        loading: false,
        offers: action.payload,
        fetchError: null
      }
    case ConsumerPostSubmissionOffersAction.FetchOffersFailure:
      return {
        ...state,
        fetchError: action.payload,
        loading: false
      }
    case ConsumerPostSubmissionOffersAction.SaveLoanOfferPageViewed:
      return {
        ...state,
        hasViewedPage: true
      }
    default:
      return state
  }
}
