export const QuestionsDialogContentMessages = {
  dialogTitle: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogContent.dialogTitle',
    description: 'Questions dialog title'
  },
  dialogDescription: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogContent.dialogDescription',
    description: 'Questions dialog description'
  },
  dialogTooltipTitle: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogContent.dialogTooltipTitle',
    description: 'Questions dialog tooltip title'
  }
}
