import { FormattedMessage, useIntl } from 'react-intl'
import { LocalePicker } from '../../../../../../shared/components/main-layout/components/footer/components/locale-picker/locale-picker.component'
import { EmailLink } from '../../../../../../ui-library/components/link/email-link/email-link.component'
import { PhoneLink } from '../../../../../../ui-library/components/link/phone-link/phone-link.component'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { AuthenticationFailureMessageBoxStyles } from './authentication-failure-message-box.styles'
import { AuthenticationFailureMessageBoxMessages } from './authentication-failure-message-box.messages'
import { AuthenticationFailureMessageBoxProps } from './authentication-failure-message-box.props'

export const AuthenticationFailureMessageBox = withShowIf((props: AuthenticationFailureMessageBoxProps) => {
  const intl = useIntl()

  return (
    <div className={AuthenticationFailureMessageBoxStyles.container}>
      <Paragraph className={AuthenticationFailureMessageBoxStyles.paragraph}>
        <FormattedMessage
          {...AuthenticationFailureMessageBoxMessages.message}
        />
      </Paragraph>
      <Paragraph className={AuthenticationFailureMessageBoxStyles.paragraph}>
        <FormattedMessage
          {...AuthenticationFailureMessageBoxMessages.technicalAssistance}
        />
      </Paragraph>
      <div className={AuthenticationFailureMessageBoxStyles.contactInfoContainer}>
        <EmailLink
          className={AuthenticationFailureMessageBoxStyles.email}
          cssLogoClasses={AuthenticationFailureMessageBoxStyles.icon}
          text={intl.formatMessage(AuthenticationFailureMessageBoxMessages.lendCareEmail)}
          emailAddress={intl.formatMessage(AuthenticationFailureMessageBoxMessages.lendCareEmail)}
        />
        <PhoneLink
          className={AuthenticationFailureMessageBoxStyles.phone}
          cssLogoClasses={AuthenticationFailureMessageBoxStyles.icon}
          text={intl.formatMessage(AuthenticationFailureMessageBoxMessages.lendCarePhoneNumber)}
          phoneNumber={intl.formatMessage(AuthenticationFailureMessageBoxMessages.lendCarePhoneNumber)}
        />
      </div>
      <LocalePicker changeLocale={props.changeLocale} />
    </div>
  )
})
