import { useEffect, useState } from 'react'
import { QuestionType } from '../../../../../../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { SecurityQuestionsForm } from '../../types/security-questions-form/security-questions-form.interface'
import { QuestionsDialogFormView } from '../questions-dialog-form-view/questions-dialog-form-view.component'
import {
  mapSecurityQuestionFormValuesToAnswers,
  createInitialSecurityFormValues
} from '../questions-dialog-form-view/types/questions-dialog-form-fields.functions'
import { QuestionsDialogFormProps } from './questions-dialog-form.props'
import { generateSecurityQuestionsFormSchema } from './questions-dialog-form.schema'

export const QuestionsDialogForm = (props: QuestionsDialogFormProps) => {
  const securityQuestions = props.securityQuestions || []
  const [disableSubmitButton, setDisableSubmitButton] = useState(true)
  const [securityFormValues, setSecurityFormValues] =
    useState<SecurityQuestionsForm>(
      createInitialSecurityFormValues(securityQuestions)
    )

  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const formSchema = generateSecurityQuestionsFormSchema()

  const onSubmit = () =>
    props.onSubmit(mapSecurityQuestionFormValuesToAnswers(securityFormValues))

  const handleChange = (type: QuestionType, value: string) => {
    setSecurityFormValues({
      ...securityFormValues,
      [type]: value
    })
  }

  useEffect(() => {
    setIsFormValid(formSchema.isValidSync(securityFormValues))
  }, [securityFormValues])

  useEffect(() => {
    setDisableSubmitButton(!isFormValid)
  }, [isFormValid])

  return (
    <>
      <QuestionsDialogFormView
        securityQuestions={securityQuestions}
        securityFormValues={securityFormValues}
        loading={props.loading}
        disabled={disableSubmitButton}
        onChange={handleChange}
        onSubmit={onSubmit}
        attemptsRemaining={props.attemptsRemaining}
      />
    </>
  )
}
