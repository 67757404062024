export enum ConsumerWorkflowStepType {
  ConsumerReviewAsset = 'consumer-review-asset',
  ConsumerNameForm = 'consumer-name-form',
  ConsumerPhoneForm = 'consumer-phone-form',
  ConsumerPhoneValidation = 'consumer-phone-validation',
  ConsumerAssetSubtypeForm =  'consumer-asset-subtype-form',
  ConsumerDobForm = 'consumer-dob-form',
  ConsumerAddressForm = 'consumer-address-form',
  ConsumerEmailForm = 'consumer-email-form',
  ConsumerNetIncome = 'consumer-net-income-form',
  ConsumerSubmit = 'consumer-submit',
  ConsumerAutoSubmit = 'consumer-auto-submit'
}
