import { ActionType, createAction } from 'typesafe-actions'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { ConsumerEmailStepState } from '../types/consumer-email-step-state.interface'

export enum ConsumerEmailAction {
  GetConsumerEmail = 'ConsumerEmailStep/GetConsumerEmail',
  SaveConsumerEmail = 'ConsumerEmailStep/SaveConsumerEmail',
  SaveConsumerEmailSuccess = 'ConsumerEmailStep/SaveConsumerEmailSuccess',
  SaveConsumerEmailFailure = 'ConsumerEmailStep/SaveConsumerEmailFailure',
  ResetConsumerEmailState = 'ConsumerEmailStep/ResetConsumerEmail'
}

const saveConsumerEmail = createAction(
  ConsumerEmailAction.SaveConsumerEmail,
  (action) => (email: ConsumerEmailStepState['email']) => action(email)
)

const saveConsumerEmailSuccess = createAction(
  ConsumerEmailAction.SaveConsumerEmailSuccess,
  (action) => (email: ConsumerEmailStepState['email']) => action(email)
)

const saveConsumerEmailFailure = createAction(
  ConsumerEmailAction.SaveConsumerEmailFailure,
  (action) => (error: HttpError) => action(error)
)

const resetConsumerEmailState = createAction(
  ConsumerEmailAction.ResetConsumerEmailState
)

export const ConsumerEmailStepActions = {
  saveConsumerEmail,
  saveConsumerEmailSuccess,
  saveConsumerEmailFailure,
  resetConsumerEmailState
}

export type ConsumerEmailStepActionType = ActionType<
  typeof ConsumerEmailStepActions
>
