import { Epic } from 'redux-observable'
import { catchError, filter, mergeMap, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { AssetSubtypeActions } from './asset-subtype-selection.actions'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { Action } from '../../../../../store/root.actions'
import { PreQualifierAssetSubtypeService } from '../../../../../api/bff/services/pre-qualifier-asset-subtype.service'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { ConsumerSessionStore } from '../../consumer-session/store/consumer-session.store'
import { AssetSubtypeState } from '../types/asset-subtype-selection-state.interface'

const fetchAssetSubtypesEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(AssetSubtypeActions.fetchAssetSubtypes)),
    switchMap((action) =>
      new PreQualifierAssetSubtypeService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .fetchAssetSubtypes(
          action.payload.locale
        )
        .pipe(
          mergeMap(
            (assetSubtypes: AssetSubtypeState['assetSubtypes']) =>
              of(
                AssetSubtypeActions.fetchAssetSubtypesSuccess(
                  assetSubtypes
                )
              )
          ),
          catchError((err: HttpError) =>
            of(
              AssetSubtypeActions.fetchAssetSubtypesFailure(err)
            )
          )
        )
    )
  )

export const assetSubtypeEpics = [fetchAssetSubtypesEpic]
