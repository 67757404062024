import React from 'react'
import { ConsumerWorkflowStepType } from '../../../../../domain/consumer/consumer-workflow/consumer-workflow-step-type.enum'
import {
  ConsumerWorkflowStep,
  ConsumerWorkflowSteps
} from '../../../../../domain/consumer/consumer-workflow/consumer-workflow-step.interface'
import { ConsumerAddressStep } from '../modules/consumer-address-step/consumer-address-step.component'
import { ConsumerDobStep } from '../modules/consumer-dob-step/consumer-dob-step.component'
import { ConsumerEmailStep } from '../modules/consumer-email-step/consumer-email-step.component'
import { ConsumerNameStep } from '../modules/consumer-name-step/consumer-name-step.component'
import { ConsumerNetIncomeStep } from '../modules/consumer-net-income-step/consumer-net-income-step.component'
import { ConsumerPhoneNumberStep } from '../modules/consumer-phone-number-step/consumer-phone-number-step.component'
import { ConsumerPhoneValidationStep } from '../modules/consumer-phone-validation/consumer-phone-validation-step.component'
import { ConsumerWorkflowStepsStore } from '../modules/consumer-workflow-steps/store/consumer-workflow-steps.store'
import { ConsumerWorkflowStepProps } from '../types/consumer-workflow-step/consumer-workflow-step.props'
import { ConsumerSubmitStep } from '../modules/consumer-submit-step/consumer-submit-step.component'
import { history } from '../../../../../store/types/root-store.functions'
import { ConsumerRoute } from '../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { ConsumerReviewAssetStep } from '../modules/consumer-review-asset-step/consumer-review-asset-step.component'
import { ConsumerAutoSubmissionStep } from '../modules/consumer-auto-submission-step/consumer-auto-submission-step.component'
import { ConsumerAssetSubtypeStep } from '../modules/consumer-asset-subtype-step/consumer-asset-subtype-step.component'
import { ConsumerWorkflowStepService } from '../../../../../domain/consumer/consumer-workflow/consumer-workflow-step.service'

export class ConsumerWorkflowFactoryService {
  private consumerWorkflowStepMap: Record<
    ConsumerWorkflowStepType,
    (props: ConsumerWorkflowStepProps) => JSX.Element
  > = {
    [ConsumerWorkflowStepType.ConsumerReviewAsset]: ConsumerReviewAssetStep,
    [ConsumerWorkflowStepType.ConsumerNameForm]: ConsumerNameStep,
    [ConsumerWorkflowStepType.ConsumerPhoneForm]: ConsumerPhoneNumberStep,
    [ConsumerWorkflowStepType.ConsumerPhoneValidation]:
      ConsumerPhoneValidationStep,
    [ConsumerWorkflowStepType.ConsumerAssetSubtypeForm]: ConsumerAssetSubtypeStep,
    [ConsumerWorkflowStepType.ConsumerDobForm]: ConsumerDobStep,
    [ConsumerWorkflowStepType.ConsumerAddressForm]: ConsumerAddressStep,
    [ConsumerWorkflowStepType.ConsumerEmailForm]: ConsumerEmailStep,
    [ConsumerWorkflowStepType.ConsumerNetIncome]: ConsumerNetIncomeStep,
    [ConsumerWorkflowStepType.ConsumerSubmit]: ConsumerSubmitStep,
    [ConsumerWorkflowStepType.ConsumerAutoSubmit]: ConsumerAutoSubmissionStep
  }

  private consumerWorkflowStepService:ConsumerWorkflowStepService;

  constructor(workflowSteps: ConsumerWorkflowSteps) {
    this.consumerWorkflowStepService = new ConsumerWorkflowStepService(workflowSteps);
  }

  public getWorkflowStepComponent(step: ConsumerWorkflowStep) {
    const Component = this.consumerWorkflowStepMap[step.type]
    return Component ? (
      <Component
        step={step}
        goBack={this.goBack.bind(this)}
        onComplete={this.onComplete.bind(this)}
      />
    ) : null
  }

  private onComplete(step: ConsumerWorkflowStep) {

    const nextStep = this.consumerWorkflowStepService.findNextStep(step);

    if(!nextStep){
      history.push(ConsumerRoute.RouteResolver)
      return;
    }

    ConsumerWorkflowStepsStore.setActiveWorkflowStep(
      nextStep
    )
  }


  private goBack(step: ConsumerWorkflowStep) {
    const previousStep = this.consumerWorkflowStepService.findPreviousStep(step);

    if(!previousStep){
      history.push(ConsumerRoute.RouteResolver)
       return;
    }

    ConsumerWorkflowStepsStore.setActiveWorkflowStep(
      previousStep
    )
  }

}
