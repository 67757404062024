import { useEffect, useState } from 'react'
import { LookupAddress } from '../../../../../../../../../../../../../../../../domain/lookup-address/lookup-address.interface'
import { withShowIf } from '../../../../../../../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { LookupAddressListProps } from './lookup-address-list.props'
import { LookupAddressListStyles } from './lookup-address-list.styles'
import {
  areThereAddressLookupItems,
  getDropdownAddressLookupItems,
  getDropdownLoadingItems,
  getDropdownNoResultsItems
} from './types/lookup-address-list.functions'

export const LookupAddressList = withShowIf((props: LookupAddressListProps) => {
  const [dropdownItems, setDropdownItems] = useState<JSX.Element[] | null>(null)

  useEffect(() => {
    const lookupAddresses: LookupAddress[] | null = props.lookupAddresses

    if (props.lookupAddressesLoading || props.retrieveAddressLoading) {
      const items: JSX.Element[] = getDropdownLoadingItems()
      setDropdownItems(items)
    } else if (areThereAddressLookupItems(lookupAddresses)) {
      const items: JSX.Element[] = getDropdownAddressLookupItems(
        lookupAddresses,
        props.onLookupAddressItemClick,
        props.switchToManualAddressForm
      )
      setDropdownItems(items)
    } else if (Array.isArray(lookupAddresses)) {
      const items: JSX.Element[] = getDropdownNoResultsItems(
        props.switchToManualAddressForm
      )
      setDropdownItems(items)
    } else {
      setDropdownItems(null)
    }
  }, [
    props.lookupAddresses,
    props.lookupAddressesLoading,
    props.onLookupAddressItemClick,
    props.retrieveAddressLoading,
    props.switchToManualAddressForm
  ])

  if (dropdownItems === null) {
    return null
  }

  return (
    <div className={LookupAddressListStyles.container} {...props.uat}>
      {dropdownItems}
    </div>
  )
})
