import { Reducer } from 'redux'
import { createAssetSubtypeState } from '../types/asset-subtype-selection-state.functions'
import { AssetSubtypeState } from '../types/asset-subtype-selection-state.interface'
import {
  AssetSubtypeAction,
  AssetSubtypeActionType
} from './asset-subtype-selection.actions'

export const AssetSubtypeReducer: Reducer<
  AssetSubtypeState,
  AssetSubtypeActionType
> = (
  state: AssetSubtypeState = createAssetSubtypeState(),
  action: AssetSubtypeActionType
): AssetSubtypeState => {
  switch (action.type) {
    case AssetSubtypeAction.FetchAssetSubtypes:
      return {
        ...state,
        fetchError: null,
        loading: true
      }
    case AssetSubtypeAction.FetchAssetSubtypesFailure:
      return {
        ...state,
        fetchError: action.payload,
        loading: false
      }
    case AssetSubtypeAction.FetchAssetSubtypesSuccess:
      return {
        ...state,
        assetSubtypes: action.payload,
        fetchError: null,
        loading: false
      }
    case AssetSubtypeAction.SetSelectedAssetSubtypeId:
      return {
        ...state,
        selectedAssetSubtypeId: action.payload
      }
    case AssetSubtypeAction.ResetAssetSubtypeState:
      return createAssetSubtypeState()
    default:
      return state
  }
}
