import { Epic } from 'redux-observable'
import { filter, map } from 'rxjs'
import { Action, isActionOf } from 'typesafe-actions'
import { push } from 'connected-react-router'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerSessionActions } from '../../../../consumer-session/store/consumer-session.actions'
import { ConsumerWorkflowStepsActions } from './consumer-workflow-steps.actions'
import { generateConsumerWorkflowStepRoute } from '../../../types/consumer-workflow-route/consumer-workflow-route.functions'
import { ConsumerProfileStore } from '../../../../consumer-profile/store/consumer-profile.store'

const updateConsumerWorkflowSessionStepsEpic: Epic<
  Action,
  Action,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(ConsumerSessionActions.fetchConsumerSessionSuccess)),
    map((action) =>
      ConsumerWorkflowStepsActions.setWorkflowSteps(
        action.payload.workflow.steps
      )
    )
  )

const navigateToActiveWorkflowStepEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerWorkflowStepsActions.setActiveWorkflowStep)),
    map((action) =>
      push(
        generateConsumerWorkflowStepRoute(
          action.payload.type,
          ConsumerProfileStore.getVendorId(state$.value)
        )
      )
    )
  )

export const consumerWorkflowStepsEpics = [
  updateConsumerWorkflowSessionStepsEpic,
  navigateToActiveWorkflowStepEpic
]
