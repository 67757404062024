import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { AssetSubtype } from '../../../../../domain/asset-subtype/asset-subtype.interface'
import { Language } from '../../../../../shared/types/language.enum'
import { RootStore } from '../../../../../store/root.store'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { AssetSubtypeState } from '../types/asset-subtype-selection-state.interface'
import { AssetSubtypeActions } from './asset-subtype-selection.actions'

const assetSubtypeState = (
  rootState: RootState
): AssetSubtypeState =>
  rootState.consumerState.assetSubtypeState

const setSelectedAssetSubtypeId = (assetSubtype: AssetSubtype['id'] | null) =>
  RootStore.dispatch(
    AssetSubtypeActions.setSelectedAssetSubtypeId(assetSubtype)
  )

const getLoading: Selector<
  RootState,
  AssetSubtypeState['loading']
> = createSelector(
  assetSubtypeState,
  (state: AssetSubtypeState) => state.loading
)

const getSelectedAssetSubtype: Selector<
  RootState,
  AssetSubtypeState['selectedAssetSubtypeId']
> = createSelector(
  assetSubtypeState,
  (state: AssetSubtypeState) => state.selectedAssetSubtypeId
)

const getAssetSubtypes: Selector<
  RootState,
  AssetSubtypeState['assetSubtypes']
> = createSelector(
  assetSubtypeState,
  (state: AssetSubtypeState) => state.assetSubtypes
)

const getFetchError: Selector<
  RootState,
  AssetSubtypeState['fetchError']
> = createSelector(
  assetSubtypeState,
  (state: AssetSubtypeState) => state.fetchError
)

const fetchAssetSubtypes = (
  locale: Language
) =>
  RootStore.dispatch(
    AssetSubtypeActions.fetchAssetSubtypes(locale)
  )

const reset = () =>
  RootStore.dispatch(
    AssetSubtypeActions.resetAssetSubtypeState()
  )

export const AssetSubtypeStore = {
  getSelectedAssetSubtype,
  setSelectedAssetSubtypeId,
  getAssetSubtypes,
  fetchAssetSubtypes,
  getFetchError,
  getLoading,
  reset
}
