import { ChangeEvent } from 'react'
import { DateOfBirthInputMask } from './date-of-birth-input-mask.interface'
import { ExtractDateFromDobText } from './extract-date-from-dob-text.interface'

export const dateOfBirthInputMask = (
  dobValue: string
): DateOfBirthInputMask => {
  const { dayOfBirth, monthOfBirth, yearOfBirth } =
    extractDateFromDobText(dobValue)

  const inputMask: string[] = []
  let moveCaretForwardBy: 1 | 0 = 0

  if (dayOfBirth.length > 0) {
    inputMask.push(dayOfBirth)
    moveCaretForwardBy = 0
  }

  if (dayOfBirth.length >= 2) {
    inputMask.push('/')
    moveCaretForwardBy = 1
  }

  if (monthOfBirth.length > 0) {
    inputMask.push(monthOfBirth)
    moveCaretForwardBy = 0
  }

  if (monthOfBirth.length >= 2) {
    inputMask.push('/')
    moveCaretForwardBy = 1
  }

  if (yearOfBirth.length > 0) {
    inputMask.push(yearOfBirth)
    moveCaretForwardBy = 0
  }

  return {
    inputMask: inputMask.join(''),
    moveCaretForwardBy
  }
}

const extractDateFromDobText = (dobText: string): ExtractDateFromDobText => {
  const dayOfBirth: string = dobText.substring(0, 2)
  const monthOfBirth: string = dobText.substring(2, 4)
  const yearOfBirth: string = dobText.substring(4, 8)

  return {
    dayOfBirth,
    monthOfBirth,
    yearOfBirth
  }
}

export const removeDobInputMaskFormat = (dobInputMask: string): string =>
  dobInputMask
    .replace(new RegExp('/', 'g'), () => '')
    .replace(new RegExp(' ', 'g'), () => '')
    .replace(new RegExp('\\.+', 'g'), () => '')

export const stopCaretJumpingToEndOfLine = (
  moveCaretForwardBy: number,
  event: ChangeEvent<HTMLInputElement>
): void => {
  const caretPosition: number =
    Number(event.target.selectionEnd) + moveCaretForwardBy
  const element: HTMLInputElement = event.target

  window.requestAnimationFrame(() => {
    element.selectionStart = caretPosition
    element.selectionEnd = caretPosition
  })
}
