import { map } from 'rxjs'
import { ConsumerSession } from '../../../domain/consumer/consumer-session/consumer-session.interface'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'
import { ConsumerSessionDtoMapper } from '../dto/consumer-session/consumer-session-dto-mapper'

export class ConsumerSessionService extends BffService {
  public getConsumerSession(): FetchResponse<ConsumerSession> {
    return super
      .createAuthorizedRequest<any>({
        method: FetchMethod.GET,
        url: `${this.bffUrl}/v1/consumer-profile`
      })
      .pipe(
        map((result) => new ConsumerSessionDtoMapper(result).toDomainObject())
      )
  }
}
