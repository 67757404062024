export const OfferListStyles = {
  offersContainer:
    'flex flex-row flex-wrap space-x-5 space-y-5 justify-evenly mx-24 my-10 p-0',
  viewContainer: 'flex flex-col justify-center mb-5 w-full',
  backgroundContainer: 'bg-offersBackground py-10 w-full',
  title: 'text-center font-bold w-full text-blue450 text-28px',
  disclaimer: 'w-full text-center',
  buttonWrapper: 'flex flex-col justify-center mt-10 space-y-4 mx-10 ms:mx-auto',
  buttonContainer: 'text-xl sm:text-2xl self-center'
}
