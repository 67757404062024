import React from 'react'
import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { MerchantSelectionViewReferenceCodeMessages } from './merchant-selection-view-reference-code.messages'
import { MerchantSelectionViewReferenceCodeProps } from './merchant-selection-view-reference-code.props'
import { MerchantSelectionViewReferenceCodeStyles } from './merchant-selection-view-reference-code.styles'
import { MerchantSelectionViewReferenceCodeUat } from './merchant-selection-view-reference-code.uat'

export const MerchantSelectionViewReferenceCode = (
  props: MerchantSelectionViewReferenceCodeProps
) => {
  return (
    <div className={MerchantSelectionViewReferenceCodeStyles.container(props.marginBottom)}>
      <Paragraph className={MerchantSelectionViewReferenceCodeStyles.label}>
        <FormattedMessage
          {...MerchantSelectionViewReferenceCodeMessages.label}
        />
      </Paragraph>
      <Paragraph
        className={MerchantSelectionViewReferenceCodeStyles.referenceCode}
        uat={uatId(MerchantSelectionViewReferenceCodeUat.referenceCode)}
      >
        {props.referenceCode}
      </Paragraph>
    </div>
  )
}
