export const ConsumerEmailFormMessages = {
  emailLabel: {
    id: 'Pages.ConsumerEmail.Form.emailLabel',
    description: 'Email'
  },
  submitButton: {
    id: 'Pages.ConsumerEmail.Form.submitButton',
    description: 'Next'
  }
}
