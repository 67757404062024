import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { FormattedMessage } from 'react-intl'
import { NoOffersMessages } from './no-offers.messages'

export const NoOffers = () => {
  return (
    <Paragraph>
      <FormattedMessage {...NoOffersMessages.noOffers} />
    </Paragraph>
  )
}
