import * as yup from 'yup'
import { FormFieldSchema } from './form-field-schema'
import { NumberSchemaOptions } from './types/form-field-schema-options/number-schema-options.interface'
import { FormFieldSchemaType } from './types/form-field-schema-type.enum'

export class NumberFieldSchema extends FormFieldSchema {
  protected schema = yup.number()
  protected type: FormFieldSchemaType = FormFieldSchemaType.NUMBER
  private min: number | undefined
  private max: number | undefined

  public getSchemaOptions(): NumberSchemaOptions {
    return {
      name: this.name,
      type: this.type,
      min: this.min,
      max: this.max,
      required: this.required
    }
  }

  public setMin(min: number): this {
    this.min = min
    this.schema = this.schema.min(min)
    return this
  }
  public setMax(max: number): this {
    this.max = max
    this.schema = this.schema.max(max)
    return this
  }
}
