import React from 'react'
import { useIntl } from 'react-intl'
import { uatId } from '../../../../../../../../../../uat/uat-id.function'
import { BannerTextType } from '../../../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { BannerText } from '../../../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { withShowIf } from '../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { DobBannerErrorMessageMessages } from './dob-banner-error-message.messages'
import { DobBannerErrorMessageStyles } from './dob-banner-error-message.styles'
import { DobBannerErrorMessageUat } from './dob-banner-error-message.uat'

export const DobBannerErrorMessage = withShowIf(() => {
  const intl = useIntl()

  return (
    <BannerText
      cssContainerClasses={DobBannerErrorMessageStyles.errorMessage}
      type={BannerTextType.DANGER}
      uat={uatId(DobBannerErrorMessageUat.errorMessage)}
      boldText={intl.formatMessage(
        DobBannerErrorMessageMessages.validationErrorMessage
      )}
    />
  )
})
