import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Paragraph } from "../../../ui-library/components/paragraph/paragraph.component";
import { withShowIf } from "../../../ui-library/helpers/with-show-if/with-show-if.component";
import { SpinnerIcon } from "../../icons/spinner/spinner.icon";
import { LoaderWithMessagesProps } from "./loader-with-messages.props";
import { LoaderWithMessagesStyle } from "./loader-with-messages.style";

export const LoaderWithMessages = withShowIf((props: LoaderWithMessagesProps) => {
  const messages = props.messages;
  const timeOutPeriod = props.timeoutPeriodInMillis ?? 15000;
  const rotateInterval = Math.floor(timeOutPeriod / messages.length);

  const [messageIndex, setMessageIndex] = useState(0);
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      stopIntervalTimer(interval)
      setNextMessageAndTimeElapsed(rotateInterval)
    }, rotateInterval);
    
    return () => cleanUpIntervalOnUnmount(interval);
  }, [props, secondsElapsed, setSecondsElapsed, messageIndex, setMessageIndex]);

  const stopIntervalTimer = (
    interval: ReturnType<typeof setTimeout>
  ) => {
    if (secondsElapsed >= timeOutPeriod) {
      clearInterval(interval);
      if (props.timedOut) {
        props.timedOut();
      }
      return;
    }
  }

  const setNextMessageAndTimeElapsed = (rotateInterval: number) => {
    const index =
        messageIndex >= messages.length - 1 ? messageIndex : messageIndex + 1;

      setSecondsElapsed(secondsElapsed + rotateInterval);
      setMessageIndex(index);
  }

  const cleanUpIntervalOnUnmount = (interval: ReturnType<typeof setTimeout>) => clearInterval(interval)

  return (
    <>
      <SpinnerIcon 
        size={props.spinnerSize} 
        cssClasses={LoaderWithMessagesStyle.spinner}
      />
      <Paragraph className={LoaderWithMessagesStyle.label}>
        <FormattedMessage {...messages[messageIndex].message} />
      </Paragraph>
    </>
  )
})