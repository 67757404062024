import { memo, useState, useEffect, ChangeEvent } from 'react'
import { SliderProps } from './slider.props'
import { sliderContainerStyle, SliderStyles } from './slider.styles'

export const Slider = memo((props: SliderProps) => {
  const [sliderVal, setSliderVal] = useState(0)
  const [inputState, setInputState] = useState<string | null>(null)

  useEffect(() => {
    setSliderVal(props.value)
  }, [props.value])

  const changeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setSliderVal(parseInt(e.target.value))
  }

  const handleKeyMouseUp = () => {
    setInputState('up')
  }

  const handleKeyMouseDown = () => {
    setInputState('down')
  }

  useEffect(() => {
    props.onChange(sliderVal)
  }, [inputState, props, sliderVal])

  return (
    <div className={sliderContainerStyle(props.cssContainerClasses)}>
      <input
        className={SliderStyles.slider}
        type="range"
        id={props.name}
        value={sliderVal}
        min={props.min}
        max={props.max}
        step={props.step}
        name={props.name}
        onChange={changeCallback}
        onMouseDown={handleKeyMouseDown}
        onMouseUp={handleKeyMouseUp}
        onKeyUp={handleKeyMouseUp}
        onKeyDown={handleKeyMouseDown}
        tabIndex={0}
        {...props.uat}
      />
    </div>
  )
})
