import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../../../store/root.store'
import { ConsumerPostSubmissionOffersActions } from './consumer-post-submission-offers.actions'
import { Language } from '../../../../../../../shared/types/language.enum'
import { Offer } from '../types/offer.interface'
import { ConsumerPostSubmissionOffersState } from '../types/consumer-post-submission-offers-state.interface'

const getConsumerPostSubmissionOfferState = (
  rootState: RootState
): ConsumerPostSubmissionOffersState =>
  rootState.consumerState.consumerPostSubmissionState
    .consumerPostSubmissionOffersState

const getLoading: Selector<
  RootState,
  ConsumerPostSubmissionOffersState['loading']
> = createSelector(
  getConsumerPostSubmissionOfferState,
  (state: ConsumerPostSubmissionOffersState) => state.loading
)

const fetchOffers = (locale: Language) =>
  RootStore.dispatch(ConsumerPostSubmissionOffersActions.fetchOffers(locale))

const getOffers: Selector<
  RootState,
  ConsumerPostSubmissionOffersState['offers']
> = createSelector(
  getConsumerPostSubmissionOfferState,
  (state: ConsumerPostSubmissionOffersState) => state.offers
)

const saveLoanOfferPageViewed = () =>
  RootStore.dispatch(
    ConsumerPostSubmissionOffersActions.saveLoanOfferPageViewed()
  )

const updateLoanDetails = (offer: Offer) =>
  RootStore.dispatch(
    ConsumerPostSubmissionOffersActions.updateLoanDetails(offer)
  )

const getFetchOffersError: Selector<
  RootState,
  ConsumerPostSubmissionOffersState['fetchError']
> = createSelector(
  getConsumerPostSubmissionOfferState,
  (state: ConsumerPostSubmissionOffersState) => state.fetchError
)

export const ConsumerPostSubmissionOffersStore = {
  getLoading,
  fetchOffers,
  getOffers,
  saveLoanOfferPageViewed,
  updateLoanDetails,
  getFetchOffersError
}
