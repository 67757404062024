export const MerchantSelectionViewMessages = {
  title: {
    id: 'Pages.MerchantSelection.Page.title',
    description: 'Merchant selection page title'
  },
  description: {
    id: 'Pages.MerchantSelection.Page.description',
    description: 'Merchant selection page description'
  },
  merchantsLocatorTitle: {
    id: 'Pages.MerchantSelection.Page.merchantsLocatorTitle',
    description: 'Select merchant page Merchants Locator Title'
  },
  selectAssetSubtypeTitle: {
    id: 'Pages.MerchantSelection.Page.selectAssetSubtypeTitle',
    description: 'Select asset subtype title'
  }
}
