import { OffersProps } from './offers.props'
import { NoOffers } from '../no-offers/no-offers.component'
import { Offer } from '../offer/offer.component'

export const Offers = (props: OffersProps) => {
  if (!props.offers) {
    return <NoOffers />
  }

  return (
    <>
      {props.offers.map((offer) => (
        <Offer key={offer.id} offer={offer} onSelect={props.onSelectedOffer} />
      ))}
    </>
  )
}
