import { ConsumerAddress } from '../../../../../../../../../../../../domain/consumer/consumer-profile/consumer-address.interface'
import { RetrieveAddress } from '../../../../../../../../../../../../domain/lookup-address/retrieve-address.interface'

export const buildConsumerAddressFromRetrieveAddress = (
  address: RetrieveAddress
): ConsumerAddress => ({
  unit: address.apt || null,
  city: address.city,
  postalCode: address.postalCode,
  province: address.province,
  street: address.address
})
