
import { HttpError } from '../../types/http-error.interface';
import { InputValidationErrorCode } from '../../validation/input-validation-error-code.enum';

export const doesErrorMessageForInputValidationExist = (
  error: HttpError | null,
  inputValidationErrorCode: InputValidationErrorCode,
): boolean => {
  if (error !== null) {
    return error.message.split(' ').some(errorMessage =>
      errorMessage.includes(inputValidationErrorCode),
    )
  }

  return false;
};
