import { ConsumerAddressService } from '../../../../../../../api/bff/services/consumer-address.service'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'

export class ConsumerAddressServiceFactory {
  constructor() {}

  create(value: RootState) {
    return new ConsumerAddressService(
      ConsumerSessionStore.getSessionToken(value)
    )
  }
}
