import { ModalLibraryMobileStyles } from '../../../libraries/modal/modal-library.styles'

export const MoreInfoStyles = {
    modal: 'w-3/4 shadow-md bg-white rounded-md max-w-md p-9 flex flex-col align-middle md:p-10',
    modalText: 'text-16px mb-5 grow',
    button: 'self-start',
    icon: 'h-6 w-6 text-blue300 ml-2',
}

export const MoreInfoMobileModalStyles = {...ModalLibraryMobileStyles, ...{
    content: {
        height: 'auto',
    }
}}