import { ChangeEvent, useRef, useState } from 'react'
import { isPositiveInteger } from '../../../../helpers/is-integer/is-integer.function'
import { InputField } from '../../input-field.component'
import { PhoneInputProps } from './phone-input.props'
import { PhoneInputStyles } from './phone-input.styles'
import { formatToPhoneNumber } from './types/format-to-phone-number.function'
import { extractNumberFromPhoneFormat } from './types/phone-input.functions'

export const PhoneInput = (props: PhoneInputProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>(props.value)
  const phoneNumberValueRef = useRef(props.value)

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = event.target.value
    const numbers: string = extractNumberFromPhoneFormat(inputValue)

    if (!isPositiveInteger(numbers)) {
      return
    }

    const isUserInsertingNextNumberToField: boolean =
      inputValue.length > phoneNumberValueRef.current.length

    if (isUserInsertingNextNumberToField) {
      setPhoneNumber(formatToPhoneNumber(numbers))
    } else {
      setPhoneNumber(inputValue)
    }

    phoneNumberValueRef.current = inputValue
    props.onChange(numbers)
  }

  return (
    <InputField label={props.label} name={props.name}>
      <input
        {...props.uat}
        autoFocus={props.autoFocus}
        className={PhoneInputStyles.field}
        name={props.name}
        maxLength={14}
        onChange={onChange}
        value={phoneNumber}
        type="tel"
        placeholder="(123) 456-7890"
      />
    </InputField>
  )
}
