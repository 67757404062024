import { ConsumerWorkflowFactoryService } from './services/consumer-workflow-factory.service'
import { generateConsumerWorkflowStepRoute } from './types/consumer-workflow-route/consumer-workflow-route.functions'
import {
  ConsumerWorkflowStep,
  ConsumerWorkflowSteps
} from '../../../../domain/consumer/consumer-workflow/consumer-workflow-step.interface'
import { ConsumerWorkflowStepStateType } from './types/consumer-workflow-step/consumer-workflow-step-state.type'
import { PreQualifierAssetType } from '../../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type.interface'
import { CoreRoute } from '../../../../libraries/router-library/components/core-route/core-route.component'

export function createConsumerWorkflowRoutes(
  workflowSteps: ConsumerWorkflowSteps,
  vendorId: string
): JSX.Element[] {
  const consumerWorkflowFactoryService = new ConsumerWorkflowFactoryService(
    workflowSteps
  )
  return workflowSteps.map((step) => (
    <CoreRoute
      key={step.order}
      path={generateConsumerWorkflowStepRoute(step.type, vendorId)}
      exact
    >
      {consumerWorkflowFactoryService.getWorkflowStepComponent(step)}
    </CoreRoute>
  ))
}

export function findActiveWorkflowStep(
  workflowSteps: ConsumerWorkflowSteps
): ConsumerWorkflowStep {
  const sessionWorkflowStep = workflowSteps.findIndex(
    (step) => step.state === ConsumerWorkflowStepStateType.incomplete
  )

  return workflowSteps[Math.max(0, sessionWorkflowStep)]
}

export function getConsumerAssetType(
  consumerAssetTypeId: number | null,
  assetType: PreQualifierAssetType[]
): PreQualifierAssetType | undefined {
  return assetType.find((asset) => asset.assetTypeId === consumerAssetTypeId)
}
