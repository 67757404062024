import { components } from 'react-select'
import { UatDataset } from '../../../../uat/types/uat-dataset.interface'
import { DropdownLibraryMenuListItemProps } from './dropdown-library-menu-list-item.props'

export const DropdownLibraryMenuListItem =
  (uat?: UatDataset) => (props: DropdownLibraryMenuListItemProps) => {
    return (
      <components.Option
        {...props}
        innerProps={
          { ...props.innerProps, ...uat, 'data-value': props.data.value } as any
        }
      />
    )
  }
