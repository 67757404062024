import { getParamFromUrl, hasParam } from './url-search-params.functions';

export const getRefId = (url:string): string | null =>{
  return getParamFromUrl(url, 'ref_id');
}

export const hasRefId = (url:string): boolean =>{
  return hasParam(url, 'ref_id');

}
