import { NumberInput } from '../../../../../../../../../../ui-library/components/input-field/components/number-input/number-input.component'
import { withShowIf } from '../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { SecurityQuestionsNumberInputProps } from './security-questions-number-input.props'

export const SecurityQuestionsNumberInput = withShowIf(
  (props: SecurityQuestionsNumberInputProps) => {
    return (
      <NumberInput
        label={props.label}
        placeholder={props.placeholder}
        name="security-questions-number-input"
        onChange={props.onChange}
        value={props.value}
        maxLength={4}
        labelClassName={props.labelClassName}
        inputClassName={props.inputClassName}
      />
    )
  }
)
