export const MerchantSelectSuccessViewMessages = {
  instructions: {
    id: 'Pages.MerchantSelectSuccess.MerchantView.instructions',
    description:
      'This is the placeholder message that will serve as instructions to the applicant.  Merchant will reach out to you shortly. For any questions see contact below.'
  },
  preApprovalAccessCodeLabel: {
    id: 'Pages.MerchantSelectSuccess.MerchantView.preApprovalAccessCodeLabel',
    description: 'Pre-Approval Access Code'
  },
  applicationSentTo: {
    id: 'Pages.MerchantSelectSuccess.MerchantView.applicationSentTo',
    description: 'Application sent to'
  },
  navigateBack: {
    id: 'Pages.MerchantSelectSuccess.MerchantView.navigateBack',
    description: 'Select Another Merchant'
  },
  backBtnText: {
    id: 'Pages.MerchantSelectSuccess.MerchantView.navigateBackToApproval',
    description: 'Back to approval'
  }
}
