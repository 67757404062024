import clsx from 'clsx'
import { ExclamationIcon } from '../../icons/exclamation/exclamation.icon'
import { ValidationErrorMessageProps } from './validation-error-message.props'
import { ValidationErrorMessageStyles } from './validation-error-message.styles'

export const ValidationErrorMessage = (props: ValidationErrorMessageProps) => (
  <div
    className={clsx(
      ValidationErrorMessageStyles.container,
      props.cssContainerClasses
    )}
    {...props.uat}
  >
    <ExclamationIcon />
    <span className={ValidationErrorMessageStyles.message}>
      {props.message}
    </span>
  </div>
)
