export const ConsumerEmailViewMessages = {
  header: {
    id: 'Pages.ConsumerEmail.View.header',
    description: 'Consumer Date Of Birth Page Header'
  },
  sendCopy: {
    id: 'Pages.ConsumerEmail.View.sendCopy',
    description: 'Consumer Email Address Page Send Copy message'
  },
  askEmail: {
    id: 'Pages.ConsumerEmail.View.askEmail',
    description: 'Consumer Email Address Page Ask for email message'
  }
}
