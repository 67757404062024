import { combineReducers } from 'redux'
import { appReducer } from '../modules/app/store/app.reducer'
import { consumerReducer } from '../modules/consumer/store/consumer.reducer'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    appState: appReducer,
    consumerState: consumerReducer
  })
