import { Epic } from 'redux-observable'
import { filter, map } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { Action } from '../../../../../../../store/root.actions'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerProfileActions } from '../../../../consumer-profile/store/consumer-profile.actions'
import { ConsumerPhoneNumberStepActions } from './consumer-phone-number-step.actions'

const createConsumerProfileAttemptEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.createConsumerProfile)),
    map(() => ConsumerPhoneNumberStepActions.saveConsumerPhoneNumber())
  )

const createConsumerProfileFailureEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.createConsumerProfileFailure)),
    map((action) =>
      ConsumerPhoneNumberStepActions.saveConsumerPhoneNumberFailure(
        action.payload
      )
    )
  )

const createConsumerProfileSuccessEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.createConsumerProfileSuccess)),
    map(() => ConsumerPhoneNumberStepActions.saveConsumerPhoneNumberSuccess())
  )

export const consumerPhoneNumberStepEpics = [
  createConsumerProfileAttemptEpic,
  createConsumerProfileSuccessEpic,
  createConsumerProfileFailureEpic
]
