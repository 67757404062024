import { FormattedMessage } from 'react-intl'
import { CloseIcon } from '../../../../../../../../../../shared/icons/close/close.icon'
import { uatId } from '../../../../../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { MerchantSelectionConfirmDialogErrorViewMessages } from './merchant-selection-confirm-dialog-error-view.messages'
import { MerchantSelectionConfirmDialogErrorViewProps } from './merchant-selection-confirm-dialog-error-view.props'
import { MerchantSelectionConfirmDialogViewErrorStyles } from './merchant-selection-confirm-dialog-error-view.styles'
import { MerchantSelectionConfirmDialogErrorViewUat } from './merchant-selection-confirm-dialog-error-view.uat'

export const MerchantSelectionConfirmDialogErrorView = withShowIf(
  (props: MerchantSelectionConfirmDialogErrorViewProps) => {
    return (
      <>
        <div
          className={
            MerchantSelectionConfirmDialogViewErrorStyles.closeIconContainer
          }
        >
          <CloseIcon
            onClick={props.onClose}
            uat={uatId(MerchantSelectionConfirmDialogErrorViewUat.closeIcon)}
            className={MerchantSelectionConfirmDialogViewErrorStyles.closeIcon}
          />
        </div>
        <Paragraph
          className={MerchantSelectionConfirmDialogViewErrorStyles.title}
          uat={uatId(MerchantSelectionConfirmDialogErrorViewUat.title)}
        >
          <FormattedMessage
            {...MerchantSelectionConfirmDialogErrorViewMessages.title}
          />
        </Paragraph>
        <Paragraph
          className={MerchantSelectionConfirmDialogViewErrorStyles.description}
          uat={uatId(MerchantSelectionConfirmDialogErrorViewUat.description)}
        >
          <FormattedMessage
            {...MerchantSelectionConfirmDialogErrorViewMessages.description}
          />
        </Paragraph>
      </>
    )
  }
)
