import { useIntl } from 'react-intl'
import { TextInput } from '../../../../../../../../ui-library/components/input-field/components/text-input/text-input.component'
import { MerchantSelectionFormMessages } from './merchant-selection-form.messages'
import { MerchantSelectionFormProps } from './merchant-selection-form.props'
import { useSelector } from 'react-redux'
import { MerchantSelectionStore } from '../../../../store/merchant-selection.store'
import { ChangeEvent, ReactNode, useMemo, useState } from 'react'
import { CloseIcon } from '../../../../../../../../shared/icons/close/close.icon'
import { MerchantSelectionFormStyles } from './merchant-selection-form.styles'
import { SearchIcon } from '../../../../../../../../shared/icons/search-icon/search-icon.icon'
import { Keyboard } from '../../../../../../../../ui-library/components/input-field/components/types/keyboard.enum'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { MerchantSelectionFormUat } from './merchant-selection-form.uat'
import { ShowIfFragment } from '../../../../../../../../ui-library/components/show-if/show-if-fragment.component'

export const MerchantSelectionForm = (props: MerchantSelectionFormProps) => {
  const intl = useIntl()
  const form = useSelector(MerchantSelectionStore.getMerchantSelectionForm);

  const [merchantName, setMerchantName] = useState<string>(
    form.merchantName ?? ''
  )

  const onChangeMerchantName = (event: ChangeEvent<HTMLInputElement>): void =>
    setMerchantName(event.target.value)

  const onClearMerchantName = (): void => {
    setMerchantName('')
    MerchantSelectionStore.resetMerchants();
    MerchantSelectionStore.resetMerchantSelectionForm();
    MerchantSelectionStore.fetchMerchants(1, props.selectedAssetSubtype, '')
  }

  const onMerchantNameSearch = () => {
    MerchantSelectionStore.resetMerchants();
    MerchantSelectionStore.fetchMerchants(1, props.selectedAssetSubtype, merchantName);
  }

  const renderAdornment = useMemo((): ReactNode => {
    if (form.merchantName) {
      return <CloseIcon onClick={onClearMerchantName} className={MerchantSelectionFormStyles.icon} />;
    } else {
      return <SearchIcon onClick={onMerchantNameSearch} className={MerchantSelectionFormStyles.icon} />;
    }
  }, [form.merchantName, merchantName]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keyboard.Enter) {
      onMerchantNameSearch()
    }
  };
  
  return (
    <div className={MerchantSelectionFormStyles.container}>
      <ShowIfFragment showIf={props.merchantsAvailableToSelect || Boolean(merchantName)}>
        <TextInput
          name={'merchantName'}
          label=''
          placeholder={intl.formatMessage(
            MerchantSelectionFormMessages.merchantNamePlaceholder
          )}
          icon={renderAdornment}
          value={merchantName}
          maxLength={255}
          onChange={onChangeMerchantName}
          onKeyUp={handleKeyPress}
          embeddedIconEnd={true}
          disabled={!props.merchantsAvailableToSelect && !Boolean(merchantName)}
          uat={uatId(MerchantSelectionFormUat.merchantNameInput)}
        />
      </ShowIfFragment>
    </div>
  )
}
