import { FormattedMessage } from 'react-intl'
import { Paragraph } from '../../../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { MerchantInfoCard } from '../../../../../merchant-info-card/merchant-info-card.component'
import { MerchantSuccessFooterMessages } from './merchant-success-footer.messages'
import { MerchantSuccessFooterProps } from './merchant-success-footer.props'
import { MerchantSuccessFooterStyles } from './merchant-success-footer.styles'

export const MerchantSuccessFooter = (props: MerchantSuccessFooterProps) => {
  return (
    <div className={MerchantSuccessFooterStyles.footer}>
      <Paragraph className={MerchantSuccessFooterStyles.title}>
        <FormattedMessage {...MerchantSuccessFooterMessages.title} />
      </Paragraph>
      <div className={MerchantSuccessFooterStyles.cardContainer}>
        <MerchantInfoCard merchant={props.merchant} />
      </div>
    </div>
  )
}
