import { Route } from 'react-router-dom'
import { CoreRouteProps } from './core-route.props'
import { hideIntercom } from '../../../../intercom/intercom';

export const CoreRoute = (props: CoreRouteProps) => {
  hideIntercom(Boolean(props.disableIntercom));

  return (
    <Route key={props.key} path={props.path} exact={props.exact}>
      {props.children}
    </Route>
  )
}
