import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { withShowIf } from '../../../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerProfileStore } from '../../../../../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerAutoAddressStore } from '../../store/consumer-auto-address.store'
import { ConsumerAutoAddressState } from '../../types/consumer-auto-address-state.interface'
import { ConsumerAutoAddressForm } from '../consumer-auto-address-form/consumer-auto-address-form.component'
import { buildConsumerAddressFromRetrieveAddress } from './consumer-auto-address-view.functions'
import { ConsumerAutoAddressViewProps } from './consumer-auto-address-view.props'
import { ConsumerAddressViewError } from '../../../../components/consumer-address-view-error/consumer-address-view-error.component'
import { doesErrorMessageForInputValidationExist } from '../../../../../../../../../../../../shared/helpers/error-message-input-validation/error-message-input-validation.util'
import { InputValidationErrorCode } from '../../../../../../../../../../../../shared/validation/input-validation-error-code.enum'

export const ConsumerAutoAddressView = withShowIf(
  (props: ConsumerAutoAddressViewProps) => {
    const consumerAddress = useSelector(ConsumerProfileStore.getConsumerAddress)
    const retrieveAddress: ConsumerAutoAddressState['retrieveAddress'] =
      useSelector(ConsumerAutoAddressStore.getRetrieveAddress)

    const isWorkflowStepComplete: ConsumerAutoAddressState['isWorkflowStepComplete'] =
      useSelector(ConsumerAutoAddressStore.getIsWorkflowStepComplete)

    useEffect(() => {
      return () => {
        ConsumerAutoAddressStore.reset()
      }
    }, [])

    useEffect(() => {
      if (isWorkflowStepComplete) {
        if (retrieveAddress) {
          ConsumerProfileStore.setConsumerAddress(
            buildConsumerAddressFromRetrieveAddress(retrieveAddress)
          )
        }
        props.onComplete()
      }
    }, [isWorkflowStepComplete, retrieveAddress, props])

    const onSubmit = (): void => {
      if (retrieveAddress !== null) {
        ConsumerAutoAddressStore.saveAddress(
          buildConsumerAddressFromRetrieveAddress(retrieveAddress)
        )
      } else if (consumerAddress) {
        ConsumerAutoAddressStore.skipSaveAddress()
      }
    }

    const saveAddressFailure = useSelector(ConsumerAutoAddressStore.getSaveAddressFailure);

    const showNonPhysicalAddressError = useMemo(() => {
      return doesErrorMessageForInputValidationExist(
        saveAddressFailure,
        InputValidationErrorCode.NonPhysicalAddress,
      );
    }, [saveAddressFailure]);
    
    return (
      <>
        <ConsumerAutoAddressForm
          switchToManualAddressForm={props.switchToManualAddressForm}
          onComplete={props.onComplete}
          onSubmit={onSubmit}
          consumerAddress={consumerAddress}
          lookupAddresses={useSelector(
            ConsumerAutoAddressStore.getLookupAddresses
          )}
          lookupAddressesLoading={useSelector(
            ConsumerAutoAddressStore.getLookupAddressesLoading
          )}
          retrieveAddress={retrieveAddress}
          retrieveAddressLoading={useSelector(
            ConsumerAutoAddressStore.getRetrieveAddressLoading
          )}
          saveAddressLoading={useSelector(
            ConsumerAutoAddressStore.getSaveAddressLoading
          )}
          showNonPhysicalAddressError={showNonPhysicalAddressError}
        />

        <ConsumerAddressViewError 
          showIf={showNonPhysicalAddressError}
        />
      </>
    )
  }
)
