import { useEffect, useMemo, useRef, useState } from 'react'
import Map, { MapRef, ViewStateChangeEvent } from 'react-map-gl'
import { MapLibraryProps } from './map-library.props'
import { MapLibraryMarker } from './components/map-library-marker/map-library-marker.component'
import { FocalPointMarker } from './components/focal-point-marker/focal-point-marker.component'
import { MapMarker } from './types/map-place.interfaces'
import { MapLibraryMarkerPopup } from './components/map-library-marker-popup/map-library-marker-popup.component'
import { getMapBounds } from './map-library.functions'
import { useIsMobile } from '../../ui-library/hooks/use-is-mobile-hook/use-is-mobile-hook'

const mapboxgl = require('mapbox-gl')
mapboxgl.workerClass = require('mapbox-gl/dist/mapbox-gl-csp-worker').default

export const MapLibrary = (props: MapLibraryProps) => {
  const isMobile = useIsMobile();
  const [lng, setLng] = useState<number>(props.focalPointCoordinates[1])
  const [lat, setLat] = useState<number>(props.focalPointCoordinates[0])
  const [zoom, setZoom] = useState<number>(props.defaultZoom)
  const [selectedMarker, setSelectedMarker] = useState<MapMarker | null>(null)
  const mapBoxRef = useRef<MapRef>(null)

  const MAP_MAX_WIDTH = 980
  const MAP_MAX_HEIGHT = 575
  const MAP_FIT_BOUNDS_PADDING = isMobile ? 40 : 125

  useEffect(() => {
    setMapBounds();
  }, [props.mapMarkers, props.focalPointCoordinates, mapBoxRef]);

  useEffect(() => {
    setLng(props.focalPointCoordinates[1])
    setLat(props.focalPointCoordinates[0])
  }, [props.focalPointCoordinates])

  const setMapBounds = () => {
    const mapBounds = getMapBounds(
      props.mapMarkers,
      props.focalPointCoordinates,
    );
    if (mapBounds) {
      mapBoxRef.current?.fitBounds(mapBounds, {
        padding: MAP_FIT_BOUNDS_PADDING,
      });
    }
  }

  const focalPointMarker = useMemo(
    () => <FocalPointMarker coordinates={props.focalPointCoordinates} />,
    [props.focalPointCoordinates]
  )

  const mapMarkers = useMemo(
    () =>
      props.mapMarkers.map((place, index) => (
        <MapLibraryMarker
          key={`marker-${index}`}
          place={place}
          setSelectedMarker={setSelectedMarker}
        />
      )),
    [props.mapMarkers]
  )

  const getMapStyle = () => ({
    width: props.width,
    height: props.height,
    maxWidth: MAP_MAX_WIDTH,
    maxHeight: MAP_MAX_HEIGHT
  })

  const onZoomHandler = (e: ViewStateChangeEvent) => setZoom(e.viewState.zoom)
  const onMoveHandler = (e: ViewStateChangeEvent) => {
    setLat(e.viewState.latitude)
    setLng(e.viewState.longitude)
  }

  const onClosePopup = () => setSelectedMarker(null)

  return (
    <Map
      ref={mapBoxRef}
      onLoad={setMapBounds}
      longitude={lng}
      latitude={lat}
      zoom={zoom}
      style={getMapStyle()}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onZoom={onZoomHandler}
      onMove={onMoveHandler}
    >
      {mapMarkers}
      {focalPointMarker}
      <MapLibraryMarkerPopup onClose={onClosePopup} marker={selectedMarker} />
    </Map>
  )
}
