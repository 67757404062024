import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ConsumerSessionStatus } from '../../../../../domain/consumer/consumer-session/consumer-session-active-status.enum'
import { ConsumerProfileStore } from '../../consumer-profile/store/consumer-profile.store'
import { ConsumerSubmitStepStore } from '../../consumer-workflow/modules/consumer-submit-step/store/consumer-submit-step.store'
import { ConsumerWorkflowStepsStore } from '../../consumer-workflow/modules/consumer-workflow-steps/store/consumer-workflow-steps.store'
import { ConsumerSessionStore } from '../store/consumer-session.store'

export const useConsumerSessionStatus = () => {
  const sessionError = useSelector(ConsumerSessionStore.getConsumerSessionError)
  const getSessionTokenError = useSelector(
    ConsumerSessionStore.getSessionTokenError
  )

  const consumerProfileId = useSelector(
    ConsumerProfileStore.getConsumerProfileId
  )

  const isSubmitWorkflowStepComplete = useSelector(
    ConsumerSubmitStepStore.getIsWorkflowStepComplete
  )

  const isWorkflowComplete = useSelector(
    ConsumerWorkflowStepsStore.isWorkflowComplete
  )

  const fetchingConsumerSession = useSelector(
    ConsumerSessionStore.getFetchingConsumerSession
  )

  const [consumerSessionStatus, setConsumerSessionStatus] = useState(
    ConsumerSessionStatus.Unknown
  )

  useEffect(() => {
    if (fetchingConsumerSession) {
      setConsumerSessionStatus(ConsumerSessionStatus.Pending)
    } else if (isWorkflowComplete || isSubmitWorkflowStepComplete) {
      setConsumerSessionStatus(ConsumerSessionStatus.PostSubmission)
    } else if (consumerProfileId) {
      setConsumerSessionStatus(ConsumerSessionStatus.PreSubmission)
    } else if (sessionError || getSessionTokenError) {
      setConsumerSessionStatus(ConsumerSessionStatus.NotStarted)
    }
  }, [
    consumerProfileId,
    fetchingConsumerSession,
    isSubmitWorkflowStepComplete,
    isWorkflowComplete,
    sessionError,
    getSessionTokenError
  ])

  return consumerSessionStatus
}
