import { useIntl } from 'react-intl'
import { BannerText } from '../../../../../../../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { BannerTextType } from '../../../../../../../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { ConsumerPhoneValidationSectionErrorMessage } from '../../consumer-phone-validation-section-error.message'
import { ConsumerPhoneValidationErrorGeneralStyle } from './consumer-phone-validation-error-general.style'
import { ConsumerPhoneValidationErrorGeneralUat } from './consumer-phone-validation-error-general.uat'
import { uatId } from '../../../../../../../../../../../../../../uat/uat-id.function'

export const ConsumerPhoneValidationErrorGeneral = () => {
  const intl = useIntl()

  return (
    <div className={ConsumerPhoneValidationErrorGeneralStyle.container}>
      <BannerText
        cssContainerClasses={ConsumerPhoneValidationErrorGeneralStyle.banner}
        type={BannerTextType.DANGER}
        text={intl.formatMessage(
          ConsumerPhoneValidationSectionErrorMessage.general
        )}
        uat={uatId(ConsumerPhoneValidationErrorGeneralUat.error)}
      />
    </div>
  )
}
