export const ConsumerPhoneValidationSectionFormMessage = {
  input: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.Form.input',
    description: 'Code'
  },
  inputPlaceholder: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.Form.inputPlaceholder',
    description: '6 digit code'
  }
}
