import { LookupAddressNext } from '../../../../../../../../../../../../domain/lookup-address/lookup-address-next.enum'
import { ConsumerAddress } from '../../../../../../../../../../../../domain/consumer/consumer-profile/consumer-address.interface'
import { LookupAddress } from '../../../../../../../../../../../../domain/lookup-address/lookup-address.interface'
import { RetrieveAddress } from '../../../../../../../../../../../../domain/lookup-address/retrieve-address.interface'

export const getRetrieveOnlyAddressLookups = (
  items: LookupAddress[]
): LookupAddress[] =>
  items.filter(
    (item: LookupAddress): boolean => item.next === LookupAddressNext.RETRIEVE
  )

export const retrieveAddressToString = (address: RetrieveAddress): string =>
  [
    [address.apt, address.address].join(' ').trim(),
    address.city,
    [address.province, address.postalCode].join(' ').trim()
  ]
    .join(', ')
    .trim()

export const saveAddressToString = (address: ConsumerAddress | null): string =>
  address
    ? [
        [address.unit, address.street].join(' ').trim(),
        address.city,
        [address.province, address.postalCode].join(' ').trim()
      ]
        .join(', ')
        .trim()
    : ''

export const isConsumerAddressValid = (
  address: ConsumerAddress | null
): boolean => {
  return Boolean(address)
}
