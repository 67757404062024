import { FormattedMessage } from 'react-intl'
import { SpinnerIcon } from '../../../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerAuthenticationLoadingViewMessages } from './consumer-authentication-loading-view.messages'
import { ConsumerAuthenticationLoadingViewStyles } from './consumer-authentication-loading-view.styles'

export const ConsumerAuthenticationLoadingView = withShowIf(() => {
  return (
    <div className={ConsumerAuthenticationLoadingViewStyles.container}>
      <SpinnerIcon size={SpinnerIconSize.LARGE} />
      <Paragraph className={ConsumerAuthenticationLoadingViewStyles.paragraph}>
        <FormattedMessage
          {...ConsumerAuthenticationLoadingViewMessages.loadingMessage}
        />
      </Paragraph>
    </div>
  )
})
