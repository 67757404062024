import { PreApprovalResult } from '../../../../../domain/consumer/consumer-profile/pre-approval-result.interface'
import { RootStore } from '../../../../../store/root.store'
import { EventType } from '../types/event-types.enum'
import { EventsActions } from './events.actions'

const connect = () => RootStore.dispatch(EventsActions.connectToEventBus())

const invokePreApprovalResult = (data: PreApprovalResult) =>
  RootStore.dispatch(EventsActions.preApprovalResult(data))

export const EventsStore = {
  connect,
  [EventType.PreApprovalResult]: invokePreApprovalResult
}
