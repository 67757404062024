import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { InlineMessageProps } from './inline-message.props'
import {
  InlineMessageContainer,
  inlineMessageStyles
} from './inline-message.styles'

export const InlineMessage = (props: InlineMessageProps) => {
  const showMessage = useMemo(() => Boolean(props.message), [props.message])

  return (
    <div className={inlineMessageStyles.container(props.cssContainerClasses)}>
      <InlineMessageContainer
        showIf={showMessage}
        className={props.cssMessageClasses}
      >
        <FormattedMessage {...props.message} />
      </InlineMessageContainer>
      <button
        {...props.uat}
        className={inlineMessageStyles.button}
        onClick={props.onClick}
      >
        <FormattedMessage {...props.buttonText} />
      </button>
    </div>
  )
}
