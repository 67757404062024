import { Reducer } from 'redux'
import { createConsumerSubmitStepState } from '../types/consumer-submit-step-state.functions'
import { ConsumerSubmitStepState } from '../types/consumer-submit-step-state.interface'
import {
  ConsumerSubmitStepAction,
  ConsumerSubmitStepActionType
} from './consumer-submit-step.actions'

export const consumerSubmitStepReducer: Reducer<
  ConsumerSubmitStepState,
  ConsumerSubmitStepActionType
> = (
  state: ConsumerSubmitStepState = createConsumerSubmitStepState(),
  action: ConsumerSubmitStepActionType
): ConsumerSubmitStepState => {
  switch (action.type) {
    case ConsumerSubmitStepAction.SubmitConsumerProfileForPreApproval:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ConsumerSubmitStepAction.SubmitConsumerProfileForPreApprovalSuccess:
      return {
        ...state,
        loading: false,
        authorizedElectronicCommunication:
          action.payload.authorizedElectronicCommunication,
        isWorkflowStepComplete: true
      }
    case ConsumerSubmitStepAction.SubmitConsumerProfileForPreApprovalFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
