import { CommonStyles } from '../../../../../../../../../../../../ui-library/styles/common.styles'

export const ConsumerManualAddressFormStyles = {
  wrapper: 'flex flex-col w-full items-center',
  container: 'flex flex-col w-full sm:max-w-xs',
  horizontal: 'flex py-4 sm:py-0 ',
  apt: 'flex w-2/5 pr-2',
  city: 'flex w-3/5 pl-2',
  province: 'flex w-3/5 pr-2 flex-col',
  postal: 'flex w-2/5 pl-2',
  autoButton: `${CommonStyles.anchorLink} text-left py-4`,
  submitButton: 'text-xl sm:text-2xl mt-auto'
}
