import { FormattedMessage } from 'react-intl'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { MerchantSelectionNotFoundViewMessages } from './merchant-selection-not-found-view.messages'
import { MerchantSelectionNotFoundViewStyles } from './merchant-selection-not-found-view.styles'

export const MerchantSelectionNotFoundView = withShowIf(() => {
  return (
    <>
      <Paragraph className={MerchantSelectionNotFoundViewStyles.title}>
        <FormattedMessage {...MerchantSelectionNotFoundViewMessages.title} />
      </Paragraph>

      <Paragraph className={MerchantSelectionNotFoundViewStyles.description}>
        <FormattedMessage
          {...MerchantSelectionNotFoundViewMessages.description}
        />
      </Paragraph>
    </>
  )
})
