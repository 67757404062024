import { Reducer } from 'redux'
import { EventsAction, EventsActionType } from './events.actions'
import { EventState } from '../types/event-state.interface'
import { createEventState } from '../types/event-state.functions'

export const eventsReducer: Reducer<EventState, EventsActionType> = (
  state: EventState = createEventState(),
  action: EventsActionType
): EventState => {
  switch (action.type) {
    case EventsAction.ConnectSuccess:
      return {
        ...state,
        connected: true
      }
    case EventsAction.ConnectFailure:
      return {
        ...state,
        error: action.payload
      }
    case EventsAction.Disconnect:
      return {
        ...state,
        connected: false
      }
    default:
      return state
  }
}
