import React from 'react'
import { useSelector } from 'react-redux'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerNameForm } from '../consumer-name-form/consumer-name-form.component'
import { withHideIf } from '../../../../../../../../ui-library/helpers/with-hide-if/with-hide-if.component'
import { ConsumerNameViewMessages } from './consumer-name-view.messages'
import { useIntl } from 'react-intl'
import { ConsumerNameViewProps } from './consumer-name-view.props'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { ConsumerNameViewUat } from './consumer-name-view.uat'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerName } from '../../../../../../../../domain/consumer/consumer-profile/consumer-name.interface'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component';

export const ConsumerNameView = withHideIf((props: ConsumerNameViewProps) => {
  const intl = useIntl();
  const onSubmit = (consumerName: ConsumerName): void => {
    ConsumerProfileStore.setConsumerName(consumerName)
    props.onComplete()
  }

  return (
    <>
      <PrimaryHeader
        showBackIcon={props.step.rules.showBackButton}
        goBack={props.goBack}
      />

      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(ConsumerNameViewMessages.paragraph)}
        titleUat={uatId(ConsumerNameViewUat.header)}
        subTitleMessage={intl.formatMessage(ConsumerNameViewMessages.formTitle)}
        subTitleUat={uatId(ConsumerNameViewUat.paragraph)}
        noHorizontalPadding
        showFooter
      >
          <ConsumerNameForm
            initialFormValues={useSelector(ConsumerProfileStore.getConsumerName)}
            onSubmit={onSubmit}
          />
      </ConsumerProfileAssetImageContainer>
    </>
  )
})
