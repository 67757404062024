import { PostalCodeFieldSchema } from '../../../../../../../../../../../../libraries/form-schema/form-field-schema/postal-code-field-schema'
import { StringFieldSchema } from '../../../../../../../../../../../../libraries/form-schema/form-field-schema/string-field-schema'
import { FormSchema } from '../../../../../../../../../../../../libraries/form-schema/form-schema'
import { ConsumerManualAddressFormSchema } from './types/consumer-manual-address-form-schema.interface'

export function generateConsumerManualAddressFormSchema(): FormSchema<ConsumerManualAddressFormSchema> {
  return new FormSchema<ConsumerManualAddressFormSchema>({
    street: new StringFieldSchema('street')
      .setMinLength(1)
      .setMaxLength(45)
      .isRequired(),
    apt: new StringFieldSchema('apt').setMaxLength(45),
    city: new StringFieldSchema('city')
      .setMinLength(1)
      .setMaxLength(35)
      .isRequired(),
    province: new StringFieldSchema('province')
      .setMinLength(2)
      .setMaxLength(2)
      .isRequired(),
    postalCode: new PostalCodeFieldSchema('postalCode')
      .setMinLength(6)
      .setMaxLength(17)
      .isRequired()
  })
}
