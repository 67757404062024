export const QuestionsDialogFormViewMessages = {
  buttonLabel: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogFormView.buttonLabel',
    description: 'Questions dialog button label'
  },
  attemptsRemainingError: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogFormView.securityQuestionsAttemptsRemainingError',
    description: 'Error for invalid answers'
  },
  submitButton: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogFormView.submitButton',
    description: 'Submit button label'
  }
}
