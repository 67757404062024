import React from 'react'
import { LinkProps } from './link.props'
import { LinkStyles } from './link.styles'
import { withShowIf } from '../../helpers/with-show-if/with-show-if.component'

export const Link = withShowIf((props: LinkProps) => {
  return (
    <>
      <a
        {...props.uat}
        href={props.link}
        target="_blank"
        className={LinkStyles.link(props.className)}
      >
        {props.text}
      </a>
    </>
  )
})
