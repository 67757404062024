import { getButtonWidth } from '../../helpers/button.functions'

const buttonStyles = {
  button: 'text-white font-montserrat font-medium gap-2.5 py-3 px-6 rounded-primaryButton w-full bg-gradient-to-b from-skyblue500 from 12.54% to-skyblue800 to-82.85%',
  disabled: 'pointer-events-none opacity-40'
}

export const primaryButtonContainerStyle = (
  cssClasses: string = '',
  fullWidth: boolean = false,
  minimumWidth: boolean = false
): string =>
  `w-full flex items-center ${getButtonWidth(fullWidth, minimumWidth)} ${cssClasses}`

export const primaryButtonStyle = (
  show: boolean,
  disable: boolean = false
): string => {
  const cssClasses: string[] = []

  cssClasses.push(buttonDisplayStyle(show))
  cssClasses.push(buttonDisableStyle(disable))

  return cssClasses.join(' ')
}

const buttonDisplayStyle = (show: boolean = true): string =>
  show ? '' : 'hidden'

const buttonDisableStyle = (disable: boolean): string =>
  disable
    ? `${buttonStyles.button} ${buttonStyles.disabled}`
    : buttonStyles.button