export const ConsumerAutoAddressFormMessages = {
  label: {
    id: 'Pages.ConsumerAddress.Auto.label',
    description: 'Address'
  },
  submitButton: {
    id: 'Pages.ConsumerAddress.Auto.submitButton',
    description: 'Next'
  },
  manualAddress: {
    id: 'Pages.ConsumerAddress.Auto.manualAddress',
    description: 'Enter address manually'
  }
}
