import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerProfileStore } from '../../../consumer-profile/store/consumer-profile.store'
import { MerchantSelectionViewMessages } from './merchant-selection-view.messages'
import { MerchantSelectionViewProps } from './merchant-selection-view.props'
import { MerchantSelectionViewStyles } from './merchant-selection-view.styles'
import { uatId } from '../../../../../../uat/uat-id.function'
import { MerchantSelectionViewUat } from './merchant-selection-view.uat'
import { useSelector } from 'react-redux'
import { Merchant } from '../../../../../../domain/merchant/merchant.interface'
import { MerchantSelectionState } from '../../types/merchant-selection-state.interface'
import { MerchantSelectionStore } from '../../store/merchant-selection.store'
import { MerchantSelectionViewReferenceCode } from './components/merchant-selection-view-reference-code/merchant-selection-view-reference-code.component'
import { MerchantList } from '../merchant-list/merchant-list.component'
import { MerchantSelectionConfirmDialog } from './components/merchant-selection-confirm-dialog/merchant-selection-confirm-dialog.component'
import { MapView } from '../../../../../../shared/components/map-view/map-view.component'
import { PreQualifierAssetTypeSelectionStore } from '../../../pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.store'
import { AssetSubtypesSelectField } from '../../../asset-subtype-selection/components/asset-subtypes-select-field/asset-subtypes-select-field'
import { AssetSubtype } from '../../../../../../domain/asset-subtype/asset-subtype.interface'
import { AssetSubtypeStore } from '../../../asset-subtype-selection/store/asset-subtype-selection.store'
import { ShowIfFragment } from '../../../../../../ui-library/components/show-if/show-if-fragment.component'
import { SpinnerIcon } from '../../../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { showAssetSubtypesSelector, showSelectAssetSubtypeTitle } from '../../merchant-selection.functions'
import { ConsumerProfileAssetImageContainer } from '../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { PrimaryHeader } from '../../../../../../ui-library/components/primary-header/primary-header.component'
import { MerchantSelectionForm } from './components/merchant-selection-form/merchant-selection-form.component'

export const MerchantSelectionView = withShowIf(
  (props: MerchantSelectionViewProps) => {
    const isPreApprovalApplicationReassigned = useSelector(
      ConsumerProfileStore.getIsPreApprovalApplicationReassigned
    )
    const intl = useIntl()
    const consumerAddress = useSelector(ConsumerProfileStore.getConsumerAddress)
    const referenceCode = useSelector(ConsumerProfileStore.getReferenceCode)
    const consumerAddressCoordinates = useSelector(
      MerchantSelectionStore.getConsumerProfileAddressCoordinates
    )
    const [selectedMerchant, setSelectedMerchant] = useState<
      MerchantSelectionState['selectedMerchant']
    >(useSelector(MerchantSelectionStore.getSelectedMerchant))
    const [merchantSelectionConfirmOpen, setMerchantSelectionConfirmOpen] =
      useState<boolean>(false)

    const selectedAssetTypeName = useSelector(
      PreQualifierAssetTypeSelectionStore.getSelectedAssetType
    )?.name
    const firstName = useSelector(ConsumerProfileStore.getConsumerFirstName)

    const selectMerchant = (merchant: Merchant) => {
      setSelectedMerchant(merchant)
      setMerchantSelectionConfirmOpen(true)
    }

    const closeConfirmDialog = () => {
      setMerchantSelectionConfirmOpen(false)
      MerchantSelectionStore.resetSubmitError()
    }

    const onDialogConfirm = () => {
      if (selectedMerchant) {
        if (!isPreApprovalApplicationReassigned) {
          MerchantSelectionStore.reassignConsumerProfileApplicationMerchant(
            selectedMerchant,
            props.selectedAssetSubtype
          )
        } else {
          MerchantSelectionStore.cloneConsumerProfileApplicationMerchant(
            selectedMerchant,
            props.selectedAssetSubtype
          )
        }
      }
    }

    useEffect(() => {
      if (consumerAddress) {
        MerchantSelectionStore.fetchConsumerProfileAddressCoordinates()
      }
    }, [consumerAddress])

    const onAssetSubtypeChange = (assetSubtypeId: AssetSubtype['id']) => AssetSubtypeStore.setSelectedAssetSubtypeId(assetSubtypeId);

    return (
      <>
        <PrimaryHeader 
          showBackIcon
          goBack={props.goBack}
          horizontalPadding
        />
        <ConsumerProfileAssetImageContainer
          titleMessage={intl.formatMessage(MerchantSelectionViewMessages.title)}
          titleUat={uatId(MerchantSelectionViewUat.title)}
          subTitleMessage={intl.formatMessage(MerchantSelectionViewMessages.description, {consumerFirstName: firstName, assetTypeName: selectedAssetTypeName})}
          subTitleUat={uatId(MerchantSelectionViewUat.description)}
        />
        <MerchantSelectionViewReferenceCode referenceCode={referenceCode} marginBottom />

        <ShowIfFragment showIf={showSelectAssetSubtypeTitle(props.selectedAssetSubtype, props.assetSubtypes)}>
          <Paragraph
            className={MerchantSelectionViewStyles.selectAssetSubtypeTitle}
          >
            <FormattedMessage {...MerchantSelectionViewMessages.selectAssetSubtypeTitle} />
          </Paragraph>
        </ShowIfFragment>
        <ShowIfFragment showIf={showAssetSubtypesSelector(props.assetSubtypes)}>
          <AssetSubtypesSelectField
            assetSubtype={props.selectedAssetSubtype || ''}
            assetSubtypes={props.assetSubtypes}
            onChange={onAssetSubtypeChange}
            fieldUat={uatId(MerchantSelectionViewUat.assetSubtypeSelect)}
            itemUat={uatId(MerchantSelectionViewUat.assetSubtypeItem)}
          />
        </ShowIfFragment>
        <div className={MerchantSelectionViewStyles.loadingContainer}>
          <SpinnerIcon size={SpinnerIconSize.LARGE} showIf={props.loadingMerchant} />
        </div>
        <ShowIfFragment showIf={!props.loadingMerchant}>
          <Paragraph
            className={MerchantSelectionViewStyles.merchantsLocatorTitle}
            uat={uatId(MerchantSelectionViewUat.merchantsLocatorTitle)}
          >
            <FormattedMessage
              {...MerchantSelectionViewMessages.merchantsLocatorTitle}
              values={{ merchantsCount: props.merchants.length }}
            />
          </Paragraph>
          <MapView
            showIf={Boolean(consumerAddressCoordinates)}
            defaultZoom={10}
            focalPointCoordinates={consumerAddressCoordinates}
            height={'43vw'}
            places={props.merchants}
            width={'70vw'}
          />
          <MerchantSelectionForm
            selectedAssetSubtype={props.selectedAssetSubtype}
            merchantsAvailableToSelect={Boolean(props.merchants.length)}
          />
          <MerchantList
            merchants={props.merchants}
            selectMerchant={selectMerchant}
            selectedAssetSubtype={props.selectedAssetSubtype}
            selectedMerchant={selectedMerchant}
            selectedMerchants={props.selectedMerchants}
          />
        </ShowIfFragment>
        <MerchantSelectionConfirmDialog
          isOpen={merchantSelectionConfirmOpen}
          merchant={selectedMerchant}
          loading={useSelector(MerchantSelectionStore.getSubmitLoading)}
          onConfirm={onDialogConfirm}
          onClose={closeConfirmDialog}
        />
      </>
    )
  }
)
