import { Epic } from 'redux-observable'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { catchError, filter, mergeMap, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { Action } from '../../../../../../../store/root.actions'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { ConsumerEmailService } from '../../../../../../../api/bff/services/consumer-email.services'
import { ConsumerEmailStepActions } from './consumer-email-step.action'

const saveConsumerEmailEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerEmailStepActions.saveConsumerEmail)),
    switchMap((action) =>
      new ConsumerEmailService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .saveConsumerEmail(action.payload)
        .pipe(
          mergeMap(() =>
            of(
              ConsumerEmailStepActions.saveConsumerEmailSuccess(action.payload)
            )
          ),
          catchError((err: HttpError) =>
            of(ConsumerEmailStepActions.saveConsumerEmailFailure(err))
          )
        )
    )
  )

export const consumerEmailStepEpics = [saveConsumerEmailEpic]
