import { MessageDescriptor } from 'react-intl';
import { ProvinceAbbreviation } from '../../../domain/province/province-abbreviation.enum';
import { HighCostLicenseDetails } from './types/high-cost-loan-details.interface';
import { HighCostLicenseDetailsMessages } from './high-cost-license-details.messages';
import { ReactAppHighCostLicenseDetail } from './types/react-app-high-cost-license-details.type';
import { LicenseDetails } from './types/license-details.interface';

const getHighCostLicenseDetails = (): ReactAppHighCostLicenseDetail | null => {
  if (process.env.REACT_APP_HIGH_COST_LICENSE_DETAILS) {
    return JSON.parse(atob(process.env.REACT_APP_HIGH_COST_LICENSE_DETAILS));
  }

  return null;
};

const createHighCostLoanDetails = (
  applicantProvince: ProvinceAbbreviation,
  licenseDetails: LicenseDetails,
): HighCostLicenseDetails => {
  const expiryUtcDate = new Date(licenseDetails.expiry);

  return {
    province: applicantProvince,
    licenseNumber: licenseDetails.license,
    licenseExpiryDate: new Date(
      expiryUtcDate.getTime() + expiryUtcDate.getTimezoneOffset() * 60000,
    ),
  };
};

export const getHighCostLicenseDetailsForProvince = (
  applicantProvince: ProvinceAbbreviation,
): HighCostLicenseDetails | null => {
  const highCostLicenseDetailsObject = getHighCostLicenseDetails();

  if (
    !highCostLicenseDetailsObject ||
    !Object.keys(highCostLicenseDetailsObject ?? {}).includes(applicantProvince)
  ) {
    return null;
  }

  return createHighCostLoanDetails(
    applicantProvince,
    highCostLicenseDetailsObject[applicantProvince],
  );
};

export const getHighCostTitleMessage = (
  applicantProvince?: ProvinceAbbreviation,
): MessageDescriptor => {
  switch (applicantProvince) {
    case ProvinceAbbreviation.Newfoundland:
      return HighCostLicenseDetailsMessages.newfoundlandHighCostProduct;
    default:
      return HighCostLicenseDetailsMessages.defaultHighCostProduct;
  }
};