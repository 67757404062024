import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { EventBus } from '../../../../../libraries/event-bus/event-bus'
import { Action } from '../../../../../store/root.actions'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { ConsumerProfileStore } from '../../../../consumer/modules/consumer-profile/store/consumer-profile.store'
import { ConsumerSessionStore } from '../../../../consumer/modules/consumer-session/store/consumer-session.store'
import { EventType } from '../types/event-types.enum'
import { EventsActions } from './events.actions'
import { EventsStore } from './events.store'

const connectToEventBusEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(EventsActions.connectToEventBus)),
    switchMap(() =>
      new EventBus()
        .connect(ConsumerSessionStore.getSessionToken(state$.value))
        .pipe(
          map((eventBus: EventBus) => {
            eventBus
              .createChannel(
                ConsumerProfileStore.getConsumerProfileId(state$.value)
              )
              .bindToAll((eventType: EventType, payload: any) => {
                if (Boolean(EventsStore[eventType])) {
                  EventsStore[eventType](payload)
                }
              })
            return EventsActions.connectToEventBusSuccess()
          }),
          catchError((err) => of(EventsActions.connectToEventBusFailure(err)))
        )
    )
  )

export const eventsEpics = [connectToEventBusEpic]
