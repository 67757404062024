export const MerchantSelectionConfirmDialogErrorViewMessages = {
  title: {
    id: 'Pages.MerchantSelection.MerchantSelectionConfirmDialog.error.title',
    description: 'Merchant Selection Confirm Dialog Error View Title'
  },
  description: {
    id: 'Pages.MerchantSelection.MerchantSelectionConfirmDialog.error.description',
    description: 'Merchant Selection Confirm Dialog Error View description'
  }
}
