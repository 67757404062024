import { defineMessages } from 'react-intl'

export const LanguageSelectorMessages = defineMessages({
  english: {
    id: 'Shared.Components.LanguageSelector.englishButton',
    description: 'French'
  },
  french: {
    id: 'Shared.Components.LanguageSelector.frenchButton',
    description: 'English'
  }
})
