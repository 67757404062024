import { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StringSchemaOptions } from '../../../../../../../../libraries/form-schema/form-field-schema/types/form-field-schema-options/string-schema-options.interface'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { TextInput } from '../../../../../../../../ui-library/components/input-field/components/text-input/text-input.component'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerNameFormMessages } from './consumer-name-form.messages'
import { ConsumerNameFormProps } from './consumer-name-form.props'
import { generateConsumerNameFormSchema } from './consumer-name-form.schema'
import { ConsumerNameFormStyles } from './consumer-name-form.styles'
import { ConsumerNameFormUat } from './consumer-name-form.uat'

export const ConsumerNameForm = (props: ConsumerNameFormProps) => {
  const intl = useIntl()

  const [firstName, setFirstName] = useState<string>(
    props.initialFormValues.firstName ?? ''
  )
  const [lastName, setLastName] = useState<string>(
    props.initialFormValues.lastName ?? ''
  )
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(true)
  const formSchema = generateConsumerNameFormSchema()

  const submitForm = () =>
    props.onSubmit({
      firstName,
      lastName
    })

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setFirstName(event.target.value)
  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setLastName(event.target.value)

  useEffect(() => {
    const isValid: boolean = formSchema.isValidSync({ firstName, lastName })
    setIsSubmitButtonDisabled(!isValid)
  }, [firstName, lastName, formSchema])

  return (
    <div className={ConsumerNameFormStyles.formWrapper}>
      <div className={ConsumerNameFormStyles.formContainer}>
        <div className={ConsumerNameFormStyles.inputField}>
          <TextInput
            autoFocus
            name={'firstName'}
            label={intl.formatMessage(ConsumerNameFormMessages.firstNameLabel)}
            value={firstName}
            maxLength={
              formSchema.getSchemaOptions<StringSchemaOptions>('firstName')
                .maxLength
            }
            onChange={onFirstNameChange}
          />
        </div>

        <div className={ConsumerNameFormStyles.inputField}>
          <TextInput
            name={'lastName'}
            label={intl.formatMessage(ConsumerNameFormMessages.lastNameLabel)}
            value={lastName}
            maxLength={
              formSchema.getSchemaOptions<StringSchemaOptions>('lastName')
                .maxLength
            }
            onChange={onLastNameChange}
          />
        </div>
      </div>
      <PrimaryButton
        onClick={submitForm}
        disabled={isSubmitButtonDisabled}
        uat={uatId(ConsumerNameFormUat.submitButton)}
        cssContainerClasses={ConsumerNameFormStyles.button}
      >
        <FormattedMessage {...ConsumerNameFormMessages.submitButton} />
      </PrimaryButton>
    </div>
  )
}
