import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ConsumerRoute } from '../../../../domain/consumer/consumer-routes/consumer-route.enum'
import {
  clearSessionToken,
  getSessionToken
} from '../../../app/components/initializer/types/session-token.functions'
import { EventsStore } from '../../../app/modules/events/store/events.store'
import { ConsumerProfileStore } from '../consumer-profile/store/consumer-profile.store'
import { ConsumerSessionStore } from './store/consumer-session.store'

export const ConsumerSession = () => {
  const history = useHistory()
  const sessionToken = useSelector(ConsumerSessionStore.getSessionToken)
  const sessionError = useSelector(ConsumerSessionStore.getConsumerSessionError)
  const consumerProfileId = useSelector(
    ConsumerProfileStore.getConsumerProfileId
  )

  useEffect(() => {
    if (sessionToken) {
      ConsumerSessionStore.fetchConsumerSession()
    }
  }, [sessionToken])

  useEffect(() => {
    if (sessionToken && consumerProfileId) {
      EventsStore.connect()
    }
  }, [consumerProfileId, sessionToken])

  useEffect(() => {
    if (sessionError?.statusCode === 401) {
      clearSessionToken()
      history.push(ConsumerRoute.RouteResolver)
    }
  }, [sessionError])

  useEffect(() => {
    const currentSessionToken = getSessionToken()
    if (currentSessionToken) {
      ConsumerSessionStore.setSessionToken(currentSessionToken)
    } else {
      ConsumerSessionStore.setGetSessionTokenError(true)
    }
  }, [])

  return null
}
