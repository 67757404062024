import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../../../store/root.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerEmailStepState } from '../types/consumer-email-step-state.interface'
import { ConsumerEmailStepActions } from './consumer-email-step.action'

const consumerEmailStepState = (rootState: RootState): ConsumerEmailStepState =>
  rootState.consumerState.consumerWorkflowState.consumerEmailStepState

const getEmail: Selector<RootState, ConsumerEmailStepState['email']> =
  createSelector(
    consumerEmailStepState,
    (state: ConsumerEmailStepState) => state.email
  )

const saveConsumerEmail = (email: ConsumerEmailStepState['email']) =>
  RootStore.dispatch(ConsumerEmailStepActions.saveConsumerEmail(email))

const getLoading: Selector<RootState, ConsumerEmailStepState['loading']> =
  createSelector(
    consumerEmailStepState,
    (state: ConsumerEmailStepState) => state.loading
  )

const getError: Selector<RootState, ConsumerEmailStepState['error']> =
  createSelector(
    consumerEmailStepState,
    (state: ConsumerEmailStepState) => state.error
  )

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerEmailStepState['isWorkflowStepComplete']
> = createSelector(
  consumerEmailStepState,
  (state: ConsumerEmailStepState) => state.isWorkflowStepComplete
)

const reset = () =>
  RootStore.dispatch(ConsumerEmailStepActions.resetConsumerEmailState())

export const ConsumerEmailStepStore = {
  getEmail,
  saveConsumerEmail,
  getError,
  getLoading,
  getIsWorkflowStepComplete,
  reset
}
