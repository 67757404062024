import { MapLibrary } from '../../../libraries/map-library/map-library.component'
import { withShowIf } from '../../../ui-library/helpers/with-show-if/with-show-if.component'
import { MapViewProps } from './map-view.props'

export const MapView = withShowIf((props: MapViewProps) => {
  const getMapLibraryCoordinates = (): [number, number] => [
    props.focalPointCoordinates?.latitude ?? 0,
    props.focalPointCoordinates?.longitude ?? 0
  ]

  return (
    <MapLibrary
      defaultZoom={props.defaultZoom}
      focalPointCoordinates={getMapLibraryCoordinates()}
      height={props.height}
      mapMarkers={props.places}
      width={props.width}
    />
  )
})
