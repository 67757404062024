import { Action } from 'redux'
import { Epic } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { ProductService } from '../../../../../api/bff/services/product.service'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { PreQualifierLoadingProductActions } from './pre-qualifier-loading-product.actions'

const fetchProductDetailsEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(PreQualifierLoadingProductActions.fetchProductDetails)),
    map((action) => action.payload),
    mergeMap(({ vendorId, sku }) =>
      new ProductService().fetchProductDetails(vendorId, sku).pipe(
        map((productDetails) =>
          PreQualifierLoadingProductActions.fetchProductDetailsSuccess(
            productDetails
          )
        ),
        catchError((error) =>
          of(PreQualifierLoadingProductActions.fetchProductDetailsError(error))
        )
      )
    )
  )

export const preQualifierLoadingProductEpics = [fetchProductDetailsEpic]
