import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../../../store/root.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerPostSubmissionApprovedState } from '../types/consumer-post-submission-approved.state'
import { ConsumerPostSubmissionApprovedActions } from './consumer-post-submission-approved.actions'

const consumerPostSubmissionApprovedState = (
  rootState: RootState
): ConsumerPostSubmissionApprovedState =>
  rootState.consumerState.consumerPostSubmissionState
    .consumerPostSubmissionApprovedState

const getApplicantLink: Selector<
  RootState,
  ConsumerPostSubmissionApprovedState['applicantLink']
> = createSelector(
  consumerPostSubmissionApprovedState,
  (state: ConsumerPostSubmissionApprovedState) => state.applicantLink
)

const fetchApplicantLink = () =>
  RootStore.dispatch(ConsumerPostSubmissionApprovedActions.fetchApplicantLink())

export const PostSubmissionApprovedStore = {
  getApplicantLink,
  fetchApplicantLink
}
