import { getParamFromUrl } from './url-search-params.functions';

const eventIdSessionProperty = '__lcpq_eventId__';

export const setEventIdToSessionStorage = (url: string): void => {
    const eventId = getParamFromUrl(url, 'eventId')
    if (eventId !== null) {
        sessionStorage.setItem(eventIdSessionProperty, eventId);
    }
}

export const getEventId = (url: string): string | null => {
    return sessionStorage.getItem(eventIdSessionProperty) || getParamFromUrl(url, 'eventId');
}

export const clearEventIdFromSessionStorage = () => {
    sessionStorage.removeItem(eventIdSessionProperty);
}