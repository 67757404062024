import { useIntl } from 'react-intl'
import { FormSchema } from '../../../../../../../../../../../../libraries/form-schema/form-schema'
import { uatId } from '../../../../../../../../../../../../uat/uat-id.function'
import { NumberInput } from '../../../../../../../../../../../../ui-library/components/input-field/components/number-input/number-input.component'
import { ConsumerPhoneValidationStepStore } from '../../../../../../store/consumer-phone-validation-step.store'
import { ConsumerPhoneValidationSectionFormMessage } from './consumer-phone-validation-section-form.message'
import { ConsumerPhoneValidationSectionFormProps } from './consumer-phone-validation-section-form.props'
import { generateConsumerPhoneValidationSectionFormSchema } from './consumer-phone-validation-section-form.schema'
import { ConsumerPhoneValidationSectionFormStyles } from './consumer-phone-validation-section-form.styles'
import { ConsumerPhoneValidationSectionFormUat } from './consumer-phone-validation-section-form.uat'
import { ConsumerPhoneValidationSectionFormSchema } from './types/consumer-phone-validation-section-form-schema.interface'

export const ConsumerPhoneValidationSectionForm = (
  props: ConsumerPhoneValidationSectionFormProps
) => {
  const intl = useIntl()

  const formSchema: FormSchema<ConsumerPhoneValidationSectionFormSchema> =
    generateConsumerPhoneValidationSectionFormSchema()

  const onChange = (smsCode: string) => {
    props.setVerificationCode(smsCode)

    const isValid: boolean = formSchema.isValidSync({
      verificationCode: smsCode
    })

    if (isValid) {
      ConsumerPhoneValidationStepStore.resetResendVerificationCodeState()
      ConsumerPhoneValidationStepStore.validatePhoneNumber(smsCode)
    }
  }

  return (
    <div className={ConsumerPhoneValidationSectionFormStyles.container}>
      <NumberInput
        uat={uatId(ConsumerPhoneValidationSectionFormUat.field)}
        autoFocus
        maxLength={6}
        placeholder={intl.formatMessage(
          ConsumerPhoneValidationSectionFormMessage.inputPlaceholder
        )}
        name={'verificationCode'}
        label={intl.formatMessage(
          ConsumerPhoneValidationSectionFormMessage.input
        )}
        value={props.verificationCode}
        onChange={onChange}
      />
    </div>
  )
}
