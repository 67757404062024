import { Reducer } from 'redux'
import { ConsumerEditPhoneNumberActionType } from '../modules/consumer-edit-phone-number/store/consumer-edit-phone-number.actions'
import { consumerEditPhoneNumberReducer } from '../modules/consumer-edit-phone-number/store/consumer-edit-phone-number.reducer'
import { createConsumerPhoneValidationStepState } from '../types/consumer-phone-validation-step-state.functions'
import { ConsumerPhoneValidationStepState } from '../types/consumer-phone-validation-step-state.interface'
import {
  ConsumerPhoneValidationStepAction,
  ConsumerPhoneValidationStepActionType
} from './consumer-phone-validation-step.actions'

export const consumerPhoneValidationStepReducer: Reducer<
  ConsumerPhoneValidationStepState,
  ConsumerPhoneValidationStepActionType
> = (
  state: ConsumerPhoneValidationStepState = createConsumerPhoneValidationStepState(),
  action: ConsumerPhoneValidationStepActionType
): ConsumerPhoneValidationStepState => {
  switch (action.type) {
    case ConsumerPhoneValidationStepAction.ValidatePhoneNumber:
      return {
        ...state,
        phoneValidationError: null,
        isWorkflowStepComplete: false,
        phoneValidationLoading: true
      }

    case ConsumerPhoneValidationStepAction.ValidatePhoneNumberSuccess:
      return {
        ...state,
        phoneValidationError: null,
        isWorkflowStepComplete: true,
        phoneValidationLoading: false
      }

    case ConsumerPhoneValidationStepAction.ValidatePhoneNumberFailure:
      return {
        ...state,
        phoneValidationError: action.payload,
        isWorkflowStepComplete: false,
        phoneValidationLoading: false
      }

    case ConsumerPhoneValidationStepAction.ResendVerificationCodeSuccess:
      return {
        ...state,
        isResendVerificationCodeSuccessful: true,
        resendVerificationCodeError: null
      }

    case ConsumerPhoneValidationStepAction.ResendVerificationCodeFailure:
      return {
        ...state,
        isResendVerificationCodeSuccessful: false,
        resendVerificationCodeError: action.payload
      }

    case ConsumerPhoneValidationStepAction.ResetPhoneValidationState:
      return {
        ...state,
        phoneValidationError: null,
        isWorkflowStepComplete: false,
        phoneValidationLoading: false
      }

    case ConsumerPhoneValidationStepAction.ResetResendVerificationCodeState:
      return {
        ...state,
        resendVerificationCodeError: null,
        isResendVerificationCodeSuccessful: false
      }

    default:
      return {
        ...state,
        consumerEditPhoneNumberState: consumerEditPhoneNumberReducer(
          state.consumerEditPhoneNumberState,
          action as ConsumerEditPhoneNumberActionType
        )
      }
  }
}
