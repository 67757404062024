import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { ConsumerRoute } from '../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { ConsumerSessionStatus } from '../../../../domain/consumer/consumer-session/consumer-session-active-status.enum'
import { Answer } from '../../../../domain/consumer/security-questions/types/questions/answer.interface'
import { MainLayout } from '../../../../shared/components/main-layout/main-layout.component'
import { LocaleHelper } from '../../../../shared/helpers/locale-helper'
import { Language } from '../../../../shared/types/language.enum'
import { clearSessionToken } from '../../../app/components/initializer/types/session-token.functions'
import { SettingsStore } from '../../../app/modules/settings/store/settings.store'
import { useConsumerSessionStatus } from '../consumer-session/hooks/use-consumer-session-active-status.hook'
import { AuthenticationFailureMessageBox } from './components/authentication-failure-message-box/authentication-failure-message-box.component'
import { ConsumerAuthenticationLoadingView } from './components/consumer-authentication-loading-view/consumer-authentication-loading-view.component'
import { SecurityQuestionsModal } from './modules/security-questions/components/security-questions-modal/security-questions-modal.component'
import { SecurityQuestionsStore } from './modules/security-questions/store/security-questions.store'

export const ConsumerAuthentication = () => {
  const params: Record<string, string> = useParams()
  clearSessionToken()
  const history = useHistory()
  const locale = useSelector(SettingsStore.getLocaleSetting)
  const consumerSessionActiveStatus = useConsumerSessionStatus()
  const authenticateError = useSelector(SecurityQuestionsStore.getError)
  const isLoading = useSelector(SecurityQuestionsStore.getIsLoading)
  const securityQuestions = useSelector(
    SecurityQuestionsStore.getSecurityQuestions
  )
  const attemptsRemaining = useSelector(
    SecurityQuestionsStore.getNumberOfAttempts
  )

  const isConsumerSessionStatusPending = useMemo(
    () => consumerSessionActiveStatus === ConsumerSessionStatus.Pending,
    [consumerSessionActiveStatus]
  )

  const isConsumerSessionStatusNotStarted = useMemo(
    () => consumerSessionActiveStatus === ConsumerSessionStatus.NotStarted,
    [consumerSessionActiveStatus]
  )

  useEffect(() => {
    if (
      consumerSessionActiveStatus === ConsumerSessionStatus.PostSubmission ||
      consumerSessionActiveStatus === ConsumerSessionStatus.PreSubmission
    ) {
      history.push(ConsumerRoute.RouteResolver)
    } else if (
      params.authCode &&
      !authenticateError &&
      !isLoading &&
      !securityQuestions &&
      consumerSessionActiveStatus === ConsumerSessionStatus.NotStarted
    ) {
      SecurityQuestionsStore.fetchSecurityQuestions(params.authCode)
    }
  }, [
    params,
    securityQuestions,
    isLoading,
    authenticateError,
    consumerSessionActiveStatus
  ])

  const modalChangeLocale = (locale: Language) => {
    SettingsStore.setLocaleSetting(locale)
  }

  const changeLocale = () => {
    if (LocaleHelper.isEnglish(locale)) {
      SettingsStore.setLocaleSetting(Language.French)
    }
    if (LocaleHelper.isFrench(locale)) {
      SettingsStore.setLocaleSetting(Language.English)
    }
  }

  const onSubmit = (answers: Answer[]) => {
    SecurityQuestionsStore.answerSecurityQuestions(answers, params.authCode)
  }

  const showSecurityQuestionsModal = () => {
    const validAttemptsRemaining =
      attemptsRemaining === null || attemptsRemaining > 0
    return (
      Boolean(securityQuestions) &&
      validAttemptsRemaining &&
      isConsumerSessionStatusNotStarted
    )
  }

  const showErrorBox = () =>
    Boolean(authenticateError) && !showSecurityQuestionsModal()

  return (
    <MainLayout
      showDefaultHeader
      hideNavBar
      hideHeader
      hideFooter
    >
      <ConsumerAuthenticationLoadingView
        showIf={isLoading || isConsumerSessionStatusPending}
      />
      <AuthenticationFailureMessageBox 
        showIf={showErrorBox()} 
        changeLocale={changeLocale}
      />
      <SecurityQuestionsModal
        isOpen={showSecurityQuestionsModal()}
        error={authenticateError}
        attemptsRemaining={attemptsRemaining}
        changeLocale={modalChangeLocale}
        securityQuestions={securityQuestions}
        currentLocale={useSelector(SettingsStore.getLocaleSetting)}
        onSubmit={onSubmit}
        loading={useSelector(
          SecurityQuestionsStore.getIsSubmittingSecurityQuestions
        )}
      />
    </MainLayout>
  )
}
