export const ConsumerPhoneValidationSectionViewMessage = {
  title: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.View.title',
    description: 'Thank you, here is your 6-digit sms code'
  },
  didNotGetCode: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.View.didNotGetCode',
    description: 'Didnt get it'
  },
  editPhoneNumber: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.View.editPhoneNumber',
    description: 'Need to edit your phone number?'
  },
  sendNewCode: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.View.sendNewCode',
    description: 'Send a new code'
  }
}
