export const OfferMessages = {
  paymentMonthlyHeader: {
    id: 'Pages.PostSubmission.Approved.Offer.paymentMonthlyHeader',
    defaultMessage: 'Est. Monthly Payment',
    description: 'Post Submission Approved Offer Monthly Payment Header'
  },

  paymentBiWeeklyHeader: {
    id: 'Pages.PostSubmission.Approved.Offer.paymentBiWeeklyHeader',
    defaultMessage: 'Est. Bi-Weekly Payment',
    description: 'Post Submission Approved Offer Bi-Weekly Payment Header'
  },

  paymentWeeklyHeader: {
    id: 'Pages.PostSubmission.Approved.Offer.paymentWeeklyHeader',
    defaultMessage: 'Est. Weekly Payment',
    description: 'Post Submission Approved Offer Weekly Payment Header'
  },

  totalFinancedHeader: {
    id: 'Pages.PostSubmission.Approved.Offer.totalFinancedHeader',
    defaultMessage: 'Total Financed',
    description: 'Post Submission Approved Offer Total Financed Header'
  },

  termHeader: {
    id: 'Pages.PostSubmission.Approved.Offer.termHeader',
    defaultMessage: 'Term',
    description: 'Post Submission Approved Offer Term Header'
  },

  term: {
    id: 'Pages.PostSubmission.Approved.Offer.term',
    defaultMessage: '{term} months',
    description: 'Term'
  },

  rateHeader: {
    id: 'Pages.PostSubmission.Approved.Offer.rateHeader',
    defaultMessage: 'Interest Rate',
    description: 'Post Submission Approved Offer Rate Header'
  },
  rate: {
    id: 'Pages.PostSubmission.Approved.Offer.rate',
    defaultMessage: '{rate}',
    description: 'Rate'
  },
  downPayment: {
    id: 'Pages.PostSubmission.Approved.Offer.downPayment',
    defaultMessage: 'Down Payment',
    description: 'Post Submission Approved Offer Down Payment Header'
  }
}
