import { ActionType, createAction } from 'typesafe-actions'
import { PreQualifierAssetType } from '../../../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { Language } from '../../../../../shared/types/language.enum'
import { PreQualifierAssetTypeSelectionState } from '../types/pre-qualifier-asset-type-selection-state.interface'

export enum PreQualifierAssetTypeSelectionAction {
  FetchAssetTypes = 'PreQualifierAssetTypeSelection/FetchAssetTypes',
  FetchAssetTypesFailure = 'PreQualifierAssetTypeSelection/FetchAssetTypesFailure',
  FetchAssetTypesSuccess = 'PreQualifierAssetTypeSelection/FetchAssetTypesSuccess',
  SetSelectedAssetType = 'PreQualifierAssetTypeSelection/SetSelectedAssetType',
  ResetPreQualifierAssetTypeState = 'PreQualifierAssetTypeSelection/ResetPreQualifierAssetTypeState'
}

const fetchAssetTypes = createAction(
  PreQualifierAssetTypeSelectionAction.FetchAssetTypes,
  (action) => (locale: Language, vendorId: string, tags: string[] | null) =>
    action({ locale, vendorId, tags })
)

const fetchAssetTypesFailure = createAction(
  PreQualifierAssetTypeSelectionAction.FetchAssetTypesFailure,
  (action) => (error: HttpError) => action(error)
)
const fetchAssetTypesSuccess = createAction(
  PreQualifierAssetTypeSelectionAction.FetchAssetTypesSuccess,
  (action) => (assetTypes: PreQualifierAssetTypeSelectionState['assetTypes']) =>
    action(assetTypes)
)

const setSelectedAssetType = createAction(
  PreQualifierAssetTypeSelectionAction.SetSelectedAssetType,
  (action) => (assetType: PreQualifierAssetType) => action(assetType)
)

const resetPreQualifierAssetTypeState = createAction(
  PreQualifierAssetTypeSelectionAction.ResetPreQualifierAssetTypeState
)

export const PreQualifierAssetTypeSelectionActions = {
  fetchAssetTypes,
  fetchAssetTypesFailure,
  fetchAssetTypesSuccess,
  setSelectedAssetType,
  resetPreQualifierAssetTypeState
}

export type PreQualifierAssetTypeSelectionActionType = ActionType<
  typeof PreQualifierAssetTypeSelectionActions
>
