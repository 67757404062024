import { createSelector, Selector } from 'reselect'
import { RootStore } from '../../../../../../../store/root.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { SubmitConsumerProfileForm } from '../../../../consumer-profile/types/submit-consumer-profile-form.interface'
import { ConsumerSubmitStepState } from '../types/consumer-submit-step-state.interface'
import { ConsumerSubmitStepActions } from './consumer-submit-step.actions'

const consumerSubmitState = (rootState: RootState): ConsumerSubmitStepState =>
  rootState.consumerState.consumerWorkflowState.consumerSubmitStepState

const getLoading: Selector<RootState, ConsumerSubmitStepState['loading']> =
  createSelector(
    consumerSubmitState,
    (state: ConsumerSubmitStepState) => state.loading
  )

const getError: Selector<RootState, ConsumerSubmitStepState['error']> =
  createSelector(
    consumerSubmitState,
    (state: ConsumerSubmitStepState) => state.error
  )

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerSubmitStepState['isWorkflowStepComplete']
> = createSelector(
  consumerSubmitState,
  (state: ConsumerSubmitStepState) => state.isWorkflowStepComplete
)

const getElectronicCommunicationConsent: Selector<
  RootState,
  ConsumerSubmitStepState['authorizedElectronicCommunication']
> = createSelector(
  consumerSubmitState,
  (state: ConsumerSubmitStepState) => state.authorizedElectronicCommunication
)

const submitConsumerProfileForPreApproval = (form: SubmitConsumerProfileForm) =>
  RootStore.dispatch(
    ConsumerSubmitStepActions.submitConsumerProfileForPreApproval(form)
  )

export const ConsumerSubmitStepStore = {
  getError,
  getIsWorkflowStepComplete,
  getElectronicCommunicationConsent,
  getLoading,
  submitConsumerProfileForPreApproval
}
