import { ActionType, createAction } from 'typesafe-actions'
import { Language } from '../../../../../../../../../../../shared/types/language.enum'
import { ConsumerAddress } from '../../../../../../../../../../../domain/consumer/consumer-profile/consumer-address.interface'
import { Provinces } from '../../../../../../../../../../../domain/province/provinces.interface'
import { ConsumerManualAddressState } from '../types/consumer-manual-address-state.interface'

export enum ConsumerManualAddressAction {
  FetchProvinces = 'ConsumerManualAddress/FetchProvinces',
  FetchProvincesFailure = 'ConsumerManualAddress/FetchProvincesFailure',
  FetchProvincesSuccess = 'ConsumerManualAddress/FetchProvincesSuccess',
  SaveManualAddress = 'ConsumerManualAddress/SaveManualAddress',
  SaveManualAddressFailure = 'ConsumerManualAddress/SaveManualAddressFailure',
  SaveManualAddressSuccess = 'ConsumerManualAddress/SaveManualAddressSuccess',
  Reset = 'ConsumerManualAddress/Reset',
  ResetSaveAddressFailure = 'ResetSaveAddressFailure,'
}

const fetchProvinces = createAction(
  ConsumerManualAddressAction.FetchProvinces,
  (action) => (locale: Language) => action(locale)
)

const fetchProvincesFailure = createAction(
  ConsumerManualAddressAction.FetchProvincesFailure,
  (action) =>
    (error: NonNullable<ConsumerManualAddressState['provincesFailure']>) =>
      action(error)
)

const fetchProvincesSuccess = createAction(
  ConsumerManualAddressAction.FetchProvincesSuccess,
  (action) => (provinces: Provinces) => action(provinces)
)

const saveManualAddress = createAction(
  ConsumerManualAddressAction.SaveManualAddress,
  (action) => (address: ConsumerAddress) => action(address)
)

const saveManualAddressFailure = createAction(
  ConsumerManualAddressAction.SaveManualAddressFailure,
  (action) =>
    (error: NonNullable<ConsumerManualAddressState['saveAddressFailure']>) =>
      action(error)
)
const saveManualAddressSuccess = createAction(
  ConsumerManualAddressAction.SaveManualAddressSuccess
)

const resetConsumerManualAddressState = createAction(
  ConsumerManualAddressAction.Reset
)

const resetSaveAddressFailure = createAction(
  ConsumerManualAddressAction.ResetSaveAddressFailure
)

export const ConsumerManualAddressActions = {
  fetchProvinces,
  fetchProvincesFailure,
  fetchProvincesSuccess,
  saveManualAddress,
  saveManualAddressFailure,
  saveManualAddressSuccess,
  resetConsumerManualAddressState,
  resetSaveAddressFailure,
}

export type ConsumerManualAddressActionType = ActionType<
  typeof ConsumerManualAddressActions
>
