import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { MerchantListItem } from './components/merchant-list-item/merchant-list-item.component'
import { MerchantListProps } from './merchant-list.props'
import { MerchantListStyles } from './merchant-list.styles'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { uatId } from '../../../../../../uat/uat-id.function'
import { MerchantListUat } from './merchant-list.uat'
import { SecondaryButton } from '../../../../../../ui-library/components/secondary-button/secondary-button.component'
import { MerchantListMessages } from './merchant-list.messages'
import { MerchantSelectionStore } from '../../store/merchant-selection.store'
import { useSelector } from 'react-redux'
import { SpinnerIcon } from '../../../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../../../shared/icons/spinner/types/spinner-size.icon.enum'

export const MerchantList = withShowIf((props: MerchantListProps) => {
  const loading = useSelector(MerchantSelectionStore.getLoading)
  const currentPage = useSelector(MerchantSelectionStore.getCurrentPage)
  const totalResultsCount = useSelector(
    MerchantSelectionStore.getTotalResultsCount
  )

  const selectedMerchants = useMemo(
    () => props.selectedMerchants.map((merchant) => merchant.id),
    [props.selectedMerchants]
  )

  const getListOfMerchants = () =>
    props.merchants.map((merchant) => (
      <MerchantListItem
        key={merchant.id}
        merchant={merchant}
        selectMerchant={props.selectMerchant}
        uat={uatId(MerchantListUat.listItems)}
        disabled={selectedMerchants.includes(merchant.id)}
      />
    ))

  const handleLoadMore = useCallback(() => {
    MerchantSelectionStore.fetchMerchants(currentPage + 1, props.selectedAssetSubtype)
  }, [currentPage])

  const canLoadMore = useMemo(
    () =>
      totalResultsCount !== null &&
      props.merchants.length < totalResultsCount &&
      !loading &&
      totalResultsCount > 0,
    [props.merchants.length, totalResultsCount, loading]
  )

  return (
    <>
      <div className={MerchantListStyles.listContainer}>
        {getListOfMerchants()}
      </div>
      <SecondaryButton
        showIf={canLoadMore}
        cssContainerClasses={MerchantListStyles.loadMoreButton}
        onClick={handleLoadMore}
        uat={uatId(MerchantListUat.loadMoreButton)}
      >
        <FormattedMessage {...MerchantListMessages.loadMoreButton} />
      </SecondaryButton>
      <div className={MerchantListStyles.loadingContainer(canLoadMore)}>
        <SpinnerIcon size={SpinnerIconSize.LARGE} showIf={loading} />
      </div>
    </>
  )
})
