import { Offer } from './offer.interface'

export function setOfferAsSelected(
  selectedOffer: Offer,
  offers: Offer[]
): Offer[] {
  return offers.map((offer) => {
    if (offer.id === selectedOffer.id) {
      return setOfferSelected(offer)
    }
    return setOfferNotSelected(offer)
  })
}

export function getSelectedOffer(offers: Offer[] = []): Offer | null {
  return offers.find((offer) => offer.isSelected) || null
}
function setOfferNotSelected(offer: Offer): Offer {
  return {
    ...offer,
    isSelected: false
  }
}

function setOfferSelected(offer: Offer): Offer {
  return {
    ...offer,
    isSelected: true
  }
}
