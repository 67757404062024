import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerDobStepState } from '../types/consumer-dob-step-state.interface'

export enum ConsumerDobAction {
  SaveConsumerDob = 'ConsumerDobStep/SaveConsumerDob',
  SaveConsumerDobFailure = 'ConsumerDobStep/SaveConsumerDobFailure',
  SaveConsumerDobSuccess = 'ConsumerDobStep/SaveConsumerDobSuccess',
  ResetConsumerDobState = 'ConsumerDobStep/ResetConsumerDobState'
}

const saveConsumerDob = createAction(
  ConsumerDobAction.SaveConsumerDob,
  (action) => (consumerDob: NonNullable<ConsumerDobStepState['dateOfBirth']>) =>
    action(consumerDob)
)

const saveConsumerDobFailure = createAction(
  ConsumerDobAction.SaveConsumerDobFailure,
  (action) => (error: NonNullable<ConsumerDobStepState['error']>) =>
    action(error)
)

const saveConsumerDobSuccess = createAction(
  ConsumerDobAction.SaveConsumerDobSuccess,
  (action) => (consumerDob: NonNullable<ConsumerDobStepState['dateOfBirth']>) =>
    action(consumerDob)
)

const resetConsumerDobState = createAction(
  ConsumerDobAction.ResetConsumerDobState
)

export const ConsumerDobStepActions = {
  saveConsumerDob,
  saveConsumerDobFailure,
  saveConsumerDobSuccess,
  resetConsumerDobState
}

export type ConsumerDobStepActionType = ActionType<
  typeof ConsumerDobStepActions
>
