import { Popup } from 'react-map-gl'
import { CloseIcon } from '../../../../shared/icons/close/close.icon'
import { formatToPhoneNumber } from '../../../../ui-library/components/input-field/components/phone-input/types/format-to-phone-number.function'
import { MapLibraryMarkerPopupProps } from './map-library-marker-popup.props'
import { MapLibraryMarkerPopupStyles } from './map-library-marker-popup.styles'

export const MapLibraryMarkerPopup = (props: MapLibraryMarkerPopupProps) => {
  if (!props.marker) {
    return null
  }

  return (
    <Popup
      anchor="top"
      latitude={props.marker.coordinates.latitude}
      longitude={props.marker.coordinates.longitude}
      closeButton={false}
      closeOnClick={false}
      style={MapLibraryMarkerPopupStyles.popup}
    >
      <div className={MapLibraryMarkerPopupStyles.container}>
        <div className={MapLibraryMarkerPopupStyles.closeIconContainer}>
          <CloseIcon
            className={MapLibraryMarkerPopupStyles.closeIcon}
            onClick={props.onClose}
          />
        </div>
        <div className={MapLibraryMarkerPopupStyles.markerName}>
          {props.marker?.name}
        </div>
        <div className={MapLibraryMarkerPopupStyles.markerAddress}>
          {props.marker?.address}
        </div>
        <div className={MapLibraryMarkerPopupStyles.markerPhone}>
          {formatToPhoneNumber(props.marker?.phoneNumber ?? '')}
        </div>
      </div>
    </Popup>
  )
}
