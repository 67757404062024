import { useSelector } from 'react-redux'
import { MainLayout } from '../../../../shared/components/main-layout/main-layout.component'
import { withShowIf } from '../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { MerchantSelectionStore } from './store/merchant-selection.store'
import { SpinnerIcon } from '../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { MerchantSelectionStyles } from './merchant-selection.styles'
import { MerchantSelectionView } from './components/merchant-selection-view/merchant-selection-view.component'
import { useEffect, useMemo } from 'react'
import { MerchantSelectionErrorView } from './components/merchant-selection-error-view/merchant-selection-error-view.component'
import { ConsumerProfileStore } from '../consumer-profile/store/consumer-profile.store'
import { PreApprovalStatus } from '../../../../domain/consumer/consumer-profile/types/pre-approval-status.enum'
import { useHistory } from 'react-router'
import { ConsumerRoute } from '../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { MerchantSelectionNotFoundView } from './components/merchant-selection-not-found-view/merchant-selection-not-found-view.component'
import { addVendorIdToPath } from '../../../../shared/helpers/path-helper'
import { AssetSubtypeStore } from '../asset-subtype-selection/store/asset-subtype-selection.store'
import { Language } from '../../../../shared/types/language.enum'
import { SettingsStore } from '../../../app/modules/settings/store/settings.store'
import { ConsumerProfileState } from '../consumer-profile/types/consumer-profile-state.interface'
import { isMerchantLocatorProductSelectionEnabled } from './merchant-selection.functions'
import { ShowIfFragment } from '../../../../ui-library/components/show-if/show-if-fragment.component'

export const MerchantSelection = withShowIf(() => {
  const locale: Language = useSelector(SettingsStore.getLocaleSetting)
  const preApprovalStatus = useSelector(
    ConsumerProfileStore.getPreApprovalStatus
  )
  const loadingMerchants = useSelector(MerchantSelectionStore.getLoading)
  const merchantError = useSelector(MerchantSelectionStore.getFetchError)
  const merchants = useSelector(MerchantSelectionStore.getMerchants)
  const selectedMerchants = useSelector(
    MerchantSelectionStore.getConsumerProfileSelectedMerchants
  )
  const assetSubtypes = useSelector(AssetSubtypeStore.getAssetSubtypes)
  const selectedAssetSubtype = useSelector(AssetSubtypeStore.getSelectedAssetSubtype)
  const loadingAssetSubtypes = useSelector(AssetSubtypeStore.getLoading)
  const consumerProfileAssetSubtype: ConsumerProfileState['assetSubtypeId'] =
    useSelector(ConsumerProfileStore.getConsumerAssetSubtype)
  const assetSubtypesError = useSelector(AssetSubtypeStore.getFetchError)
  const history = useHistory()
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)
  const totalResultsCount = useSelector(MerchantSelectionStore.getTotalResultsCount) 

  const goBack = () => {
    history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitApproved))
  }

  useEffect(() => {
    MerchantSelectionStore.fetchConsumerProfileSelectedMerchants()

    return () => {
      MerchantSelectionStore.resetMerchants()
      MerchantSelectionStore.resetMerchantSelectionForm()
    }
  }, [])

  useEffect(() => {
    if (isMerchantLocatorProductSelectionEnabled()) {
      AssetSubtypeStore.fetchAssetSubtypes(locale)
    }
  }, [locale])

  useEffect(() => {
    if (consumerProfileAssetSubtype) {
      AssetSubtypeStore.setSelectedAssetSubtypeId(consumerProfileAssetSubtype ? String(consumerProfileAssetSubtype) : null)
    }
  }, [consumerProfileAssetSubtype])

  useEffect(() => {
    if (isMerchantLocatorProductSelectionEnabled() && selectedAssetSubtype && !loadingMerchants) {
      MerchantSelectionStore.resetMerchants();
      MerchantSelectionStore.fetchMerchants(1, selectedAssetSubtype);
    }
  }, [selectedAssetSubtype])



  useEffect(() => {
    if (preApprovalStatus === PreApprovalStatus.Approved && !loadingMerchants && totalResultsCount === null) {
      MerchantSelectionStore.fetchMerchants(1, consumerProfileAssetSubtype ? String(consumerProfileAssetSubtype) : null)
    } else if (preApprovalStatus !== PreApprovalStatus.Approved) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitLoading))
    }
  }, [preApprovalStatus, consumerProfileAssetSubtype, totalResultsCount])

  const shouldShowMerchants = useMemo<boolean>(() => {
    return !Boolean(merchantError) && !Boolean(assetSubtypesError)
  }, [merchantError, assetSubtypesError])

  const shouldShowNotFound = useMemo<boolean>(() => {
    return !loadingMerchants && merchants.length === 0 && Boolean(selectedAssetSubtype) && !Boolean(merchantError) && !Boolean(assetSubtypesError)
  }, [loadingMerchants, merchants, selectedAssetSubtype, merchantError, assetSubtypesError])

  const shouldShowError = useMemo<boolean>(() => {
    return Boolean(merchantError) || Boolean(assetSubtypesError)
  }, [merchantError, assetSubtypes])

  return (
    <MainLayout 
      goBack={goBack} 
      showContactInfoFooter 
      noHorizontalPadding 
      centerAlignDisclaimer
    >
      <ShowIfFragment showIf={loadingAssetSubtypes && !shouldShowMerchants}>
        <div className={MerchantSelectionStyles.loadingContainer}>
          <SpinnerIcon size={SpinnerIconSize.LARGE} />
        </div>
      </ShowIfFragment>
      <MerchantSelectionView
        showIf={shouldShowMerchants}
        assetSubtypes={assetSubtypes}
        loadingMerchant={loadingMerchants}
        merchants={merchants}
        selectedAssetSubtype={selectedAssetSubtype}
        selectedMerchants={selectedMerchants}
        goBack={goBack}
      />

      <MerchantSelectionNotFoundView showIf={shouldShowNotFound} />
      <MerchantSelectionErrorView showIf={shouldShowError} />
    </MainLayout>
  )
})
