import React, { useCallback } from 'react'
import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component'
import { ConsumerDobView } from './components/consumer-dob-view/consumer-dob-view.component'
import { ConsumerDobStepProps } from './consumer-dob-step.props'

export const ConsumerDobStep = (props: ConsumerDobStepProps) => {
  const onComplete = () => props.onComplete(props.step)

  const goBack = useCallback(() => {
    props.goBack(props.step)
  }, [props.step, props.goBack])

  return (
    <MainLayout goBack={props.goBack} step={props.step} hideFooter>
      <ConsumerDobView
        onComplete={onComplete}
        goBack={goBack}
        step={props.step}
      />
    </MainLayout>
  )
}
