import { EmailFieldSchema } from '../../../../../../../../libraries/form-schema/form-field-schema/email-field-schema'
import { FormSchema } from '../../../../../../../../libraries/form-schema/form-schema'
import { ConsumerEmailFormSchema } from './types/consumer-email-form-schema.interface'

export function generateConsumerEmailFormSchema(): FormSchema<ConsumerEmailFormSchema> {
  return new FormSchema<ConsumerEmailFormSchema>({
    email: new EmailFieldSchema('email')
      .setMinLength(1)
      .setMaxLength(100)
      .isRequired()
  })
}
