import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { MainLayout } from '../../../../../../../../shared/components/main-layout/main-layout.component'
import { SpinnerIcon } from '../../../../../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { ConsumerSubmitStepStore } from '../../../consumer-submit-step/store/consumer-submit-step.store'
import { ConsumerAutoSubmissionMessages } from './consumer-auto-submission-view.messages'
import { ConsumerAutoSubmissionStyles } from './consumer-auto-submission-view.styles'
import { SubmitConsumerProfileFormFactory } from '../../../../types/submit-consumer-profile-form.factory'
import { useSelector } from 'react-redux'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'

export const ConsumerAutoSubmissionView = () => {
  const refId = useSelector(ConsumerProfileStore.getRefId);
  const referrer = useSelector(ConsumerProfileStore.getReferrer);
  const eventId = useSelector(ConsumerProfileStore.getEventId);
  const campaignId = useSelector(ConsumerProfileStore.getCampaignId);

  useEffect(() => {
    submit()
  }, [])

  const submit = () => {
    ConsumerSubmitStepStore.submitConsumerProfileForPreApproval(
      SubmitConsumerProfileFormFactory.create(true,true, refId, referrer, eventId, campaignId)
    )
  }

  return (
    <MainLayout centerAlignDisclaimer>
      <div className={ConsumerAutoSubmissionStyles.container}>
        <SpinnerIcon size={SpinnerIconSize.LARGE} />
        <Paragraph className={ConsumerAutoSubmissionStyles.paragraph}>
          <FormattedMessage
            {...ConsumerAutoSubmissionMessages.loadingMessage}
          />
        </Paragraph>
      </div>
    </MainLayout>
  )
}
