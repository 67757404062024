import { DobFieldSchema } from '../../../../../../../../libraries/form-schema/form-field-schema/dob-field-schema'
import { FormSchema } from '../../../../../../../../libraries/form-schema/form-schema'
import { ConsumerDobFormErrorMessageId } from './types/consumer-dob-form-error-message-id.enum'
import { ConsumerDobFormSchema } from './types/consumer-dob-form-schema.interface'

export function generateConsumerDobFormSchema(): FormSchema<ConsumerDobFormSchema> {
  return new FormSchema<ConsumerDobFormSchema>({
    dateOfBirth: new DobFieldSchema('dateOfBirth')
      .setDobFormat(
        'MM/dd/yyyy',
        ConsumerDobFormErrorMessageId.InvalidDobFormat
      )
      .setOldestYear(1900, ConsumerDobFormErrorMessageId.InvalidDobFormat)
      .setYoungestAge(18, ConsumerDobFormErrorMessageId.MinAgeRequirement)
      .isRequired()
  })
}
