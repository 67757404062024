import { ConsumerProfileErrorCode } from '../../../../../../../../../../../../domain/consumer/consumer-profile/types/consumer-profile-error-codes.enum';
import { HttpError } from '../../../../../../../../../../../../shared/types/http-error.interface';
import { LocalizationMessage } from '../../../../../../../../../../../../shared/types/localization-message.interface';
import { ConsumerPhoneValidationErrorGeneralUat } from '../consumer-phone-validation-section-error/components/consumer-phone-validation-error-general/consumer-phone-validation-error-general.uat';
import { ConsumerPhoneValidationSectionErrorMessage } from '../consumer-phone-validation-section-error/consumer-phone-validation-section-error.message';
import { ConsumerPhoneValidationSectionErrorUat } from '../consumer-phone-validation-section-error/consumer-phone-validation-section-error.uat';
import { ConsumerPhoneValidationResendCodeBannerDangerMessage } from './consumer-phone-validation-resend-code-banner-danger.message';
import { ConsumerPhoneValidationResendCodeErrorUat } from './consumer-phone-validation-resend-code-error.uat';


function mapErrorToLocalizationMessage(error:HttpError|null):LocalizationMessage| null{
    if(!error || !error.code){
        return null
    }

    switch(error.code){
        case ConsumerProfileErrorCode.IncorrectVerificationCode:
            return ConsumerPhoneValidationSectionErrorMessage.invalidCode
        case ConsumerProfileErrorCode.VerificationAttemptsExhausted:
            return ConsumerPhoneValidationSectionErrorMessage.verificationAttemptsExhausted
        case ConsumerProfileErrorCode.VerificationCodeExpired:
            return ConsumerPhoneValidationResendCodeBannerDangerMessage.verificationCodeExpired
        case ConsumerProfileErrorCode.ResetVerificationCodeAttemptsExhausted:
         return ConsumerPhoneValidationResendCodeBannerDangerMessage.maxResetCode
        case ConsumerProfileErrorCode.IncorrectVerificationCode:
            return ConsumerPhoneValidationSectionErrorMessage.invalidCode
        case ConsumerProfileErrorCode.VerificationAttemptsExhausted:
            return ConsumerPhoneValidationSectionErrorMessage.verificationAttemptsExhausted
        default:
            return null
    }
}

function mapErrorToUatId(error:HttpError | null):string{
    if(!error?.code){
        return ConsumerPhoneValidationErrorGeneralUat.error;
    }

    switch(error.code){
        case ConsumerProfileErrorCode.IncorrectVerificationCode:
            return ConsumerPhoneValidationSectionErrorUat.invalidCode
        case ConsumerProfileErrorCode.VerificationAttemptsExhausted:
            return ConsumerPhoneValidationSectionErrorUat.verificationAttemptsExhausted
        case ConsumerProfileErrorCode.ResetVerificationCodeAttemptsExhausted:    
            return ConsumerPhoneValidationResendCodeErrorUat.error 
        default:
            return ConsumerPhoneValidationErrorGeneralUat.error;
    }
}



function isDangerError(error:HttpError | null):boolean{
    if(!error?.code){
        return false;
    }

    return error.code === ConsumerProfileErrorCode.ResetVerificationCodeAttemptsExhausted || error.code === ConsumerProfileErrorCode.VerificationAttemptsExhausted;
}

function isNormalError(error:HttpError | null):boolean{
   if(!error?.code){
    return false;
   } 

   return !isDangerError(error)
}

function getDangerGeneralErrorMessage():LocalizationMessage{
    return ConsumerPhoneValidationResendCodeBannerDangerMessage.general
}

function getStandardGeneralErrorMessage():LocalizationMessage{
    return ConsumerPhoneValidationSectionErrorMessage.general
}

export const ConsumerPhoneVerificationCodeErrorFunctions = {
    mapErrorToLocalizationMessage,
    mapErrorToUatId,
    isDangerError,
    isNormalError,
    getDangerGeneralErrorMessage,
    getStandardGeneralErrorMessage
}