import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerSessionTokenDto } from '../../../../../../../api/bff/dto/consumer-session-token/consumer-session-token.dto'
import { Answer } from '../../../../../../../domain/consumer/security-questions/types/questions/answer.interface'
import { QuestionType } from '../../../../../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { SecurityQuestionsError } from '../types/security-questions-error/security-questions-error.interface'

export enum SecurityQuestionsAction {
  FetchSecurityQuestions = 'SecurityQuestions/FetchSecurityQuestions',
  FetchSecurityQuestionsSuccess = 'SecurityQuestions/FetchSecurityQuestionsSuccess',
  FetchSecurityQuestionsError = 'SecurityQuestions/FetchSecurityQuestionsError',
  AnswerSecurityQuestions = 'SecurityQuestions/AnswerSecurityQuestions',
  AnswerSecurityQuestionsSuccess = 'SecurityQuestions/AnswerSecurityQuestionsSuccess',
  AnswerSecurityQuestionsError = 'SecurityQuestions/AnswerSecurityQuestionsError'
}

const fetchSecurityQuestions = createAction(
  SecurityQuestionsAction.FetchSecurityQuestions,
  (action) => (authCode: string) => action(authCode)
)

const fetchSecurityQuestionsSuccess = createAction(
  SecurityQuestionsAction.FetchSecurityQuestionsSuccess,
  (action) => (questions: QuestionType[]) => action(questions)
)

const fetchSecurityQuestionsError = createAction(
  SecurityQuestionsAction.FetchSecurityQuestionsError,
  (action) => (error?: HttpError) => action(error)
)

const answerSecurityQuestions = createAction(
  SecurityQuestionsAction.AnswerSecurityQuestions,
  (action) => (answers: Answer[], authCode: string) =>
    action({
      answers,
      authCode
    })
)

const answerSecurityQuestionsSuccess = createAction(
  SecurityQuestionsAction.AnswerSecurityQuestionsSuccess,
  (action) => (result: ConsumerSessionTokenDto) => action(result)
)

const answerSecurityQuestionsError = createAction(
  SecurityQuestionsAction.AnswerSecurityQuestionsError,
  (action) => (error: SecurityQuestionsError) => action(error)
)

export const SecurityQuestionsActions = {
  fetchSecurityQuestions,
  fetchSecurityQuestionsSuccess,
  fetchSecurityQuestionsError,
  answerSecurityQuestions,
  answerSecurityQuestionsSuccess,
  answerSecurityQuestionsError
}

export type SecurityQuestionsActionsType = ActionType<
  typeof SecurityQuestionsActions
>
