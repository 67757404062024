import { Epic } from 'redux-observable'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { filter, map } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { Action } from '../../../../../../../store/root.actions'
import { ConsumerProfileActions } from '../../../../consumer-profile/store/consumer-profile.actions'
import { ConsumerSubmitStepActions } from './consumer-submit-step.actions'

const submitConsumerProfileForPreApprovalEpic: Epic<
  Action,
  Action,
  RootState
> = (action$) =>
  action$.pipe(
    filter(
      isActionOf(ConsumerSubmitStepActions.submitConsumerProfileForPreApproval)
    ),
    map((action) =>
      ConsumerProfileActions.submitConsumerProfile(action.payload)
    )
  )

const submitConsumerProfileForPreApprovalSuccessEpic: Epic<
  Action,
  Action,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.submitConsumerProfileSuccess)),
    map((action) =>
      ConsumerSubmitStepActions.submitConsumerProfileForPreApprovalSuccess(
        action.payload
      )
    )
  )

const submitConsumerProfileForPreApprovalFailureEpic: Epic<
  Action,
  Action,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.submitConsumerProfileFailure)),
    map((action) =>
      ConsumerSubmitStepActions.submitConsumerProfileForPreApprovalFailure(
        action.payload
      )
    )
  )

export const consumerSubmitStepEpics = [
  submitConsumerProfileForPreApprovalEpic,
  submitConsumerProfileForPreApprovalSuccessEpic,
  submitConsumerProfileForPreApprovalFailureEpic
]
