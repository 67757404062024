import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { OfferElementProps } from './offer-element.props'

export const OfferElement = (props: OfferElementProps) => {
  return (
    <div className="bg-white min-h-min w-full text-left">
      <h1>{props.title}</h1>
      <Paragraph className={props.msgClassName}>{props.msg}</Paragraph>
    </div>
  )
}
