import { createAppState } from '../../../modules/app/types/app-state.functions'
import { createConsumerState } from '../../../modules/consumer/types/consumer-state.functions'
import { RootState } from './root-state.interface'

export function createRootState(): RootState {
  return {
    appState: createAppState(),
    consumerState: createConsumerState()
  }
}
