import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {GeoLocationActions} from './geo-location.actions';
import { RootState } from '../../../../../store/types/root-state/root-state.interface';
import { GeoLocationService } from '../../../../../api/bff/services/geo-location.service';

const fetchProvinceEpic: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(GeoLocationActions.fetchProvince)),
    map(action => action.payload),
    mergeMap(coordinates =>
      new GeoLocationService().fetchProvince(coordinates).pipe(
        map(result => GeoLocationActions.fetchProvinceSuccess(result)),
        catchError(error => of(GeoLocationActions.fetchProvinceFailure(error))),
      ),
    ),
  );

export const geoLocationEpics = [fetchProvinceEpic];
