import { Coordinates } from '@mapbox/mapbox-sdk/lib/classes/mapi-request';
import { LngLatBounds } from 'mapbox-gl';
import { MapMarker } from './types/map-place.interfaces';

export const getMapBounds = (
  markers?: MapMarker[],
  focalPoint?: Coordinates,
): LngLatBounds | null => {
  if (
    markers !== undefined &&
    markers.length &&
    focalPoint !== undefined
  ) {
    const bounds = new LngLatBounds();
    markers.forEach(marker => {
      bounds.extend([
        marker.coordinates?.longitude || -50,
        marker.coordinates?.latitude || 0,
      ]);
    });
    bounds.extend([focalPoint[1], focalPoint[0]]);
    return bounds;
  }
  return null;
};