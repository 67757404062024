import { createConsumerAddressStepState } from '../../modules/consumer-address-step/types/consumer-address-step-state.functions'
import { createConsumerAssetSubtypeState } from '../../modules/consumer-asset-subtype-step/types/consumer-asset-subtype-step.functions'
import { createConsumerDobStepState } from '../../modules/consumer-dob-step/types/consumer-dob-step-state.functions'
import { createConsumerEmailStepState } from '../../modules/consumer-email-step/types/consumer-email-step-state.function'
import { createConsumerNetIncomeStepState } from '../../modules/consumer-net-income-step/types/consumer-net-income-step-state.functions'
import { createConsumerPhoneNumberStepState } from '../../modules/consumer-phone-number-step/types/consumer-phone-number-step-state.functions'
import { createConsumerPhoneValidationStepState } from '../../modules/consumer-phone-validation/types/consumer-phone-validation-step-state.functions'
import { createConsumerSubmitStepState } from '../../modules/consumer-submit-step/types/consumer-submit-step-state.functions'
import { createConsumerWorkflowStepsState } from '../../modules/consumer-workflow-steps/types/consumer-workflow-steps-state.functions'
import { ConsumerWorkflowState } from './consumer-workflow-state.interface'

export function createConsumerWorkflowState(): ConsumerWorkflowState {
  return {
    consumerWorkflowStepsState: createConsumerWorkflowStepsState(),
    consumerPhoneNumberStepState: createConsumerPhoneNumberStepState(),
    consumerPhoneValidationStepState: createConsumerPhoneValidationStepState(),
    consumerDobStepState: createConsumerDobStepState(),
    consumerAssetSubtypeStepState: createConsumerAssetSubtypeState(),
    consumerEmailStepState: createConsumerEmailStepState(),
    consumerAddressStepState: createConsumerAddressStepState(),
    consumerNetIncomeStepState: createConsumerNetIncomeStepState(),
    consumerSubmitStepState: createConsumerSubmitStepState()
  }
}
