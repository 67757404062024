import { ConsumerDobFormErrorMessageId } from '../../types/consumer-dob-form-error-message-id.enum'

export const DobValidationMessageMessages = {
  [ConsumerDobFormErrorMessageId.InvalidDobFormat]: {
    id: 'Pages.ConsumerDob.Form.ValidationMessage.invalidDobFormat',
    description: 'date of birth field '
  },
  [ConsumerDobFormErrorMessageId.MinAgeRequirement]: {
    id: 'Pages.ConsumerDob.Form.ValidationMessage.minAgeRequirement',
    description: 'date of birth field '
  }
}
