import { mergeMap, Observable } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'
import { PRE_QUALIFIER_VERSION } from '../../pre-qualifier-version'
import { AcceptLanguageHeaderValue } from './types/accept-language-header-value.type'
import { AuthorizationHeaderValue } from './types/authorization-header-value.type'
import { FetchRequest } from './types/fetch-request.interface'
import { v4 as uuidv4 } from 'uuid'
import { buildUrlWithQueryParams } from './types/query-string-formatter.functions'

export class FetchService {
  public createRequest(request: FetchRequest): Observable<Response> {
    const requestData = this.buildRequestData(request)

    let requestUrl

    if (request.query) {
      requestUrl = buildUrlWithQueryParams(request.url, request.query)
    } else {
      requestUrl = request.url
    }

    return fromFetch(requestUrl, requestData).pipe(
      mergeMap(async (response: Response): Promise<Response> => {
        if (!response.ok) {
          throw await response.json()
        }
        return response
      })
    )
  }

  private buildRequestData(request: FetchRequest): RequestInit {
    return {
      method: request.method,
      body: request.body ? JSON.stringify(request.body) : undefined,
      headers: this.generateHeaders(request.sessionToken, request.locale)
    }
  }

  private generateHeaders = (
    token: AuthorizationHeaderValue = null,
    locale: AcceptLanguageHeaderValue = null
  ): Headers => {
    const headers: Headers = new Headers()
    headers.append('Content-Type', 'application/json')

    if (token) {
      headers.append('Authorization', `Bearer ${token}`)
    }

    if (locale) {
      headers.append('accept-language', locale)
    }

    headers.append('X-LCQB-Version', PRE_QUALIFIER_VERSION)
    headers.append('X-LCQB-Request-ID', uuidv4())

    return headers
  }
}
