export const TextInputStyles = {
  container: 'relative',
  field: (withIcon: boolean = false, fullWidth: boolean = false) =>
    `${
      withIcon ? 'pl-8' : ''
    } border-gray70 font-light appearance-none text-16px border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline focus:border-blue400 focus:border-2 ${
      !fullWidth ? 'sm:w-328' : ''
    }`,
  error: `border-red200`
}
