import { createSelector } from 'reselect'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerAssetSubtypeStepState } from '../types/consumer-asset-subtype-step-interface'
import { Selector } from 'react-redux'
import { RootStore } from '../../../../../../../store/root.store'
import { ConsumerAssetSubtypeStepActions } from './consumer-asset-subtype-step.actions'

const getConsumerAssetSubtypeState = (
  rootState: RootState
): ConsumerAssetSubtypeStepState =>
  rootState.consumerState.consumerWorkflowState.consumerAssetSubtypeStepState

const getAssetSubtypeId: Selector<
  RootState,
  ConsumerAssetSubtypeStepState['assetSubtypeId']
> = createSelector(
  getConsumerAssetSubtypeState,
  (state: ConsumerAssetSubtypeStepState) => state.assetSubtypeId
)

const getError: Selector<RootState, ConsumerAssetSubtypeStepState['error']> =
  createSelector(
    getConsumerAssetSubtypeState,
    (state: ConsumerAssetSubtypeStepState) => state.error
  )

const isLoading: Selector<RootState, ConsumerAssetSubtypeStepState['loading']> =
  createSelector(
    getConsumerAssetSubtypeState,
    (state: ConsumerAssetSubtypeStepState) => state.loading
  )

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerAssetSubtypeStepState['isWorkflowStepComplete']
> = createSelector(
  getConsumerAssetSubtypeState,
  (state: ConsumerAssetSubtypeStepState) => state.isWorkflowStepComplete
)

const saveConsumerAssetSubtypeId = (
  assetSubtypeId: NonNullable<ConsumerAssetSubtypeStepState['assetSubtypeId']>
) =>
  RootStore.dispatch(
    ConsumerAssetSubtypeStepActions.saveConsumerAssetSubtypeId(assetSubtypeId)
  )

export const ConsumerAssetSubtypeStore = {
  getAssetSubtypeId,
  getError,
  isLoading,
  getIsWorkflowStepComplete,
  saveConsumerAssetSubtypeId
}
