import enMessages from '../../../../../i18n/locales/en.json'
import frMessages from '../../../../../i18n/locales/fr.json'
import { LocaleHelper } from '../../../../../shared/helpers/locale-helper'
import { Language } from '../../../../../shared/types/language.enum'
import { getParamFromUrl, hasParam } from './url-search-params.functions'

export const getLocaleMessages = (code: Language) =>
  LocaleHelper.isFrench(code) ? frMessages : enMessages


export function getLocale(queryString:string, windowNavigatorLanguage:string):Language{
  if(!hasLocale(queryString)){
    return LocaleHelper.determineLocale(windowNavigatorLanguage);
  }

  return LocaleHelper.determineLocale(getParamFromUrl(queryString, 'locale'))
}

export function hasLocale(queryString:string):boolean{
  return hasParam(queryString, 'locale');
}
