export const PreQualifierAssetTypeDetailsDialogMessages = {
  competitiveRateTitle: {
    id: 'Pages.PreQualifierAssetTypeSelection.AssetTypeDialog.competitiveRateTitle',
    description: 'Competitive Rate title'
  },
  termsTitle: {
    id: 'Pages.PreQualifierAssetTypeSelection.AssetTypeDialog.termsTitle',
    description: 'Loan terms title'
  },
  preQualifyAmountTitle: {
    id: 'Pages.PreQualifierAssetTypeSelection.AssetTypeDialog.preQualifyAmountTitle',
    description: 'Pre-Qualifier Amount title'
  },
  disclaimer: {
    id: 'Pages.PreQualifierAssetTypeSelection.AssetTypeDialog.disclaimer',
    description: 'disclaimer message'
  },
  closeButtonLabel: {
    id: 'Pages.PreQualifierAssetTypeSelection.AssetTypeDialog.closeButtonLabel',
    description: 'close button label'
  },
  selectButtonLabel: {
    id: 'Pages.PreQualifierAssetTypeSelection.AssetTypeDialog.selectButtonLabel',
    description: 'select button label'
  },
  upToLabel: {
    id: 'Pages.PreQualifierAssetTypeSelection.AssetTypeDialog.upToLabel',
    description: 'select button label'
  }
}
