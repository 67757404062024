import { Observable } from 'rxjs'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { BffService } from '../bff.service'
import { Coordinates } from '../../../domain/coordinate/coordinate.interface'
import { Province } from '../../../domain/province/province.interface'

export class GeoLocationService extends BffService {
  public fetchProvince(coordinates: Coordinates): Observable<Province> {
    return super.createAuthorizedRequest({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/geocode/province?latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`,
    })
  }
}
