export const SecurityQuestionFormInputsMessages = {
  applicantPrimaryPhoneLast4DigitsQuestion: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogFormView.applicant4DigitCodeQuestion',
    description: 'Questions dialog 4 digit code'
  },
  applicantPrimaryPhoneLast4DigitsPlaceholder: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogFormView.applicant4DigitCodePlaceholder',
    description: 'Questions dialog 4 digit code placeholder'
  },
  requiredField: {
    id: 'Pages.ConsumerAuthentication.QuestionsDialogFormView.requiredField',
    description: 'Questions dialog required field'
  }
}
