import React from 'react'
import { Provider } from 'react-redux'
import { InitializerComponent } from './components/initializer/initializer.component'
import { RootStore } from '../../store/root.store'
import { AppStyles } from './app.styles'
import { ConnectedRouter } from 'connected-react-router'
import { uatId } from '../../uat/uat-id.function'
import { appUat } from './app.uat'
import '../../style/index.scss'
import { history } from '../../store/types/root-store.functions'
import 'mapbox-gl/dist/mapbox-gl.css'
import { initializeIntercom } from '../../intercom/intercom'

export const App = () => {
  initializeIntercom();

  return (
    <div className={AppStyles.container} {...uatId(appUat.root)}>
      <Provider store={RootStore}>
        <ConnectedRouter history={history}>
          <InitializerComponent />
        </ConnectedRouter>
      </Provider>
    </div>
  )  
};
