import { ConsumerPhoneNumberStepState } from './consumer-phone-number-step-state.interface'

export function createConsumerPhoneNumberStepState(): ConsumerPhoneNumberStepState {
  return {
    phoneNumber: null,
    loading: false,
    error: null,
    isWorkflowStepComplete: false
  }
}
