import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { SpinnerIcon } from '../../../../../../../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../../../../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { uatId } from '../../../../../../../../../../uat/uat-id.function'
import { InlineMessage } from '../../../../../../../../../../ui-library/components/inline-message/inline-message.component'
import { withShowIf } from '../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerPhoneValidationStepStore } from '../../../../store/consumer-phone-validation-step.store'
import { ConsumerPhoneValidationStepState } from '../../../../types/consumer-phone-validation-step-state.interface'
import { ConsumerPhoneValidationStandardError } from './components/consumer-phone-validation-section-error/consumer-phone-validation-standard-error.component'
import { ConsumerPhoneValidationSectionForm } from './components/consumer-phone-validation-section-form/consumer-phone-validation-section-form.component'
import { ConsumerPhoneValidationSectionViewMessage } from './consumer-phone-validation-section-view.message'
import { ConsumerPhoneValidationSectionViewProps } from './consumer-phone-validation-section-view.props'
import { ConsumerPhoneValidationSectionViewStyles } from './consumer-phone-validation-section-view.styles'
import { ConsumerPhoneValidationSectionViewUat } from './consumer-phone-validation-section-view.uat'
import { ConsumerPhoneValidationResendCodeBannerInfo } from './components/consumer-phone-validation-resend-code-banner/components/consumer-phone-validation-resend-code-banner-info/consumer-phone-validation-resend-code-banner-info.component'
import { ConsumerPhoneVerificationCodeErrorFunctions } from './components/consumer-phone-verification-error/consumer-phone-verification-code-error.functions'
import { ConsumerPhoneValidationResendCodeBannerStyle } from './components/consumer-phone-validation-resend-code-banner/consumer-phone-validation-resend-code-banner.style'
import { ConsumerPhoneDangerError } from './components/consumer-phone-verification-error/consumer-phone-danger-error.component'

export const ConsumerPhoneValidationSectionView = withShowIf(
  (props: ConsumerPhoneValidationSectionViewProps) => {
    const loading: ConsumerPhoneValidationStepState['phoneValidationLoading'] =
      useSelector(
        ConsumerPhoneValidationStepStore.getPhoneValidationStateLoading
      )

    const phoneValidationError: ConsumerPhoneValidationStepState['phoneValidationError'] =
      useSelector(ConsumerPhoneValidationStepStore.getPhoneValidationError)

    const isWorkflowStepComplete: ConsumerPhoneValidationStepState['isWorkflowStepComplete'] =
      useSelector(ConsumerPhoneValidationStepStore.getIsWorkflowStepComplete)


  const resendVerificationCodeError: ConsumerPhoneValidationStepState['resendVerificationCodeError'] =
    useSelector(ConsumerPhoneValidationStepStore.getResendVerificationCodeError)
 
  const isResendVerificationCodeSuccessful: ConsumerPhoneValidationStepState['isResendVerificationCodeSuccessful'] =
    useSelector(
      ConsumerPhoneValidationStepStore.getIsResendVerificationCodeSuccessful
    )

  const [verificationCode, setVerificationCode] = useState<string>('')

    useEffect(() => {
      if (isWorkflowStepComplete) {
        props.onComplete()
      }
    }, [isWorkflowStepComplete, props])

    const onClickResendVerificationCode = () => {
      setVerificationCode('')
      ConsumerPhoneValidationStepStore.resetPhoneValidationState()
      ConsumerPhoneValidationStepStore.resetResendVerificationCodeState()
      ConsumerPhoneValidationStepStore.resendVerificationCode()
    }
    useEffect(() => {
      if (verificationCode.length < 6) {
        ConsumerPhoneValidationStepStore.resetPhoneValidationState()
      }
    }, [verificationCode])

    const showStandardError = useMemo(() => ConsumerPhoneVerificationCodeErrorFunctions.isNormalError(phoneValidationError || resendVerificationCodeError) , [phoneValidationError, resendVerificationCodeError ]);
    const showDangerError = useMemo(() => ConsumerPhoneVerificationCodeErrorFunctions.isDangerError(phoneValidationError || resendVerificationCodeError) , [phoneValidationError, resendVerificationCodeError ]);
    const errorMsg = useMemo(
      ()=> {
        const msg = ConsumerPhoneVerificationCodeErrorFunctions.mapErrorToLocalizationMessage(phoneValidationError || resendVerificationCodeError);

        if(!msg){
          return Boolean(resendVerificationCodeError)? ConsumerPhoneVerificationCodeErrorFunctions.getDangerGeneralErrorMessage() : ConsumerPhoneVerificationCodeErrorFunctions.getStandardGeneralErrorMessage()
        }

        return msg;
      },
      [phoneValidationError, resendVerificationCodeError]
    );
    const errorUatId = useMemo(
      ()=> ConsumerPhoneVerificationCodeErrorFunctions.mapErrorToUatId(phoneValidationError || resendVerificationCodeError) ,
      [phoneValidationError, resendVerificationCodeError] 
    );

    return (
      <div className={ConsumerPhoneValidationSectionViewStyles.container}>
        <InlineMessage
          buttonText={ConsumerPhoneValidationSectionViewMessage.editPhoneNumber}
          onClick={props.setSectionToUpdatePhoneNumber}
          uat={uatId(
            ConsumerPhoneValidationSectionViewUat.editPhoneNumberButton
          )}
          cssContainerClasses={
            ConsumerPhoneValidationSectionViewStyles.editPhoneNumberContainer
          }
        />

        <ConsumerPhoneValidationSectionForm
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
        />

        <div className={ConsumerPhoneValidationResendCodeBannerStyle.bannerWrapper}>
          <ConsumerPhoneValidationResendCodeBannerInfo
            showIf={isResendVerificationCodeSuccessful}
            cssContainerClasses={
              ConsumerPhoneValidationResendCodeBannerStyle.bannerContainer
            }
          />
          <ConsumerPhoneDangerError
            errorMessage={errorMsg}
            showIf={showDangerError}
            uatId={errorUatId}
          />
        </div>

        <InlineMessage
          buttonText={ConsumerPhoneValidationSectionViewMessage.sendNewCode}
          message={ConsumerPhoneValidationSectionViewMessage.didNotGetCode}
          onClick={onClickResendVerificationCode}
          uat={uatId(ConsumerPhoneValidationSectionViewUat.resendCodeButton)}
          cssMessageClasses={ConsumerPhoneValidationSectionViewStyles.bold}
          cssContainerClasses={ConsumerPhoneValidationSectionViewStyles.newCodeContainer}
        />

        <SpinnerIcon
          showIf={loading}
          size={SpinnerIconSize.LARGE}
          cssClasses={ConsumerPhoneValidationSectionViewStyles.loadingIcon}
        />

        <ConsumerPhoneValidationStandardError 
          showIf={showStandardError} 
          errorMessage={errorMsg} 
          uatId={errorUatId} 
          />
      </div>
    )
  }
)
