export const ConsumerPhoneNumberViewMessages = {
  header: {
    id: 'Pages.ConsumerPhoneNumber.View.header',
    description: 'Getting Pre-Qualified'
  },
  paragraph: {
    id: 'Pages.ConsumerPhoneNumber.View.paragraph',
    description: 'Hi! Getting Pre-Qualified?'
  },
  greetings: {
    id: 'Pages.ConsumerPhoneNumber.View.greetings',
    description: 'Nice to meet you'
  },
  verify: {
    id: 'Pages.ConsumerPhoneNumber.View.verify',
    description: 'Verify your identity'
  },
  errorGeneral: {
    id: 'Pages.ConsumerPhoneNumber.View.errorGeneral',
    description: 'Cannot create consumer profile'
  },
  errorValidation: {
    id: 'Pages.ConsumerPhoneNumber.View.errorValidation',
    description: 'Only canadian phone numbers are permitted'
  }
}
