import { ConsumerWorkflowStepType } from './consumer-workflow-step-type.enum';
import { ConsumerWorkflowStep } from './consumer-workflow-step.interface';

export class ConsumerWorkflowStepService{
    private orderedWorkflowSteps:ConsumerWorkflowStep[];

    constructor(workflowSteps:ConsumerWorkflowStep[]){
        this.orderedWorkflowSteps = [...workflowSteps].sort((a,b) => a.order - b.order);
    }

    public findNextStep(currentStep:ConsumerWorkflowStep):ConsumerWorkflowStep | null{
        const nextStepIndex = this.findStepIndex(currentStep) + 1;

        if(nextStepIndex === 0 || (nextStepIndex) >= this.orderedWorkflowSteps.length){
        return null;
        }

        return this.orderedWorkflowSteps[nextStepIndex]
    }

    public findPreviousStep(currentStep:ConsumerWorkflowStep):ConsumerWorkflowStep | null{
        const previousStepIndex = this.findStepIndex(currentStep) - 1;

        if(previousStepIndex < 0){
        return null;
        }

        const result = this.orderedWorkflowSteps[previousStepIndex];

        if(result.type === ConsumerWorkflowStepType.ConsumerNameForm || result.type === ConsumerWorkflowStepType.ConsumerPhoneForm){
            return null
        }

        return result;
    }



    private findStepIndex(step:ConsumerWorkflowStep):number{
        return this.orderedWorkflowSteps.findIndex(workflowStep => workflowStep.order === step.order);
    }
}