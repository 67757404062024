export const ConsumerPostSubmissionDeclinedViewMessages = {
  header: {
    id: 'Pages.PostSubmission.Declined.View.header',
    description: 'Post Submission Declined View Header'
  },
  title: {
    id: 'Pages.PostSubmission.Declined.View.title',
    description: 'Post Submission Declined View title'
  },
  contactInfo: {
    id: 'Pages.PostSubmission.Declined.View.contactInfo',
    description: 'Post Submission Declined View Contact Information'
  }
}
