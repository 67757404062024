import { ActionType, createAction } from 'typesafe-actions'
import { Language } from '../../../../../../../shared/types/language.enum'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { Offer } from '../types/offer.interface'

export enum ConsumerPostSubmissionOffersAction {
  FetchOffers = 'PostSubmissionApprovedView/FetchOffers',
  FetchOffersFailure = 'PostSubmissionApprovedView/FetchFailure',
  FetchOffersSuccess = 'PostSubmissionApprovedView/FetchSuccess',
  SaveLoanOfferPageViewed = 'PostSubmissionApprovedView/SaveLoanOfferPageViewed',
  SaveLoanOfferPageViewedSuccess = 'PostSubmissionApprovedView/SaveLoanOfferPageViewedSuccess',
  GoToNext = 'PostSubmissionApprovedView/GoToNext',
  UpdateLoanDetails = 'PostSubmissionApprovedView/UpdateLoanDetails',
  UpdateLoanDetailsSuccess = 'PostSubmissionApprovedView/UpdateLoanDetailsSuccess',
  UpdateLoanDetailsFailure = 'PostSubmissionApprovedView/UpdateLoanDetailsFailure'
}

const fetchOffers = createAction(
  ConsumerPostSubmissionOffersAction.FetchOffers,
  (action) => (locale: Language) => action({ locale })
)

const fetchOffersFailure = createAction(
  ConsumerPostSubmissionOffersAction.FetchOffersFailure,
  (action) => (error: HttpError) => action(error)
)

const fetchOffersSuccess = createAction(
  ConsumerPostSubmissionOffersAction.FetchOffersSuccess,
  (action) => (offers: Offer[]) => action(offers)
)

const saveLoanOfferPageViewed = createAction(
  ConsumerPostSubmissionOffersAction.SaveLoanOfferPageViewed,
  (action) => () => action()
)

const saveLoanOfferPageViewedSuccess = createAction(
  ConsumerPostSubmissionOffersAction.SaveLoanOfferPageViewedSuccess,
  (action) => () => action()
)

const goToNext = createAction(
  ConsumerPostSubmissionOffersAction.GoToNext,
  (action) => () => action()
)

const updateLoanDetails = createAction(
  ConsumerPostSubmissionOffersAction.UpdateLoanDetails,
  (action) => (offer: Offer) => action(offer)
)

const updateLoanDetailsSuccess = createAction(
  ConsumerPostSubmissionOffersAction.UpdateLoanDetailsSuccess,
  (action) => () => action()
)

const updateLoanDetailsFailure = createAction(
  ConsumerPostSubmissionOffersAction.UpdateLoanDetailsFailure,
  (action) => (error: HttpError) => action(error)
)

export const ConsumerPostSubmissionOffersActions = {
  fetchOffers,
  fetchOffersFailure,
  fetchOffersSuccess,
  saveLoanOfferPageViewed,
  saveLoanOfferPageViewedSuccess,
  goToNext,
  updateLoanDetails,
  updateLoanDetailsFailure,
  updateLoanDetailsSuccess
}

export type ConsumerPostSubmissionOffersActionType = ActionType<
  typeof ConsumerPostSubmissionOffersActions
>
