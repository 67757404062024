import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PreQualifierAssetTypeListItem } from './components/pre-qualifier-asset-type-list-item/pre-qualifier-asset-type-list-item.component'
import { PreQualifierAssetTypeListMessages } from './pre-qualifier-asset-type-list.messages'
import { PreQualifierAssetTypeListProps } from './pre-qualifier-asset-type-list.props'
import { PreQualifierAssetTypeListStyles } from './pre-qualifier-asset-type-list.styles'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { uatId } from '../../../../../../uat/uat-id.function'
import { PreQualifierAssetTypeListUat } from './pre-qualifier-asset-type-list.uat'

export const PreQualifierAssetTypeList = withShowIf(
  (props: PreQualifierAssetTypeListProps) => {
    const getListOfAssetTypes = () =>
      props.assetTypes.map((assetType) => (
        <PreQualifierAssetTypeListItem
          key={assetType.id}
          assetType={assetType}
          selected={assetType.id === props.selectedAssetType?.id}
          selectAssetType={props.selectAssetType}
          uat={uatId(PreQualifierAssetTypeListUat.listItems)}
        />
      ))

    return (
      <div className={PreQualifierAssetTypeListStyles.container}>
        <div
          className={PreQualifierAssetTypeListStyles.description}
          {...uatId(PreQualifierAssetTypeListUat.title)}
        >
          <FormattedMessage {...PreQualifierAssetTypeListMessages.title} />
        </div>
        <div className={PreQualifierAssetTypeListStyles.listContainer}>
          {getListOfAssetTypes()}
        </div>
      </div>
    )
  }
)
