import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerPhoneNumberStepState } from '../types/consumer-phone-number-step-state.interface'

const consumerPhoneNumberState = (
  rootState: RootState
): ConsumerPhoneNumberStepState =>
  rootState.consumerState.consumerWorkflowState.consumerPhoneNumberStepState

const getPhoneNumber: Selector<
  RootState,
  ConsumerPhoneNumberStepState['phoneNumber']
> = createSelector(
  consumerPhoneNumberState,
  (state: ConsumerPhoneNumberStepState) => state.phoneNumber
)

const getLoading: Selector<RootState, ConsumerPhoneNumberStepState['loading']> =
  createSelector(
    consumerPhoneNumberState,
    (state: ConsumerPhoneNumberStepState) => state.loading
  )

const getError: Selector<RootState, ConsumerPhoneNumberStepState['error']> =
  createSelector(
    consumerPhoneNumberState,
    (state: ConsumerPhoneNumberStepState) => state.error
  )

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerPhoneNumberStepState['isWorkflowStepComplete']
> = createSelector(
  consumerPhoneNumberState,
  (state: ConsumerPhoneNumberStepState) => state.isWorkflowStepComplete
)

export const ConsumerPhoneNumberStepStore = {
  getError,
  getIsWorkflowStepComplete,
  getLoading,
  getPhoneNumber
}
