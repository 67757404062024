import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { HttpError } from '../../../../../../../../../shared/types/http-error.interface'
import { Action } from '../../../../../../../../../store/root.actions'
import { RootState } from '../../../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerSessionStore } from '../../../../../../consumer-session/store/consumer-session.store'
import { ConsumerEditPhoneNumberActions } from './consumer-edit-phone-number.actions'
import { ConsumerPhoneService } from '../../../../../../../../../api/bff/services/consumer-phone.service'

const editPhoneNumberEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerEditPhoneNumberActions.editPhoneNumber)),
    switchMap((action) =>
      new ConsumerPhoneService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .updatePhoneNumber(action.payload)
        .pipe(
          map(() => ConsumerEditPhoneNumberActions.editPhoneNumberSuccess()),
          catchError((err: HttpError) =>
            of(ConsumerEditPhoneNumberActions.editPhoneNumberFailure(err))
          )
        )
    )
  )

export const consumerEditPhoneNumberEpics = [editPhoneNumberEpic]
