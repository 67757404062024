import { Epic } from 'redux-observable'
import { catchError, filter, mergeMap, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { PreQualifierAssetTypeSelectionActions } from './pre-qualifier-asset-type-selection.actions'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { Action } from '../../../../../store/root.actions'
import { PreQualifierAssetTypeService } from '../../../../../api/bff/services/pre-qualifier-asset-type.service'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { ConsumerSessionStore } from '../../consumer-session/store/consumer-session.store'
import { PreQualifierAssetTypeSelectionState } from '../types/pre-qualifier-asset-type-selection-state.interface'

const fetchAssetTypesEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(PreQualifierAssetTypeSelectionActions.fetchAssetTypes)),
    switchMap((action) =>
      new PreQualifierAssetTypeService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .fetchAssetTypes(
          action.payload.locale,
          action.payload.vendorId,
          action.payload.tags
        )
        .pipe(
          mergeMap(
            (assetTypes: PreQualifierAssetTypeSelectionState['assetTypes']) =>
              of(
                PreQualifierAssetTypeSelectionActions.fetchAssetTypesSuccess(
                  assetTypes
                )
              )
          ),
          catchError((err: HttpError) =>
            of(
              PreQualifierAssetTypeSelectionActions.fetchAssetTypesFailure(err)
            )
          )
        )
    )
  )

export const preQualifierAssetTypeSelectionEpics = [fetchAssetTypesEpic]
