import { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ConsumerRoute } from '../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { ConsumerSessionStatus } from '../../../../domain/consumer/consumer-session/consumer-session-active-status.enum'
import { MainLayout } from '../../../../shared/components/main-layout/main-layout.component'
import { addVendorIdToPath } from '../../../../shared/helpers/path-helper'
import { SpinnerIcon } from '../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { Paragraph } from '../../../../ui-library/components/paragraph/paragraph.component'
import { ConsumerProfileStore } from '../consumer-profile/store/consumer-profile.store'
import { useConsumerSessionStatus } from '../consumer-session/hooks/use-consumer-session-active-status.hook'
import { findActiveWorkflowStep } from '../consumer-workflow/consumer-workflow.functions'
import { ConsumerWorkflowStepsStore } from '../consumer-workflow/modules/consumer-workflow-steps/store/consumer-workflow-steps.store'
import { ConsumerRouteResolverMessages } from './consumer-route-resolver.messages'
import { ConsumerRouteResolverStyles } from './consumer-route-resolver.styles'

export const ConsumerRouteResolver = () => {
  const history = useHistory()
  const workflowSteps = useSelector(ConsumerWorkflowStepsStore.getWorkflowSteps)
  const consumerSessionStatus = useConsumerSessionStatus()
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)
  const bannerUrl = useSelector(ConsumerProfileStore.getConsumerBannerUrl)

  useEffect(() => {
    if (
      workflowSteps !== null &&
      consumerSessionStatus === ConsumerSessionStatus.PreSubmission
    ) {
      ConsumerWorkflowStepsStore.setActiveWorkflowStep(
        findActiveWorkflowStep(workflowSteps)
      )
    } else if (consumerSessionStatus === ConsumerSessionStatus.PostSubmission) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitLoading))
    } else if (consumerSessionStatus === ConsumerSessionStatus.NotStarted) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SelectAssetType))
    }
  }, [workflowSteps, consumerSessionStatus])

  const showBanner = useMemo(() => {
    return !Boolean(bannerUrl)
  }, [consumerSessionStatus])

  return (
    <MainLayout
      showDefaultHeader={showBanner}
      hideHeader={showBanner}
      hideNavBar={showBanner}
      hideFooter
    >
      <div className={ConsumerRouteResolverStyles.container}>
        <SpinnerIcon size={SpinnerIconSize.LARGE} />
        <Paragraph className={ConsumerRouteResolverStyles.paragraph}>
          <FormattedMessage {...ConsumerRouteResolverMessages.loadingMessage} />
        </Paragraph>
      </div>
    </MainLayout>
  )
}
