import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../../../uat/uat-id.function'
import { PrimaryButton } from '../../../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { MerchantListItemActionFooterMessages } from './merchant-list-item-action-footer.messages'
import { MerchantListItemActionFooterProps } from './merchant-list-item-action-footer.props'
import { MerchantListItemActionFooterStyles } from './merchant-list-item-action-footer.styles'
import { MerchantListItemActionFooterUat } from './merchant-list-item-action-footer.uat'

export const MerchantListItemActionFooter = (
  props: MerchantListItemActionFooterProps
) => {
  return (
    <div className={MerchantListItemActionFooterStyles.buttonContainer}>
      <PrimaryButton
        onClick={props.openDetailsDialog}
        uat={uatId(MerchantListItemActionFooterUat.selectMerchantButton)}
        disabled={props.disabled}
        minimumWidth
      >
        <FormattedMessage
          {...MerchantListItemActionFooterMessages.selectButton}
        />
      </PrimaryButton>
    </div>
  )
}
