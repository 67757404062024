export const PrimaryHeaderStyles = {
  header:
    'justify-center flex items-center text-center col-span-6 text-skyblue900 font-semibold text-20px sm:grid sm:col-span-4 sm:text-42px sm:text-center',
  container: (horizontalPadding: boolean) => 
    `grid grid-flow-row w-full sm:mt-11 sm:mb-6 ${
      horizontalPadding ? 'pl-x px-8 sm:px-16' : ''
    }`,
  iconContainer: 'flex items-center cursor-pointer',
  primaryHeaderBackIcon: 'h-4 sm:h-8',
  backButtonText: 'underline inline',
}