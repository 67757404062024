import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../../../store/root.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerDobStepState } from '../types/consumer-dob-step-state.interface'
import { ConsumerDobStepActions } from './consumer-dob-step.actions'

const getConsumerDobState = (rootState: RootState): ConsumerDobStepState =>
  rootState.consumerState.consumerWorkflowState.consumerDobStepState

const getDateOfBirth: Selector<RootState, ConsumerDobStepState['dateOfBirth']> =
  createSelector(
    getConsumerDobState,
    (state: ConsumerDobStepState) => state.dateOfBirth
  )

const getError: Selector<RootState, ConsumerDobStepState['error']> =
  createSelector(
    getConsumerDobState,
    (state: ConsumerDobStepState) => state.error
  )

const isLoading: Selector<RootState, ConsumerDobStepState['loading']> =
  createSelector(
    getConsumerDobState,
    (state: ConsumerDobStepState) => state.loading
  )

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerDobStepState['isWorkflowStepComplete']
> = createSelector(
  getConsumerDobState,
  (state: ConsumerDobStepState) => state.isWorkflowStepComplete
)

const saveConsumerDob = (
  dateOfBirth: NonNullable<ConsumerDobStepState['dateOfBirth']>
) => RootStore.dispatch(ConsumerDobStepActions.saveConsumerDob(dateOfBirth))

const reset = () =>
  RootStore.dispatch(ConsumerDobStepActions.resetConsumerDobState())

export const ConsumerDobStepStore = {
  getDateOfBirth,
  getError,
  isLoading,
  getIsWorkflowStepComplete,
  saveConsumerDob,
  reset
}
