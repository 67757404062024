import { QuestionType } from '../../../../../../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { StringFieldSchema } from '../../../../../../../../libraries/form-schema/form-field-schema/string-field-schema'
import { FormSchema } from '../../../../../../../../libraries/form-schema/form-schema'
import { SecurityQuestionsForm } from '../../types/security-questions-form/security-questions-form.interface'

export function generateSecurityQuestionsFormSchema(): FormSchema<SecurityQuestionsForm> {
  return new FormSchema<SecurityQuestionsForm>({
    [QuestionType.Applicant4DigitCode]: new StringFieldSchema(
      QuestionType.Applicant4DigitCode
    )
      .setMinLength(4)
      .setMaxLength(4)
      .isRequired()
  })
}
