import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormSchema } from '../../../../../../../../libraries/form-schema/form-schema'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { DobInput } from '../../../../../../../../ui-library/components/input-field/components/dob-input/dob-input.component'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerDobStepStore } from '../../store/consumer-dob-step.store'
import { DobBannerErrorMessage } from './components/dob-banner-error-message/dob-banner-error-message.component'
import { DobValidationMessage } from './components/dob-validation-message/dob-validation-message.component'
import { ConsumerDobFormMessages } from './consumer-dob-form.messages'
import { ConsumerDobFormProps } from './consumer-dob-form.props'
import { generateConsumerDobFormSchema } from './consumer-dob-form.schema'
import { ConsumerDobFormStyles } from './consumer-dob-form.styles'
import { ConsumerDobFormUat } from './consumer-dob-form.uat'
import { ConsumerDobFormErrorMessageId } from './types/consumer-dob-form-error-message-id.enum'
import { ConsumerDobFormSchema } from './types/consumer-dob-form-schema.interface'

export const ConsumerDobForm = (props: ConsumerDobFormProps) => {
  const intl = useIntl()

  const getInitialValue = () => props.initialFormValue ?? ''
  const [dateOfBirth, setDateOfBirth] = useState<string>(
    getInitialValue().toString()
  )
  const [validationErrorMessageId, setValidationErrorMessageId] =
    useState<ConsumerDobFormErrorMessageId | null>(null)
  const [showBannerErrorMessage, setShowBannerErrorMessage] =
    useState<boolean>(false)
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(
    !Boolean(props.initialFormValue)
  )

  const formSchema: FormSchema<ConsumerDobFormSchema> =
    generateConsumerDobFormSchema()

  const onChange = (dob: string) => {
    setDateOfBirth(dob)
    if (dob.length < 10) {
      setShowBannerErrorMessage(false)
      setValidationErrorMessageId(null)
    }
    dob.length === 10
      ? setDisableSubmitButton(false)
      : setDisableSubmitButton(true)
  }

  useEffect(() => {
    if (props.error) {
      setShowBannerErrorMessage(true)
    } else {
      setShowBannerErrorMessage(false)
    }
  }, [props.error])

  const onSubmit = () => {
    try {
      setShowBannerErrorMessage(false)
      formSchema.isValidOrThrow({ dateOfBirth })
      setValidationErrorMessageId(null)
      ConsumerDobStepStore.saveConsumerDob(dateOfBirth)
    } catch (err: any) {
      setValidationErrorMessageId(err.errors[0])
    }
  }

  return (
    <div className={ConsumerDobFormStyles.container}>
      <DobInput
        uat={uatId(ConsumerDobFormUat.field)}
        autoFocus
        label={intl.formatMessage(ConsumerDobFormMessages.inputLabel)}
        dateOfBirth={dateOfBirth}
        name={'dateOfBirth'}
        placeholder={intl.formatMessage(
          ConsumerDobFormMessages.inputPlaceholder
        )}
        onChange={onChange}
      />
      <DobValidationMessage
        errorMessageId={validationErrorMessageId}
      ></DobValidationMessage>
      <DobBannerErrorMessage
        showIf={showBannerErrorMessage}
      ></DobBannerErrorMessage>
      <PrimaryButton
        onClick={onSubmit}
        cssContainerClasses={ConsumerDobFormStyles.submitButton}
        disabled={disableSubmitButton}
        loading={props.loading}
        uat={uatId(ConsumerDobFormUat.submitButton)}
      >
        <FormattedMessage {...ConsumerDobFormMessages.submitButton} />
      </PrimaryButton>
    </div>
  )
}
