import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerEditPhoneNumberActions } from '../modules/consumer-edit-phone-number/store/consumer-edit-phone-number.actions'
import { ConsumerPhoneValidationStepState } from '../types/consumer-phone-validation-step-state.interface'

export enum ConsumerPhoneValidationStepAction {
  ResendVerificationCode = 'ConsumerPhoneValidationStep/ResendVerificationCode',
  ResendVerificationCodeFailure = 'ConsumerPhoneValidationStep/ResendVerificationCodeFailure',
  ResendVerificationCodeSuccess = 'ConsumerPhoneValidationStep/ResendVerificationCodeSuccess',
  ResetPhoneValidationState = 'ConsumerPhoneValidationStep/ResetPhoneValidationState',
  ResetResendVerificationCodeState = 'ConsumerPhoneValidationStep/ResetResendVerificationCodeState',
  ValidatePhoneNumber = 'ConsumerPhoneValidationStep/ValidatePhoneNumber',
  ValidatePhoneNumberFailure = 'ConsumerPhoneValidationStep/ValidatePhoneNumberFailure',
  ValidatePhoneNumberSuccess = 'ConsumerPhoneValidationStep/ValidatePhoneNumberSuccess'
}

const resendVerificationCode = createAction(
  ConsumerPhoneValidationStepAction.ResendVerificationCode
)

const resendVerificationCodeFailure = createAction(
  ConsumerPhoneValidationStepAction.ResendVerificationCodeFailure,
  (action) =>
    (
      error: NonNullable<
        ConsumerPhoneValidationStepState['resendVerificationCodeError']
      >
    ) =>
      action(error)
)

const resendVerificationCodeSuccess = createAction(
  ConsumerPhoneValidationStepAction.ResendVerificationCodeSuccess
)

const resetPhoneValidationState = createAction(
  ConsumerPhoneValidationStepAction.ResetPhoneValidationState
)

const resetResendVerificationCodeState = createAction(
  ConsumerPhoneValidationStepAction.ResetResendVerificationCodeState
)
const validatePhoneNumber = createAction(
  ConsumerPhoneValidationStepAction.ValidatePhoneNumber,
  (action) => (code: string) => action(code)
)

const validatePhoneNumberFailure = createAction(
  ConsumerPhoneValidationStepAction.ValidatePhoneNumberFailure,
  (action) =>
    (
      error: NonNullable<
        ConsumerPhoneValidationStepState['phoneValidationError']
      >
    ) =>
      action(error)
)

const validatePhoneNumberSuccess = createAction(
  ConsumerPhoneValidationStepAction.ValidatePhoneNumberSuccess
)

export const ConsumerPhoneValidationStepActions = {
  ...ConsumerEditPhoneNumberActions,
  resendVerificationCode,
  resendVerificationCodeFailure,
  resendVerificationCodeSuccess,
  resetPhoneValidationState,
  resetResendVerificationCodeState,
  validatePhoneNumber,
  validatePhoneNumberFailure,
  validatePhoneNumberSuccess
}

export type ConsumerPhoneValidationStepActionType = ActionType<
  typeof ConsumerPhoneValidationStepActions
>
