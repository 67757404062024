import { ConsumerProfileErrorCode } from '../../../../../../../../../../../../domain/consumer/consumer-profile/types/consumer-profile-error-codes.enum'
import { LocalizationMessage } from '../../../../../../../../../../../../shared/types/localization-message.interface'
import { ConsumerEditPhoneNumberState } from '../../../../types/consumer-edit-phone-number-state.interface'
import { ConsumerEditPhoneNumberSectionFormMessage } from './consumer-edit-phone-number-section-form.message'

export const getEditPhoneNumberErrorMessage = (
  error: ConsumerEditPhoneNumberState['error']
): LocalizationMessage => {
  switch (error?.code) {
    case ConsumerProfileErrorCode.InvalidCanadianPhoneNumber:
      return ConsumerEditPhoneNumberSectionFormMessage.invalidPhoneNumber
    case ConsumerProfileErrorCode.UpdatePhoneNumberAttemptsExhausted:
      return ConsumerEditPhoneNumberSectionFormMessage.updatePhoneNumberAttemptsExhausted
    default:
      return ConsumerEditPhoneNumberSectionFormMessage.errorGeneral
  }
}
