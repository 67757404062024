export const MapLibraryMarkerPopupStyles = {
  popup: {
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    borderRadius: '0.25rem'
  },
  container: 'flex flex-col w-32  bg-white text-black',
  closeIconContainer: 'flex flex-row justify-end mb-2',
  closeIcon: 'block w-2 h-2 cursor-pointer',
  markerName: 'font-bold text-13px mb-2',
  markerAddress: 'text-12px mb-2',
  markerPhone: 'text-12px'
}
