export const ConsumerPostSubmissionApprovedActionTextMessages = {
  title: {
    id: 'Pages.PostSubmission.Approved.ActionText.title',
    description: 'Post Submission Approved Action Text Header'
  },
  description: {
    id: 'Pages.PostSubmission.Approved.ActionText.description',
    description: 'Post Submission Approved Action Text Description'
  },
  actionText1: {
    id: 'Pages.PostSubmission.Approved.ActionText.actionText1',
    description: 'Post Submission Approved Action Text 1'
  },
  actionText2: {
    id: 'Pages.PostSubmission.Approved.ActionText.actionText2',
    description: 'Post Submission Approved Action Text 2'
  },
  actionText3: {
    id: 'Pages.PostSubmission.Approved.ActionText.actionText3',
    description: 'Post Submission Approved Action Text 3'
  },
  actionText4: {
    id: 'Pages.PostSubmission.Approved.ActionText.actionText4',
    description: 'Post Submission Approved Action Text 4'
  },
  step1Title: {
    id: 'Pages.PostSubmission.Approved.ActionText.step1Title',
    description: 'Post Submission Approved Step 1 Title',
  },
  step1Details: {
    id: 'Pages.PostSubmission.Approved.ActionText.step1Details',
    description: 'Post Submission Approved Step 1 Details',
  },
  step2Title: {
    id: 'Pages.PostSubmission.Approved.ActionText.step2Title',
    description: 'Post Submission Approved Step 2 Title',
  },
  step2Details: {
    id: 'Pages.PostSubmission.Approved.ActionText.step2Details',
    description: 'Post Submission Approved Step 2 Details',
  },
  step3Title: {
    id: 'Pages.PostSubmission.Approved.ActionText.step3Title',
    description: 'Post Submission Approved Step 3 Title',
  },
  step3Details: {
    id: 'Pages.PostSubmission.Approved.ActionText.step3Details',
    description: 'Post Submission Approved Step 3 Details',
  },
  nextSteps: {
    id: 'Pages.PostSubmission.Approved.ActionText.nextSteps',
    description: 'Next Steps Text'
  }
}
