export const ConsumerManualAddressFormUat = {
  auto: 'workflow.consumerAddress.manual.auto',
  submitButton: 'workflow.consumerAddress.manual.submitButton',
  province: 'workflow.consumerAddress.manual.province',
  provinceOptionList: 'workflow.consumerAddress.manual.provinceOptionList',
  provinceOptionListItem:
    'workflow.consumerAddress.manual.provinceOptionListItem',
  apt: 'workflow.consumerAddress.manual.apt',
  street: 'workflow.consumerAddress.manual.street',
  city: 'workflow.consumerAddress.manual.city',
  postalCode: 'workflow.consumerAddress.manual.postalCode'
}
