import { CheckboxInputProps } from './checkbox-input.props'
import { CheckboxInputStyles } from './checkbox-input.styles'

export const CheckboxInput = (props: CheckboxInputProps) => (
  <>
    <input
      id={props.name}
      className={CheckboxInputStyles.field}
      name={props.name}
      onChange={props.onChange}
      checked={props.value}
      type="checkbox"
      {...props.uat}
    />
    <label
      htmlFor={props.name}
      className={props.className ?? CheckboxInputStyles.label}
      onClick={() => props.onChange}
    >
      {props.label}
    </label>
  </>
)
