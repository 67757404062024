import { FormattedMessage } from 'react-intl'
import { Paragraph } from '../../../ui-library/components/paragraph/paragraph.component'
import { SecondaryButton } from '../../../ui-library/components/secondary-button/secondary-button.component'
import { InfoIcon } from '../../icons/info/info.icon'
import { MoreInfoMessages } from './more-info.messages'
import { MoreInfoProps } from './more-info.props'
import { MoreInfoMobileModalStyles, MoreInfoStyles } from './more-info.styles'
import { ModalLibrary } from '../../../libraries/modal/modal-library.component'
import { uatId } from '../../../uat/uat-id.function'
import { MoreInfoUat } from './more-info.uat'
import { withShowIf } from '../../../ui-library/helpers/with-show-if/with-show-if.component'

export const MoreInfo = withShowIf((props: MoreInfoProps) => {
    const handleMoreInfoOpen = () => {
        props.onOpen();
    }

    const handleMoreInfoClose = () => {
        props.onClose();
    }
    
    return (
        <>
            <ModalLibrary 
                open={props.open} 
                className={MoreInfoStyles.modal} 
                onClose={handleMoreInfoClose} 
                mobileStyle={MoreInfoMobileModalStyles}
                smallModal
            >
                <div>
                    <Paragraph className={MoreInfoStyles.modalText}>
                        {props.message}
                    </Paragraph>
                    <SecondaryButton 
                        onClick={handleMoreInfoClose} 
                        fullWidth 
                        uat={uatId(MoreInfoUat.closeButton)}
                    >
                        <FormattedMessage {...MoreInfoMessages.closeButton} />
                    </SecondaryButton>
                </div>
            </ModalLibrary>        
            <button className={MoreInfoStyles.button} onClick={handleMoreInfoOpen} {...props.uat}>
                <InfoIcon className={MoreInfoStyles.icon} />
            </button>
        </>
    )
});