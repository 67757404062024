import { useIntl } from 'react-intl'
import { ConsumerPhoneVerificationCodeErrorProps } from './consumer-phone-verification-code-error.props'
import { withShowIf } from '../../../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { BannerText } from '../../../../../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { BannerTextType } from '../../../../../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { uatId } from '../../../../../../../../../../../../uat/uat-id.function'
import { ConsumerPhoneValidationResendCodeBannerStyle } from '../consumer-phone-validation-resend-code-banner/consumer-phone-validation-resend-code-banner.style'

export const ConsumerPhoneDangerError = withShowIf(
  (props: ConsumerPhoneVerificationCodeErrorProps) => {
    const intl = useIntl()

    return (
      <BannerText
        cssContainerClasses={ConsumerPhoneValidationResendCodeBannerStyle.bannerContainer}
        type={BannerTextType.DANGER}
        boldText={props.errorMessage? intl.formatMessage(props.errorMessage) : ''}
        uat={uatId(props.uatId)}
      />
    )
  }
)
