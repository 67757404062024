import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerAddress } from '../../../../../../../../../../../domain/consumer/consumer-profile/consumer-address.interface'
import { LookupAddress } from '../../../../../../../../../../../domain/lookup-address/lookup-address.interface'
import { RetrieveAddress } from '../../../../../../../../../../../domain/lookup-address/retrieve-address.interface'
import { ConsumerAutoAddressState } from '../types/consumer-auto-address-state.interface'

export enum ConsumerAutoAddressAction {
  LookupAddress = 'ConsumerAutoAddress/LookupAddress',
  LookupAddressFailure = 'ConsumerAutoAddress/LookupAddressFailure',
  LookupAddressSuccess = 'ConsumerAutoAddress/LookupAddressSuccess',
  RetrieveAddress = 'ConsumerAutoAddress/RetrieveAddress',
  RetrieveAddressFailure = 'ConsumerAutoAddress/RetrieveAddressFailure',
  RetrieveAddressSuccess = 'ConsumerAutoAddress/RetrieveAddressSuccess',
  SaveAutoAddress = 'ConsumerAutoAddress/SaveAutoAddress',
  SaveAutoAddressFailure = 'ConsumerAutoAddress/SaveAutoAddressFailure',
  SaveAutoAddressSuccess = 'ConsumerAutoAddress/SaveAutoAddressSuccess',
  Reset = 'ConsumerAutoAddress/Reset',
  ResetSaveAddressFailure = 'ResetSaveAddressFailure'
}

const lookupAddress = createAction(
  ConsumerAutoAddressAction.LookupAddress,
  (action) => (address: string) => action(address)
)

const lookupAddressFailure = createAction(
  ConsumerAutoAddressAction.LookupAddressFailure,
  (action) =>
    (error: NonNullable<ConsumerAutoAddressState['lookupAddressesFailure']>) =>
      action(error)
)

const lookupAddressSuccess = createAction(
  ConsumerAutoAddressAction.LookupAddressSuccess,
  (action) => (addresses: LookupAddress[]) => action(addresses)
)

const retrieveAddress = createAction(
  ConsumerAutoAddressAction.RetrieveAddress,
  (action) => (id: LookupAddress['id']) => action(id)
)

const retrieveAddressFailure = createAction(
  ConsumerAutoAddressAction.RetrieveAddressFailure,
  (action) =>
    (error: NonNullable<ConsumerAutoAddressState['retrieveAddressFailure']>) =>
      action(error)
)
const retrieveAddressSuccess = createAction(
  ConsumerAutoAddressAction.RetrieveAddressSuccess,
  (action) => (address: RetrieveAddress) => action(address)
)

const saveAutoAddress = createAction(
  ConsumerAutoAddressAction.SaveAutoAddress,
  (action) => (address: ConsumerAddress) => action(address)
)

const saveAutoAddressFailure = createAction(
  ConsumerAutoAddressAction.SaveAutoAddressFailure,
  (action) =>
    (error: NonNullable<ConsumerAutoAddressState['saveAddressFailure']>) =>
      action(error)
)
const saveAutoAddressSuccess = createAction(
  ConsumerAutoAddressAction.SaveAutoAddressSuccess
)

const resetConsumerAutoAddressState = createAction(
  ConsumerAutoAddressAction.Reset
)

const resetSaveAddressFailure = createAction(
  ConsumerAutoAddressAction.ResetSaveAddressFailure
)

export const ConsumerAutoAddressActions = {
  lookupAddress,
  lookupAddressFailure,
  lookupAddressSuccess,
  retrieveAddress,
  retrieveAddressFailure,
  retrieveAddressSuccess,
  saveAutoAddress,
  saveAutoAddressFailure,
  saveAutoAddressSuccess,
  resetConsumerAutoAddressState,
  resetSaveAddressFailure
}

export type ConsumerAutoAddressActionType = ActionType<
  typeof ConsumerAutoAddressActions
>
