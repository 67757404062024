import React from 'react'
import { EmailLinkProps } from './email-link.props'
import { EmailLinkStyles } from './email-link.styles'
import { withShowIf } from '../../../helpers/with-show-if/with-show-if.component'
import { MailIcon } from '../../../../shared/icons/mail/mail.icon'

export const EmailLink = withShowIf((props: EmailLinkProps) => {
  return (
    <>
      <a
        {...props.uat}
        href={`mailto:${props.emailAddress}`}
        className={EmailLinkStyles.link(props.className)}
      >
        <MailIcon className={EmailLinkStyles.icon(props.cssLogoClasses)} />
        {props.text}
      </a>
    </>
  )
})
