import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { MerchantSelectionErrorViewMessages } from './merchant-selection-error-view.messages'
import { MerchantSelectionErrorViewStyles } from './merchant-selection-error-view.styles'
import { MerchantSelectionErrorViewUat } from './merchant-selection-error-view.uat'

export const MerchantSelectionErrorView = withShowIf(() => {
  return (
    <div className={MerchantSelectionErrorViewStyles.container}>
      <Paragraph uat={uatId(MerchantSelectionErrorViewUat.message)}>
        <FormattedMessage
          {...MerchantSelectionErrorViewMessages.errorMessage}
        />
      </Paragraph>
    </div>
  )
})
