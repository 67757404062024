import { getButtonWidth } from '../../helpers/button.functions'

const buttonStyles = {
  button: 'border-blue300 border-2 p-2 rounded-md w-full text-blue300',
  disabled: 'pointer-events-none opacity-40'
}

export const secondaryButtonContainerStyle = (
  cssClasses: string = '',
  fullWidth: boolean = false,
  minimumWidth: boolean = false
): string =>
  `w-full flex items-center ${getButtonWidth(fullWidth, minimumWidth)} ${cssClasses}`

export const secondaryButtonStyles = (isDisable: boolean = false): string =>
  isDisable
    ? `${buttonStyles.button} ${buttonStyles.disabled}`
    : buttonStyles.button

