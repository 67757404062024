import { createConsumerAutoAddressState } from '../components/consumer-address-view/modules/consumer-auto-address/types/consumer-auto-address-state.functions'
import { createConsumerManualAddressState } from '../components/consumer-address-view/modules/consumer-manual-address/types/consumer-manual-address-state.functions'
import { ConsumerAddressStepState } from './consumer-address-step-state.interface'

export const createConsumerAddressStepState = (): ConsumerAddressStepState => {
  return {
    auto: createConsumerAutoAddressState(),
    manual: createConsumerManualAddressState()
  }
}
