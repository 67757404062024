import { ActionType, createAction } from 'typesafe-actions'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { ConsumerProfileState } from '../../../../consumer-profile/types/consumer-profile-state.interface'

export enum ConsumerNetIncomeStepAction {
  SaveConsumerNetIncome = 'ConsumerNetIncomeStep/SaveConsumerNetIncome',
  SaveConsumerNetIncomeSuccess = 'ConsumerNetIncomeStep/SaveConsumerNetIncomeSuccess',
  SaveConsumerNetIncomeFailure = 'ConsumerNetIncomeStep/SaveConsumerNetIncomeFailure',
  ResetConsumerNetIncomeState = 'ConsumerNetIncomeStep/ResetConsumerNetIncomeState'
}

const saveConsumerNetIncome = createAction(
  ConsumerNetIncomeStepAction.SaveConsumerNetIncome,
  (action) => (netIncome: ConsumerProfileState['netIncome']) =>
    action(netIncome)
)

const saveConsumerNetIncomeSuccess = createAction(
  ConsumerNetIncomeStepAction.SaveConsumerNetIncomeSuccess
)

const saveConsumerNetIncomeFailure = createAction(
  ConsumerNetIncomeStepAction.SaveConsumerNetIncomeFailure,
  (action) => (error: HttpError) => action(error)
)

const resetConsumerNetIncomeState = createAction(
  ConsumerNetIncomeStepAction.ResetConsumerNetIncomeState
)

export const ConsumerNetIncomeStepActions = {
  saveConsumerNetIncome,
  saveConsumerNetIncomeSuccess,
  saveConsumerNetIncomeFailure,
  resetConsumerNetIncomeState
}

export type ConsumerNetIncomeStepActionType = ActionType<
  typeof ConsumerNetIncomeStepActions
>
