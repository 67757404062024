import { createMerchantSelectionForm } from './merchant-selection-form/merchant-selection-form.functions'
import { MerchantSelectionState } from './merchant-selection-state.interface'

export function createMerchantSelectionState(): MerchantSelectionState {
  return {
    consumerProfileAddressCoordinates: null,
    currentPage: 1,
    merchants: [],
    fetchError: null,
    loading: false,
    selectedMerchant: null,
    submitError: null,
    submitLoading: false,
    totalResultsCount: null,
    selectedMerchants: [],
    merchantSelectionForm: createMerchantSelectionForm(),
  }
}
