import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { uatId } from '../../../../../../../../../../../../uat/uat-id.function'
import { AutoAddressInput } from './components/auto-address-input/auto-address-input.component'
import { PrimaryButton } from '../../../../../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerAutoAddressStore } from '../../store/consumer-auto-address.store'
import { ConsumerAutoAddressFormMessages } from './consumer-auto-address-form.messages'
import { ConsumerAutoAddressFormProps } from './consumer-auto-address-form.props'
import { ConsumerAutoAddressFormStyles } from './consumer-auto-address-form.styles'
import { ConsumerAutoAddressFormUat } from './consumer-auto-address-form.uat'
import {
  getRetrieveOnlyAddressLookups,
  isConsumerAddressValid,
  retrieveAddressToString,
  saveAddressToString
} from './consumer-auto-address-form.functions'
import { LookupAddress } from '../../../../../../../../../../../../domain/lookup-address/lookup-address.interface'

export const ConsumerAutoAddressForm = (
  props: ConsumerAutoAddressFormProps
) => {
  const intl = useIntl()

  const [address, setAddress] = useState<string>(
    saveAddressToString(props.consumerAddress)
  )

  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(
    !isConsumerAddressValid(props.consumerAddress)
  )

  const [retrieveOnlyLookupAddresses, setRetrieveOnlyLookupAddresses] =
    useState<LookupAddress[] | null>(null)

  const onChange = (debouncedAddress: string): void => {
    if (debouncedAddress !== '') {
      ConsumerAutoAddressStore.lookupAddress(debouncedAddress)
    }
    setDisableSubmitButton(true)
  }

  const onLookupAddressItemClick = (id: LookupAddress['id']): void => {
    setDisableSubmitButton(true)
    ConsumerAutoAddressStore.retrieveAddress(id)
  }

  useEffect(() => {
    if (Array.isArray(props.lookupAddresses)) {
      const retrieveOnlyAddressLookups: LookupAddress[] =
        getRetrieveOnlyAddressLookups(props.lookupAddresses)
      setRetrieveOnlyLookupAddresses(retrieveOnlyAddressLookups)
    }
  }, [props.lookupAddresses])

  useEffect(() => {
    if (props.retrieveAddress !== null) {
      setAddress(retrieveAddressToString(props.retrieveAddress))
      setDisableSubmitButton(false)
    }
  }, [props.retrieveAddress])

  return (
    <div className={ConsumerAutoAddressFormStyles.container}>
      <AutoAddressInput
        address={address}
        label={intl.formatMessage(ConsumerAutoAddressFormMessages.label)}
        lookupAddresses={retrieveOnlyLookupAddresses}
        lookupAddressesLoading={props.lookupAddressesLoading}
        maxLength={100}
        onChange={onChange}
        onLookupAddressItemClick={onLookupAddressItemClick}
        retrieveAddress={props.retrieveAddress}
        retrieveAddressLoading={props.retrieveAddressLoading}
        setAddress={setAddress}
        switchToManualAddressForm={props.switchToManualAddressForm}
        showNonPhysicalAddressError={props.showNonPhysicalAddressError}
      />
      <button
        {...uatId(ConsumerAutoAddressFormUat.manual)}
        className={ConsumerAutoAddressFormStyles.manualButton}
        onClick={props.switchToManualAddressForm}
      >
        <FormattedMessage {...ConsumerAutoAddressFormMessages.manualAddress} />
      </button>
      <PrimaryButton
        cssContainerClasses={ConsumerAutoAddressFormStyles.submitButton}
        disabled={disableSubmitButton}
        loading={props.saveAddressLoading}
        onClick={props.onSubmit}
        uat={uatId(ConsumerAutoAddressFormUat.submitButton)}
      >
        <FormattedMessage {...ConsumerAutoAddressFormMessages.submitButton} />
      </PrimaryButton>
    </div>
  )
}
