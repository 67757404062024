import bannerLogo from '../../../../../assets/lc-banner-logo.svg'
import banner from '../../../../../assets/banner.svg'
import { DefaultHeaderStyles } from './default-header.styles'
import { withShowIf } from '../../../../../ui-library/helpers/with-show-if/with-show-if.component'

export const DefaultHeader = withShowIf(() => {
  return (
    <div className={DefaultHeaderStyles.container}>
      <img className={DefaultHeaderStyles.bannerLogo} src={bannerLogo} alt="" />
      <img className={DefaultHeaderStyles.banner} src={banner} alt="" />
    </div>
  )
})
