import { MailIconProp } from './mail.icon.prop'

export const MailIcon = (props: MailIconProp) => (
  <svg
    {...props.uat}
    className={props.className}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00976562 1.83789C0.00976562 1.28561 0.457481 0.837891 1.00977 0.837891H17C17.5523 0.837891 18 1.28561 18 1.83789V13.1621C18 14.2667 17.1046 15.1621 16 15.1621H2C0.89543 15.1621 0 14.2667 0 13.1621V2.16211C0 2.11449 0.00332856 2.06765 0.00976562 2.0218V1.83789ZM2 4.06165V13.1621H16V4.06199L11.1215 8.9405C9.94992 10.1121 8.05042 10.1121 6.87885 8.9405L2 4.06165ZM3.57232 2.80554H14.428L9.70728 7.52628C9.31675 7.91681 8.68359 7.91681 8.29306 7.52628L3.57232 2.80554Z"
      fill="#1477CA"
    />
  </svg>
)
