import { createEventState } from '../modules/events/types/event-state.functions'
import { createSettingsState } from '../modules/settings/types/settings-state.functions'
import { AppState } from './app-state.interface'

export function createAppState(): AppState {
  return {
    settingsState: createSettingsState(),
    eventsState: createEventState()
  }
}
