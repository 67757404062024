import { MessageDescriptor, useIntl } from 'react-intl'
import { QuestionType } from '../../../../../../../../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { QuestionsDialogFormViewFunctions } from '../../types/questions-dialog-form-view.functions'
import { SecurityQuestionsNumberInput } from '../security-questions-number-input/security-questions-number-input.component'
import { SecurityQuestionFormInputsMessages } from './security-question-form-inputs.messages'
import { SecurityQuestionsFormInputsProps } from './security-question-form-inputs.props'
import { QuestionsDialogFormInputsStyles } from './security-question-form-inputs.styles'

export const SecurityQuestionsFormInputs = (
  props: SecurityQuestionsFormInputsProps
) => {
  const intl = useIntl()

  const message = (
    messageId: MessageDescriptor,
    value?: { [key: string]: any }
  ) => {
    return intl.formatMessage(messageId, value)
  }

  const handleChange = (question: QuestionType) => (value: string) => {
    props.onChange(question, value)
  }

  const securityQuestionFormInputs = props.securityQuestions.map(
    (question: QuestionType) => (
      <div key={question}>
        <SecurityQuestionsNumberInput
          showIf={
            QuestionsDialogFormViewFunctions.getFieldType(question) === 'number'
          }
          label={message(
            QuestionsDialogFormViewFunctions.getFieldMessage(
              question,
              'Question',
              SecurityQuestionFormInputsMessages
            )
          )}
          placeholder={message(
            QuestionsDialogFormViewFunctions.getFieldMessage(
              question,
              'Placeholder',
              SecurityQuestionFormInputsMessages
            )
          )}
          onChange={handleChange(question)}
          value={props.securityFormValues[question].toString()}
          labelClassName={QuestionsDialogFormInputsStyles.question}
          inputClassName={QuestionsDialogFormInputsStyles.answerInput}
        />
      </div>
    )
  )
  return <>{securityQuestionFormInputs}</>
}
