import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { ConsumerSessionState } from '../types/consumer-session-state.interface'
import { createSelector } from 'reselect'
import { Selector } from 'react-redux'
import { RootStore } from '../../../../../store/root.store'
import { ConsumerSessionActions } from './consumer-session.actions'

const getSessionState = (rootState: RootState): ConsumerSessionState =>
  rootState.consumerState.consumerSessionState

const getSessionToken: Selector<
  RootState,
  ConsumerSessionState['sessionToken']
> = createSelector(
  getSessionState,
  (state: ConsumerSessionState) => state.sessionToken
)

const getSessionTokenError: Selector<
  RootState,
  ConsumerSessionState['getSessionTokenError']
> = createSelector(
  getSessionState,
  (state: ConsumerSessionState) => state.getSessionTokenError
)

const getFetchingConsumerSession: Selector<
  RootState,
  ConsumerSessionState['fetchingConsumerSession']
> = createSelector(
  getSessionState,
  (state: ConsumerSessionState) => state.fetchingConsumerSession
)

const setGetSessionTokenError = (
  value: ConsumerSessionState['getSessionTokenError']
) => RootStore.dispatch(ConsumerSessionActions.getSessionTokenFailure(value))

const setSessionToken = (sessionToken: ConsumerSessionState['sessionToken']) =>
  RootStore.dispatch(ConsumerSessionActions.setSessionToken(sessionToken))

const setIsLoading = () => RootStore.dispatch(ConsumerSessionActions.setIsLoading())

const fetchConsumerSession = () =>
  RootStore.dispatch(ConsumerSessionActions.fetchConsumerSession())

const getConsumerSessionError: Selector<
  RootState,
  ConsumerSessionState['error']
> = createSelector(
  getSessionState,
  (state: ConsumerSessionState) => state.error
)

export const ConsumerSessionStore = {
  getSessionToken,
  setSessionToken,
  getSessionTokenError,
  getFetchingConsumerSession,
  setGetSessionTokenError,
  setIsLoading,
  fetchConsumerSession,
  getConsumerSessionError
}
