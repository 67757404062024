import { GeoLocationState } from '../types/geo-location-state.interface';
import { createGeoLocationStateState } from '../types/geo-location-tab-state.functions';
import { GeoLocationActionType, GeoLocationAction } from './geo-location.actions';

export function geoLocationReducer(
  state: GeoLocationState = createGeoLocationStateState(),
  action: GeoLocationActionType,
): GeoLocationState {
  switch (action.type) {
    case GeoLocationAction.FetchProvince:
      return {
        ...state,
        isLoading: true,
      };
    case GeoLocationAction.FetchProvinceSuccess:
      return {
        ...state,
        province: action.payload,
        isLoading: false,
      };
    case GeoLocationAction.FetchProvinceFailure:
      return {
        ...state,
        isLoading: false,
      };
    case GeoLocationAction.SetConsumerCoordinates:
      return {
        ...state,
        coordinates: action.payload
      }
    case GeoLocationAction.SetHighCostLicenseDetails:
      return {
        ...state,
        highCostLicenseDetails: action.payload,
      }
    default:
      return state;
  }
}
