import React from 'react'
import { WithShowIfProps } from './with-show-if.props'

export const withShowIf = <P extends React.PropsWithChildren<any>>(
  Component: React.FC<P>
): React.FC<P & WithShowIfProps> => {
  return (props) => {
    if (props.showIf === false) {
      return null
    }
    return <Component {...props} />
  }
}
