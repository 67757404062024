import { Reducer } from 'redux'
import {
  ConsumerAssetSubtypeStepAction,
  ConsumerAssetSubtypeStepActionType
} from './consumer-asset-subtype-step.actions'
import { ConsumerAssetSubtypeStepState } from '../types/consumer-asset-subtype-step-interface'
import { createConsumerAssetSubtypeState } from '../types/consumer-asset-subtype-step.functions'

export const consumerAssetSubtypeStepReducer: Reducer<
  ConsumerAssetSubtypeStepState,
  ConsumerAssetSubtypeStepActionType
> = (
  state: ConsumerAssetSubtypeStepState = createConsumerAssetSubtypeState(),
  action: ConsumerAssetSubtypeStepActionType
): ConsumerAssetSubtypeStepState => {
  switch (action.type) {
    case ConsumerAssetSubtypeStepAction.SaveConsumerAssetSubtypeId:
      return {
        ...state,
        error: null,
        loading: true
      }
    case ConsumerAssetSubtypeStepAction.SaveConsumerAssetSubtypeIdSuccess:
      return {
        ...state,
        assetSubtypeId: action.payload,
        isWorkflowStepComplete: true,
        loading: false
      }
    case ConsumerAssetSubtypeStepAction.SaveConsumerAssetSubtypeIdFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}
