import { LookupAddress } from '../../../../../../../../../../../../../../../../../domain/lookup-address/lookup-address.interface'
import { LoadingItem } from '../components/loading-item/loading-item.component'
import { LookupAddressItem } from '../components/lookup-address-item/lookup-address-item.component'
import { ManualAddressItem } from '../components/manual-address-item/manual-address-item.component'
import { NoResultsItem } from '../components/no-results-item/no-results-item.component'

export const isFirstDropdownItem = (key: number): boolean => key === 0

export const getDropdownLoadingItems = (): JSX.Element[] => [
  <LoadingItem key={0} />
]

export const getDropdownAddressLookupItems = (
  addressLookups: LookupAddress[],
  onLookupAddressItemClick: (id: LookupAddress['id']) => void,
  switchToManualAddressForm: () => void
): JSX.Element[] => {
  let key: number = 0
  const items: JSX.Element[] = []

  for (; key < addressLookups.length; key++) {
    items.push(
      <LookupAddressItem
        address={addressLookups[key].text}
        description={addressLookups[key].description}
        id={addressLookups[key].id}
        isFirstItem={isFirstDropdownItem(key)}
        key={key}
        onLookupAddressItemClick={onLookupAddressItemClick}
      />
    )
  }

  items.push(
    <ManualAddressItem
      key={key}
      switchToManualAddressForm={switchToManualAddressForm}
    />
  )

  return items
}

export const getDropdownNoResultsItems = (
  switchToManualAddressForm: () => void
): JSX.Element[] => [
  <NoResultsItem key={0} />,
  <ManualAddressItem
    key={1}
    switchToManualAddressForm={switchToManualAddressForm}
  />
]

export const areThereAddressLookupItems = (
  addressLookups: LookupAddress[] | null
): addressLookups is LookupAddress[] =>
  Array.isArray(addressLookups) && addressLookups.length >= 1
