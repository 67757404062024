import Select from 'react-select'
import { DropdownLibraryControl } from './components/dropdown-library-control/dropdown-library-control.component'
import { DropdownLibraryInput } from './components/dropdown-library-input/dropdown-library-input.component'
import { DropdownLibraryMenuListItem } from './components/dropdown-library-menu-list-item/dropdown-library-menu-list-item.component'
import { DropdownLibraryMenuList } from './components/dropdown-library-menu-list/dropdown-library-menu-list.component'
import { DropdownLibrarySingleValue } from './components/dropdown-library-single-value/dropdown-library-single-value.component'
import { DropdownLibraryProps } from './dropdown-library.props'
import { DropdownLibraryStyles } from './dropdown-library.styles'

export function DropdownLibrary(props: DropdownLibraryProps) {
  return (
    <Select
      placeholder={props.placeholder}
      components={{
        Control: DropdownLibraryControl(props.controlUat),
        MenuList: DropdownLibraryMenuList(props.menuListUat),
        SingleValue: DropdownLibrarySingleValue(props.singleValueUat),
        Option: DropdownLibraryMenuListItem(props.menuListItemUat),
        Input: DropdownLibraryInput(props.searchable ?? false)
      }}
      name={props.name}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      styles={DropdownLibraryStyles}
      className={props.className}
    />
  )
}
