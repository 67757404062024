import { ConsumerRoutes } from './consumer.routes'
import { ConsumerSession } from './modules/consumer-session/consumer-session.component'
import { ConsumerWorkflow } from './modules/consumer-workflow/consumer-workflow.component'

export const ConsumerModule = () => {
  return (
    <>
      <ConsumerSession></ConsumerSession>
      <ConsumerWorkflow></ConsumerWorkflow>
      <ConsumerRoutes></ConsumerRoutes>
    </>
  )
}
