import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { Slider } from '../../../../../../../../ui-library/components/slider/slider.component'
import { ConsumerNetIncomeError } from '../consumer-net-income-error/consumer-net-income-error.component'
import { ConsumerNetIncomeSalary } from '../consumer-net-income-salary/consumer-net-income-salary.component'
import { ConsumerNetIncomeFormMessages } from './consumer-net-income-form.messages'
import { ConsumerNetIncomeFormProps } from './consumer-net-income-form.props'
import { ConsumerNetIncomeFormStyles } from './consumer-net-income-form.styles'
import { ConsumerNetIncomeFormUat } from './consumer-net-income-form.uat'

export const ConsumerNetIncomeForm = (props: ConsumerNetIncomeFormProps) => {
  const [netMonthlyIncome, setNetMonthlyIncome] = useState<number>(
    props.initialFormValue || 0
  )
  const [disableSubmitButton, setDisableSubmitButton] = useState(
    props.initialFormValue === null || props.initialFormValue === undefined
  )
  const submitForm = () => props.onSubmit(netMonthlyIncome)

  const saveConsumerNetIncomeError = props.submitError

  const sliderValueChanged = useCallback(
    (value) => {
      if (value > 0) {
        handleOnSliderClick()
      }
      setNetMonthlyIncome(value)
    },
    [setNetMonthlyIncome]
  )

  const sliderProps = useMemo(
    () => ({
      min: 0,
      max: 20000,
      value: props.initialFormValue || 0,
      step: 100,
      name: 'net-monthly-income',
      onChange: (e: any) => sliderValueChanged(e),
      cssContainerClasses: ConsumerNetIncomeFormStyles.slider
    }),
    [props.initialFormValue, sliderValueChanged]
  )

  const handleOnSliderClick = () => {
    setDisableSubmitButton(false)
  }

  return (
    <div className={ConsumerNetIncomeFormStyles.wrapper}>
      <div className={ConsumerNetIncomeFormStyles.container}>
        <div className={ConsumerNetIncomeFormStyles.sliderField}>
          <div className={ConsumerNetIncomeFormStyles.sliderContainer}>
            <Slider
              {...sliderProps}
              uat={uatId(ConsumerNetIncomeFormUat.slider)}
            />
          </div>
        </div>

        <ConsumerNetIncomeSalary netMonthlyIncome={netMonthlyIncome} />

        <ConsumerNetIncomeError showIf={Boolean(saveConsumerNetIncomeError)} />

        <PrimaryButton
          onClick={submitForm}
          cssContainerClasses={ConsumerNetIncomeFormStyles.submitButton}
          disabled={disableSubmitButton}
          loading={props.loading}
          uat={uatId(ConsumerNetIncomeFormUat.submitButton)}
        >
          <FormattedMessage {...ConsumerNetIncomeFormMessages.submitButton} />
        </PrimaryButton>
      </div>
    </div>
  )
}
