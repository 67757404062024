import { useIntl } from 'react-intl'
import { BannerTextType } from '../../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { BannerText } from '../../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { withShowIf } from '../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerAssetSubtypeErrorStyles } from './consumer-asset-subtype-error.styles'
import { ConsumerAssetSubtypeErrorMessages } from './consumer-asset-subtype-error.messages'
import { uatId } from '../../../../../../../../../uat/uat-id.function'
import { ConsumerAssetSubtypeErrorUat } from './consumer-asset-subtype-error.uat'

export const ConsumerAssetSubtypeError = withShowIf(() => {
    const intl = useIntl();
    
    return (
        <BannerText
            uat={uatId(ConsumerAssetSubtypeErrorUat.error)}
            cssContainerClasses={ConsumerAssetSubtypeErrorStyles.errorMessage}
            type={BannerTextType.DANGER}
            boldText={intl.formatMessage(ConsumerAssetSubtypeErrorMessages.errorMessage)}
        />
    )
})