import { Reducer } from 'redux'
import { ConsumerPostSubmissionApprovedActionType } from '../modules/consumer-post-submission-approved/store/consumer-post-submission-approved.actions'
import { consumerPostSubmissionApprovedReducer } from '../modules/consumer-post-submission-approved/store/consumer-post-submission-approved.reducer'
import { createConsumerPostSubmissionState } from '../types/consumer-post-submission-state.function'
import { ConsumerPostSubmissionState } from '../types/consumer-post-submission-state.interface'
import { ConsumerPostSubmissionActionType } from './consumer-post-submission.actions'
import { consumerPostSubmissionOffersReducer } from '../modules/consumer-post-submission-offers/store/consumer-post-submission-offers.reducer'
import { ConsumerPostSubmissionOffersActionType } from '../modules/consumer-post-submission-offers/store/consumer-post-submission-offers.actions'

export const consumerPostSubmissionReducer: Reducer<
  ConsumerPostSubmissionState,
  ConsumerPostSubmissionActionType
> = (
  state: ConsumerPostSubmissionState = createConsumerPostSubmissionState(),
  action: ConsumerPostSubmissionActionType
): ConsumerPostSubmissionState => {
  return {
    ...state,
    consumerPostSubmissionApprovedState: consumerPostSubmissionApprovedReducer(
      state.consumerPostSubmissionApprovedState,
      action as ConsumerPostSubmissionApprovedActionType
    ),
    consumerPostSubmissionOffersState: consumerPostSubmissionOffersReducer(
      state.consumerPostSubmissionOffersState,
      action as ConsumerPostSubmissionOffersActionType
    )
  }
}
