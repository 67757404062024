import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../../../../../../../store/root.store'
import { RootState } from '../../../../../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerAutoAddressState } from '../types/consumer-auto-address-state.interface'
import { ConsumerAddress } from '../../../../../../../../../../../domain/consumer/consumer-profile/consumer-address.interface'
import { ConsumerAutoAddressActions } from './consumer-auto-address.actions'
import { LookupAddress } from '../../../../../../../../../../../domain/lookup-address/lookup-address.interface'

const consumerAutoAddressState = (
  rootState: RootState
): ConsumerAutoAddressState =>
  rootState.consumerState.consumerWorkflowState.consumerAddressStepState.auto

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerAutoAddressState['isWorkflowStepComplete']
> = createSelector(
  consumerAutoAddressState,
  (state: ConsumerAutoAddressState) => state.isWorkflowStepComplete
)

const getLookupAddresses: Selector<
  RootState,
  ConsumerAutoAddressState['lookupAddresses']
> = createSelector(
  consumerAutoAddressState,
  (state: ConsumerAutoAddressState) => state.lookupAddresses
)

const getLookupAddressesLoading: Selector<
  RootState,
  ConsumerAutoAddressState['lookupAddressesLoading']
> = createSelector(
  consumerAutoAddressState,
  (state: ConsumerAutoAddressState) => state.lookupAddressesLoading
)

const getRetrieveAddress: Selector<
  RootState,
  ConsumerAutoAddressState['retrieveAddress']
> = createSelector(
  consumerAutoAddressState,
  (state: ConsumerAutoAddressState) => state.retrieveAddress
)

const getRetrieveAddressLoading: Selector<
  RootState,
  ConsumerAutoAddressState['retrieveAddressLoading']
> = createSelector(
  consumerAutoAddressState,
  (state: ConsumerAutoAddressState) => state.retrieveAddressLoading
)

const getSaveAddressLoading: Selector<
  RootState,
  ConsumerAutoAddressState['saveAddressLoading']
> = createSelector(
  consumerAutoAddressState,
  (state: ConsumerAutoAddressState) => state.saveAddressLoading
)

const getSaveAddressFailure: Selector<
  RootState,
  ConsumerAutoAddressState['saveAddressFailure']
> = createSelector(
  consumerAutoAddressState,
  (state: ConsumerAutoAddressState) => state.saveAddressFailure
)

const lookupAddress = (address: string) => {
  RootStore.dispatch(ConsumerAutoAddressActions.lookupAddress(address))
}

const retrieveAddress = (id: LookupAddress['id']) => {
  RootStore.dispatch(ConsumerAutoAddressActions.retrieveAddress(id))
}

const saveAddress = (address: ConsumerAddress) => {
  RootStore.dispatch(ConsumerAutoAddressActions.saveAutoAddress(address))
}

const skipSaveAddress = () => {
  RootStore.dispatch(ConsumerAutoAddressActions.saveAutoAddressSuccess())
}

const reset = () =>
  RootStore.dispatch(ConsumerAutoAddressActions.resetConsumerAutoAddressState())

const resetSaveAddressFailure = () =>
  RootStore.dispatch(ConsumerAutoAddressActions.resetSaveAddressFailure())

export const ConsumerAutoAddressStore = {
  getIsWorkflowStepComplete,
  getLookupAddresses,
  getLookupAddressesLoading,
  getRetrieveAddress,
  getRetrieveAddressLoading,
  getSaveAddressLoading,
  getSaveAddressFailure,
  lookupAddress,
  retrieveAddress,
  saveAddress,
  skipSaveAddress,
  reset,
  resetSaveAddressFailure
}
