import React, { ChangeEvent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { uatId } from '../../../../../../../../../../uat/uat-id.function'
import { CheckboxInput } from '../../../../../../../../../../ui-library/components/input-field/components/checkbox-input/checkbox-input.component'
import { ConsumerSubmitConsentUat } from './consumer-submit-consent.uat'
import { ConsumerSubmitConsentProps } from './consumer-submit-consent.props'
import { Paragraph } from '../../../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { ConsumerSubmitConsentStyles } from './consumer-submit-consent.styles'
import { Link } from '../../../../../../../../../../ui-library/components/link/link.component'

export const ConsumerSubmitConsent = (props: ConsumerSubmitConsentProps) => {
  const intl = useIntl()

  const onCreditConsentChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    props.onConsentChange(Boolean(event.target.checked))
  }

  return (
    <>
      <Paragraph className={ConsumerSubmitConsentStyles.header}>
        <FormattedMessage {...props.consentLabel} />
      </Paragraph>
      <div className={ConsumerSubmitConsentStyles.checkboxContainer}>
        <CheckboxInput
          name={props.name}
          label={intl.formatMessage(props.consentText)}
          value={props.consentValue}
          mustBeTrue
          onChange={onCreditConsentChange}
          uat={uatId(ConsumerSubmitConsentUat.consentCheckbox)}
          className={ConsumerSubmitConsentStyles.label}
        />
        <br />
        <Link
          showIf={Boolean(props.consentLink)}
          link={props.consentLink}
          text={props.consentLinkText}
          uat={uatId(ConsumerSubmitConsentUat.viewConsentLink)}
          className={ConsumerSubmitConsentStyles.consentLink}
        />
      </div>
    </>
  )
}
