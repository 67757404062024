import { BannerTextType } from './banner-text-type.enum'

export const BannerTextStyles = {
  container: (type: BannerTextType, cssContainerClasses: string = ''): string =>
    `${containerTypeStyle(
      type
    )} px-4 py-2 rounded-lg border-2 ${cssContainerClasses}`,
  boldText: 'font-bold inline',
  text: 'inline'
}

const containerTypeStyle = (type: BannerTextType): string => {
  switch (type) {
    case BannerTextType.INFO:
      return 'border-blue100 bg-white150'
    case BannerTextType.DANGER:
      return 'border-red100 bg-red'
    default:
      return ''
  }
}
