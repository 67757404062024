import { Merchants } from '../../../domain/merchant/merchants.interface'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'

export class ConsumerProfileSelectedMerchantsService extends BffService {
  public getSelectedMerchants(): FetchResponse<Merchants> {
    return super.createAuthorizedRequest({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/consumer-profile/selected-merchants`
    })
  }
}
