import {  useIntl } from 'react-intl'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerProfileAssetOverview } from '../consumer-profile-asset-overview/consumer-profile-asset-overview.component'
import { ConsumerProductViewMessages } from './consumer-review-asset-view.messages'
import { ConsumerReviewAssetViewProps } from './consumer-review-asset-view.props'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'

export const ConsumerReviewAssetView = (
  props: ConsumerReviewAssetViewProps
) => {
  const intl = useIntl();

  return (
    <>
      <PrimaryHeader
        showBackIcon={props.step.rules.showBackButton}
        goBack={props.goBack}
      />
      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(ConsumerProductViewMessages.header)}
        subTitleMessage={intl.formatMessage(ConsumerProductViewMessages.description)}
        showFooter
        noHorizontalPadding
      >
        <ConsumerProfileAssetOverview
          onComplete={props.onComplete}
          productDetails={props.productDetails}
        />
      </ConsumerProfileAssetImageContainer>
    </>
  )
}
