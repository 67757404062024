import { createConsumerEditPhoneNumberState } from '../modules/consumer-edit-phone-number/types/consumer-edit-phone-number-state.functions'
import { ConsumerPhoneValidationStepState } from './consumer-phone-validation-step-state.interface'

export function createConsumerPhoneValidationStepState(): ConsumerPhoneValidationStepState {
  return {
    isWorkflowStepComplete: false,
    isResendVerificationCodeSuccessful: false,
    phoneValidationError: null,
    phoneValidationLoading: false,
    resendVerificationCodeError: null,
    consumerEditPhoneNumberState: createConsumerEditPhoneNumberState()
  }
}
