import { mergeMap, OperatorFunction } from 'rxjs'
import { SessionToken } from '../../shared/types/session-token.type'
import { FetchService } from '../fetch/fetch.service'
import { FetchResponse } from '../fetch/types/fetch-response.type'
import { BffRequest } from './types/bff-request.interface'

export abstract class BffService {
  private fetchService = new FetchService()

  constructor(private sessionToken: SessionToken = null) {}

  public setSessionToken(newToken: SessionToken): this {
    this.sessionToken = newToken
    return this
  }

  protected get bffUrl(): string {
    return process.env.REACT_APP_PRE_QUALIFIER_BFF_URL ?? ''
  }

  protected createAuthorizedRequest<T>(request: BffRequest): FetchResponse<T> {
    return this.fetchService
      .createRequest({
        ...request,
        sessionToken: this.sessionToken
      })
      .pipe(this.handleRequestResponse())
  }

  protected createRequest<T>(request: BffRequest): FetchResponse<T> {
    return this.fetchService
      .createRequest(request)
      .pipe(this.handleRequestResponse())
  }

  private handleRequestResponse(): OperatorFunction<Response, any> {
    return mergeMap(async (response: Response) => {
      if (response.headers.get('Content-Type')?.includes('json')) {
        return await response.json()
      }

      return null
    })
  }
}
