import { FormattedMessage, useIntl } from 'react-intl'
import { MainLayout } from '../../../../../../../../shared/components/main-layout/main-layout.component'
import { CheckmarkIcon } from '../../../../../../../../shared/icons/check-mark/check-mark.icon'
import { MerchantSuccessFooter } from './components/merchant-success-footer/merchant-success-footer.component'
import { MerchantSelectSuccessViewStyles } from './merchant-select-success-view.styles'
import { MerchantSelectSuccessViewMessages } from './merchant-select-success-view.messages'
import { useSelector } from 'react-redux'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { useHistory } from 'react-router'
import { MerchantSelectSuccessViewProps } from './merchant-select-success-view.props'
import { ConsumerRoute } from '../../../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { addVendorIdToPath } from '../../../../../../../../shared/helpers/path-helper'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { MerchantSelectionViewReferenceCode } from '../../../merchant-selection-view/components/merchant-selection-view-reference-code/merchant-selection-view-reference-code.component'

export const MerchantSelectSuccessView = (
  props: MerchantSelectSuccessViewProps
) => {
  const intl = useIntl();
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)
  const history = useHistory()

  const goBackToSelectMerchant = () => {
    history.push(addVendorIdToPath(vendorId, ConsumerRoute.SelectMerchant))
  }

  const goBackToSubmitApproved = () => {
    history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitApproved))
  }

  const approvalSentMessage = `${intl.formatMessage(MerchantSelectSuccessViewMessages.applicationSentTo)} ${props.merchant.name}`;

  return (
    <MainLayout 
      noHorizontalPadding 
      showContactInfoFooter 
      centerAlignDisclaimer
    >
      <PrimaryHeader
        showBackIcon
        goBack={goBackToSubmitApproved}
        backButtonText={intl.formatMessage(MerchantSelectSuccessViewMessages.backBtnText)}
        horizontalPadding
      />

      <ConsumerProfileAssetImageContainer
        titleClasses={MerchantSelectSuccessViewStyles.headerText}
        titleMessage={approvalSentMessage}
        titleStatusIcon={
          <CheckmarkIcon
            className={MerchantSelectSuccessViewStyles.checkMarkIcon}
          />
        }
        subTitleMessage={intl.formatMessage(MerchantSelectSuccessViewMessages.instructions)}
      >
        <div className={MerchantSelectSuccessViewStyles.container}>
          <div className={MerchantSelectSuccessViewStyles.buttonWrapper}>
            <PrimaryButton 
              onClick={goBackToSelectMerchant} 
              cssContainerClasses={MerchantSelectSuccessViewStyles.buttonContainer}>
              <FormattedMessage
                {...MerchantSelectSuccessViewMessages.navigateBack}
              />
            </PrimaryButton>
          </div>
        </div>
      </ConsumerProfileAssetImageContainer>
      <MerchantSelectionViewReferenceCode referenceCode={useSelector(ConsumerProfileStore.getReferenceCode)} />
      <MerchantSuccessFooter merchant={props.merchant} />
    </MainLayout>
  )
}
