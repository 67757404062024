import { BffService } from '../bff.service'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { ConsumerNetIncomeStepState } from '../../../modules/consumer/modules/consumer-workflow/modules/consumer-net-income-step/types/consumer-net-income-step-state.interface'

export class ConsumerNetIncomeService extends BffService {
  public saveConsumerNetIncome(
    netIncome: ConsumerNetIncomeStepState['netIncome']
  ): FetchResponse<null> {
    return super.createAuthorizedRequest({
      method: FetchMethod.PATCH,
      url: `${this.bffUrl}/v1/consumer-profile/net-monthly-income`,
      body: {
        netMonthlyIncome: netIncome
      }
    })
  }
}
