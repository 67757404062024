import { FormattedMessage, useIntl } from 'react-intl'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { QuestionsDialogFormViewMessages } from './questions-dialog-form-view.messages'
import { QuestionsDialogFormViewProps } from './questions-dialog-form-view.props'
import { QuestionsDialogFormViewStyles } from './questions-dialog-form-view.styles'
import { SecurityQuestionsFormInputs } from './components/security-questions-form-inputs/security-question-form-inputs.component'
import { BannerText } from '../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { BannerTextType } from '../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'

export const QuestionsDialogFormView = (
  props: QuestionsDialogFormViewProps
) => {
  const intl = useIntl()
  const getErrorMessage = () =>
    intl.formatMessage(QuestionsDialogFormViewMessages.attemptsRemainingError, {
      attemptsRemaining: props.attemptsRemaining
    })

  return (
    <div>
      <SecurityQuestionsFormInputs
        securityFormValues={props.securityFormValues}
        securityQuestions={props.securityQuestions}
        onChange={props.onChange}
      />
      <BannerText
        cssContainerClasses={QuestionsDialogFormViewStyles.bannerContainer}
        type={BannerTextType.DANGER}
        text={getErrorMessage()}
        showIf={Boolean(props.attemptsRemaining) && !props.loading}
      />
      <div className={QuestionsDialogFormViewStyles.buttonContainer}>
        <PrimaryButton
          onClick={props.onSubmit}
          disabled={props.disabled}
          loading={props.loading}
        >
          <FormattedMessage {...QuestionsDialogFormViewMessages.submitButton} />
        </PrimaryButton>
      </div>
    </div>
  )
}
