import { Reducer } from 'redux'
import { createMerchantSelectionState } from '../types/merchant-selection-state.functions'
import { MerchantSelectionState } from '../types/merchant-selection-state.interface'
import {
  MerchantSelectionAction,
  MerchantSelectionActionType
} from './merchant-selection.actions'
import { createMerchantSelectionForm } from '../types/merchant-selection-form/merchant-selection-form.functions'

export const merchantSelectionReducer: Reducer<
  MerchantSelectionState,
  MerchantSelectionActionType
> = (
  state: MerchantSelectionState = createMerchantSelectionState(),
  action: MerchantSelectionActionType
): MerchantSelectionState => {
  switch (action.type) {
    case MerchantSelectionAction.FetchMerchants:
      return {
        ...state,
        fetchError: null,
        currentPage: action.payload.page,
        loading: true,
        merchantSelectionForm: {
          ...state.merchantSelectionForm,
          merchantName: action.payload.merchantName
        },       
      }
    case MerchantSelectionAction.FetchConsumerProfileSelectedMerchantsFailure:
    case MerchantSelectionAction.FetchMerchantsFailure:
      return {
        ...state,
        fetchError: action.payload,
        loading: false
      }
    case MerchantSelectionAction.FetchMerchantsSuccess:
      return {
        ...state,
        merchants: [...state.merchants, ...action.payload.results],
        totalResultsCount: action.payload.total,
        fetchError: null,
        loading: false
      }
    case MerchantSelectionAction.FetchConsumerProfileAddressCoordinates:
      return {
        ...state,
        fetchError: null,
        loading: true
      }
    case MerchantSelectionAction.FetchConsumerProfileAddressCoordinatesFailure:
      return {
        ...state,
        fetchError: action.payload,
        loading: false
      }
    case MerchantSelectionAction.FetchConsumerProfileAddressCoordinatesSuccess:
      return {
        ...state,
        consumerProfileAddressCoordinates: action.payload,
        fetchError: null,
        loading: false
      }
    case MerchantSelectionAction.ReassignConsumerProfileApplicationMerchant:
      return {
        ...state,
        submitLoading: true,
        selectedMerchant: action.payload.merchant
      }
    case MerchantSelectionAction.ReassignConsumerProfileApplicationMerchantFailure:
      return {
        ...state,
        submitError: action.payload,
        submitLoading: false
      }
    case MerchantSelectionAction.ReassignConsumerProfileApplicationMerchantSuccess:
      return {
        ...state,
        submitLoading: false
      }
    case MerchantSelectionAction.CloneConsumerProfileApplicationMerchant:
      return {
        ...state,
        selectedMerchant: action.payload.merchant,
        submitLoading: true
      }
    case MerchantSelectionAction.CloneConsumerProfileApplicationMerchantFailure:
      return {
        ...state,
        submitError: action.payload,
        submitLoading: false
      }
    case MerchantSelectionAction.CloneConsumerProfileApplicationMerchantSuccess:
      return {
        ...state,
        submitLoading: false
      }
    case MerchantSelectionAction.ResetMerchants:
      return {
        ...state,
        merchants: [],
        totalResultsCount: null,
        fetchError: null,
        loading: false
      }
      case MerchantSelectionAction.ResetSubmitError:
      return {
        ...state,
        submitError: null
      }
    case MerchantSelectionAction.FetchConsumerProfileSelectedMerchantsSuccess:
      return {
        ...state,
        selectedMerchants: action.payload
      }
    case MerchantSelectionAction.ResetMerchantState:
      return createMerchantSelectionState()
    case MerchantSelectionAction.ResetMerchantSelectionForm:
      return {
        ...state,
        merchantSelectionForm: createMerchantSelectionForm()
      }
    default:
      return state
  }
}
