import { createConsumerPostSubmissionApprovedState } from '../modules/consumer-post-submission-approved/types/consumer-post-submission-state-approved.function'
import { ConsumerPostSubmissionState } from './consumer-post-submission-state.interface'
import { createConsumerPostSubmissionOffersState } from '../modules/consumer-post-submission-offers/types/consumer-post-submission-offers-state.functions'

export function createConsumerPostSubmissionState(): ConsumerPostSubmissionState {
  return {
    consumerPostSubmissionApprovedState:
      createConsumerPostSubmissionApprovedState(),
    consumerPostSubmissionOffersState: createConsumerPostSubmissionOffersState()
  }
}
