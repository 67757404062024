import React from 'react'
import lcLogo from '../../../../../assets/lc-logo.png'
import { withHideIf } from '../../../../../ui-library/helpers/with-hide-if/with-hide-if.component'
import { NavBarStyles } from './nav-bar.styles'

export const NavBar = withHideIf(() => {
  return (
    <div className={NavBarStyles.container}>
      <img className={NavBarStyles.logo} src={lcLogo} alt="" />
    </div>
  )
})
