import { Paragraph } from '../../../../../ui-library/components/paragraph/paragraph.component';
import { withShowIf } from '../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ProductDetailsStyles } from './product-details.styles';
import { formatCurrency } from '../../../../helpers/currency/currency-formatter';
import { useSelector } from 'react-redux';
import { SettingsStore } from '../../../../../modules/app/modules/settings/store/settings.store';
import { useEffect, useMemo, useState } from 'react';
import { ProductDetailsProps } from './product-details.props';
import { FormattedMessage } from 'react-intl';
import { ProductDetailsMessages } from './product-details.messages';

export const ProductDetails = withShowIf((props: ProductDetailsProps) => {
    const locale = useSelector(SettingsStore.getLocaleSetting);
    const [assetImage, setAssetImage] = useState<string>('');

    const preQualifierVendorImage = useMemo(() => {
        if (props.productDetails?.vendor.defaultProductImageUrl) {
            return props.productDetails.vendor.defaultProductImageUrl
        }
        if (props.consumerProfileVendor?.defaultProductImageUrl) {
            return props.consumerProfileVendor.defaultProductImageUrl
        }
        return '';
    }, [props.productDetails, props.consumerProfileVendor]);

    const preQualifierImage = useMemo(() => {
        if (props.productDetails?.imageUrl) {
            return props.productDetails.imageUrl
        }
        if (props.consumerProfileAsset?.imageUrl) {
            return props.consumerProfileAsset.imageUrl
        }
        return '';
    }, [props.productDetails, props.consumerProfileAsset]);

    const preQualifierModel = useMemo(() => {
        if (props.productDetails?.model) {
            return props.productDetails.model
        }
        if (props.consumerProfileAsset?.model) {
            return props.consumerProfileAsset.model
        }
        return '';
    }, [props.productDetails, props.consumerProfileAsset]);

    const preQualifierProductMsrp = useMemo(() => {
        if (props.productDetails?.msrp) {
            return props.productDetails.msrp
        }
        if (props.consumerProfileAsset?.msrp) {
            return props.consumerProfileAsset.msrp
        }
        return 0;
    }, [props.productDetails, props.consumerProfileAsset]);

    const handleProductImageError = (): void => {
        if (preQualifierVendorImage !== '') {
            setAssetImage(preQualifierVendorImage);
        } else {
            setAssetImage('/brp_logo.png');
        }
    }

    useEffect(() => {
        if (preQualifierImage !== '') {
            setAssetImage(preQualifierImage);
        }
    }, [preQualifierImage]);
    
    return (
        <div className={ProductDetailsStyles.container}>
            <div className={ProductDetailsStyles.productDetailsContainer}>
                <span className={ProductDetailsStyles.brandLogoContainer}>
                    <img className={ProductDetailsStyles.brandLogo} src={preQualifierVendorImage} />
                </span>
                <Paragraph className={ProductDetailsStyles.productName}>
                    {preQualifierModel}
                </Paragraph>
            </div>
            <div className={ProductDetailsStyles.productDisplayContainer}>
                <img className={ProductDetailsStyles.image} src={assetImage} onError={handleProductImageError} />

                <div className={ProductDetailsStyles.msrpContainer}>
                    <Paragraph className={ProductDetailsStyles.msrpTitle}>
                        <FormattedMessage {...ProductDetailsMessages.msrp} />
                    </Paragraph>
                    <Paragraph className={ProductDetailsStyles.msrpValue}>
                        {formatCurrency(locale, preQualifierProductMsrp)}
                    </Paragraph>
                </div>
            </div>
        </div>
    )
});