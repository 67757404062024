import { CommonStyles } from '../../../../../../../../../../ui-library/styles/common.styles'

export const ConsumerPhoneValidationSectionViewStyles = {
  container: 'w-full items-center',
  editPhoneNumber: `${CommonStyles.anchorLink}`,
  editPhoneNumberContainer: 'py-4',
  newCodeContainer: 'mt-3 sm:mt-0',
  newCode: `${CommonStyles.anchorLink}`,
  bold: 'font-bold',
  loadingIcon: 'mt-16',
  title:
    'font-bold text-16px text-blue700 text-center mt-6 sm:text-20px sm:mt-12 sm:font-normal'
}
