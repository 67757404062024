import clsx from 'clsx'
import { ErrorMessage } from './components/error-message/error-message.component'
import { InputFieldProps } from './input-field.props'
import { InputFieldStyles } from './input-field.styles'

export const InputField = (props: InputFieldProps) => {
  const labelClassName = props.labelClassName ? props.labelClassName : InputFieldStyles.label
  
  return (
    <div
      className={clsx(
        InputFieldStyles.container(Boolean(props.fullWidth)),
        props.containerClassName
      )}
    >
      <label 
        htmlFor={props.name} 
        className={labelClassName}
      >
        {props.label}
      </label>
      {props.children}
      <ErrorMessage errorMessage={props.errorMessage} showIf={props.error} />
    </div>
  )
}

