import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import { createEpicMiddleware, EpicMiddleware } from 'redux-observable'
import { Action } from '../root.actions'
import { RootState } from './root-state/root-state.interface'
import { createLogger as createReduxLogger } from 'redux-logger'
import { createRootReducer } from '../root.reducer'
import { createRootState } from './root-state/root-state.functions'
import { rootEpics } from '../root.epics'

export const history = createBrowserHistory()

export function configureRootStore() {
  const composeEnhancer = getComposeEnhancer()
  const epicMiddleware = createEpicMiddleware<Action, Action, RootState>()

  const store = createStore(
    createRootReducer(history),
    createRootState(),
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        ...getAllMiddleware(epicMiddleware)
      )
    )
  )

  if ((module as any).hot) {
    ;(module as any).hot.accept('../root.reducer', () => {
      store.replaceReducer(createRootReducer(history))
    })
  }

  epicMiddleware.run(rootEpics)

  return store
}

function getComposeEnhancer() {
  return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

function getAllMiddleware(
  epicMiddleware: EpicMiddleware<Action, Action, RootState>
) {
  if (process.env.REACT_APP_USE_REDUX_LOGGER) {
    return [epicMiddleware, createReduxLogger({ collapsed: true })]
  }
  return [epicMiddleware]
}
