import { defineMessages } from 'react-intl';

export const ConsumerAssetSubtypeViewMessages = defineMessages({
    header: {
        id: 'Pages.ConsumerAssetSubtype.View.Header',
        description: 'Consumer Asset Subtype Page Header',
    },    
    title: {
        id: 'Pages.ConsumerAssetSubtype.View.Title',
        description: 'Consumer Asset Subtype Page Title',
    },
    subtitle: {
        id: 'Pages.ConsumerAssetSubtype.Form.subtitle',
        description: 'Consumer Asset Subtype Subtitle',
    },
    nextButton: {
        id: 'Pages.ConsumerAssetSubtype.Form.nextButton',
        description: 'Consumer Asset Subtype Next Button',
    },
    selectAssetSubtypePlaceholder: {
        id: 'Pages.ConsumerAssetSubtype.Form.selectAssetSubtypePlaceholder',
        description: 'Consumer Asset Subtype Select Placeholder',
    }
});