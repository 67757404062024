import { LocalizationMessage } from '../../../../../../../../../shared/types/localization-message.interface'
import { ConsumerPhoneNumberStepState } from '../../../types/consumer-phone-number-step-state.interface'
import { ConsumerPhoneNumberViewMessages } from '../../consumer-phone-number-view/consumer-phone-number-view.message'

export const getErrorMessage = (
  error: ConsumerPhoneNumberStepState['error']
): LocalizationMessage => {
  switch (error?.statusCode) {
    case 400:
      return ConsumerPhoneNumberViewMessages.errorValidation
    default:
      return ConsumerPhoneNumberViewMessages.errorGeneral
  }
}
