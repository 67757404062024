import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { FormattedMessage } from 'react-intl'
import { ConsumerPostSubmissionApprovedViewMessages } from '../../../consumer-post-submission-approved/components/consumer-post-submission-approved-view/consumer-post-submission-approved-view.messages'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { ConsumerPostSubmissionApprovedViewUat } from '../../../consumer-post-submission-approved/components/consumer-post-submission-approved-view/consumer-post-submission-approved-view.uat'
import { truncateConsumerName } from '../../../../../../../../shared/helpers/truncate/truncate-consumer-name.function'
import { OfferList } from '../offer-list/offer-list.component'
import { MainLayout } from '../../../../../../../../shared/components/main-layout/main-layout.component'
import { OffersViewComponentProps } from './offers-view-component.props'
import { ConsumerProfileAssetSummaryView } from '../consumer-profile-asset-summary-view/consumer-profile-asset-summary-view.component'
import { OffersViewComponentStyles } from './offers-view-component.styles'

export const OffersView = (props: OffersViewComponentProps) => {
  return (
    <MainLayout showContactInfoFooter noHorizontalPadding centerAlignDisclaimer>
      <div className={OffersViewComponentStyles.headerContainer}>
        <Paragraph className={OffersViewComponentStyles.header}>
          <FormattedMessage
            {...ConsumerPostSubmissionApprovedViewMessages.header}
          />
        </Paragraph>
      </div>
      <div
        className={OffersViewComponentStyles.approvedContainer}
      >
        <Paragraph
          uat={uatId(
            ConsumerPostSubmissionApprovedViewUat.approvedAmountMessage
          )}
          className={
            OffersViewComponentStyles.approvedAmountHeaderMessage
          }
        >
          <FormattedMessage
            {...ConsumerPostSubmissionApprovedViewMessages.approvedAmountMessageHeader}
            values={{ name: truncateConsumerName(props.firstName) }}
          />
        </Paragraph>
        <Paragraph
          uat={uatId(ConsumerPostSubmissionApprovedViewUat.approvedAmountMessage)}
          className={OffersViewComponentStyles.approvedAmountMessage}
        >
          <FormattedMessage
            {...ConsumerPostSubmissionApprovedViewMessages.approvedAmountMessage}
          />
        </Paragraph>

        <ConsumerProfileAssetSummaryView
          showIf={Boolean(props.consumerProfileAsset)}
          consumerProfileAsset={props.consumerProfileAsset}
          vendorImage={props.vendorImage}
        />
        <Paragraph
          uat={uatId(ConsumerPostSubmissionApprovedViewUat.aboutMessage)}
          className={OffersViewComponentStyles.approvedAmountMessage}
        >
          <FormattedMessage
            {...ConsumerPostSubmissionApprovedViewMessages.aboutMessage}
          />
        </Paragraph>
      </div>

      <OfferList
        offers={props.offers}
        onSelectedOffer={props.onSelectedOffer}
        onBtnCompleteLater={props.onBtnCompleteLater}
        onBtnSelectOffer={props.onBtnSelectedOffer}
      />
    </MainLayout>
  )
}
