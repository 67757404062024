export const ConsumerSubmitViewMessages = {
  header: {
    id: 'Pages.ConsumerSubmit.View.header',
    description: 'Consumer Submit Page Header'
  },
  title: {
    id: 'Pages.ConsumerSubmit.View.title',
    description: 'Consumer Submit Page title'
  },
  instructionStep1: {
    id: 'Pages.ConsumerSubmit.View.instructionStep1',
    description: 'Consumer Submit Page instructionStep1'
  },
  instructionStep2: {
    id: 'Pages.ConsumerSubmit.View.instructionStep2',
    description: 'Consumer Submit Page instructionStep2'
  }
}
