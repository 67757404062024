import { BannerTextProps } from './banner-text.props'
import { BannerTextStyles } from './banner-text.styles'
import { withShowIf } from '../../helpers/with-show-if/with-show-if.component'

export const BannerText = withShowIf((props: BannerTextProps) => (
  <div
    className={BannerTextStyles.container(
      props.type,
      props.cssContainerClasses
    )}
    {...props.uat}
  >
    <div className={BannerTextStyles.boldText}>{props.boldText}</div>
    <div className={BannerTextStyles.text}>{props.text}</div>
  </div>
))
