import { combineEpics, Epic } from 'redux-observable'
import { RootState } from './types/root-state/root-state.interface'
import { Action } from './root.actions'
import { consumerEpics } from '../modules/consumer/store/consumer.epics'
import { appEpics } from '../modules/app/store/app.epics'

export const rootEpics: Epic<Action, Action, RootState> = combineEpics(
  ...appEpics,
  ...consumerEpics
)
