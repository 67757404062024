import { PreQualifierLoadingProductState } from './pre-qualifier-loading-product-state.interface'

export const createPreQualifierLoadingProductState =
  (): PreQualifierLoadingProductState => {
    return {
      isLoading: false,
      error: undefined,
      productSku: '',
      productDetails: undefined
    }
  }
