
import React from 'react';
import { HighCostLicenseDetailsStyles } from './high-cost-license-details.styles';
import { useIsMobile } from '../../../ui-library/hooks/use-is-mobile-hook/use-is-mobile-hook';
import { useIntl } from 'react-intl';
import { HighCostLicenseDetailsMessages } from './high-cost-license-details.messages';
import { getHighCostTitleMessage } from './high-cost-license-details.functions';
import { HighCostLicenseDetailsProps } from './high-cost-license-details.props';
import { withShowIf } from '../../../ui-library/helpers/with-show-if/with-show-if.component';
import { uatId } from '../../../uat/uat-id.function';
import { HighCostLicenseDetailsUat } from './high-cost-license-details.uat';

export const HighCostLicenseDetails = withShowIf((props: HighCostLicenseDetailsProps) => {
  const isMobile = useIsMobile();
  const intl = useIntl();

  return (
    <div className={HighCostLicenseDetailsStyles.container(isMobile)}>
      <h6
      className={HighCostLicenseDetailsStyles.provinceLabel}
      {...uatId(HighCostLicenseDetailsUat.highCostDetailsTitleText)}>
        {intl.formatMessage(
          getHighCostTitleMessage(props.highCostLicenseDetails?.province),
        )}
      </h6>
      <span
        className={HighCostLicenseDetailsStyles.expiryLabel}
        {...uatId(
          HighCostLicenseDetailsUat.highCostDetailsLicenseDetailsText
        )}>
        {intl.formatMessage(HighCostLicenseDetailsMessages.licenseNumberAndExpiryDate, {
          licenseNumber: props.highCostLicenseDetails?.licenseNumber,
          licenseExpiryDate: props.highCostLicenseDetails?.licenseExpiryDate.toLocaleDateString(
            intl.locale,
            {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          ),
        })}
      </span>
    </div>
  );
});
