import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ExclamationIcon } from '../../../../../../../../../../../../../../../../../../shared/icons/exclamation/exclamation.icon'
import { NoResultsItemMessages } from './no-results-item.messages'
import { NoResultsItemStyles } from './no-results-item.styles'

export const NoResultsItem = () => {
  return (
    <div className={NoResultsItemStyles.container}>
      <span className={NoResultsItemStyles.icon}>
        <ExclamationIcon />
      </span>
      <span className={NoResultsItemStyles.text}>
        <FormattedMessage {...NoResultsItemMessages.paragraph} />
      </span>
    </div>
  )
}
