export const ConsumerNetIncomeSalarywMessages = {
  header: {
    id: 'Pages.ConsumerNetIncome.Salary.header',
    description: 'This means you earn:'
  },
  labelBiWeekly: {
    id: 'Pages.ConsumerNetIncome.Salary.biweekly',
    description: 'Bi-Weekly'
  },
  labelMonthly: {
    id: 'Pages.ConsumerNetIncome.Salary.monthly',
    description: 'Monthly'
  },
  labelAnnually: {
    id: 'Pages.ConsumerNetIncome.Salary.annually',
    description: 'Annually'
  }
}
