import * as yup from 'yup'
import { FormFieldSchema } from './form-field-schema'
import { StringSchemaOptions } from './types/form-field-schema-options/string-schema-options.interface'
import { FormFieldSchemaType } from './types/form-field-schema-type.enum'

export class PostalCodeFieldSchema extends FormFieldSchema {
  protected postalCodeRegex = new RegExp(
    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
  )
  protected schema = yup.string().matches(this.postalCodeRegex)
  protected type: FormFieldSchemaType = FormFieldSchemaType.STRING
  private minLength: number | undefined
  private maxLength: number | undefined

  public getSchemaOptions(): StringSchemaOptions {
    return {
      name: this.name,
      type: this.type,
      minLength: this.minLength,
      maxLength: this.maxLength,
      required: this.required
    }
  }

  public setMinLength(min: number): this {
    this.minLength = min
    this.schema = this.schema.min(min).trim()
    return this
  }

  public setMaxLength(max: number): this {
    this.maxLength = max
    this.schema = this.schema.max(max).trim()
    return this
  }
}
