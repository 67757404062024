import {
  CreateConsumerProfileWithAssetForm
} from '../../../../consumer-profile/types/create-consumer-profile-with-asset-form.interface'
import { ConsumerProfileState } from '../../../../consumer-profile/types/consumer-profile-state.interface'
import { SettingsState } from '../../../../../../app/modules/settings/types/settings-state.interface'
import { PreQualifierProduct } from '../../../../../../../domain/pre-qualifier-product/pre-qualifier-product.interface'
import { CreateConsumerProfileForm } from '../../../../consumer-profile/types/create-consumer-profile-form.interface'
import {
  PreQualifierAssetType
} from '../../../../../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type.interface'
import { CreateConsumerProfileStandardForm } from './create-consumer-profile-standard-form.interface'

export class CreateConsumerProfileFormFactory {
  public static createWithAssetForm(
    consumerProfileState:ConsumerProfileState,
    phoneNumber:ConsumerProfileState['phoneNumber'],
    locale: SettingsState['locale'],
    productId:PreQualifierProduct['id']
  ): CreateConsumerProfileWithAssetForm{
    return {
      ...this.createStandardForm(consumerProfileState, locale, phoneNumber),
      productId: productId,
    }
  }

  public static createForm(
    consumerProfileState:ConsumerProfileState,
    phoneNumber:ConsumerProfileState['phoneNumber'],
    locale: SettingsState['locale'],
    preQualifierAssetTypeId: PreQualifierAssetType['id']
  ): CreateConsumerProfileForm {
    return {
      ...this.createStandardForm(consumerProfileState, locale, phoneNumber),
      preQualifierAssetTypeId,
      vendorId: consumerProfileState.vendorId,
      referrer: consumerProfileState.referrer,
      campaignId: consumerProfileState.campaignId
    }
  }

  private static createStandardForm(
    consumerProfileState:ConsumerProfileState,
    locale:SettingsState['locale'],
    phoneNumber:ConsumerProfileState['phoneNumber']
  ): CreateConsumerProfileStandardForm{
    return {
      firstName: consumerProfileState.firstName,
      lastName: consumerProfileState.lastName,
      phoneNumber,
      preferredLanguage: locale,
      refId:consumerProfileState.refId,
    }
  }
}