export const HighCostLicenseDetailsMessages = {
  newfoundlandHighCostProduct: {
    id: 'HighCostLicenseDetails.newfoundlandHighCostProduct',
    description: 'Newfoundland High-Cost Product Label',
  },
  defaultHighCostProduct: {
    id: 'highCostLicenseDetails.defaultHighCostProduct',
    description: 'Default High-Cost Product Label',
  },
  licenseNumberAndExpiryDate: {
    id: 'HighCostLicenseDetails.licenseNumberAndExpiryDate',
    description: 'License Number and Expiry Date Label',
  },
};
