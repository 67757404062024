import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { truncateConsumerName } from '../../../../../../../../shared/helpers/truncate/truncate-consumer-name.function'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { ConsumerDobStepStore } from '../../store/consumer-dob-step.store'
import { ConsumerDobStepState } from '../../types/consumer-dob-step-state.interface'
import { ConsumerDobForm } from '../consumer-dob-form/consumer-dob-form.component'
import { ConsumerDobViewMessages } from './consumer-dob-view.messages'
import { ConsumerDobViewProps } from './consumer-dob-view.props'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'

export const ConsumerDobView = (props: ConsumerDobViewProps) => {
  const intl = useIntl();

  const firstName: ConsumerProfileState['firstName'] = useSelector(
    ConsumerProfileStore.getConsumerFirstName
  )

  const consumerDateOfBirth: ConsumerProfileState['dateOfBirth'] = useSelector(
    ConsumerProfileStore.getConsumerDob
  )

  const dateOfBirth: ConsumerDobStepState['dateOfBirth'] = useSelector(
    ConsumerDobStepStore.getDateOfBirth
  )

  const isWorkflowStepComplete: ConsumerDobStepState['isWorkflowStepComplete'] =
    useSelector(ConsumerDobStepStore.getIsWorkflowStepComplete)

  useEffect(() => {
    if (isWorkflowStepComplete) {
      ConsumerProfileStore.setConsumerDob(dateOfBirth)
      props.onComplete()
    }
  }, [dateOfBirth, isWorkflowStepComplete, props])

  useEffect(() => {
    return () => {
      ConsumerDobStepStore.reset()
    }
  }, []);

  return (
    <>
      <PrimaryHeader
        showBackIcon={props.step.rules.showBackButton}
        goBack={props.goBack}
      />

      <ConsumerProfileAssetImageContainer
        titleMessage={`${intl.formatMessage(ConsumerDobViewMessages.name, {name: truncateConsumerName(firstName)})} ${intl.formatMessage(ConsumerDobViewMessages.minimumAge)}`}
        subTitleMessage={intl.formatMessage(ConsumerDobViewMessages.born)}
        noHorizontalPadding
        showFooter
      >
        <ConsumerDobForm
          onComplete={props.onComplete}
          isWorkflowStepComplete={isWorkflowStepComplete}
          initialFormValue={consumerDateOfBirth}
          error={useSelector(ConsumerDobStepStore.getError)}
          loading={useSelector(ConsumerDobStepStore.isLoading)}
        />
      </ConsumerProfileAssetImageContainer>
    </>
  )
}
