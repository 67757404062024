import { defineMessages } from "react-intl";

export const PostSubmissionLoadingViewMessages = [
  defineMessages({
    message: {
      id: 'Pages.PostSubmission.Loading.View.initialLabel',
    description: 'Initial submission text'
    },
  }),
  defineMessages({
    message: {
      id: 'Pages.PostSubmission.Loading.View.label',
      description: 'Processing application'
    },
  }),
  defineMessages({
    message: {
      id: 'Pages.PostSubmission.Loading.View.finalLabel',
      description: 'Final loading label'
    },
  }),
]
