export const ConsumerNameViewMessages = {
  header: {
    id: 'Pages.ConsumerName.View.header',
    description: 'Consumer Name Page Header'
  },
  paragraph: {
    id: 'Pages.ConsumerName.View.paragraph',
    description: 'Consumer Name Page paragraph'
  },
  formTitle: {
    id: 'Pages.ConsumerName.Form.title',
    description: 'Title of the form'
  },
}
