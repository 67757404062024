import { Reducer } from 'redux'
import { SettingsAction, SettingsActionType } from './settings.actions'
import { createSettingsState } from '../types/settings-state.functions'
import { SettingsState } from '../types/settings-state.interface'

export const settingsReducer: Reducer<SettingsState, SettingsActionType> = (
  state: SettingsState = createSettingsState(),
  action: SettingsActionType
): SettingsState => {
  switch (action.type) {
    case SettingsAction.SetLocaleSetting:
      return {
        ...state,
        locale: action.payload
      }
    default:
      return state
  }
}
