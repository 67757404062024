import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../../../uat/uat-id.function'
import { AddressLink } from '../../../../../../../../../../ui-library/components/link/address-link/address-link.component'
import { Paragraph } from '../../../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { PrimaryButton } from '../../../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { SecondaryButton } from '../../../../../../../../../../ui-library/components/secondary-button/secondary-button.component'
import { withShowIf } from '../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { MerchantSelectionConfirmDialogViewMessages } from './merchant-selection-confirm-dialog-view.messages'
import { MerchantSelectionConfirmDialogViewProps } from './merchant-selection-confirm-dialog-view.props'
import { MerchantSelectionConfirmDialogViewStyles } from './merchant-selection-confirm-dialog-view.styles'
import { MerchantSelectionConfirmDialogViewUat } from './merchant-selection-confirm-dialog-view.uat'

export const MerchantSelectionConfirmDialogView = withShowIf(
  (props: MerchantSelectionConfirmDialogViewProps) => {
    const onClose = useCallback(() => props.onClose(false), [props.onClose])

    return (
      <>
        <Paragraph
          className={MerchantSelectionConfirmDialogViewStyles.title}
          uat={uatId(MerchantSelectionConfirmDialogViewUat.title)}
        >
          <FormattedMessage
            {...MerchantSelectionConfirmDialogViewMessages.title}
            values={{ merchantName: props.merchant?.name }}
          />
        </Paragraph>
        <AddressLink
          uat={uatId(MerchantSelectionConfirmDialogViewUat.addressLink)}
          address={props.merchant?.address}
          text={props.merchant?.address}
          className={MerchantSelectionConfirmDialogViewStyles.address}
        />
        <div
          className={MerchantSelectionConfirmDialogViewStyles.buttonsContainer}
        >
          <SecondaryButton
            fullWidth
            cssContainerClasses={
              MerchantSelectionConfirmDialogViewStyles.closeButton
            }
            onClick={onClose}
            uat={uatId(MerchantSelectionConfirmDialogViewUat.cancelButton)}
          >
            <FormattedMessage
              {...MerchantSelectionConfirmDialogViewMessages.cancelButtonLabel}
            />
          </SecondaryButton>

          <PrimaryButton
            fullWidth
            loading={props.loading}
            onClick={props.onConfirm}
            uat={uatId(MerchantSelectionConfirmDialogViewUat.confirmButton)}
          >
            <FormattedMessage
              {...MerchantSelectionConfirmDialogViewMessages.selectButtonLabel}
            />
          </PrimaryButton>
        </div>
      </>
    )
  }
)
