import { PaymentFrequency } from './payment-frequency'
import { Offer } from './offer.interface'

export class LoanDetailsDto {
  frequency!: PaymentFrequency
  rate!: number
  term!: number

  public static create(offer: Offer): LoanDetailsDto {
    return new LoanDetailsDto()
      .setRate(offer.rate)
      .setTerm(offer.term)
      .setFrequency(offer.frequency)
  }

  private setRate(rate: number): this {
    this.rate = rate
    return this
  }

  private setTerm(term: number): this {
    this.term = term
    return this
  }

  private setFrequency(frequency: PaymentFrequency): this {
    this.frequency = frequency
    return this
  }
}
