import { ConsumerRouteLocation } from '../../../domain/consumer/consumer-routes/consumer-route-location.interface'
import { ConsumerRouteSearchParam } from '../../../domain/consumer/consumer-routes/consumer-route-search-param.enum'

export function createRouteDueToResetRequest (location:ConsumerRouteLocation):string{
  if(!location.search || location.search.trim().length === 0){
    return location.pathname;
  }

  return `${location.pathname}?${createQueryStringWithoutReset(location.search)}`
}

export function createQueryString(queryString:string, toExcludeList:ConsumerRouteSearchParam[]=[]):string{
  const params = new URLSearchParams(queryString);
  const toExcludeStrings = toExcludeList.map(toExclude => toExclude.toLowerCase());
  let result= '';

  for(const [key,value] of Array.from(params.entries())){
    if(toExcludeStrings.some(toExclude => toExclude === key.toLowerCase())){
      continue
    }
    result +=`${key}=${value}&`
  }

  if(result.length>0){
    result = result.slice(0, -1);
  }

  return result

}

function createQueryStringWithoutReset(queryString:string):string{
  return createQueryString(queryString, [ConsumerRouteSearchParam.Reset]);
}

