import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { Action } from '../../../../../../../store/root.actions'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { consumerEditPhoneNumberEpics } from '../modules/consumer-edit-phone-number/store/consumer-edit-phone-number.epics'
import { ConsumerPhoneService } from '../../../../../../../api/bff/services/consumer-phone.service'
import { ConsumerPhoneValidationStepActions } from './consumer-phone-validation-step.actions'

const validatePhoneNumberEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerPhoneValidationStepActions.validatePhoneNumber)),
    switchMap((action) =>
      new ConsumerPhoneService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .verifyPhoneNumber(action.payload)
        .pipe(
          map(() =>
            ConsumerPhoneValidationStepActions.validatePhoneNumberSuccess()
          ),
          catchError((err: HttpError) =>
            of(
              ConsumerPhoneValidationStepActions.validatePhoneNumberFailure(err)
            )
          )
        )
    )
  )

const resendVerificationCodeEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf(ConsumerPhoneValidationStepActions.resendVerificationCode)
    ),
    switchMap(() =>
      new ConsumerPhoneService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .sendNewVerificationCode()
        .pipe(
          map(() =>
            ConsumerPhoneValidationStepActions.resendVerificationCodeSuccess()
          ),
          catchError((err: HttpError) =>
            of(
              ConsumerPhoneValidationStepActions.resendVerificationCodeFailure(
                err
              )
            )
          )
        )
    )
  )

export const consumerPhoneValidationStepEpics = [
  resendVerificationCodeEpic,
  validatePhoneNumberEpic,
  ...consumerEditPhoneNumberEpics
]
