export const ConsumerPostSubmissionApprovedReferenceCodeStyles = {
  wrapper: 'flex justify-center w-full bg-white150',
  container:
    'max-w-6xl gap-x-3 w-full flex flex-col items-center justify-center py-4 sm:px-0 sm:flex-row px-8 sm:px-16',
  sectionReferenceCode: 'flex flex-col w-full sm:w-1/4',
  sectionDescription: 'flex flex-col w-full mt-2 sm:mt-0',
  meaning: 'self-center text-13px text-blue300',
  referenceCodeLabel:
    'text-black self-center text-center font-black text-14px sm:text-16px',
  referenceCode:
    'text-blue300 font-bold my-2 text-24px text-center sm:my-4',
  description: 'text-16px font-bold mb-4 mt-1',
  note: 'text-gray text-13px',
  details: 'text-gray text-13px mb-3',
}
