import { ConsumerPostSubmissionOffersViewProps } from '../consumer-post-submission-offers-view/consumer-post-submission-offers-view.props'
import { OfferListStyles } from './offer-list.styles'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { FormattedMessage } from 'react-intl'
import { ConsumerPostSubmissionOffersViewMessages } from '../consumer-post-submission-offers-view/consumer-post-submission-offers-view.messages'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { SecondaryButton } from '../../../../../../../../ui-library/components/secondary-button/secondary-button.component'
import { OfferListMessages } from './offer-list.messages'
import { Offers } from '../offers/offers.component'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { OfferListUat } from './offer-list.uat'

export const OfferList = (props: ConsumerPostSubmissionOffersViewProps) => {
  return (
    <div className={OfferListStyles.viewContainer}>
      <div className={OfferListStyles.backgroundContainer}>
        <Paragraph
          className={OfferListStyles.title}
          uat={uatId(OfferListUat.selectOffer)}
        >
          <FormattedMessage {...OfferListMessages.title} />
        </Paragraph>
        <div className={OfferListStyles.offersContainer}>
          <Offers
            onSelectedOffer={props.onSelectedOffer}
            offers={props.offers}
          />
        </div>
      </div>
      <div className={OfferListStyles.buttonWrapper}>
        <PrimaryButton
          onClick={props.onBtnSelectOffer}
          cssContainerClasses={OfferListStyles.buttonContainer}
          uat={uatId(OfferListUat.nextButton)}
        >
          <FormattedMessage
            {...ConsumerPostSubmissionOffersViewMessages.btnSelectedOffer}
          />
        </PrimaryButton>
        <SecondaryButton
          onClick={props.onBtnCompleteLater}
          cssContainerClasses={'self-center'}
          uat={uatId(OfferListUat.completeLaterButton)}
        >
          <FormattedMessage
            {...ConsumerPostSubmissionOffersViewMessages.btnCompleteLater}
          />
        </SecondaryButton>
      </div>
    </div>
  )
}
