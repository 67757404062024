export const ConsumerNetIncomeViewMessages = {
  title: {
    id: 'Pages.ConsumerNetIncome.View.title',
    description: 'Consumer Net Income Page Title'
  },
  header: {
    id: 'Pages.ConsumerNetIncome.View.header',
    description: 'Consumer Net Income Page Header'
  },
  moreInfoDescription: {
    id: 'Pages.ConsumerNetIncome.View.MoreInfo.description',
  },
  subTitle: {
    id: 'Pages.ConsumerNetIncome.Form.label',
    description: 'Your Net Monthly Income'
  },
  moreInfoModal: {
    id: 'Pages.ConsumerNetIncome.Form.label',
    description: 'Your Net Monthly Income'
  },
}
