import { MainLayout } from '../../../../../shared/components/main-layout/main-layout.component'
import { PostSubmissionLoadingViewStyles } from '../modules/consumer-post-submission-loading/components/consumer-post-submission-loading-view/consumer-post-submission-loading-view.styles'
import { uatId } from '../../../../../uat/uat-id.function'
import { ConsumerPostSubmissionLoadingUat } from '../modules/consumer-post-submission-loading/components/consumer-post-submission-loading-view/consumer-post-submission-loading-view.uat'
import { SpinnerIconSize } from '../../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { PostSubmissionLoadingViewMessages } from '../modules/consumer-post-submission-loading/components/consumer-post-submission-loading-view/consumer-post-submission-loading-view.messages'
import { LoaderWithMessages } from '../../../../../shared/components/loader-with-messages/loader-with-messages.component'

export const ConsumerPostSubmissionSpinner = () => {
  return (
    <MainLayout centerAlignDisclaimer>
      <div
        className={PostSubmissionLoadingViewStyles.container}
        {...uatId(ConsumerPostSubmissionLoadingUat.spinner)}
      >
        <LoaderWithMessages
          timeoutPeriodInMillis={15000}
          spinnerSize={SpinnerIconSize.LARGE}
          messages={PostSubmissionLoadingViewMessages}
        />
      </div>
    </MainLayout>
  )
}
