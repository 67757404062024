import { ConsumerSession } from '../../../../domain/consumer/consumer-session/consumer-session.interface'
import { ConsumerProfileDtoMapper } from '../consumer-profile/consumer-profile-dto-mapper'
import { ConsumerWorkflowDtoMapper } from '../consumer-workflow/consumer-workflow-dto-mapper'
import { ConsumerSessionDto } from './consumer-session.dto'

export class ConsumerSessionDtoMapper {
  constructor(private data: ConsumerSessionDto) {}

  public toDomainObject(): ConsumerSession {
    return {
      consumerProfile: new ConsumerProfileDtoMapper(
        this.data.consumerProfile
      ).toDomainObject(),
      workflow: new ConsumerWorkflowDtoMapper(
        this.data.workflow
      ).toDomainObject()
    }
  }
}
