import { Reducer } from 'redux'
import { createConsumerWorkflowStepsState } from '../types/consumer-workflow-steps-state.functions'
import { ConsumerWorkflowStepsState } from '../types/consumer-workflow-steps-state.interface'
import {
  ConsumerWorkflowStepsAction,
  ConsumerWorkflowStepsActionType
} from './consumer-workflow-steps.actions'

export const consumerWorkflowStepsReducer: Reducer<
  ConsumerWorkflowStepsState,
  ConsumerWorkflowStepsActionType
> = (
  state: ConsumerWorkflowStepsState = createConsumerWorkflowStepsState(),
  action: ConsumerWorkflowStepsActionType
): ConsumerWorkflowStepsState => {
  switch (action.type) {
    case ConsumerWorkflowStepsAction.SetActiveWorkflowStep:
      return {
        ...state,
        activeWorkflowStep: action.payload
      }
    case ConsumerWorkflowStepsAction.SetWorkflow:
      return {
        ...state,
        workflowSteps: action.payload
      }
    default:
      return state
  }
}
