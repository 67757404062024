import { ActionType, createAction } from 'typesafe-actions'
import { Language } from '../../../../../shared/types/language.enum'

export enum SettingsAction {
  SetLocaleSetting = 'Settings/SetLocaleSetting'
}

const setLocaleSetting = createAction(
  SettingsAction.SetLocaleSetting,
  (action) => (locale: Language) => action(locale)
)

export const SettingsActions = {
  setLocaleSetting
}

export type SettingsActionType = ActionType<typeof SettingsActions>
