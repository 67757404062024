import { SettingsStore } from '../../../../../../../app/modules/settings/store/settings.store'
import { Offer } from '../../types/offer.interface'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ConsumerPostSubmissionOffersStore } from '../../store/consumer-post-submission-offers.store'
import {
  getSelectedOffer,
  setOfferAsSelected
} from '../../types/consumer-post-submission-offers-view.functions'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { useHistory } from 'react-router'
import { ConsumerRoute } from '../../../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { ConsumerPostSubmissionSpinner } from '../../../../components/consumer-post-submission-spinner'
import { OffersView } from '../offers-view/offers-view.component'
import { addVendorIdToPath } from '../../../../../../../../shared/helpers/path-helper'
import { PreApprovalStatus } from '../../../../../../../../domain/consumer/consumer-profile/types/pre-approval-status.enum'

export const ConsumerPostSubmissionOffersView = () => {
  const history = useHistory()
  const locale = useSelector(SettingsStore.getLocaleSetting)
  const [offers, setOffers] = useState<Offer[]>([])
  const isLoading = useSelector(ConsumerPostSubmissionOffersStore.getLoading)
  const offersTemp = useSelector(ConsumerPostSubmissionOffersStore.getOffers)
  const fetchOffersError = useSelector(
    ConsumerPostSubmissionOffersStore.getFetchOffersError
  )
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)
  const vendorImage = useSelector(ConsumerProfileStore.getVendor)?.defaultProductImageUrl;

  const firstName: ConsumerProfileState['firstName'] =
    useSelector(ConsumerProfileStore.getConsumerFirstName) || ''

  const consumerProfileAsset = useSelector(
    ConsumerProfileStore.getConsumerProfileAsset
  )

  useEffect(() => {
    if (fetchOffersError) {
      ConsumerProfileStore.setPreApprovalStatus(PreApprovalStatus.Declined);
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitError))
    }
  }, [fetchOffersError])

  useEffect(() => {
    setOffers(offersTemp || [])
  }, [offersTemp])

  useEffect(() => {
    ConsumerPostSubmissionOffersStore.fetchOffers(locale)
  }, [locale])

  const onSelectedOffer = (offer: Offer) => {
    setOffers(setOfferAsSelected(offer, offers))
  }

  const onBtnSelectedOffer = () => {
    const selectedOffer = getSelectedOffer(offers)
    if (selectedOffer) {
      ConsumerPostSubmissionOffersStore.updateLoanDetails(selectedOffer)
    }
    ConsumerProfileStore.setLoanOfferPageViewed()
    history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitApproved))
  }

  const onBtnCompleteLater = () => {
    ConsumerProfileStore.setLoanOfferPageViewed()
    history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitApproved))
  }

  if (isLoading) {
    return <ConsumerPostSubmissionSpinner />
  }

  return (
    <OffersView
      firstName={firstName}
      locale={locale}
      offers={offers}
      onSelectedOffer={onSelectedOffer}
      onBtnSelectedOffer={onBtnSelectedOffer}
      onBtnCompleteLater={onBtnCompleteLater}
      consumerProfileAsset={consumerProfileAsset}
      vendorImage={vendorImage}
    />
  )
}
