import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import ReactDOM from 'react-dom'
import './style/index.scss'
import { App } from './modules/app/app.component'
import { initReactGA } from './modules/app/app.functions'

const rootEl = document.getElementById('root')
initReactGA()

ReactDOM.render(<App />, rootEl)
