import { DropdownLibrary } from '../../../libraries/dropdown-library/dropdown-library.component'
import { DropdownOption } from '../../../libraries/dropdown-library/types/dropdown-option.interface'
import { generateOptions, getSelectedOption } from './select-field.functions'
import { SelectFieldProps } from './select-field.props'
import { selectFieldStyles } from './select-field.styles'
import { SelectFieldOption } from './types/select-field-option.interface'

export function SelectField<T extends SelectFieldOption>(
  props: SelectFieldProps<T>
) {
  const getOptions = () =>
    generateOptions(props.optionValueKey, props.optionLabelKey, props.options)

  const getValue = () => getSelectedOption(getOptions(), props.value)

  const onChange = (selectedOption: DropdownOption) =>
    props.onChange(selectedOption.value.toString())

  return (
    <>
      <label htmlFor={props.name} className={selectFieldStyles.label}>{props.label}</label>
      <DropdownLibrary
        placeholder={props.placeholder}
        name={props.name}
        value={getValue()}
        options={getOptions()}
        onChange={onChange}
        searchable={props.searchable}
        controlUat={props.uat}
        menuListUat={props.optionListUat}
        menuListItemUat={props.optionListItemUat}
        singleValueUat={props.selectedOptionUat}
        className={props.className}
      />
    </>
  )
}
