import { ActionType, createAction } from 'typesafe-actions'
import { AssetSubtype } from '../../../../../domain/asset-subtype/asset-subtype.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { Language } from '../../../../../shared/types/language.enum'
import { AssetSubtypeState } from '../types/asset-subtype-selection-state.interface'

export enum AssetSubtypeAction {
  FetchAssetSubtypes = 'AssetSubtype/FetchAssetSubtypes',
  FetchAssetSubtypesFailure = 'AssetSubtype/FetchAssetSubtypesFailure',
  FetchAssetSubtypesSuccess = 'AssetSubtype/FetchAssetSubtypesSuccess',
  SetSelectedAssetSubtypeId = 'AssetSubtype/SetSelectedAssetSubtypeId',
  ResetAssetSubtypeState = 'AssetSubtype/ResetAssetSubtypeState'
}

const fetchAssetSubtypes = createAction(
  AssetSubtypeAction.FetchAssetSubtypes,
  (action) => (locale: Language) =>
    action({ locale })
)

const fetchAssetSubtypesFailure = createAction(
  AssetSubtypeAction.FetchAssetSubtypesFailure,
  (action) => (error: HttpError) => action(error)
)
const fetchAssetSubtypesSuccess = createAction(
  AssetSubtypeAction.FetchAssetSubtypesSuccess,
  (action) => (assetSubtypes: AssetSubtypeState['assetSubtypes']) =>
    action(assetSubtypes)
)

const setSelectedAssetSubtypeId = createAction(
  AssetSubtypeAction.SetSelectedAssetSubtypeId,
  (action) => (assetSubtypeId: AssetSubtype['id'] | null) => action(assetSubtypeId)
)

const resetAssetSubtypeState = createAction(
  AssetSubtypeAction.ResetAssetSubtypeState
)

export const AssetSubtypeActions = {
  fetchAssetSubtypes,
  fetchAssetSubtypesFailure,
  fetchAssetSubtypesSuccess,
  setSelectedAssetSubtypeId,
  resetAssetSubtypeState
}

export type AssetSubtypeActionType = ActionType<
  typeof AssetSubtypeActions
>
