import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ConsumerRoute } from '../../../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { addVendorIdToPath } from '../../../../../../../../shared/helpers/path-helper'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerSubmitStepStore } from '../../store/consumer-submit-step.store'
import { ConsumerSubmitStepState } from '../../types/consumer-submit-step-state.interface'
import { ConsumerSubmitForm } from '../consumer-submit-form/consumer-submit-form.component'
import { ConsumerSubmitViewMessages } from './consumer-submit-view.messages'
import { ConsumerSubmitViewProps } from './consumer-submit-view.props'
import { ConsumerSubmitViewUat } from './consumer-submit-view.uat'
import { SubmitConsumerProfileFormFactory } from '../../../../types/submit-consumer-profile-form.factory'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'

export const ConsumerSubmitView = (props: ConsumerSubmitViewProps) => {
  const intl = useIntl()
  const history = useHistory()
  const refId = useSelector(ConsumerProfileStore.getRefId);
  const referrer = useSelector(ConsumerProfileStore.getReferrer);
  const eventId = useSelector(ConsumerProfileStore.getEventId);
  const campaignId = useSelector(ConsumerProfileStore.getCampaignId);

  const consumerAuthorizedElectronicCommunication = useSelector(
    ConsumerProfileStore.getElectronicCommunicationConsent
  )
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)

  const authorizedElectronicCommunication = useSelector(
    ConsumerSubmitStepStore.getElectronicCommunicationConsent
  )

  const error: ConsumerSubmitStepState['error'] = useSelector(
    ConsumerSubmitStepStore.getError
  )
  const loading = useSelector(ConsumerSubmitStepStore.getLoading)
  const isWorkflowStepComplete = useSelector(
    ConsumerSubmitStepStore.getIsWorkflowStepComplete
  )

  useEffect(() => {
    if (isWorkflowStepComplete) {
      ConsumerProfileStore.setAuthorizedElectronicCommunication(
        authorizedElectronicCommunication
      )
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SubmitLoading))
    }
  }, [isWorkflowStepComplete, authorizedElectronicCommunication, history])

  const onSubmit = (
    authorizedElectronicCommunication: ConsumerSubmitStepState['authorizedElectronicCommunication'],
    creditConsent: ConsumerSubmitStepState['creditConsent']
  ): void => {
    ConsumerSubmitStepStore.submitConsumerProfileForPreApproval(
      SubmitConsumerProfileFormFactory.create(
        authorizedElectronicCommunication,
        creditConsent,
        refId,
        referrer,
        eventId,
        campaignId
      )
    )
  }

  return (
    <>
      <PrimaryHeader
        showBackIcon={props.step.rules.showBackButton}
        goBack={props.goBack}
      />

      <ConsumerProfileAssetImageContainer
        titleMessage={`${intl.formatMessage(ConsumerSubmitViewMessages.title)} ${intl.formatMessage(ConsumerSubmitViewMessages.instructionStep1)}`}
        subTitleMessage={intl.formatMessage(ConsumerSubmitViewMessages.instructionStep2)}
        subTitleUat={uatId(ConsumerSubmitViewUat.instructions)}
        noHorizontalPadding
      >
        <ConsumerSubmitForm
          initialFormValue={consumerAuthorizedElectronicCommunication}
          error={error}
          loading={loading}
          onSubmit={onSubmit}
        />
      </ConsumerProfileAssetImageContainer>
    </>
  )
}
