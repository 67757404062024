export class EventBusConfig {
  public getEventBusKey(): string {
    return this.get('REACT_APP_PUSHER_KEY')
  }

  public getCluster(): string {
    return this.get('REACT_APP_PUSHER_CLUSTER')
  }

  public getAuthEndpoint(): string {
    return this.get('REACT_APP_PUSHER_AUTH_ENDPOINT')
  }

  public getEventBusChannelPrefix(): string {
    return this.get('REACT_APP_EVENT_BUS_CHANNEL_PREFIX')
  }

  public getEnv(): string {
    return this.get('NODE_ENV')
  }

  private get(key: string): string {
    return process.env[key] ?? ''
  }
}
