import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../../../store/root.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerPhoneValidationStepState } from '../types/consumer-phone-validation-step-state.interface'
import { ConsumerPhoneValidationStepActions } from './consumer-phone-validation-step.actions'

const consumerPhoneValidationState = (
  rootState: RootState
): ConsumerPhoneValidationStepState =>
  rootState.consumerState.consumerWorkflowState.consumerPhoneValidationStepState

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerPhoneValidationStepState['isWorkflowStepComplete']
> = createSelector(
  consumerPhoneValidationState,
  (state: ConsumerPhoneValidationStepState) => state.isWorkflowStepComplete
)

const getIsResendVerificationCodeSuccessful: Selector<
  RootState,
  ConsumerPhoneValidationStepState['isResendVerificationCodeSuccessful']
> = createSelector(
  consumerPhoneValidationState,
  (state: ConsumerPhoneValidationStepState) =>
    state.isResendVerificationCodeSuccessful
)

const getPhoneValidationError: Selector<
  RootState,
  ConsumerPhoneValidationStepState['phoneValidationError']
> = createSelector(
  consumerPhoneValidationState,
  (state: ConsumerPhoneValidationStepState) => state.phoneValidationError
)

const getPhoneValidationStateLoading: Selector<
  RootState,
  ConsumerPhoneValidationStepState['phoneValidationLoading']
> = createSelector(
  consumerPhoneValidationState,
  (state: ConsumerPhoneValidationStepState) => state.phoneValidationLoading
)

const getResendVerificationCodeError: Selector<
  RootState,
  ConsumerPhoneValidationStepState['resendVerificationCodeError']
> = createSelector(
  consumerPhoneValidationState,
  (state: ConsumerPhoneValidationStepState) => state.resendVerificationCodeError
)

const resendVerificationCode = () =>
  RootStore.dispatch(
    ConsumerPhoneValidationStepActions.resendVerificationCode()
  )

const resetPhoneValidationState = () =>
  RootStore.dispatch(
    ConsumerPhoneValidationStepActions.resetPhoneValidationState()
  )

const resetResendVerificationCodeState = () =>
  RootStore.dispatch(
    ConsumerPhoneValidationStepActions.resetResendVerificationCodeState()
  )
const validatePhoneNumber = (code: string) =>
  RootStore.dispatch(
    ConsumerPhoneValidationStepActions.validatePhoneNumber(code)
  )

export const ConsumerPhoneValidationStepStore = {
  getIsWorkflowStepComplete,
  getIsResendVerificationCodeSuccessful,
  getPhoneValidationError,
  getPhoneValidationStateLoading,
  getResendVerificationCodeError,
  resendVerificationCode,
  resetPhoneValidationState,
  resetResendVerificationCodeState,
  validatePhoneNumber
}
