import React from 'react'
import { OfficeIcon } from '../../../../../../../shared/icons/office/office.icon'
import { MerchantLogoProps } from './merchant-logo.props'
import { MerchantLogoStyles } from './merchant-logo.styles'

export const MerchantLogo = (props: MerchantLogoProps) => {
  return props.logoUrl ? (
    <img className={MerchantLogoStyles.logo} src={props.logoUrl} />
  ) : (
    <OfficeIcon className={MerchantLogoStyles.logo} />
  )
}
