import { ConsumerEditPhoneNumberState } from './consumer-edit-phone-number-state.interface'

export function createConsumerEditPhoneNumberState(): ConsumerEditPhoneNumberState {
  return {
    error: null,
    isEditSuccessful: false,
    loading: false,
    phoneNumber: null
  }
}
