import { ChangeEvent, useState } from 'react'
import { isPositiveInteger } from '../../../../helpers/is-integer/is-integer.function'
import { InputField } from '../../input-field.component'
import { DobInputProps } from './dob-input.props'
import { DobInputStyles } from './dob-input.styles'
import {
  dateOfBirthInputMask,
  removeDobInputMaskFormat,
  stopCaretJumpingToEndOfLine
} from './types/dob-input.functions'

export const DobInput = (props: DobInputProps) => {
  const [dob, setDob] = useState<string>(props.dateOfBirth)

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = event.target.value
    const dobValue: string = removeDobInputMaskFormat(inputValue)

    if (!isPositiveInteger(dobValue)) {
      return
    }

    const isUserInsertingNumberToField = inputValue.length > dob.length

    if (isUserInsertingNumberToField) {
      const { inputMask, moveCaretForwardBy } = dateOfBirthInputMask(dobValue)
      stopCaretJumpingToEndOfLine(moveCaretForwardBy, event)

      setDob(inputMask)
      props.onChange(inputMask)
    } else {
      setDob(inputValue)
      props.onChange(inputValue)
    }
  }

  return (
    <InputField label={props.label} name={props.name}>
      <input
        {...props.uat}
        autoFocus={props.autoFocus}
        className={DobInputStyles.field}
        placeholder={props.placeholder}
        name={props.name}
        onChange={onChange}
        value={dob}
        type="tel"
      />
    </InputField>
  )
}
