import { Epic } from 'redux-observable'
import { Action } from '../../../../../../../store/root.actions'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { catchError, filter, mergeMap, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { ConsumerPostSubmissionOffersActions } from './consumer-post-submission-offers.actions'
import { OffersService } from '../../../../../../../api/bff/services/offers.service'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { of } from 'rxjs'
import { map } from 'rxjs/operators'
import { Offer } from '../types/offer.interface'

const fetchOffers: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(ConsumerPostSubmissionOffersActions.fetchOffers)),
    switchMap((action) =>
      new OffersService(ConsumerSessionStore.getSessionToken(state$.value))
        .fetchOffers(action.payload.locale)
        .pipe(
          mergeMap((offers: Offer[]) =>
            of(ConsumerPostSubmissionOffersActions.fetchOffersSuccess(offers))
          ),
          catchError((error: HttpError) =>
            of(ConsumerPostSubmissionOffersActions.fetchOffersFailure(error))
          )
        )
    )
  )

const saveLoanOfferPageViewed: Epic<Action, Action, RootState> = (action$) =>
  action$.pipe(
    filter(
      isActionOf(ConsumerPostSubmissionOffersActions.saveLoanOfferPageViewed)
    ),
    map(() =>
      ConsumerPostSubmissionOffersActions.saveLoanOfferPageViewedSuccess()
    )
  )

const updateLoanDetails: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(ConsumerPostSubmissionOffersActions.updateLoanDetails)),
    switchMap((action) =>
      new OffersService(ConsumerSessionStore.getSessionToken(state$.value))
        .updateLoanDetails(action.payload)
        .pipe(
          map(() =>
            ConsumerPostSubmissionOffersActions.updateLoanDetailsSuccess()
          ),
          catchError((error: HttpError) =>
            of(
              ConsumerPostSubmissionOffersActions.updateLoanDetailsFailure(
                error
              )
            )
          )
        )
    )
  )

export const ConsumerPostSubmissionOffersEpics = [
  fetchOffers,
  saveLoanOfferPageViewed,
  updateLoanDetails
]
