import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerProfileDto } from '../../../../../api/bff/dto/consumer-profile/consumer-profile.dto'
import { ConsumerProfile } from '../../../../../domain/consumer/consumer-profile/consumer-profile.interface'
import { Coordinates } from '../../../../../domain/coordinate/coordinate.interface'
import { Merchant } from '../../../../../domain/merchant/merchant.interface'
import { Merchants } from '../../../../../domain/merchant/merchants.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { MerchantsResult } from '../types/merchants-result.interface'

export enum MerchantSelectionAction {
  FetchMerchants = 'MerchantSelection/FetchMerchants',
  FetchMerchantsFailure = 'MerchantSelection/FetchMerchantsFailure',
  FetchMerchantsSuccess = 'MerchantSelection/FetchMerchantsSuccess',
  ReassignConsumerProfileApplicationMerchant = 'MerchantSelection/ReassignConsumerProfileApplicationMerchant',
  ReassignConsumerProfileApplicationMerchantFailure = 'MerchantSelection/ReassignConsumerProfileApplicationMerchantFailure',
  ReassignConsumerProfileApplicationMerchantSuccess = 'MerchantSelection/ReassignConsumerProfileApplicationMerchantSuccess',
  CloneConsumerProfileApplicationMerchant = 'MerchantSelection/CloneConsumerProfileApplicationMerchant',
  CloneConsumerProfileApplicationMerchantFailure = 'MerchantSelection/CloneConsumerProfileApplicationMerchantFailure',
  CloneConsumerProfileApplicationMerchantSuccess = 'MerchantSelection/CloneConsumerProfileApplicationMerchantSuccess',
  FetchConsumerProfileAddressCoordinates = 'MerchantSelection/FetchConsumerProfileAddressCoordinates',
  FetchConsumerProfileAddressCoordinatesFailure = 'MerchantSelection/FetchConsumerProfileAddressCoordinatesFailure',
  FetchConsumerProfileAddressCoordinatesSuccess = 'MerchantSelection/FetchConsumerProfileAddressCoordinatesSuccess',
  ResetMerchants = 'MerchantSelection/ResetMerchants',
  ResetMerchantState = 'MerchantSelection/ResetMerchantState',
  ResetSubmitError = 'MerchantSelection/ResetSubmitError',
  FetchConsumerProfileSelectedMerchants = 'MerchantSelection/FetchConsumerProfileSelectedMerchants',
  FetchConsumerProfileSelectedMerchantsFailure = 'MerchantSelection/FetchConsumerProfileSelectedMerchantsFailure',
  FetchConsumerProfileSelectedMerchantsSuccess = 'MerchantSelection/FetchConsumerProfileSelectedMerchantsSuccess',
  ResetMerchantSelectionForm = 'MerchantSelection/ResetMerchantSelectionForm'
}

const fetchMerchants = createAction(
  MerchantSelectionAction.FetchMerchants,
  (action) => (page: number, assetSubtypeId: string | null, merchantName?: string | null) => action({page, assetSubtypeId, merchantName})
)

const fetchMerchantsFailure = createAction(
  MerchantSelectionAction.FetchMerchantsFailure,
  (action) => (error: HttpError) => action(error)
)
const fetchMerchantsSuccess = createAction(
  MerchantSelectionAction.FetchMerchantsSuccess,
  (action) => (merchants: MerchantsResult) => action(merchants)
)

const fetchConsumerProfileAddressCoordinates = createAction(
  MerchantSelectionAction.FetchConsumerProfileAddressCoordinates,
  (action) => () => action()
)

const fetchConsumerProfileAddressCoordinatesFailure = createAction(
  MerchantSelectionAction.FetchConsumerProfileAddressCoordinatesFailure,
  (action) => (error: HttpError) => action(error)
)
const fetchConsumerProfileAddressCoordinatesSuccess = createAction(
  MerchantSelectionAction.FetchConsumerProfileAddressCoordinatesSuccess,
  (action) => (coordinates: Coordinates) => action(coordinates)
)

const reassignConsumerProfileApplicationMerchant = createAction(
  MerchantSelectionAction.ReassignConsumerProfileApplicationMerchant,
  (action) => (merchant: Merchant, assetSubtypeId: string | null) => action({merchant, assetSubtypeId})
)

const reassignConsumerProfileApplicationMerchantFailure = createAction(
  MerchantSelectionAction.ReassignConsumerProfileApplicationMerchantFailure,
  (action) => (error: HttpError) => action(error)
)
const reassignConsumerProfileApplicationMerchantSuccess = createAction(
  MerchantSelectionAction.ReassignConsumerProfileApplicationMerchantSuccess,
  (action) => (consumerProfileDto: ConsumerProfile) =>
    action(consumerProfileDto)
)
const cloneConsumerProfileApplicationMerchant = createAction(
  MerchantSelectionAction.CloneConsumerProfileApplicationMerchant,
  (action) => (merchant: Merchant, assetSubtypeId: string | null) => action({merchant, assetSubtypeId})
)

const cloneConsumerProfileApplicationMerchantFailure = createAction(
  MerchantSelectionAction.CloneConsumerProfileApplicationMerchantFailure,
  (action) => (error: HttpError) => action(error)
)
const cloneConsumerProfileApplicationMerchantSuccess = createAction(
  MerchantSelectionAction.CloneConsumerProfileApplicationMerchantSuccess,
  (action) => (consumerProfileDto: ConsumerProfileDto) =>
    action(consumerProfileDto)
)

const resetMerchants = createAction(
  MerchantSelectionAction.ResetMerchants
)

const resetMerchantState = createAction(
  MerchantSelectionAction.ResetMerchantState
)

const fetchConsumerProfileSelectedMerchants = createAction(
  MerchantSelectionAction.FetchConsumerProfileSelectedMerchants
)

const fetchConsumerProfileSelectedMerchantsSuccess = createAction(
  MerchantSelectionAction.FetchConsumerProfileSelectedMerchantsSuccess,
  (action) => (selectedMerchants: Merchants) => action(selectedMerchants)
)

const fetchConsumerProfileSelectedMerchantsFailure = createAction(
  MerchantSelectionAction.FetchConsumerProfileSelectedMerchantsFailure,
  (action) => (error: HttpError) => action(error)
)

const resetSubmitError = createAction(MerchantSelectionAction.ResetSubmitError)
const resetMerchantSelectionForm = createAction(MerchantSelectionAction.ResetMerchantSelectionForm)

export const MerchantSelectionActions = {
  fetchMerchants,
  fetchMerchantsFailure,
  fetchMerchantsSuccess,
  fetchConsumerProfileAddressCoordinates,
  fetchConsumerProfileAddressCoordinatesFailure,
  fetchConsumerProfileAddressCoordinatesSuccess,
  reassignConsumerProfileApplicationMerchant,
  reassignConsumerProfileApplicationMerchantFailure,
  reassignConsumerProfileApplicationMerchantSuccess,
  cloneConsumerProfileApplicationMerchant,
  cloneConsumerProfileApplicationMerchantFailure,
  cloneConsumerProfileApplicationMerchantSuccess,
  resetMerchants,
  resetMerchantState,
  resetSubmitError,
  fetchConsumerProfileSelectedMerchants,
  fetchConsumerProfileSelectedMerchantsSuccess,
  fetchConsumerProfileSelectedMerchantsFailure,
  resetMerchantSelectionForm,
}

export type MerchantSelectionActionType = ActionType<
  typeof MerchantSelectionActions
>
