import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'

export class ConsumerPhoneService extends BffService {
  public verifyPhoneNumber(verificationCode: string): FetchResponse<null> {
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/consumer-profile/verify-phone-number`,
      body: {
        verificationCode
      }
    })
  }

  public sendNewVerificationCode(): FetchResponse<null> {
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/consumer-profile/request-new-verification-code`
    })
  }

  public updatePhoneNumber(phoneNumber: string): FetchResponse<null> {
    return super.createAuthorizedRequest({
      method: FetchMethod.PATCH,
      url: `${this.bffUrl}/v1/consumer-profile/phone-number`,
      body: {
        phoneNumber
      }
    })
  }
}
