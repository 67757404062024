import { StylesConfig } from 'react-select'
import { DropdownOption } from './types/dropdown-option.interface'

export const DropdownLibraryStyles: StylesConfig<DropdownOption> = {
  placeholder: (provided) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    ...provided
  })
}
