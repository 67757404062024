import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerPhoneNumberStepState } from '../types/consumer-phone-number-step-state.interface'

export enum ConsumerPhoneNumberStepAction {
  SaveConsumerPhoneNumber = 'ConsumerPhoneNumberStep/SaveConsumerPhoneNumber',
  SaveConsumerPhoneNumberSuccess = 'ConsumerPhoneNumberStep/SaveConsumerPhoneNumberSuccess',
  SaveConsumerPhoneNumberFailure = 'ConsumerPhoneNumberStep/SaveConsumerPhoneNumberFailure'
}

const saveConsumerPhoneNumber = createAction(
  ConsumerPhoneNumberStepAction.SaveConsumerPhoneNumber
)

const saveConsumerPhoneNumberSuccess = createAction(
  ConsumerPhoneNumberStepAction.SaveConsumerPhoneNumberSuccess
)

const saveConsumerPhoneNumberFailure = createAction(
  ConsumerPhoneNumberStepAction.SaveConsumerPhoneNumberFailure,
  (action) => (error: NonNullable<ConsumerPhoneNumberStepState['error']>) =>
    action(error)
)

export const ConsumerPhoneNumberStepActions = {
  saveConsumerPhoneNumber,
  saveConsumerPhoneNumberSuccess,
  saveConsumerPhoneNumberFailure
}

export type ConsumerPhoneNumberStepActionType = ActionType<
  typeof ConsumerPhoneNumberStepActions
>
