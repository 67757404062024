import { ActionType, createAction } from 'typesafe-actions'
import { PreQualifierProduct } from '../../../../../domain/pre-qualifier-product/pre-qualifier-product.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'

export enum PreQualifierLoadingProductAction {
  FetchProductDetails = 'PreQualifierLoadingProduct/FetchProductDetails',
  FetchProductDetailsSuccess = 'PreQualifierLoadingProduct/FetchProductDetailsSuccess',
  FetchProductDetailsFailure = 'PreQualifierLoadingProduct/FetchProductDetailsFailure'
}

const fetchProductDetails = createAction(
  PreQualifierLoadingProductAction.FetchProductDetails,
  (action) => (vendorId: string, sku: string) => action({ vendorId, sku })
)

const fetchProductDetailsSuccess = createAction(
  PreQualifierLoadingProductAction.FetchProductDetailsSuccess,
  (action) => (productDetails: PreQualifierProduct) => action(productDetails)
)

const fetchProductDetailsError = createAction(
  PreQualifierLoadingProductAction.FetchProductDetailsFailure,
  (action) => (error: HttpError) => action(error)
)

export const PreQualifierLoadingProductActions = {
  fetchProductDetails,
  fetchProductDetailsSuccess,
  fetchProductDetailsError
}

export type PreQualifierLoadingProductActionType = ActionType<
  typeof PreQualifierLoadingProductActions
>
