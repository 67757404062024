import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../store/root.store'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { Language } from '../../../../../shared/types/language.enum'
import { SettingsState } from '../types/settings-state.interface'
import { SettingsActions } from './settings.actions'

const getSettingsState = (rootState: RootState): SettingsState =>
  rootState.appState.settingsState

const getLocaleSetting: Selector<RootState, Language> = createSelector(
  getSettingsState,
  (state: SettingsState) => state.locale
)

const setLocaleSetting = (locale: Language) =>
  RootStore.dispatch(SettingsActions.setLocaleSetting(locale))

export const SettingsStore = {
  setLocaleSetting,
  getLocaleSetting
}
