import { GlobeIconProp } from './globe.icon.prop'

export const GlobeIcon = (props: GlobeIconProp) => (
  <svg
    {...props.uat}
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM10.4938 14.2023C12.5536 13.3003 14.0309 11.3148 14.205 8.96666C13.4735 9.36564 12.5511 9.69272 11.4997 9.92041C11.3673 11.5824 11.0069 13.0663 10.4938 14.2023ZM5.50622 2.79766C3.77533 3.5557 2.45578 5.07872 1.97518 6.93912C2.18107 7.13447 2.53167 7.37472 3.06518 7.61183C3.46413 7.78914 3.92936 7.94868 4.44922 8.08199C4.49647 6.01628 4.89199 4.15768 5.50622 2.79766ZM6.31164 10.1985C6.45094 11.4933 6.74239 12.6068 7.1104 13.4348C7.37176 14.0229 7.63694 14.3887 7.84159 14.5835C7.91438 14.6528 7.96706 14.6897 8 14.7087C8.03294 14.6897 8.08562 14.6528 8.15841 14.5835C8.36306 14.3887 8.62824 14.0229 8.8896 13.4348C9.25761 12.6068 9.54906 11.4933 9.68836 10.1985C9.14408 10.2504 8.57923 10.2778 8.00001 10.2778C7.42078 10.2778 6.85593 10.2504 6.31164 10.1985ZM4.50032 9.92041C4.63267 11.5824 4.99314 13.0663 5.50622 14.2023C3.4464 13.3003 1.96911 11.3148 1.79501 8.96665C2.52649 9.36563 3.4489 9.69272 4.50032 9.92041ZM9.77749 8.40187C9.21692 8.46521 8.62157 8.5 8.00001 8.5C7.37845 8.5 6.78308 8.46521 6.22251 8.40187C6.23419 6.42362 6.59811 4.71781 7.1104 3.56517C7.37176 2.9771 7.63694 2.61125 7.84159 2.41649C7.91438 2.34722 7.96706 2.3103 8 2.29125C8.03294 2.3103 8.08562 2.34722 8.15841 2.41649C8.36306 2.61125 8.62824 2.9771 8.8896 3.56517C9.40189 4.71781 9.76581 6.42362 9.77749 8.40187ZM11.5508 8.082C11.5035 6.01629 11.108 4.15768 10.4938 2.79766C12.2247 3.5557 13.5442 5.07873 14.0248 6.93913C13.8189 7.13447 13.4683 7.37472 12.9348 7.61183C12.5359 7.78914 12.0706 7.94869 11.5508 8.082Z"
      fill="#1477CA"
    />
  </svg>
)
