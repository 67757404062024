import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { SelectField } from '../../../ui-library/components/select-field/select-field.component'
import { Language } from '../../types/language.enum'
import { LanguageSelectorMessages } from './language-selector.messages'
import { LanguageSelectorProps } from './language-selector.props'

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const intl = useIntl()

  const [locale, setLocale] = useState(Language.English)

  useEffect(() => {
    setLocale(props.currentLocale)
  }, [props.currentLocale])

  const changeLocale = (locale: string) => {
    props.changeLocale(locale as Language)
  }

  const options = useMemo(
    () => [
      {
        name: intl.formatMessage(LanguageSelectorMessages.english),
        value: Language.English
      },
      {
        name: intl.formatMessage(LanguageSelectorMessages.french),
        value: Language.French
      }
    ],
    []
  )

  return (
    <SelectField
      name="language-selector"
      onChange={changeLocale}
      options={options}
      value={locale}
      optionLabelKey={'name'}
      optionValueKey={'value'}
    />
  )
}
