import { OfferMessages } from './offer.messages'
import { OfferStyles } from './offer.styles'
import { OfferProps } from './offer.props'
import { getPaymentTypeHeader } from './types/offer-view.component.functions'
import { useSelector } from 'react-redux'
import { SettingsStore } from '../../../../../../../app/modules/settings/store/settings.store'
import { formatCurrency } from '../../../../../../../../shared/helpers/currency/currency-formatter'
import { SelectedOfferOverlay } from '../selected-offer-overlay/selected-offer-overlay.component'
import { OfferElement } from '../offer-element/offer-element.component'
import { SimpleFormattedMessage } from '../../../../../../../../ui-library/components/formatted-message/simple-formatted.message'
import { rateFormatter } from '../../../../../../../../shared/helpers/rate/rate-formatter'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { OfferUat } from './offer.uat'

export const Offer = (props: OfferProps) => {
  const onSelect = () => props.onSelect(props.offer)
  const locale = useSelector(SettingsStore.getLocaleSetting)

  return (
    <div
      className={'relative cursor-pointer first:mt-5 first:ml-5'}
      onClick={onSelect}
      {...uatId(OfferUat.container)}
    >
      {props.offer.isSelected ? <SelectedOfferOverlay /> : ''}

      <div className="w-56 gap-0 bg-white py-4 px-5">
        <OfferElement
          msg={formatCurrency(locale, props.offer.payment, 2)}
          msgClassName={OfferStyles.payment}
          title={
            <SimpleFormattedMessage
              msgDescriptor={getPaymentTypeHeader(props.offer.frequency)}
            />
          }
        />

        <OfferElement
          msg={
            <SimpleFormattedMessage
              msgDescriptor={OfferMessages.term}
              values={{ term: props.offer.term }}
            />
          }
          msgClassName={OfferStyles.default}
          title={
            <SimpleFormattedMessage msgDescriptor={OfferMessages.termHeader} />
          }
        />

        <OfferElement
          msg={
            <SimpleFormattedMessage
              msgDescriptor={OfferMessages.rate}
              values={{ rate: rateFormatter(props.offer.rate) }}
            />
          }
          msgClassName={OfferStyles.default}
          title={
            <SimpleFormattedMessage msgDescriptor={OfferMessages.rateHeader} />
          }
        />

        <OfferElement
          msg={formatCurrency(locale, props.offer.totalFinanced, 2)}
          msgClassName={OfferStyles.default}
          title={
            <SimpleFormattedMessage
              msgDescriptor={OfferMessages.totalFinancedHeader}
            />
          }
        />

        <OfferElement
          msg={formatCurrency(locale, props.offer.downPayment, 2)}
          msgClassName={OfferStyles.default}
          title={
            <SimpleFormattedMessage msgDescriptor={OfferMessages.downPayment} />
          }
        />
      </div>
    </div>
  )
}
