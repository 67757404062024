import { MerchantInfoCard } from '../../../merchant-info-card/merchant-info-card.component'
import { MerchantListItemActionFooter } from './components/merchant-list-item-action-footer/merchant-list-item-action-footer.component'
import { MerchantListItemProps } from './merchant-list-item.props'

export const MerchantListItem = (props: MerchantListItemProps) => {
  const openDetailsDialog = () => {
    props.selectMerchant(props.merchant)
  }

  return (
    <MerchantInfoCard
      merchant={props.merchant}
      uat={props.uat}
      footer={
        <MerchantListItemActionFooter
          openDetailsDialog={openDetailsDialog}
          disabled={props.disabled}
        />
      }
    />
  )
}
