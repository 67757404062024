import { withShowIf } from '../../helpers/with-show-if/with-show-if.component'
import { ProgressIndicatorProps } from './progress-indicator.props'
import { ProgressIndicatorStyles } from './progress-indicator.styles'

export const ProgressIndicator = withShowIf((props: ProgressIndicatorProps) => (
  <div className={ProgressIndicatorStyles.container}>
    <div className={ProgressIndicatorStyles.indicatorPlaceholder}>
      <div
        className={ProgressIndicatorStyles.indicator}
        style={{ width: `${props.value}%` }}
      />
    </div>
  </div>
))
