import { ConsumerWorkflowStepType } from '../../../../../../domain/consumer/consumer-workflow/consumer-workflow-step-type.enum'

export const generateConsumerWorkflowStepRoute = (
  type: ConsumerWorkflowStepType,
  vendorId: string
): string => {
  if (type !== ConsumerWorkflowStepType.ConsumerAutoSubmit) {
    return `/vendor/${vendorId}/${type}`
  } else {
    return `/${type}`
  }
}
