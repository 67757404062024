import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerProfileSessionInfo } from '../../../../../domain/consumer/consumer-profile/consumer-profile-session-token.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { CreateConsumerProfileForm } from '../types/create-consumer-profile-form.interface'
import { ConsumerProfileState } from '../types/consumer-profile-state.interface'
import { ConsumerName } from '../../../../../domain/consumer/consumer-profile/consumer-name.interface'
import { SubmitConsumerProfileForm } from '../types/submit-consumer-profile-form.interface'
import { PreApprovalResult } from '../../../../../domain/consumer/consumer-profile/pre-approval-result.interface'
import { CreateConsumerProfileWithAssetForm } from '../types/create-consumer-profile-with-asset-form.interface'
import { ConsumerProfile } from '../../../../../domain/consumer/consumer-profile/consumer-profile.interface'

export enum ConsumerProfileAction {
  CreateConsumerProfile = 'ConsumerProfile/CreateConsumerProfile',
  CreateConsumerProfileWithAsset = 'ConsumerProfile/CreateConsumerProfileWithAsset',
  CreateConsumerProfileFailure = 'ConsumerProfile/CreateConsumerProfileFailure',
  CreateConsumerProfileSuccess = 'ConsumerProfile/CreateConsumerProfileSuccess',
  SetConsumerAddress = 'ConsumerProfile/SetConsumerAddress',
  SetConsumerBannerUrl = 'ConsumerProfile/SetConsumerBannerUrl',
  SetConsumerDob = 'ConsumerProfile/SetConsumerDob',
  SetConsumerEmail = 'ConsumerProfile/SetConsumerEmail',
  SetConsumerName = 'ConsumerProfile/SetConsumerName',
  SetConsumerPhoneNumber = 'ConsumerProfile/SetConsumerPhoneNumber',
  SetConsumerProfile = 'ConsumerProfile/SetConsumerProfile',
  SetConsumerProfileId = 'ConsumerProfile/SetConsumerProfileId',
  SetConsumerVerificationCode = 'ConsumerProfile/SetConsumerVerificationCode',
  SetConsumerNetIncome = 'ConsumerProfile/SetConsumerNetIncome',
  SetConsumerAssetSubtype = 'ConsumerProfile/SetConsumerAssetSubtype',
  SetConsumerAssetType = 'ConsumerProfile/SetConsumerAssetType',
  setConsumerAssetSubtype = 'ConsumerProfile/SetConsumerAssetSubtype',
  SetElectonicCommunicationConsent = 'ConsumerProfile/SetElectonicCommunicationConsent',
  SubmitConsumerProfile = 'ConsumerProfile/SubmitConsumerProfile',
  SubmitConsumerProfileFailure = 'ConsumerProfile/SubmitConsumerProfileFailure',
  SubmitConsumerProfileSuccess = 'ConsumerProfile/SubmitConsumerProfileSuccess',
  SavePreApprovalResult = 'ConsumerProfile/SavePreApprovalResult',
  SetLoanOfferPageViewed = 'ConsumerProfile/SetLoanOfferPageViewed',
  SetLoanOfferPageViewedSuccess = 'ConsumerProfile/SetLoanOfferPageViewedSuccess',
  SetVendor = 'ConsumerProfile/SetVendor',
  SetVendorId = 'ConsumerProfile/SetVendorId',
  SetRefId = 'ConsumerProfile/SetRefId',
  SetReferrer = 'ConsumerProfile/SetReferrer',
  SetEventId = 'ConsumerProfile/SetEventId',
  SetCampaignId = 'ConsumerProfile/SetCampaignId',
  SetPreApprovalStatus = 'ConsumerProfile/SetPreApprovalStatus',
}

const createConsumerProfile = createAction(
  ConsumerProfileAction.CreateConsumerProfile,
  (action) => (form: CreateConsumerProfileForm) => action(form)
)

const createConsumerProfileWithAsset = createAction(
  ConsumerProfileAction.CreateConsumerProfileWithAsset,
  (action) => (form: CreateConsumerProfileWithAssetForm) => action(form)
)

const setConsumerProfileId = createAction(
  ConsumerProfileAction.SetConsumerProfileId,
  (action) => (id: ConsumerProfileState['consumerProfileId']) => action(id)
)

const createConsumerProfileFailure = createAction(
  ConsumerProfileAction.CreateConsumerProfileFailure,
  (action) => (error: HttpError) => action(error)
)
const createConsumerProfileSuccess = createAction(
  ConsumerProfileAction.CreateConsumerProfileSuccess,
  (action) => (data: ConsumerProfileSessionInfo) => action(data)
)

const setConsumerAddress = createAction(
  ConsumerProfileAction.SetConsumerAddress,
  (action) => (address: ConsumerProfileState['address']) => action(address)
)

const setConsumerDob = createAction(
  ConsumerProfileAction.SetConsumerDob,
  (action) => (dob: ConsumerProfileState['dateOfBirth']) => action(dob)
)

const setConsumerBannerUrl = createAction(
  ConsumerProfileAction.SetConsumerBannerUrl,
  (action) => (bannerUrl: ConsumerProfileState['bannerUrl']) =>
    action(bannerUrl)
)
const setConsumerEmail = createAction(
  ConsumerProfileAction.SetConsumerEmail,
  (action) => (email: ConsumerProfileState['email']) => action(email)
)

const setConsumerName = createAction(
  ConsumerProfileAction.SetConsumerName,
  (action) => (name: ConsumerName) => action(name)
)

const setConsumerPhoneNumber = createAction(
  ConsumerProfileAction.SetConsumerPhoneNumber,
  (action) => (phoneNumber: ConsumerProfileState['phoneNumber']) =>
    action(phoneNumber)
)

const setConsumerNetIncome = createAction(
  ConsumerProfileAction.SetConsumerNetIncome,
  (action) => (netIncome: ConsumerProfileState['netIncome']) =>
    action(netIncome)
)

const setConsumerProfile = createAction(
  ConsumerProfileAction.SetConsumerProfile,
  (action) => (consumerProfile: ConsumerProfile) => action(consumerProfile)
)

const setConsumerAssetType = createAction(
  ConsumerProfileAction.SetConsumerAssetType,
  (action) => (assetTypeId: ConsumerProfileState['assetTypeId']) =>
    action(assetTypeId)
)

const setConsumerAssetSubtype = createAction(
  ConsumerProfileAction.SetConsumerAssetSubtype,
  (action) => (assetSubtypeId: ConsumerProfileState['assetSubtypeId']) =>
  action(assetSubtypeId)
)

const setVendorId = createAction(
  ConsumerProfileAction.SetVendorId,
  (action) => (vendorId: string) => action(vendorId)
)

const setAuthorizedElectronicCommunication = createAction(
  ConsumerProfileAction.SetElectonicCommunicationConsent,
  (action) =>
    (
      authorizedElectronicCommunication: ConsumerProfileState['authorizedElectronicCommunication']
    ) =>
      action(authorizedElectronicCommunication)
)

const submitConsumerProfile = createAction(
  ConsumerProfileAction.SubmitConsumerProfile,
  (action) => (form: SubmitConsumerProfileForm) => action(form)
)

const submitConsumerProfileFailure = createAction(
  ConsumerProfileAction.SubmitConsumerProfileFailure,
  (action) => (error: HttpError) => action(error)
)
const submitConsumerProfileSuccess = createAction(
  ConsumerProfileAction.SubmitConsumerProfileSuccess,
  (action) => (submitForm: SubmitConsumerProfileForm) => action(submitForm)
)

const savePreApprovalResult = createAction(
  ConsumerProfileAction.SavePreApprovalResult,
  (action) => (payload: PreApprovalResult) => action(payload)
)

const setLoanOfferPageViewed = createAction(
  ConsumerProfileAction.SetLoanOfferPageViewed,
  (action) => () => action()
)

const setLoanOfferPageViewedSuccess = createAction(
  ConsumerProfileAction.SetLoanOfferPageViewedSuccess,
  (action) => () => action()
)

const setRefId = createAction(
  ConsumerProfileAction.SetRefId,
  (action) => (refId: ConsumerProfileState['refId']) => action(refId)
)

const setReferrer = createAction(
  ConsumerProfileAction.SetReferrer,
  (action) => (referrer: ConsumerProfileState['referrer']) => action(referrer)
)

const setEventId = createAction(
  ConsumerProfileAction.SetEventId,
  (action) => (eventId: ConsumerProfileState['eventId']) => action(eventId)
)

const setCampaignId = createAction(
  ConsumerProfileAction.SetCampaignId,
  (action) => (campaignId: ConsumerProfileState['campaignId']) => action(campaignId)
)

const setVendor = createAction(
  ConsumerProfileAction.SetVendor,
  (action) => (vendor: ConsumerProfileState['vendor']) => action(vendor)
)

const setPreApprovalStatus = createAction(
  ConsumerProfileAction.SetPreApprovalStatus,
  (action) => (preApprovalStatus: ConsumerProfileState['preApprovalStatus']) => action(preApprovalStatus)
)

export const ConsumerProfileActions = {
  createConsumerProfile,
  createConsumerProfileWithAsset,
  createConsumerProfileFailure,
  createConsumerProfileSuccess,
  setVendorId,
  setVendor,
  setConsumerProfileId,
  setConsumerAddress,
  setConsumerDob,
  setConsumerEmail,
  setConsumerName,
  setConsumerBannerUrl,
  setConsumerPhoneNumber,
  setConsumerProfile,
  setConsumerAssetType,
  setConsumerAssetSubtype,
  setConsumerNetIncome,
  setAuthorizedElectronicCommunication,
  submitConsumerProfile,
  submitConsumerProfileFailure,
  submitConsumerProfileSuccess,
  savePreApprovalResult,
  setLoanOfferPageViewed,
  setLoanOfferPageViewedSuccess,
  setRefId,
  setReferrer,
  setEventId,
  setCampaignId,
  setPreApprovalStatus,
}

export type ConsumerProfileActionType = ActionType<
  typeof ConsumerProfileActions
>
