import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { ConsumerWorkflowStepsActions } from './consumer-workflow-steps.actions'
import { ConsumerWorkflowStepsState } from '../types/consumer-workflow-steps-state.interface'
import { RootStore } from '../../../../../../../store/root.store'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import {
  ConsumerWorkflowStep,
  ConsumerWorkflowSteps
} from '../../../../../../../domain/consumer/consumer-workflow/consumer-workflow-step.interface'
import { ConsumerWorkflowStepStateType } from '../../../types/consumer-workflow-step/consumer-workflow-step-state.type'

const getConsumerWorkflowState = (
  rootState: RootState
): ConsumerWorkflowStepsState =>
  rootState.consumerState.consumerWorkflowState.consumerWorkflowStepsState

const getWorkflowSteps: Selector<RootState, ConsumerWorkflowSteps | null> =
  createSelector(
    getConsumerWorkflowState,
    (state: ConsumerWorkflowStepsState) => state.workflowSteps
  )

const getActiveWorkflowStep: Selector<RootState, ConsumerWorkflowStep | null> =
  createSelector(
    getConsumerWorkflowState,
    (state: ConsumerWorkflowStepsState) => state.activeWorkflowStep
  )

const isWorkflowComplete: Selector<RootState, boolean> = createSelector(
  getConsumerWorkflowState,
  (state: ConsumerWorkflowStepsState) =>
    state.workflowSteps?.every(
      (step) => step.state === ConsumerWorkflowStepStateType.completed
    ) ?? false
)

const setActiveWorkflowStep = (workflowStep: ConsumerWorkflowStep) =>
  RootStore.dispatch(
    ConsumerWorkflowStepsActions.setActiveWorkflowStep(workflowStep)
  )

const setWorkflowSteps = (workflowSteps: ConsumerWorkflowSteps) =>
  RootStore.dispatch(
    ConsumerWorkflowStepsActions.setWorkflowSteps(workflowSteps)
  )

export const ConsumerWorkflowStepsStore = {
  getWorkflowSteps,
  getActiveWorkflowStep,
  setActiveWorkflowStep,
  isWorkflowComplete,
  setWorkflowSteps
}
