import { Action } from 'redux'
import { Epic } from 'redux-observable'
import { catchError, filter, mergeMap, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { ConsumerDobService } from '../../../../../../../api/bff/services/consumer-dob.service'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { ConsumerDobStepActions } from './consumer-dob-step.actions'

const saveConsumerDobEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerDobStepActions.saveConsumerDob)),
    switchMap((action) =>
      new ConsumerDobService(ConsumerSessionStore.getSessionToken(state$.value))
        .saveConsumerDob(action.payload)
        .pipe(
          mergeMap(() =>
            of(ConsumerDobStepActions.saveConsumerDobSuccess(action.payload))
          ),
          catchError((error: HttpError) =>
            of(ConsumerDobStepActions.saveConsumerDobFailure(error))
          )
        )
    )
  )

export const consumerDobStepEpics = [saveConsumerDobEpic]
