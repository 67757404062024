import { ConsumerManualAddressState } from './consumer-manual-address-state.interface'

export const createConsumerManualAddressState =
  (): ConsumerManualAddressState => {
    return {
      isWorkflowStepComplete: false,
      provinces: null,
      provincesFailure: null,
      saveAddressFailure: null,
      saveAddressLoading: false
    }
  }
