import { ActionType, createAction } from 'typesafe-actions';
import { Coordinates } from '../../../../../domain/coordinate/coordinate.interface';
import { Province } from '../../../../../domain/province/province.interface';
import { HttpError } from '../../../../../shared/types/http-error.interface';
import { HighCostLicenseDetails } from '../../../../../shared/components/high-cost-license-details/types/high-cost-loan-details.interface';

export enum GeoLocationAction {
  FetchProvince = 'GeoLocation/FetchProvince',
  FetchProvinceSuccess = 'GeoLocation/FetchProvinceSuccess',
  FetchProvinceFailure = 'GeoLocation/FetchProvinceFailure',
  SetConsumerCoordinates = 'ConsumerProfile/SetConsumerCoordinates',
  SetShowHighCostDetails = 'ConsumerProfile/SetShowHighCostDetails',
  SetHighCostLicenseDetails = 'ConsumerProfile/SetHighCostLicenseDetails',
}

const fetchProvince = createAction(
  GeoLocationAction.FetchProvince,
  action => (coordinates: Coordinates) => action(coordinates),
);

const fetchProvinceSuccess = createAction(
  GeoLocationAction.FetchProvinceSuccess,
  action => (province: Province) => action(province),
);

const fetchProvinceFailure = createAction(
  GeoLocationAction.FetchProvinceFailure,
  action => (error: HttpError) => action(error),
);

const setConsumerCoordinates = createAction(
  GeoLocationAction.SetConsumerCoordinates,
  (action) => (coordinates: Coordinates) => action(coordinates)
)

const setHighCostLicenseDetails = createAction(
  GeoLocationAction.SetHighCostLicenseDetails,
  action => (highCostLicenseDetails: HighCostLicenseDetails | null) =>
    action(highCostLicenseDetails),
)

export const GeoLocationActions = {
  fetchProvince,
  fetchProvinceSuccess,
  fetchProvinceFailure,
  setConsumerCoordinates,
  setHighCostLicenseDetails,
};

export type GeoLocationActionType = ActionType<typeof GeoLocationActions>;
