import { ActionType, createAction } from 'typesafe-actions'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { ConsumerPostSubmissionApprovedState } from '../types/consumer-post-submission-approved.state'

export enum ConsumerPostSubmissionApprovedAction {
  FetchApplicantLink = 'ConsumerPostSubmission/FetchApplicantLink',
  FetchApplicantLinkSuccess = 'ConsumerPostSubmission/FetchApplicantLinkSuccess',
  FetchApplicantLinkFailure = 'ConsumerPostSubmission/FetchApplicantLinkFailure'
}

const fetchApplicantLink = createAction(
  ConsumerPostSubmissionApprovedAction.FetchApplicantLink
)

const fetchApplicantLinkSuccess = createAction(
  ConsumerPostSubmissionApprovedAction.FetchApplicantLinkSuccess,
  (action) =>
    (applicantLink: ConsumerPostSubmissionApprovedState['applicantLink']) =>
      action(applicantLink)
)

const fetchApplicantLinkFailure = createAction(
  ConsumerPostSubmissionApprovedAction.FetchApplicantLinkFailure,
  (action) => (error: HttpError) => action(error)
)

export const ConsumerPostSubmissionApprovedActions = {
  fetchApplicantLink,
  fetchApplicantLinkSuccess,
  fetchApplicantLinkFailure
}

export type ConsumerPostSubmissionApprovedActionType = ActionType<
  typeof ConsumerPostSubmissionApprovedActions
>
