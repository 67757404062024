import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { Merchant } from '../../../../../domain/merchant/merchant.interface'
import { RootStore } from '../../../../../store/root.store'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { MerchantSelectionState } from '../types/merchant-selection-state.interface'
import { MerchantSelectionActions } from './merchant-selection.actions'

const merchantSelectionState = (rootState: RootState): MerchantSelectionState =>
  rootState.consumerState.merchantSelectionState

const getLoading: Selector<RootState, MerchantSelectionState['loading']> =
  createSelector(
    merchantSelectionState,
    (state: MerchantSelectionState) => state.loading
  )

const getSelectedMerchant: Selector<
  RootState,
  MerchantSelectionState['selectedMerchant']
> = createSelector(
  merchantSelectionState,
  (state: MerchantSelectionState) => state.selectedMerchant
)

const getMerchants: Selector<RootState, MerchantSelectionState['merchants']> =
  createSelector(
    merchantSelectionState,
    (state: MerchantSelectionState) => state.merchants
  )

const getTotalResultsCount: Selector<RootState, number | null> = createSelector(
  merchantSelectionState,
  (state?: MerchantSelectionState) => state?.totalResultsCount ?? null
)

const getCurrentPage: Selector<RootState, number> = createSelector(
  merchantSelectionState,
  (state?: MerchantSelectionState) => state?.currentPage || 1
)

const getFetchError: Selector<RootState, MerchantSelectionState['fetchError']> =
  createSelector(
    merchantSelectionState,
    (state: MerchantSelectionState) => state.fetchError
  )
const getSubmitError: Selector<
  RootState,
  MerchantSelectionState['submitError']
> = createSelector(
  merchantSelectionState,
  (state: MerchantSelectionState) => state.submitError
)

const getSubmitLoading: Selector<
  RootState,
  MerchantSelectionState['submitLoading']
> = createSelector(
  merchantSelectionState,
  (state: MerchantSelectionState) => state.submitLoading
)

const getConsumerProfileAddressCoordinates: Selector<
  RootState,
  MerchantSelectionState['consumerProfileAddressCoordinates']
> = createSelector(
  merchantSelectionState,
  (state: MerchantSelectionState) => state.consumerProfileAddressCoordinates
)

const resetSubmitError = () =>
  RootStore.dispatch(MerchantSelectionActions.resetSubmitError())

const reassignConsumerProfileApplicationMerchant = (merchant: Merchant, assetSubtypeId: string | null) =>
  RootStore.dispatch(
    MerchantSelectionActions.reassignConsumerProfileApplicationMerchant(
      merchant,
      assetSubtypeId
    )
  )
const cloneConsumerProfileApplicationMerchant = (merchant: Merchant, assetSubtypeId: string | null) =>
  RootStore.dispatch(
    MerchantSelectionActions.cloneConsumerProfileApplicationMerchant(merchant, assetSubtypeId)
  )

const fetchMerchants = (page: number, assetSubtypeId: string | null, merchantName?: string | null) =>
  RootStore.dispatch(MerchantSelectionActions.fetchMerchants(page, assetSubtypeId, merchantName))

const resetMerchants = () =>
  RootStore.dispatch(MerchantSelectionActions.resetMerchants())

const resetMerchantSelectionForm = () => 
  RootStore.dispatch(MerchantSelectionActions.resetMerchantSelectionForm())

const fetchConsumerProfileAddressCoordinates = () =>
  RootStore.dispatch(
    MerchantSelectionActions.fetchConsumerProfileAddressCoordinates()
  )

const fetchConsumerProfileSelectedMerchants = () =>
  RootStore.dispatch(
    MerchantSelectionActions.fetchConsumerProfileSelectedMerchants()
  )

const getConsumerProfileSelectedMerchants: Selector<
  RootState,
  MerchantSelectionState['selectedMerchants']
> = createSelector(
  merchantSelectionState,
  (state: MerchantSelectionState) => state.selectedMerchants
)

const getMerchantSelectionForm: Selector<
  RootState,
  MerchantSelectionState['merchantSelectionForm']
> = createSelector(
  merchantSelectionState,
  (state: MerchantSelectionState) => state.merchantSelectionForm
)

const reset = () =>
  RootStore.dispatch(MerchantSelectionActions.resetMerchantState())

export const MerchantSelectionStore = {
  getSelectedMerchant,
  getConsumerProfileAddressCoordinates,
  reassignConsumerProfileApplicationMerchant,
  cloneConsumerProfileApplicationMerchant,
  getMerchants,
  fetchMerchants,
  fetchConsumerProfileAddressCoordinates,
  getCurrentPage,
  getFetchError,
  getSubmitError,
  getLoading,
  getTotalResultsCount,
  getSubmitLoading,
  resetSubmitError,
  fetchConsumerProfileSelectedMerchants,
  getConsumerProfileSelectedMerchants,
  reset,
  resetMerchants,
  getMerchantSelectionForm,
  resetMerchantSelectionForm,
}
