import React from 'react'
import { PhoneLinkProps } from './phone-link.props'
import { PhoneLinkStyles } from './phone-link.styles'
import { withShowIf } from '../../../helpers/with-show-if/with-show-if.component'
import { PhoneIcon } from '../../../../shared/icons/phone/phone.icon'

export const PhoneLink = withShowIf((props: PhoneLinkProps) => {
  return (
    <>
      <a
        {...props.uat}
        href={`tel:${props.phoneNumber}`}
        className={PhoneLinkStyles.link(props.className)}
      >
        <PhoneIcon className={PhoneLinkStyles.icon(props.cssLogoClasses)} />
        {props.text}
      </a>
    </>
  )
})
