import { Reducer } from 'redux'
import { EventsActionType } from '../modules/events/store/events.actions'
import { eventsReducer } from '../modules/events/store/events.reducer'
import { SettingsActionType } from '../modules/settings/store/settings.actions'
import { settingsReducer } from '../modules/settings/store/settings.reducer'
import { createAppState } from '../types/app-state.functions'
import { AppState } from '../types/app-state.interface'
import { AppActionType } from './app.actions'

export const appReducer: Reducer<AppState, AppActionType> = (
  state: AppState = createAppState(),
  action: AppActionType
): AppState => ({
  ...state,
  settingsState: settingsReducer(
    state.settingsState,
    action as SettingsActionType
  ),
  eventsState: eventsReducer(state.eventsState, action as EventsActionType)
})
