import { GeoLocationState } from './geo-location-state.interface';

export function createGeoLocationStateState(): GeoLocationState {
  return {
    coordinates: null,
    highCostLicenseDetails: null,
    province: null,
    isLoading: false,
  };
}
