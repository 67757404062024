import Pusher, { AuthOptions } from 'pusher-js/with-encryption'
import { Observable, of, throwError } from 'rxjs'
import { EventBusChannel } from './event-bus-channel'
import { EventBusConfig } from './event-bus.config'

export class EventBus {
  private instance: Pusher | null = null

  constructor(private eventBusConfig = new EventBusConfig()) {}

  public createChannel(channelId: number | null): EventBusChannel {
    if (!this.instance) {
      throw new Error('EventBus instance not initialized')
    }

    if (!channelId) {
      throw new Error('channel id not defined')
    }

    return new EventBusChannel(
      `${this.eventBusConfig.getEventBusChannelPrefix()}${channelId}`,
      this.instance
    )
  }

  public connect(sessionToken: string | null): Observable<this> {
    if (!sessionToken) {
      return throwError(
        () => new Error('Cannot connect to EventBus without session token')
      )
    }

    this.instance = new Pusher(this.eventBusConfig.getEventBusKey(), {
      cluster: this.eventBusConfig.getCluster(),
      authEndpoint: this.eventBusConfig.getAuthEndpoint(),
      forceTLS: this.forceTls(),
      auth: this.getAuthConfig(sessionToken)
    })
    this.instance.connection.bind('error', this.listenForErrors)

    return of(this)
  }

  private listenForErrors(err: any): void {
    if (err?.error?.data?.code === 4004) {
      console.error('Socket Connection Interrupted')
    } else {
      console.error('Socket Connection Error', err)
    }
  }

  private forceTls(): boolean {
    return this.eventBusConfig.getEnv() === 'production'
  }

  private getAuthConfig(sessionToken: string): AuthOptions {
    return {
      headers: { Authorization: `Bearer ${sessionToken}` }
    }
  }
}
