import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootStore } from '../../../../../../../../../store/root.store'
import { RootState } from '../../../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerEditPhoneNumberState } from '../types/consumer-edit-phone-number-state.interface'
import { ConsumerEditPhoneNumberActions } from './consumer-edit-phone-number.actions'

const consumerEditPhoneNumberState = (
  rootState: RootState
): ConsumerEditPhoneNumberState =>
  rootState.consumerState.consumerWorkflowState.consumerPhoneValidationStepState
    .consumerEditPhoneNumberState

const editPhoneNumber = (
  phoneNumber: NonNullable<ConsumerEditPhoneNumberState['phoneNumber']>
) =>
  RootStore.dispatch(
    ConsumerEditPhoneNumberActions.editPhoneNumber(phoneNumber)
  )

const getError: Selector<RootState, ConsumerEditPhoneNumberState['error']> =
  createSelector(
    consumerEditPhoneNumberState,
    (state: ConsumerEditPhoneNumberState) => state.error
  )

const getIsEditSuccessful: Selector<
  RootState,
  ConsumerEditPhoneNumberState['isEditSuccessful']
> = createSelector(
  consumerEditPhoneNumberState,
  (state: ConsumerEditPhoneNumberState) => state.isEditSuccessful
)
const getLoading: Selector<RootState, ConsumerEditPhoneNumberState['loading']> =
  createSelector(
    consumerEditPhoneNumberState,
    (state: ConsumerEditPhoneNumberState) => state.loading
  )

const getPhoneNumber: Selector<
  RootState,
  ConsumerEditPhoneNumberState['phoneNumber']
> = createSelector(
  consumerEditPhoneNumberState,
  (state: ConsumerEditPhoneNumberState) => state.phoneNumber
)

const resetEditPhoneNumberState = () =>
  RootStore.dispatch(ConsumerEditPhoneNumberActions.resetEditPhoneNumberState())

export const ConsumerEditPhoneNumberStore = {
  editPhoneNumber,
  getError,
  getIsEditSuccessful,
  getLoading,
  getPhoneNumber,
  resetEditPhoneNumberState
}
