import { createSecurityQuestionsState } from '../types/security-questions-state/security-questions-state.functions'
import { SecurityQuestionsState } from '../types/security-questions-state/security-questions-state.interface'
import {
  SecurityQuestionsAction,
  SecurityQuestionsActionsType
} from './security-questions.actions'

export const securityQuestionsReducer = (
  state: SecurityQuestionsState = createSecurityQuestionsState(),
  action: SecurityQuestionsActionsType
): SecurityQuestionsState => {
  switch (action.type) {
    case SecurityQuestionsAction.FetchSecurityQuestions:
      return {
        ...state,
        isLoading: true
      }

    case SecurityQuestionsAction.FetchSecurityQuestionsSuccess:
      return {
        ...state,
        isLoading: false,
        securityQuestions: action.payload
      }

    case SecurityQuestionsAction.FetchSecurityQuestionsError:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case SecurityQuestionsAction.AnswerSecurityQuestions:
      return {
        ...state,
        submittingSecurityQuestions: true,
        error: undefined
      }
    case SecurityQuestionsAction.AnswerSecurityQuestionsSuccess:
      return {
        ...state,
        submittingSecurityQuestions: false,
        error: undefined
      }
    case SecurityQuestionsAction.AnswerSecurityQuestionsError:
      return {
        ...state,
        attemptsRemaining: action.payload.attemptsRemaining,
        submittingSecurityQuestions: false,
        error: action.payload
      }
    default:
      return state
  }
}
