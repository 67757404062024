import { ChangeEvent, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { debounceTime, Subject, Subscription } from 'rxjs'
import { LookupAddress } from '../../../../../../../../../../../../../../domain/lookup-address/lookup-address.interface'
import { MapMarkerIcon } from '../../../../../../../../../../../../../../shared/icons/map-marker/map-marker.icon'
import { uatId } from '../../../../../../../../../../../../../../uat/uat-id.function'
import { TextInput } from '../../../../../../../../../../../../../../ui-library/components/input-field/components/text-input/text-input.component'
import { AutoAddressInputProps } from './auto-address-input.props'
import { AutoAddressInputStyles } from './auto-address-input.styles'
import { AutoAddressUat } from './auto-address-input.uat'
import { LookupAddressList } from './components/lookup-address-list/lookup-address-list.component'
import { ConsumerAutoAddressStore } from '../../../../store/consumer-auto-address.store'

export const AutoAddressInput = (props: AutoAddressInputProps) => {
  const [lookupAddresses, setLookupAddresses] = useState<
    LookupAddress[] | null
  >(null)

  const [debounceSubscription, setDebounceSubscription] =
    useState<Subscription>()
  const [debounceSubject] = useState<Subject<string>>(new Subject())

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: [
        { name: 'offset', options: { offset: [0, -15] } },
        { name: 'flip', enabled: false }
      ]
    }
  )

  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  useEffect(() => {
    if (Array.isArray(props.lookupAddresses)) {
      setLookupAddresses(props.lookupAddresses)
    }
  }, [props.lookupAddresses])

  useEffect(() => {
    if (!debounceSubscription) {
      setDebounceSubscription(
        debounceSubject.pipe(debounceTime(300)).subscribe(props.onChange)
      )
    }
  }, [debounceSubject, debounceSubscription, props])

  useEffect(() => {
    if (props.retrieveAddress !== null) {
      setShowDropdown(false)
      setLookupAddresses(null)
    }
  }, [props.retrieveAddress])

  const onChange = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const address: string = event.target.value
    props.setAddress(address)

    if (props.showNonPhysicalAddressError) {
      ConsumerAutoAddressStore.resetSaveAddressFailure()
    }

    if (address === '') {
      setLookupAddresses(null)
      setShowDropdown(false)
    } else {
      setShowDropdown(true)
    }
    if (update) {
      await update()
    }
    debounceSubject.next(address)
  }

  return (
    <>
      <div className={AutoAddressInputStyles.field} ref={setReferenceElement}>
        <TextInput
          autoFocus
          label={props.label}
          icon={<MapMarkerIcon />}
          maxLength={props.maxLength}
          name={'auto-address'}
          onChange={onChange}
          value={props.address}
          uat={uatId(AutoAddressUat.input)}
        />
      </div>
      <div
        ref={setPopperElement}
        className={AutoAddressInputStyles.popper}
        style={{ ...styles.popper }}
        {...attributes.popper}
      >
        <LookupAddressList
          lookupAddresses={lookupAddresses}
          lookupAddressesLoading={props.lookupAddressesLoading}
          onLookupAddressItemClick={props.onLookupAddressItemClick}
          retrieveAddressLoading={props.retrieveAddressLoading}
          showIf={showDropdown}
          switchToManualAddressForm={props.switchToManualAddressForm}
          uat={uatId(AutoAddressUat.popper)}
        />
      </div>
    </>
  )
}
