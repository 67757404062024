import { getParamFromUrl } from './url-search-params.functions'

export const setReferrerToSessionStorage = (url: string) => {
  const referrer = getParamFromUrl(url, 'referrer')
  if (referrer !== null) {
    sessionStorage.setItem('__lcpq_referrer', referrer)
  }
}

export const getReferrer = (url: string): string | null => {
  return sessionStorage.getItem('__lcpq_referrer') || getParamFromUrl(url, 'referrer')
}

export const clearReferrerFromSessionStorage = () => {
  sessionStorage.removeItem('__lcpq_referrer')
}