import { FormattedMessage } from 'react-intl'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { PreQualifierLoadingProductErrorViewMessages } from './pre-qualifier-loading-product-error-view.messages'
import { PreQualifierLoadingProductErrorViewStyles } from './pre-qualifier-loading-product-error-view.styles'

export const PreQualifierLoadingProductErrorView = withShowIf(() => {
  return (
    <div className={PreQualifierLoadingProductErrorViewStyles.container}>
      <Paragraph>
        <FormattedMessage
          {...PreQualifierLoadingProductErrorViewMessages.errorMessage}
        />
      </Paragraph>
    </div>
  )
})
