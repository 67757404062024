import { formatToPhoneNumber } from '../../../../../../ui-library/components/input-field/components/phone-input/types/format-to-phone-number.function'
import { AddressLink } from '../../../../../../ui-library/components/link/address-link/address-link.component'
import { PhoneLink } from '../../../../../../ui-library/components/link/phone-link/phone-link.component'
import { WebsiteLink } from '../../../../../../ui-library/components/link/website-link/website-link.component'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { MerchantLogo } from './components/merchant-logo.component'
import { MerchantInfoCardProps } from './merchant-info-card.props'
import { MerchantInfoCardStyles } from './merchant-info-card.styles'

export const MerchantInfoCard = (props: MerchantInfoCardProps) => {
  return (
    <div className={MerchantInfoCardStyles.container} {...props.uat}>
      <div className={MerchantInfoCardStyles.contactInfoContainer}>
        <div className={MerchantInfoCardStyles.merchantNameContainer}>
          <MerchantLogo
            logoUrl={props.merchant.logoUrl}
            merchantName={props.merchant.name}
          />
          <Paragraph className={MerchantInfoCardStyles.merchantName}>
            {props.merchant.name}
          </Paragraph>
        </div>
        <AddressLink
          address={props.merchant.address}
          text={props.merchant.address}
          className={MerchantInfoCardStyles.contactInfo}
        />
        <PhoneLink
          phoneNumber={props.merchant.phoneNumber}
          text={formatToPhoneNumber(props.merchant.phoneNumber)}
          className={MerchantInfoCardStyles.contactInfo}
        />
        <WebsiteLink
          websiteLink={props.merchant.websiteUrl}
          text={props.merchant.websiteUrl}
          className={MerchantInfoCardStyles.contactInfo}
        />
      </div>
      {props.footer}
    </div>
  )
}
