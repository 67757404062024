import { Redirect, Route } from 'react-router-dom'
import { DynamicRouteProps } from './dynamic-route.props'
import { hideIntercom } from '../../../../intercom/intercom';

export const DynamicRoute = (props: DynamicRouteProps) => {
  if (!props.enabled) {
    return <Redirect to={props.redirectPath} />
  }
  hideIntercom(Boolean(props.disableIntercom));

  return (
    <Route key={props.key} path={props.path} exact={props.exact}>
      {props.children}
    </Route>
  )
}
