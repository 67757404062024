export enum ProvinceAbbreviation {
  Alberta = 'AB',
  Quebec = 'QC',
  BritishColumbia = 'BC',
  Manitoba = 'MB',
  NewBrunswick = 'NB',
  Newfoundland = 'NL',
  NorthwestTerritories = 'NT',
  NovaScotia = 'NS',
  Nunavut = 'NU',
  Ontario = 'ON',
  PrinceEdwardIsland = 'PE',
  Saskatchewan = 'SK',
  YukonTerritory = 'YT',
}
