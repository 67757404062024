import {
  ConsumerWorkflowStep,
  ConsumerWorkflowSteps
} from '../../../../domain/consumer/consumer-workflow/consumer-workflow-step.interface'

export const getProgressionPercentage = (
  activeWorkflowStep: ConsumerWorkflowStep | null,
  workflowSteps: ConsumerWorkflowSteps | null
) => {
  if (!workflowSteps || !activeWorkflowStep) {
    return 0
  }
  const progressTrackableWorkflowSteps = workflowSteps.filter(
    (step) => step.rules.isStepProgressTrackable
  )
  const completedProgressTrackableWorkflowSteps =
    progressTrackableWorkflowSteps.filter(
      (step) => step.order <= activeWorkflowStep?.order
    )

  return (
    (completedProgressTrackableWorkflowSteps.length /
      progressTrackableWorkflowSteps.length) *
    100
  )
}
