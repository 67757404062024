import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { RootStore } from '../../../../../../../store/root.store'
import { ConsumerNetIncomeStepState } from '../types/consumer-net-income-step-state.interface'
import { ConsumerProfileState } from '../../../../consumer-profile/types/consumer-profile-state.interface'
import { ConsumerNetIncomeStepActions } from './consumer-net-income-step.actions'

const consumerNetIncomeState = (
  rootState: RootState
): ConsumerNetIncomeStepState =>
  rootState.consumerState.consumerWorkflowState.consumerNetIncomeStepState

const saveConsumerNetIncome = (netIncome: ConsumerProfileState['netIncome']) =>
  RootStore.dispatch(
    ConsumerNetIncomeStepActions.saveConsumerNetIncome(netIncome)
  )

const getLoading: Selector<RootState, ConsumerNetIncomeStepState['loading']> =
  createSelector(
    consumerNetIncomeState,
    (state: ConsumerNetIncomeStepState) => state.loading
  )

const getError: Selector<RootState, ConsumerNetIncomeStepState['error']> =
  createSelector(
    consumerNetIncomeState,
    (state: ConsumerNetIncomeStepState) => state.error
  )

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerNetIncomeStepState['isWorkflowStepComplete']
> = createSelector(
  consumerNetIncomeState,
  (state: ConsumerNetIncomeStepState) => state.isWorkflowStepComplete
)

const reset = () =>
  RootStore.dispatch(ConsumerNetIncomeStepActions.resetConsumerNetIncomeState())

export const ConsumerNetIncomeStepStore = {
  saveConsumerNetIncome,
  getIsWorkflowStepComplete,
  getLoading,
  getError,
  reset
}
