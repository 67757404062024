export const ConsumerPhoneValidationResendCodeBannerDangerMessage = {
  general: {
    id: 'Pages.ConsumerPhoneValidation.ResendVerificationCode.Error.general',
    description: 'An unforeseen error has occurred. Please try again.'
  },
  maxResetCode: {
    id: 'Pages.ConsumerPhoneValidation.ResendVerificationCode.Error.errorMaxResetCode',
    description: 'No more reset verification code attempts'
  },
  verificationCodeExpired:{
    id:'Pages.ConsumerPhoneValidation.VerificationCode.Error.errorExpired',
    description:'verification code expired'
  }
}
