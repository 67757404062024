import * as yup from 'yup'
import { FormFieldSchemaOptions } from './types/form-field-schema-options/form-field-schema-options.type'
import { FormFieldSchemaType } from './types/form-field-schema-type.enum'

export abstract class FormFieldSchema {
  protected schema: yup.AnySchema = yup.string()
  protected type: FormFieldSchemaType = FormFieldSchemaType.STRING
  protected required: boolean | undefined

  constructor(protected name: string) {}

  public getName(): string {
    return this.name
  }

  public getSchema(): yup.AnySchema {
    return this.schema
  }

  public isRequired(): this {
    this.required = true
    this.schema = this.schema.required()
    return this
  }

  public abstract getSchemaOptions(): FormFieldSchemaOptions
}
