import { SubmitConsumerProfileForm } from '../../consumer-profile/types/submit-consumer-profile-form.interface'
import { ConsumerProfileState } from '../../consumer-profile/types/consumer-profile-state.interface'

export class SubmitConsumerProfileFormFactory {
  public static create(
    authorizedElectronicCommunication: ConsumerProfileState['authorizedElectronicCommunication'],
    creditConsent: ConsumerProfileState['creditConsent'],
    refId: ConsumerProfileState['refId'],
    referrer: ConsumerProfileState['referrer'],
    eventId: ConsumerProfileState['eventId'],
    campaignId: ConsumerProfileState['campaignId']
  ): SubmitConsumerProfileForm {
    return {
      authorizedElectronicCommunication,
      creditConsent,
      refId,
      referrer,
      eventId,
      campaignId
    }
  }

}