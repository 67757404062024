import { LookupAddressItemProps } from './lookup-address-item.props'
import { LookupAddressItemStyles } from './lookup-address-item.styles'

export const LookupAddressItem = (props: LookupAddressItemProps) => {
  const fullAddress: string = `${props.address}, ${props.description}`
  const onClick = () => props.onLookupAddressItemClick(props.id)
  return (
    <div
      onClick={onClick}
      className={LookupAddressItemStyles.item(props.isFirstItem)}
      title={fullAddress}
    >
      {fullAddress}
    </div>
  )
}
