export const ConsumerNameFormMessages = {
  title: {
    id: 'Pages.ConsumerName.Form.title',
    description: 'Title of the form'
  },
  firstNameLabel: {
    id: 'Pages.ConsumerName.Form.firstNameLabel',
    description: 'First Name'
  },
  lastNameLabel: {
    id: 'Pages.ConsumerName.Form.lastNameLabel',
    description: 'Last Name'
  },
  submitButton: {
    id: 'Pages.ConsumerName.Form.submitButton',
    description: 'Submit Button'
  }
}
