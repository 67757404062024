import { ActionType, createAction } from 'typesafe-actions'
import { PreApprovalResult } from '../../../../../domain/consumer/consumer-profile/pre-approval-result.interface'

export enum EventsAction {
  Connect = 'Events/Connect',
  ConnectSuccess = 'Events/ConnectSuccess',
  ConnectFailure = 'Events/ConnectFailure',
  Disconnect = 'Events/Disconnect',
  PreApprovalResult = 'Events/PreApprovalResult'
}

const connectToEventBus = createAction(EventsAction.Connect)
const connectToEventBusSuccess = createAction(EventsAction.ConnectSuccess)
const connectToEventBusFailure = createAction(
  EventsAction.ConnectFailure,
  (action) => (error: Error) => action(error)
)
const disconnectFromEventBus = createAction(EventsAction.Disconnect)

const preApprovalResult = createAction(
  EventsAction.PreApprovalResult,
  (action) => (data: PreApprovalResult) => action(data)
)

export const EventsActions = {
  connectToEventBus,
  disconnectFromEventBus,
  connectToEventBusSuccess,
  connectToEventBusFailure,
  preApprovalResult
}

export type EventsActionType = ActionType<typeof EventsActions>
