import { map } from 'rxjs'
import { ConsumerProfile } from '../../../domain/consumer/consumer-profile/consumer-profile.interface'
import { Merchant } from '../../../domain/merchant/merchant.interface'
import { MerchantsResult } from '../../../modules/consumer/modules/merchant-selection/types/merchants-result.interface'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'
import { ConsumerProfileDtoMapper } from '../dto/consumer-profile/consumer-profile-dto-mapper'
import { ConsumerProfileDto } from '../dto/consumer-profile/consumer-profile.dto'
import { createMerchantQuery } from '../types/bff-request.functions'

export class MerchantService extends BffService {
  public fetchMerchants(
    page: number, 
    assetSubtypeId: string | null,
    merchantName?: string | null
  ): FetchResponse<MerchantsResult> {
    return super.createAuthorizedRequest<MerchantsResult>({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/consumer-profile/nearest-merchants`,
      query: createMerchantQuery(assetSubtypeId, page, merchantName)
    })
  }

  public reassignConsumerProfileApplicationMerchant(
    merchant: Merchant,
    assetSubtypeId: string | null
  ): FetchResponse<ConsumerProfile> {
    return super
      .createAuthorizedRequest<ConsumerProfileDto>({
        method: FetchMethod.PATCH,
        url: `${this.bffUrl}/v1/consumer-profile/application/merchant/${merchant.id}`,
        query: createMerchantQuery(assetSubtypeId)
      })
      .pipe(
        map((result) => new ConsumerProfileDtoMapper(result).toDomainObject())
      )
  }

  public cloneConsumerProfileApplicationMerchant(
    merchant: Merchant,
    assetSubtypeId: string | null
  ): FetchResponse<ConsumerProfileDto> {
    return super.createAuthorizedRequest<ConsumerProfileDto>({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/consumer-profile/application/clone`,
      body: {
        merchantId: merchant.id
      },
      query: createMerchantQuery(assetSubtypeId)
    })
  }
}
