import { ConsumerProfile } from '../../../../domain/consumer/consumer-profile/consumer-profile.interface'
import { ConsumerProfileDto } from './consumer-profile.dto'

export class ConsumerProfileDtoMapper {
  constructor(private data: ConsumerProfileDto) {}

  public toDomainObject(): ConsumerProfile {
    return {
      consumerProfileId: this.data.id,
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      phoneNumber: this.data.phoneNumber,
      assetSubtypeId: this.data.assetSubtypeId,
      assetTypeId: this.data.assetTypeId,
      dateOfBirth: this.data.dateOfBirth,
      email: this.data.emailAddress,
      address: this.data.address,
      netIncome: this.data.netMonthlyIncome,
      isPreApprovalApplicationReassigned:
      this.data.isPreApprovalApplicationReassigned,
      authorizedElectronicCommunication:
      this.data.authorizedElectronicCommunication,
      creditConsent: this.data.creditConsent,
      preApprovalStatus: this.data.preApprovalStatus,
      preApprovalAmount: this.data.preApprovalAmount,
      referenceCode: this.data.referenceCode,
      canViewOfferPage: this.data.canViewOfferPage,
      consumerProfileAsset: this.data.asset,
      vendorId: this.data.vendorId,
      vendor: this.data.vendor,
      bannerUrl: this.data.bannerUrl,
      preQualifierAssetTypeUiConfig: this.data.preQualifierAssetTypeUiConfig,
      postSubmissionConfig: this.data.postSubmissionConfig
    }
  }
}
