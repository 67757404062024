import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { PreQualifierAssetType } from '../../../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type.interface'
import { Language } from '../../../../../shared/types/language.enum'
import { RootStore } from '../../../../../store/root.store'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { PreQualifierAssetTypeSelectionState } from '../types/pre-qualifier-asset-type-selection-state.interface'
import { PreQualifierAssetTypeSelectionActions } from './pre-qualifier-asset-type-selection.actions'
import { PreQualifierAssetTypeUiConfig } from '../../../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type-ui-config/pre-qualifier-asset-type-ui-config.interface'

const preQualifierAssetTypeState = (
  rootState: RootState
): PreQualifierAssetTypeSelectionState =>
  rootState.consumerState.preQualifierAssetTypeSelectionState

const setSelectedAssetType = (assetType: PreQualifierAssetType) =>
  RootStore.dispatch(
    PreQualifierAssetTypeSelectionActions.setSelectedAssetType(assetType)
  )

const getLoading: Selector<
  RootState,
  PreQualifierAssetTypeSelectionState['loading']
> = createSelector(
  preQualifierAssetTypeState,
  (state: PreQualifierAssetTypeSelectionState) => state.loading
)

const getSelectedAssetType: Selector<
  RootState,
  PreQualifierAssetTypeSelectionState['selectedAssetType']
> = createSelector(
  preQualifierAssetTypeState,
  (state: PreQualifierAssetTypeSelectionState) => state.selectedAssetType
)

const getSelectedAssetTypeImageUrl: Selector<
  RootState,
  PreQualifierAssetTypeUiConfig['assetTypeImageUrl'] | null
> = createSelector(
  preQualifierAssetTypeState,
  (state: PreQualifierAssetTypeSelectionState) => state.selectedAssetType?.preQualifierAssetTypeUiConfig.assetTypeImageUrl || null,
);

const getSelectedAssetTypeImageTitle: Selector<
  RootState,
  PreQualifierAssetTypeUiConfig['assetTypeImageTitle'] | null
> = createSelector(
  preQualifierAssetTypeState,
  (state: PreQualifierAssetTypeSelectionState) => state.selectedAssetType?.preQualifierAssetTypeUiConfig.assetTypeImageTitle || null,
);

const getAssetTypes: Selector<
  RootState,
  PreQualifierAssetTypeSelectionState['assetTypes']
> = createSelector(
  preQualifierAssetTypeState,
  (state: PreQualifierAssetTypeSelectionState) => state.assetTypes
)

const getFetchError: Selector<
  RootState,
  PreQualifierAssetTypeSelectionState['fetchError']
> = createSelector(
  preQualifierAssetTypeState,
  (state: PreQualifierAssetTypeSelectionState) => state.fetchError
)

const fetchAssetTypes = (
  locale: Language,
  vendorId: string,
  tags: string[] | null
) =>
  RootStore.dispatch(
    PreQualifierAssetTypeSelectionActions.fetchAssetTypes(locale, vendorId, tags)
  )

const reset = () =>
  RootStore.dispatch(
    PreQualifierAssetTypeSelectionActions.resetPreQualifierAssetTypeState()
  )

export const PreQualifierAssetTypeSelectionStore = {
  getSelectedAssetType,
  setSelectedAssetType,
  getAssetTypes,
  fetchAssetTypes,
  getFetchError,
  getLoading,
  reset,
  getSelectedAssetTypeImageUrl,
  getSelectedAssetTypeImageTitle,
}
