import { withShowIf } from '../../../../../ui-library/helpers/with-show-if/with-show-if.component';
import { AssetTypeDetailsProps } from './asset-type-details.props';
import { AssetTypeDetailsStyles } from './asset-type-details.styles';
import { Paragraph } from '../../../../../ui-library/components/paragraph/paragraph.component';

export const AssetTypeDetails = withShowIf((props: AssetTypeDetailsProps) => {
    return (
        <div className={AssetTypeDetailsStyles.assetImageContainer}>
            <Paragraph className={AssetTypeDetailsStyles.assetName}>
                {props.assetTypeName}
            </Paragraph>
            <div className={AssetTypeDetailsStyles.imageContainer}>                
                <img
                    className={AssetTypeDetailsStyles.assetImage}
                    src={props.assetTypeImageUrl ?? ''}
                    {...props.assetTypeImageUat}
                />
            </div>
        </div>
    )
});