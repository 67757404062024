import { FormattedMessage } from 'react-intl'
import { MainLayout } from '../../../../../../../../shared/components/main-layout/main-layout.component'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { ConsumerPostSubmissionDeclinedViewMessages } from './consumer-post-submission-declined-view.messages'
import { ConsumerPostSubmissionDeclinedViewStyles } from './consumer-post-submission-declined-view.styles'
import { ConsumerPostSubmissionDeclinedViewUat } from './consumer-post-submission-declined-view.uat'

export const ConsumerPostSubmissionDeclinedView = () => {
  return (
    <MainLayout showContactInfoFooter noHorizontalPadding centerAlignDisclaimer>
      <Paragraph
        className={ConsumerPostSubmissionDeclinedViewStyles.text}
        uat={uatId(ConsumerPostSubmissionDeclinedViewUat.title)}
      >
        <FormattedMessage
          {...ConsumerPostSubmissionDeclinedViewMessages.title}
        />
      </Paragraph>
      <Paragraph
        className={ConsumerPostSubmissionDeclinedViewStyles.text}
        uat={uatId(ConsumerPostSubmissionDeclinedViewUat.contactInfo)}
      >
        <FormattedMessage
          {...ConsumerPostSubmissionDeclinedViewMessages.contactInfo}
        />
      </Paragraph>
    </MainLayout>
  )
}
