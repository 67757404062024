import { ActionType, createAction } from 'typesafe-actions'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { SubmitConsumerProfileForm } from '../../../../consumer-profile/types/submit-consumer-profile-form.interface'

export enum ConsumerSubmitStepAction {
  SubmitConsumerProfileForPreApproval = 'ConsumerSubmitStepAction/SubmitConsumerProfileForPreApproval',
  SubmitConsumerProfileForPreApprovalSuccess = 'ConsumerSubmitStepAction/SubmitConsumerProfileForPreApprovalSuccess',
  SubmitConsumerProfileForPreApprovalFailure = 'ConsumerSubmitStepAction/SubmitConsumerProfileForPreApprovalFailure'
}

const submitConsumerProfileForPreApproval = createAction(
  ConsumerSubmitStepAction.SubmitConsumerProfileForPreApproval,
  (action) => (submitForm: SubmitConsumerProfileForm) => action(submitForm)
)

const submitConsumerProfileForPreApprovalSuccess = createAction(
  ConsumerSubmitStepAction.SubmitConsumerProfileForPreApprovalSuccess,
  (action) => (submitForm: SubmitConsumerProfileForm) => action(submitForm)
)

const submitConsumerProfileForPreApprovalFailure = createAction(
  ConsumerSubmitStepAction.SubmitConsumerProfileForPreApprovalFailure,
  (action) => (error: HttpError) => action(error)
)

export const ConsumerSubmitStepActions = {
  submitConsumerProfileForPreApproval,
  submitConsumerProfileForPreApprovalSuccess,
  submitConsumerProfileForPreApprovalFailure
}

export type ConsumerSubmitStepActionType = ActionType<
  typeof ConsumerSubmitStepActions
>
