import { withShowIf } from '../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerEditPhoneNumberSectionForm } from './components/consumer-edit-phone-number-section-form/consumer-edit-phone-number-section-form.component'
import { ConsumerEditPhoneNumberSectionViewProps } from './consumer-edit-phone-number-section-view.props'
import { ConsumerEditPhoneNumberSectionViewStyles } from './consumer-edit-phone-number-section-view.styles'

export const ConsumerEditPhoneNumberSectionView = withShowIf(
  (props: ConsumerEditPhoneNumberSectionViewProps) => {
    return (
      <div
        className={ConsumerEditPhoneNumberSectionViewStyles.updatePhoneForm}
      >
        <ConsumerEditPhoneNumberSectionForm
          setSectionToPhoneValidation={props.setSectionToPhoneValidation}
        />
      </div>
    )
  }
)
