import { ConsumerPhoneValidationSection } from './consumer-phone-validation-section.enum'

export const isConsumerPhoneValidationSection = (
  currentSection: ConsumerPhoneValidationSection
): boolean =>
  currentSection ===
  ConsumerPhoneValidationSection.PHONE_NUMBER_VALIDATION_SECTION

export const isConsumerEditPhoneNumberSection = (
  currentSection: ConsumerPhoneValidationSection
): boolean =>
  currentSection === ConsumerPhoneValidationSection.EDIT_PHONE_NUMBER_SECTION
