import React from 'react'
import { CheckmarkIcon } from '../../../../../../../../shared/icons/check-mark/check-mark.icon'
import { PreQualifierAssetTypeListItemProps } from './pre-qualifier-asset-type-list-item.props'
import { PreQualifierAssetTypeListItemStyles } from './pre-qualifier-asset-type-list-item.styles'

export const PreQualifierAssetTypeListItem = (
  props: PreQualifierAssetTypeListItemProps
) => {
  const openDetailsDialog = () => {
    props.selectAssetType(props.assetType)
  }

  const getContainerClassName = (): string => {
    const classNames = [PreQualifierAssetTypeListItemStyles.container]
    if (props.selected) {
      classNames.push(PreQualifierAssetTypeListItemStyles.selected)
    }
    return classNames.join(' ')
  }

  return (
    <div
      className={getContainerClassName()}
      onClick={openDetailsDialog}
      {...props.uat}
    >
      <img
        className={PreQualifierAssetTypeListItemStyles.image}
        src={props.assetType.imageUrl}
        alt={props.assetType.name}
      />
      <div className={PreQualifierAssetTypeListItemStyles.text}>
        {props.assetType.name}
      </div>
      <CheckmarkIcon
        className={PreQualifierAssetTypeListItemStyles.checkmark}
        showIf={props.selected}
      />
    </div>
  )
}
