import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ManualAddressItemMessages } from './manual-address-item.messages'
import { ManualAddressItemProps } from './manual-address-item.props'
import { ManualAddressItemStyles } from './manual-address-item.styles'

export const ManualAddressItem = (props: ManualAddressItemProps) => {
  return (
    <div className={ManualAddressItemStyles.container}>
      <div
        className={ManualAddressItemStyles.item}
        onClick={props.switchToManualAddressForm}
      >
        <FormattedMessage {...ManualAddressItemMessages.manualLink} />
      </div>
    </div>
  )
}
