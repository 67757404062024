import { Reducer } from 'redux'
import { createConsumerPostSubmissionApprovedState } from '../types/consumer-post-submission-state-approved.function'
import { ConsumerPostSubmissionApprovedState } from '../types/consumer-post-submission-approved.state'
import {
  ConsumerPostSubmissionApprovedAction,
  ConsumerPostSubmissionApprovedActionType
} from './consumer-post-submission-approved.actions'

export const consumerPostSubmissionApprovedReducer: Reducer<
  ConsumerPostSubmissionApprovedState,
  ConsumerPostSubmissionApprovedActionType
> = (
  state: ConsumerPostSubmissionApprovedState = createConsumerPostSubmissionApprovedState(),
  action: ConsumerPostSubmissionApprovedActionType
): ConsumerPostSubmissionApprovedState => {
  switch (action.type) {
    case ConsumerPostSubmissionApprovedAction.FetchApplicantLinkSuccess:
      return {
        ...state,
        applicantLink: action.payload
      }
    case ConsumerPostSubmissionApprovedAction.FetchApplicantLinkFailure:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}
