import { from, Observable } from 'rxjs'
import { ConsumerAddress } from '../../../../domain/consumer/consumer-profile/consumer-address.interface'
import { Coordinates } from '../../../../domain/coordinate/coordinate.interface'
import { GeocodeProvider } from '../../../../domain/geolocation/geocode-provider.interface'
import { MapLibraryGeocodeService } from '../../../../libraries/map-library/services/map-library-geocode.service'

export class MapViewService {
  private geoCodingService: GeocodeProvider

  constructor() {
    this.geoCodingService = new MapLibraryGeocodeService()
  }

  public getAddressCoordinates(
    address: ConsumerAddress | null
  ): Observable<Coordinates> {
    return from(
      this.geoCodingService.getCoordinates(
        `${address?.street} ${address?.city} ${address?.postalCode} ${address?.province}`
      )
    )
  }
}
