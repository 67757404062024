import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ConsumerRoute } from '../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { addVendorIdToPath } from '../../../../../../shared/helpers/path-helper'
import { ConsumerProfileStore } from '../../../consumer-profile/store/consumer-profile.store'
import { MerchantSelectionStore } from '../../store/merchant-selection.store'
import { MerchantSelectSuccessView } from './components/merchant-select-success-view/merchant-select-success-view.component'

export const MerchantSelectSuccess = () => {
  const merchant = useSelector(MerchantSelectionStore.getSelectedMerchant)
  const history = useHistory()
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)

  useEffect(() => {
    if (merchant === null) {
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SelectMerchant))
    }
  }, [merchant])

  if (merchant) {
    return <MerchantSelectSuccessView merchant={merchant} />
  }

  return null
}
