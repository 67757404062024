import { useState } from 'react'
import { useIntl } from 'react-intl'
import { uatId } from '../../../../../../../../../../../../../../uat/uat-id.function'
import { SelectField } from '../../../../../../../../../../../../../../ui-library/components/select-field/select-field.component'
import { Province } from '../../../../../../../../../../../../../../domain/province/province.interface'
import { ProvincesSelectFieldMessages } from './provinces-select-field.messages'
import { ProvincesSelectFieldProps } from './provinces-select-field.props'

export const ProvincesSelectField = (props: ProvincesSelectFieldProps) => {
  const intl = useIntl()

  const [selectedProvince, setSelectedProvince] = useState<Province['id']>(
    props.province
  )

  const onChange = (selectedProvinceId: string): void => {
    setSelectedProvince(selectedProvinceId)
    props.onChange(selectedProvinceId)
  }

  const getProvinces = () => props.provinces ?? []

  return (
    <SelectField
      name="Provinces"
      label={intl.formatMessage(ProvincesSelectFieldMessages.label)}
      onChange={onChange}
      optionLabelKey={'name'}
      optionValueKey={'id'}
      value={selectedProvince}
      options={getProvinces()}
      placeholder={intl.formatMessage(ProvincesSelectFieldMessages.placeholder)}
      uat={uatId(props.uat)}
      optionListUat={uatId(props.uatOptionList)}
      optionListItemUat={uatId(props.uatOptionListItem)}
    />
  )
}
