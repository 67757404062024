import { useIntl } from 'react-intl';
import { BannerTextType } from '../../../../../../../../../../ui-library/components/banner-text/banner-text-type.enum';
import { BannerText } from '../../../../../../../../../../ui-library/components/banner-text/banner-text.component';
import { withShowIf } from '../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component';
import { uatId } from '../../../../../../../../../../uat/uat-id.function';
import { ConsumerAddressViewErrorUat } from './consumer-address-view-error.uat';
import { ConsumerAddressViewErrorMessages } from './consumer-address-view-error.messages';
import { ConsumerAddressViewErrorStyles } from './consumer-address-view-error.styles';

export const ConsumerAddressViewError = withShowIf(() => {
  const intl = useIntl();
  
  return (
    <BannerText
      uat={uatId(ConsumerAddressViewErrorUat.error)}
      cssContainerClasses={ConsumerAddressViewErrorStyles.errorMessage}
      type={BannerTextType.DANGER}
      boldText={intl.formatMessage(ConsumerAddressViewErrorMessages.errorMessage)}
    />
  )
})