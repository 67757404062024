import { AssetSubtypeState } from './asset-subtype-selection-state.interface'

export function createAssetSubtypeState(): AssetSubtypeState {
  return {
    assetSubtypes: [],
    fetchError: null,
    loading: false,
    selectedAssetSubtypeId: null
  }
}
