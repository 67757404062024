import { Answer } from '../../../../../../../../../domain/consumer/security-questions/types/questions/answer.interface'
import { QuestionType } from '../../../../../../../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { SecurityQuestionsForm } from '../../../types/security-questions-form/security-questions-form.interface'

export const mapSecurityQuestionFormValuesToAnswers = (
  values: SecurityQuestionsForm
): Answer[] => {
  const answers: Answer[] = []

  Object.entries(values).forEach(([key, value]) => {
    answers.push({
      questionType: key as QuestionType,
      answer: value as string
    })
  })

  return answers
}

export const createInitialSecurityFormValues = (questions: QuestionType[]) =>
  questions.reduce(
    (formValues: any, key: string) => ({ ...formValues, [key]: '' }),
    {}
  )
