import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ConsumerWorkflowStepsStore } from '../../../modules/consumer/modules/consumer-workflow/modules/consumer-workflow-steps/store/consumer-workflow-steps.store'
import { ProgressIndicator } from '../../../ui-library/components/progress-indicator/progress-indicator.component'
import { ContactInfoFooter } from './components/contact-info-footer/contact-info-footer.component'
import { Footer } from './components/footer/footer.component'
import { getProgressionPercentage } from './components/main-layout.functions'
import { NavBar } from './components/nav-bar/nav-bar.component'
import { MainLayoutProps } from './main-layout.props'
import { MainLayoutStyles } from './main-layout.styles'
import { MainLayoutFooterMessages } from './main-layout.messages'
import { useIntl } from 'react-intl'
import { DefaultHeader } from './components/default-header/default-header.component'
import { HighCostLicenseDetails } from '../high-cost-license-details/high-cost-license-details.component'
import { GeoLocationStore } from '../../../modules/consumer/modules/geo-location/store/geo-location.store'

export const MainLayout = (props: MainLayoutProps) => {
  const intl = useIntl()
  const activeWorkflowStep = useSelector(
    ConsumerWorkflowStepsStore.getActiveWorkflowStep
  )
  const workflowSteps = useSelector(ConsumerWorkflowStepsStore.getWorkflowSteps)
  const highCostDetails = useSelector(GeoLocationStore.getHighCostLicenseDetails)
  const showProgressIndicator = useMemo(
    () => Boolean(activeWorkflowStep?.rules.showProgressIndicator),
    [activeWorkflowStep?.rules.showProgressIndicator]
  )
  const progressIndicatorValue = useMemo(
    () => getProgressionPercentage(activeWorkflowStep, workflowSteps),
    [activeWorkflowStep, workflowSteps]
  )

  return (
    <>
      <NavBar hideIf={props.hideNavBar} />
      <DefaultHeader showIf={Boolean(props.showDefaultHeader)} />
      <ProgressIndicator
        showIf={showProgressIndicator}
        value={progressIndicatorValue}
      />
      <HighCostLicenseDetails
        showIf={Boolean(highCostDetails)} 
        highCostLicenseDetails={highCostDetails} />
      <main
        className={MainLayoutStyles.main(Boolean(props.noHorizontalPadding))}
      >
        {props.children}
      </main>
      <Footer
        hideIf={props.hideFooter}
        centerAlignDisclaimer={props.centerAlignDisclaimer}
      />
      <ContactInfoFooter
        headerText={intl.formatMessage(MainLayoutFooterMessages.title)}
        email={intl.formatMessage(MainLayoutFooterMessages.lendCareEmail)}
        phoneNumber={intl.formatMessage(
          MainLayoutFooterMessages.lendCarePhoneNumber
        )}
        showIf={Boolean(props.showContactInfoFooter)}
      />
    </>
  )
}
