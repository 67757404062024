import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component'
import { ConsumerPhoneValidationStepProps } from './consumer-phone-validation-step.props'
import { ConsumerPhoneValidationView } from './components/consumer-phone-validation-view/consumer-phone-validation-view.component'
import { useCallback } from 'react'

export const ConsumerPhoneValidationStep = (
  props: ConsumerPhoneValidationStepProps
) => {
  const onComplete = () => props.onComplete(props.step)

  const goBack = useCallback(
    () => props.goBack(props.step),
    [props.step, props.goBack]
  )

  return (
    <MainLayout hideFooter>
      <ConsumerPhoneValidationView
        onComplete={onComplete}
        goBack={goBack}
        step={props.step}
      />
    </MainLayout>
  )
}
