import { useMemo, useState } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { FormSchema } from '../../../../../../../../libraries/form-schema/form-schema'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { BannerTextType } from '../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { BannerText } from '../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { PhoneInput } from '../../../../../../../../ui-library/components/input-field/components/phone-input/phone-input.component'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { PhoneNumberFormSchema } from '../../../../../../../../shared/schema/phone-number/phone-number-schema.interface'
import { generatePhoneNumberFormSchema } from '../../../../../../../../shared/schema/phone-number/phone-number.schema'
import { ConsumerPhoneNumberFormMessages } from './consumer-phone-number-form.messages'
import { ConsumerPhoneNumberFormProps } from './consumer-phone-number-form.props'
import { ConsumerPhoneNumberFormStyles } from './consumer-phone-number-form.styles'
import { ConsumerPhoneNumberFormUat } from './consumer-phone-number-form.uat'
import { getErrorMessage } from './types/consumer-phone-number-form.functions'

export const ConsumerPhoneNumberForm = (
  props: ConsumerPhoneNumberFormProps
) => {
  const [phoneNumber, setPhoneNumber] = useState<string>(
    props.phoneNumber ?? ''
  )
  const intl: IntlShape = useIntl()

  const formSchema: FormSchema<PhoneNumberFormSchema> =
    generatePhoneNumberFormSchema()

  const submitForm = (): void => props.onSubmit(phoneNumber)
  const onPhoneNumberChange = (phoneNumber: string): void => setPhoneNumber(phoneNumber);

  const isFormValid = useMemo(() => {
    return formSchema.isValidSync({ phoneNumber });
  }, [phoneNumber, formSchema]);

  const disableSubmitButton = useMemo(() => {
    return !isFormValid || props.loading;
  }, [isFormValid, props.loading]); 

  const errorMessage = useMemo(() => {
    if (props.error) {
      return intl.formatMessage(getErrorMessage(props.error))
    }
    if (phoneNumber.length < 10 && errorMessage) {
      return undefined;
    }
  }, [props.error, phoneNumber]);

  return (
    <>
      <div
        className={ConsumerPhoneNumberFormStyles.formContainer}
      >
          <PhoneInput
            autoFocus
            name={'phoneNumber'}
            label={intl.formatMessage(
              ConsumerPhoneNumberFormMessages.phoneNumberLabel
            )}
            value={phoneNumber}
            onChange={onPhoneNumberChange}
            uat={uatId(ConsumerPhoneNumberFormUat.errorMessage)}
          />
        <BannerText
          uat={uatId(ConsumerPhoneNumberFormUat.errorMessage)}
          cssContainerClasses={ConsumerPhoneNumberFormStyles.errorBanner}
          type={BannerTextType.DANGER}
          showIf={Boolean(errorMessage)}
          text={errorMessage}
        />

        <PrimaryButton
          onClick={submitForm}
          disabled={disableSubmitButton}
          cssContainerClasses={ConsumerPhoneNumberFormStyles.button}
          loading={props.loading}
          uat={uatId(ConsumerPhoneNumberFormUat.submitButton)}
        >
          <FormattedMessage {...ConsumerPhoneNumberFormMessages.submitButton} />
        </PrimaryButton>
      </div>
    </>
  )
}
