import { useSelector } from 'react-redux'
import { Modal } from '../../../../../../../../ui-library/components/modal/modal.component'
import { MerchantSelectionStore } from '../../../../store/merchant-selection.store'
import { MerchantSelectionConfirmDialogErrorView } from './components/merchant-selection-confirm-dialog-error-view/merchant-selection-confirm-dialog-error-view.component'
import { MerchantSelectionConfirmDialogView } from './components/merchant-selection-confirm-dialog-view/merchant-selection-confirm-dialog-view.component'
import { MerchantSelectionConfirmDialogProps } from './merchant-selection-confirm-dialog.props'
import { MerchantSelectionConfirmDialogStyles } from './merchant-selection-confirm-dialog.styles'

export const MerchantSelectionConfirmDialog = (props: MerchantSelectionConfirmDialogProps) => {
  const error = useSelector(MerchantSelectionStore.getSubmitError)

  return (
    <Modal
      open={props.isOpen}
      className={MerchantSelectionConfirmDialogStyles.dialog}
    >
      <MerchantSelectionConfirmDialogErrorView
        showIf={Boolean(error)}
        onClose={props.onClose}
      />
      <MerchantSelectionConfirmDialogView
        showIf={!Boolean(error)}
        loading={props.loading}
        onClose={props.onClose}
        onConfirm={props.onConfirm}
        merchant={props.merchant}
      />
    </Modal>
  )
}
