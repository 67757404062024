import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { ConsumerSessionService } from '../../../../../api/bff/services/consumer-session.service'
import { ConsumerSession } from '../../../../../domain/consumer/consumer-session/consumer-session.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { Action } from '../../../../../store/root.actions'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { setSessionTokenToLocalStorage } from '../../../../app/components/initializer/types/session-token.functions'
import { SecurityQuestionsActions } from '../../consumer-authentication/modules/security-questions/store/security-questions.actions'
import { ConsumerProfileActions } from '../../consumer-profile/store/consumer-profile.actions'
import { clearVendorAndProductIdFromLocalStorage } from '../../pre-qualifier-loading-product/types/pre-qualifier-product-local-storage.functions'
import { ConsumerSessionActions } from './consumer-session.actions'
import { ConsumerSessionStore } from './consumer-session.store'

const createConsumerProfileSuccessEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerProfileActions.createConsumerProfileSuccess)),
    map((action) => {
      clearVendorAndProductIdFromLocalStorage()
      setSessionTokenToLocalStorage(action.payload.sessionToken)
      return ConsumerSessionActions.setSessionToken(action.payload.sessionToken)
    })
  )

const setConsumerProfileSessionEpic: Epic<Action, Action, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(SecurityQuestionsActions.answerSecurityQuestionsSuccess)),
    map((action) => {
      setSessionTokenToLocalStorage(action.payload.accessToken)
      return ConsumerSessionActions.setSessionToken(action.payload.accessToken)
    })
  )

const updateConsumerProfileSessionEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerSessionActions.fetchConsumerSession)),
    switchMap(() =>
      new ConsumerSessionService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .getConsumerSession()
        .pipe(
          map((consumerSession: ConsumerSession) =>
            ConsumerSessionActions.fetchConsumerSessionSuccess(consumerSession)
          ),
          catchError((err: HttpError) =>
            of(ConsumerSessionActions.fetchConsumerSessionFailure(err))
          )
        )
    )
  )

export const consumerSessionEpics = [
  createConsumerProfileSuccessEpic,
  setConsumerProfileSessionEpic,
  updateConsumerProfileSessionEpic
]
