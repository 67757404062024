import { DisclaimerStyles } from './disclaimer.styles'
import { FormattedMessage } from 'react-intl'
import { DisclaimerMessages } from './disclaimer.messages'
import { getLinkForLocale } from './types/disclaimer.functions'
import { DisclaimerProps } from './disclaimer.props'
import { CommonStyles } from '../../../../../../../ui-library/styles/common.styles'
import { uatId } from '../../../../../../../uat/uat-id.function'
import { disclaimerUat } from './disclaimer.uat'

export const Disclaimer = (props: DisclaimerProps) => {
  const LearnMoreLink = (linkText: string) => (
    <a
      {...uatId(disclaimerUat.link)}
      className={CommonStyles.anchorLink}
      target="_blank"
      rel="noreferrer"
      href={getLinkForLocale(props.locale)}
    >
      {linkText}
    </a>
  )

  return (
    <div className={DisclaimerStyles.container(props.alignCenter)}>
      <p>
        <FormattedMessage {...DisclaimerMessages.creditCheckNotice} />
      </p>
      <p>
        <FormattedMessage
          {...DisclaimerMessages.learnMore}
          values={{
            a: LearnMoreLink
          }}
        />
      </p>
    </div>
  )
}
