import { useIntl } from 'react-intl'
import { ValidationErrorMessage } from '../../../../../../../../../../shared/components/validation-error-message/validation-error-message.component'
import { uatId } from '../../../../../../../../../../uat/uat-id.function'
import { DobValidationMessageMessages } from './dob-validation-message.messages'
import { DobValidationMessageProps } from './dob-validation-message.props'
import { DobValidationMessageStyles } from './dob-validation-message.styles'
import { DobValidationMessageUat } from './dob-validation-message.uat'

export const DobValidationMessage = (props: DobValidationMessageProps) => {
  const intl = useIntl()

  if (props.errorMessageId === null) {
    return null
  }

  return (
    <ValidationErrorMessage
      cssContainerClasses={DobValidationMessageStyles.errorMessage}
      message={intl.formatMessage(
        DobValidationMessageMessages[props.errorMessageId]
      )}
      uat={uatId(DobValidationMessageUat.validationErrorMessage)}
    ></ValidationErrorMessage>
  )
}
