export const ConsumerDobFormMessages = {
  inputLabel: {
    id: 'Pages.ConsumerDob.Form.inputLabel',
    description: 'Date of Birth (mm/dd/yyyy)'
  },
  inputPlaceholder: {
    id: 'Pages.ConsumerDob.Form.inputPlaceholder',
    description: '09/21/1991'
  },
  submitButton: {
    id: 'Pages.ConsumerDob.Form.submitButton',
    description: 'text for the submit button'
  }
}
