import { FormSchema } from '../../../../../../../../libraries/form-schema/form-schema'
import { StringFieldSchema } from '../../../../../../../../libraries/form-schema/form-field-schema/string-field-schema'
import { ConsumerNameFormSchema } from './types/consumer-name-form-schema.interface'

export function generateConsumerNameFormSchema(): FormSchema<ConsumerNameFormSchema> {
  return new FormSchema<ConsumerNameFormSchema>({
    firstName: new StringFieldSchema('firstName')
      .setMinLength(1)
      .setMaxLength(100)
      .isRequired(),
    lastName: new StringFieldSchema('lastName')
      .setMinLength(1)
      .setMaxLength(100)
      .isRequired()
  })
}
