export const MerchantSelectionNotFoundViewMessages = {
  title: {
    id: 'Pages.MerchantSelectionNotFound.Page.title',
    description: 'Merchant selection page title'
  },
  description: {
    id: 'Pages.MerchantSelectionNotFound.Page.description',
    description: 'Merchant selection page description'
  }
}
