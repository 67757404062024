import * as yup from 'yup'
import { FormFieldSchema } from './form-field-schema'
import { BooleanSchemaOptions } from './types/form-field-schema-options/boolean-schema-options.interface'
import { FormFieldSchemaType } from './types/form-field-schema-type.enum'

export class BooleanFieldSchema extends FormFieldSchema {
  protected schema = yup.boolean()
  protected type: FormFieldSchemaType = FormFieldSchemaType.BOOLEAN
  private mustBeTrue: boolean = false

  public getSchemaOptions(): BooleanSchemaOptions {
    return {
      name: this.name,
      type: this.type,
      required: this.required,
      mustBeTrue: this.mustBeTrue
    }
  }

  public isTrue(): this {
    this.mustBeTrue = true
    this.schema = this.schema.oneOf([true])
    return this
  }
}
