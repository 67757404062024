import { UserMarkerIconProp } from './user-marker.icon.prop'

export const UserMarkerIcon = (props: UserMarkerIconProp) => (
  <svg
    {...props.uat}
    className={props.className}
    width="79"
    height="78"
    viewBox="0 0 79 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse opacity="0.5" cx="39.5" cy="39" rx="39.5" ry="39" fill="#1477CA" />
    <ellipse cx="39.5" cy="39" rx="11.5" ry="12" fill="#1477CA" />
  </svg>
)
