import { getParamFromUrl } from './url-search-params.functions'

const campaignIdSessionProperty = '__lcpq_campaignId'

export const setCampaignIdToSessionStorage = (url: string) => {
  const campaignId = getParamFromUrl(url, 'campaignId')
  if (campaignId !== null) {
    sessionStorage.setItem(campaignIdSessionProperty, campaignId)
  }
}

export const getCampaignId = (url: string): string | null => {
  return sessionStorage.getItem(campaignIdSessionProperty) || getParamFromUrl(url, 'campaignId')
}

export const clearCampaignIdFromSessionStorage = () => {
  sessionStorage.removeItem(campaignIdSessionProperty)
}