import React from 'react'
import { WithHideIfProps } from './with-hide-if.props'

export const withHideIf = <P extends React.PropsWithChildren<any>>(
  Component: React.FC<P>
): React.FC<P & WithHideIfProps> => {
  return (props) => {
    if (props.hideIf) {
      return null
    }
    return <Component {...props} />
  }
}
