import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerAddressViewMessages } from './consumer-address-view.messages'
import { ConsumerAddressViewProps } from './consumer-address-view.props'
import { ConsumerAddressViewUat } from './consumer-address-view.uat'
import { ConsumerAutoAddressView } from './modules/consumer-auto-address/components/consumer-auto-address-view/consumer-auto-address-view.component'
import { ConsumerManualAddressView } from './modules/consumer-manual-address/components/consumer-manual-address-view/consumer-manual-address-view.component'
import {
  isAutoForm,
} from './types/consumer-address-view.functions'
import { FormAddressType } from './types/form-address-type.enum'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'

export const ConsumerAddressView = (props: ConsumerAddressViewProps) => {
  const intl = useIntl();
  const [addressFormType, setAddressFormType] = useState<FormAddressType>(
    FormAddressType.AUTO
  )

  const switchToAutoAddressForm = () => setAddressFormType(FormAddressType.AUTO)
  const switchToManualAddressForm = () =>
    setAddressFormType(FormAddressType.MANUAL)

  const shouldShowAutoForm = useMemo(() => {
    return isAutoForm(addressFormType);
  }, [addressFormType]);
  
  return (
    <>
      <PrimaryHeader
        showBackIcon={props.step.rules.showBackButton}
        goBack={props.goBack}
      />
      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(ConsumerAddressViewMessages.title)}
        titleUat={uatId(ConsumerAddressViewUat.title)}
        subTitleMessage={
          shouldShowAutoForm 
            ? intl.formatMessage(ConsumerAddressViewMessages.autoFormSubtitle) 
            : intl.formatMessage(ConsumerAddressViewMessages.manualFormSubtitle)
        }
        noHorizontalPadding
        showFooter
      >
        <ConsumerAutoAddressView
          onComplete={props.onComplete}
          showIf={shouldShowAutoForm}
          switchToManualAddressForm={switchToManualAddressForm}
        />
        <ConsumerManualAddressView
          onComplete={props.onComplete}
          showIf={!shouldShowAutoForm}
          switchToAutoAddressForm={switchToAutoAddressForm}
        />
      </ConsumerProfileAssetImageContainer>
      
    </>
  )
}
