import { PhoneIconProp } from './phone.icon.prop'

export const PhoneIcon = (props: PhoneIconProp) => (
  <svg
    {...props.uat}
    className={props.className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8C16 6.94941 15.7931 5.90918 15.391 4.93848C14.989 3.96797 14.3997 3.08594 13.6569 2.34316C12.914 1.6002 12.0321 1.01094 11.0615 0.608984C10.0909 0.206836 9.05059 0 8 0V1.6C8.84045 1.6 9.67268 1.76562 10.4492 2.08711C11.2257 2.40879 11.9312 2.88027 12.5255 3.47461C13.1198 4.06875 13.5912 4.77441 13.9128 5.55078C14.2344 6.32734 14.4 7.15957 14.4 8H16Z"
      fill="#1477CA"
    />
    <path
      d="M0 6.4V2.4C0 1.9582 0.358179 1.6 0.8 1.6H4.8C5.24182 1.6 5.6 1.9582 5.6 2.4V5.6C5.6 6.0418 5.24182 6.4 4.8 6.4H3.2C3.2 9.93457 6.06538 12.8 9.6 12.8V11.2C9.6 10.7582 9.95818 10.4 10.4 10.4H13.6C14.0418 10.4 14.4 10.7582 14.4 11.2V15.2C14.4 15.6418 14.0418 16 13.6 16H9.6C4.29807 16 0 11.702 0 6.4Z"
      fill="#1477CA"
    />
    <path
      d="M12.4346 6.16309C12.6759 6.74551 12.8 7.36973 12.8 8H11.36C11.36 7.55879 11.2731 7.12188 11.1042 6.71426C10.9354 6.30645 10.6879 5.93613 10.3759 5.62422C10.0639 5.31211 9.69348 5.06465 9.28582 4.8957C8.87817 4.72695 8.44124 4.64004 8 4.64004V3.2C8.63035 3.2 9.25452 3.32422 9.83689 3.56543C10.4192 3.80664 10.9484 4.16016 11.3941 4.60586C11.8398 5.05156 12.1934 5.58066 12.4346 6.16309Z"
      fill="#1477CA"
    />
  </svg>
)
