import { createSelector, Selector } from 'reselect';
import { GeoLocationActions } from './geo-location.actions';
import { RootState } from '../../../../../store/types/root-state/root-state.interface';
import { Coordinates } from '../../../../../domain/coordinate/coordinate.interface';
import { Province } from '../../../../../domain/province/province.interface';
import { GeoLocationState } from '../types/geo-location-state.interface';
import { RootStore } from '../../../../../store/root.store';
import { HighCostLicenseDetails } from '../../../../../shared/components/high-cost-license-details/types/high-cost-loan-details.interface';

const getGeolocationState: Selector<
  RootState,
  GeoLocationState | undefined
> = createSelector(
  (state: RootState) => state.consumerState.geoLocationState,
  (state?: GeoLocationState) => state,
);

const fetchProvince = (coordinates: Coordinates): void => {
  RootStore.dispatch(GeoLocationActions.fetchProvince(coordinates));
};

const getProvince: Selector<RootState, Province | null> = createSelector(
  getGeolocationState,
  (state?: GeoLocationState) => state?.province ?? null,
);

const setConsumerCoordinates = (coordinates: Coordinates) =>
  RootStore.dispatch(GeoLocationActions.setConsumerCoordinates(coordinates))

const getCoordinates: Selector<RootState, Coordinates | null> = createSelector(
  getGeolocationState,
  (state?: GeoLocationState) => state?.coordinates ?? null,
);

const setHighCostLicenseDetails = (
  highCostLicenseDetails: HighCostLicenseDetails | null,
) => RootStore.dispatch(GeoLocationActions.setHighCostLicenseDetails(highCostLicenseDetails));

const getHighCostLicenseDetails: Selector<
  RootState,
  HighCostLicenseDetails | null
> = createSelector(
  getGeolocationState,
  (state?: GeoLocationState) => state?.highCostLicenseDetails ?? null,
);

export const GeoLocationStore = {
  getGeolocationState,
  fetchProvince,
  getProvince,
  setConsumerCoordinates,
  getCoordinates,
  setHighCostLicenseDetails,
  getHighCostLicenseDetails,
}