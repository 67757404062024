import { ChangeEvent } from 'react'
import { PostalCodeInputMask } from './postal-code-input-mask.interface'

export const postalCodeInputMask = (
  postalCodeText: string
): PostalCodeInputMask => {
  const postalCodeList: string[] = postalCodeText.split('')
  let moveCaretForwardBy = 0

  if (postalCodeList.length >= 2) {
    postalCodeList.splice(3, 0, ' ')
    moveCaretForwardBy = 1
  }

  return {
    inputMask: postalCodeList.join(''),
    moveCaretForwardBy
  }
}

export const removePostalCodeInputMaskFormat = (
  postalCodeInputMask: string
): string => postalCodeInputMask.replace(new RegExp(' ', 'g'), () => '')

export const stopCaretJumpingToEndOfLine = (
  moveCaretForwardBy: number,
  event: ChangeEvent<HTMLInputElement>
): void => {
  const caretPosition: number =
    Number(event.target.selectionEnd) + moveCaretForwardBy
  const element: HTMLInputElement = event.target

  window.requestAnimationFrame(() => {
    element.selectionStart = caretPosition
    element.selectionEnd = caretPosition
  })
}
