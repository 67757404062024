import { Action } from '../../../../../../../store/root.actions'
import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { ConsumerProfileService } from '../../../../../../../api/bff/services/consumer-profile.service'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { ConsumerPostSubmissionApprovedActions } from './consumer-post-submission-approved.actions'
import { ConsumerPostSubmissionApprovedApplicantLink } from '../types/consumer-post-submission-approved-applicant-link.interface'

const fetchApplicantLink: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf(ConsumerPostSubmissionApprovedActions.fetchApplicantLink)
    ),
    switchMap(() =>
      new ConsumerProfileService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .getApplicantLink()
        .pipe(
          map((result: ConsumerPostSubmissionApprovedApplicantLink) => {
            return ConsumerPostSubmissionApprovedActions.fetchApplicantLinkSuccess(
              result.applicantLink
            )
          }),
          catchError((err: HttpError) =>
            of(
              ConsumerPostSubmissionApprovedActions.fetchApplicantLinkFailure(
                err
              )
            )
          )
        )
    )
  )

export const consumerPostSubmissionApprovedEpics = [fetchApplicantLink]
