import { PinIconProp } from './pin.icon.prop'

export const PinIcon = (props: PinIconProp) => (
  <svg
    {...props.uat}
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="23"
    viewBox="0 0 18 23"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2721 9.77212C13.2721 11.9813 11.4813 13.7721 9.27214 13.7721C7.06301 13.7721 5.27214 11.9813 5.27214 9.77212C5.27214 7.56298 7.06301 5.77212 9.27214 5.77212C11.4813 5.77212 13.2721 7.56298 13.2721 9.77212ZM11.2721 9.77212C11.2721 10.8767 10.3767 11.7721 9.27214 11.7721C8.16758 11.7721 7.27214 10.8767 7.27214 9.77212C7.27214 8.66755 8.16758 7.77212 9.27214 7.77212C10.3767 7.77212 11.2721 8.66755 11.2721 9.77212Z"
      fill="#1477CA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.79417 16.0183C-0.805757 12.5909 -0.945617 6.89409 2.48178 3.29417C5.90918 -0.305757 11.6059 -0.445617 15.2059 2.98178C18.8058 6.40918 18.9457 12.1059 15.5183 15.7059L9.3124 22.2241L2.79417 16.0183ZM14.0698 14.3268L9.24298 19.3965L4.17324 14.5698C1.3733 11.904 1.26452 7.47318 3.93028 4.67324C6.59603 1.8733 11.0268 1.76452 13.8268 4.43028C16.6267 7.09603 16.7355 11.5268 14.0698 14.3268Z"
      fill="#1477CA"
    />
  </svg>
)
