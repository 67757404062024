import { BffService } from '../bff.service'
import { Language } from '../../../shared/types/language.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { LoanDetailsDto } from '../../../modules/consumer/modules/consumer-post-submission/modules/consumer-post-submission-offers/types/loan-details.dto'
import { Offer } from '../../../modules/consumer/modules/consumer-post-submission/modules/consumer-post-submission-offers/types/offer.interface'

export class OffersService extends BffService {
  public fetchOffers(locale: Language): FetchResponse<Offer[]> {
    return super.createAuthorizedRequest<Offer[]>({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/consumer-profile/loan-offers`,
      locale
    })
  }

  public updateLoanDetails(offer: Offer): FetchResponse<void> {
    return super.createAuthorizedRequest<void>({
      method: FetchMethod.PATCH,
      url: `${this.bffUrl}/v1/consumer-profile/loan-offers/`,
      body: LoanDetailsDto.create(offer)
    })
  }

  public saveLoanOfferPageViewed(): FetchResponse<void> {
    return super.createAuthorizedRequest({
      method: FetchMethod.PUT,
      url: `${this.bffUrl}/v1/consumer-profile/loan-offers/page-viewed`
    })
  }
}
