import { SessionToken } from '../../../../../shared/types/session-token.type';

export const getSessionToken = ():SessionToken =>
  localStorage.getItem('sessionToken') || null

export const clearSessionToken = () => {
  localStorage.removeItem('sessionToken')
}

export const setSessionTokenToLocalStorage = (sessionToken: SessionToken) =>
  sessionToken && localStorage.setItem('sessionToken', sessionToken)

