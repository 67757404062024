export const ConsumerPostSubmissionOffersViewMessages = {
  btnSelectedOffer: {
    id: 'Pages.Consumer.Post.Submission.Offers.View.BtnSelectedOffer',
    description: 'Selected Offer button'
  },
  btnCompleteLater: {
    id: 'Pages.Consumer.Post.Submission.Offers.View.BtnCompleteLater',
    description: 'Complete later button'
  }
}
