import { Reducer } from 'redux'
import { ConsumerAutoAddressActionType } from '../components/consumer-address-view/modules/consumer-auto-address/store/consumer-auto-address.actions'
import { consumerAutoAddressReducer } from '../components/consumer-address-view/modules/consumer-auto-address/store/consumer-auto-address.reducer'
import { ConsumerManualAddressActionType } from '../components/consumer-address-view/modules/consumer-manual-address/store/consumer-manual-address.actions'
import { consumerManualAddressReducer } from '../components/consumer-address-view/modules/consumer-manual-address/store/consumer-manual-address.reducer'
import { createConsumerAddressStepState } from '../types/consumer-address-step-state.functions'
import { ConsumerAddressStepState } from '../types/consumer-address-step-state.interface'
import { ConsumerAddressStepActionType } from './consumer-address-step.actions'

export const consumerAddressStepReducer: Reducer<
  ConsumerAddressStepState,
  ConsumerAddressStepActionType
> = (
  state: ConsumerAddressStepState = createConsumerAddressStepState(),
  action: ConsumerAddressStepActionType
): ConsumerAddressStepState => {
  switch (action.type) {
    default:
      return {
        ...state,
        auto: consumerAutoAddressReducer(
          state.auto,
          action as ConsumerAutoAddressActionType
        ),
        manual: consumerManualAddressReducer(
          state.manual,
          action as ConsumerManualAddressActionType
        )
      }
  }
}
