export const ConsumerProfileAssetOverviewMessages = {
  msrp: {
    id: 'Pages.ConsumerProduct.Form.msrp',
    description: 'Consumer product page msrp title'
  },
  submitButton: {
    id: 'Pages.ConsumerProduct.Form.submitButton',
    description: 'text for the submit button'
  }
}
