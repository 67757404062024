export const ConsumerPhoneValidationSectionErrorMessage = {
  invalidCode: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.Error.invalidCode',
    description: 'Invalid code, please try again'
  },
  verificationAttemptsExhausted: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.Error.verificationAttemptsExhausted',
    description: 'No more verification attempts.'
  },
  general: {
    id: 'Pages.ConsumerPhoneValidation.ValidatePhoneNumberSection.Error.general',
    description: 'Catch all error message'
  }
}
