import React from 'react'
import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { ConsumerPostSubmissionApprovedReferenceCodeMessages } from './consumer-post-submission-approved-reference-code.messages'
import { ConsumerPostSubmissionApprovedReferenceCodeProps } from './consumer-post-submission-approved-reference-code.props'
import { ConsumerPostSubmissionApprovedReferenceCodeStyles } from './consumer-post-submission-approved-reference-code.styles'
import { ConsumerPostSubmissionApprovedReferenceCodeUat } from './consumer-post-submission-approved-reference-code.uat'

export const PostSubmissionApprovedReferenceCode = (
  props: ConsumerPostSubmissionApprovedReferenceCodeProps
) => {
  return (
    <div className={ConsumerPostSubmissionApprovedReferenceCodeStyles.wrapper}>
      <div
        className={ConsumerPostSubmissionApprovedReferenceCodeStyles.container}
      >
        <div
          className={
            ConsumerPostSubmissionApprovedReferenceCodeStyles.sectionReferenceCode
          }
        >
          <Paragraph
            className={
              ConsumerPostSubmissionApprovedReferenceCodeStyles.referenceCodeLabel
            }
          >
            <FormattedMessage
              {...ConsumerPostSubmissionApprovedReferenceCodeMessages.referenceCodeLabel}
            />
          </Paragraph>
          <Paragraph
            className={
              ConsumerPostSubmissionApprovedReferenceCodeStyles.referenceCode
            }
            uat={uatId(
              ConsumerPostSubmissionApprovedReferenceCodeUat.referenceCode
            )}
          >
            {props.referenceCode}
          </Paragraph>
        </div>
        <div
          className={
            ConsumerPostSubmissionApprovedReferenceCodeStyles.sectionDescription
          }
        >
          <Paragraph
            uat={uatId(
              ConsumerPostSubmissionApprovedReferenceCodeUat.description
            )}
            className={
              ConsumerPostSubmissionApprovedReferenceCodeStyles.description
            }
          >
            <FormattedMessage
              {...ConsumerPostSubmissionApprovedReferenceCodeMessages.description}
            />
          </Paragraph>

          <Paragraph
            className={ConsumerPostSubmissionApprovedReferenceCodeStyles.details}
          >
            <FormattedMessage
              {...ConsumerPostSubmissionApprovedReferenceCodeMessages.details}
            />
          </Paragraph>
          
          <Paragraph
            className={ConsumerPostSubmissionApprovedReferenceCodeStyles.note}
          >
            <FormattedMessage
              {...ConsumerPostSubmissionApprovedReferenceCodeMessages.note}
            />
          </Paragraph>
        </div>
      </div>
    </div>
  )
}
