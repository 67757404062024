import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerSubmitFormMessages } from './consumer-submit-form.messages'
import { ConsumerSubmitFormProps } from './consumer-submit-form.props'
import { generateConsumerSubmitFormSchema } from './consumer-submit-form.schema'
import { ConsumerSubmitFormStyles } from './consumer-submit-form.styles'
import { ConsumerSubmitFormUat } from './consumer-submit-form.uat'
import { ConsumerSubmitConsent } from './components/consumer-submit-consent/consumer-submit-consent.component'
import { Footer } from '../../../../../../../../shared/components/main-layout/components/footer/footer.component'

export const ConsumerSubmitForm = (props: ConsumerSubmitFormProps) => {
  const intl = useIntl()

  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [
    authorizedElectronicCommunicationConsent,
    setAuthorizedElectronicCommunicationConsent
  ] = useState<boolean>(props.initialFormValue ?? false)
  const [creditConsent, setCreditConsent] = useState<boolean>(
    props.initialFormValue ?? false
  )
  const [disableSubmitButton, setDisableSubmitButton] = useState(true)

  const formSchema = generateConsumerSubmitFormSchema()

  const submitForm = () =>
    props.onSubmit(authorizedElectronicCommunicationConsent, creditConsent)

  useEffect(() => {
    const disableButtonIfInvalidFormOrLoading: boolean =
      !isFormValid || props.loading
    setDisableSubmitButton(disableButtonIfInvalidFormOrLoading)
  }, [isFormValid, props.loading])

  useEffect(() => {
    setIsFormValid(
      formSchema.isValidSync({
        authorizedElectronicCommunicationConsent,
        creditConsent
      })
    )
  }, [authorizedElectronicCommunicationConsent, creditConsent, formSchema])

  return (
    <div className={ConsumerSubmitFormStyles.formContainer}>
      <ConsumerSubmitConsent
        name="credit-consent"
        onConsentChange={setCreditConsent}
        consentValue={creditConsent}
        consentLink={intl.formatMessage(ConsumerSubmitFormMessages.consentLink)}
        consentLinkText={intl.formatMessage(
          ConsumerSubmitFormMessages.viewConsentDetails
        )}
        consentLabel={ConsumerSubmitFormMessages.creditConsentLabel}
        consentText={ConsumerSubmitFormMessages.creditConsentText}
        uat={uatId(ConsumerSubmitFormUat.creditConsent)}
      />
      <ConsumerSubmitConsent
        onConsentChange={setAuthorizedElectronicCommunicationConsent}
        name="authorized-electronic-communication-consent"
        consentValue={authorizedElectronicCommunicationConsent}
        consentLabel={
          ConsumerSubmitFormMessages.authorizedElectronicCommunicationConsentLabel
        }
        consentText={
          ConsumerSubmitFormMessages.authorizedElectronicCommunicationConsentText
        }
        uat={uatId(
          ConsumerSubmitFormUat.authorizedElectronicCommunicationConsentCheckbox
        )}
      />
      <div className={ConsumerSubmitFormStyles.buttonWrapper}>
        <PrimaryButton
          onClick={submitForm}
          disabled={disableSubmitButton}
          loading={props.loading}
          uat={uatId(ConsumerSubmitFormUat.submitButton)}
          cssContainerClasses={ConsumerSubmitFormStyles.submitButtonContainer}
          cssButtonClasses={ConsumerSubmitFormStyles.submitButton}
        >
          <FormattedMessage {...ConsumerSubmitFormMessages.submitButton} />
        </PrimaryButton>
      </div>
      <Footer />
    </div>
  )
}
