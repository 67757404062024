import { Marker } from 'react-map-gl'
import { UserMarkerIcon } from '../../../../shared/icons/user-marker/user-marker.icon'
import { FocalPointMarkerProps } from './focal-point-marker.props'

export const FocalPointMarker = (props: FocalPointMarkerProps) => {
  return (
    <Marker
      key={`user-location`}
      latitude={props.coordinates[0]}
      longitude={props.coordinates[1]}
      anchor={'center'}
    >
      <UserMarkerIcon />
    </Marker>
  )
}
