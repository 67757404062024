import styled from 'styled-components'
import { withShowIf } from '../../helpers/with-show-if/with-show-if.component'
import { CommonStyles } from '../../styles/common.styles'

export const inlineMessageStyles = {
  container: (cssProp: string = ''): string => `text-center ${cssProp}`,
  button: `${CommonStyles.anchorLink}`
}

export const InlineMessageContainer = withShowIf(
  styled.span`
    padding-right: 4px;
  `
)
