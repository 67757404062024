import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { AssetSubtypesSelectField } from '../../../../../asset-subtype-selection/components/asset-subtypes-select-field/asset-subtypes-select-field'
import { ConsumerAssetSubtypeViewProps } from './consumer-asset-subtype-view.props'
import { ConsumerAssetSubtypeViewStyles } from './consumer-asset-subtype-view.styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConsumerAssetSubtypeViewMessages } from './consumer-asset-subtype-view.messages'
import { ConsumerAssetSubtypeError } from './consumer-asset-subtype-error/consumer-asset-subtype-error.component'
import { useEffect, useMemo, useState } from 'react'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { ConsumerAssetSubtypeViewUat } from './consumer-asset-subtype-view.uat'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'

export const ConsumerAssetSubtypeView = (
  props: ConsumerAssetSubtypeViewProps
) => {
  const [selectedAssetSubtype, setSelectedAssetSubtype] = useState<string>(
    props.assetSubtype
  )
  const intl = useIntl()

  const onSubmit = () => {
    props.saveAssetSubtype()
    props.onComplete()
  }

  const hasAssetTypeSelected = useMemo(() => {
    return Boolean(props.assetSubtype)
  }, [props.assetSubtype])

  useEffect(() => {
    if (props.assetSubtypes.length === 1) {
      setSelectedAssetSubtype(props.assetSubtypes[0].id.toString())
      props.saveAssetSubtype()
      props.onComplete()
    }
  }, [props.assetSubtypes])

  useEffect(() => {
    setSelectedAssetSubtype(props.assetSubtype)
  }, [props.assetSubtype])

  return (
    <>
      <PrimaryHeader />

      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(
          ConsumerAssetSubtypeViewMessages.title
        )}
        subTitleMessage={intl.formatMessage(
          ConsumerAssetSubtypeViewMessages.subtitle
        )}
        noHorizontalPadding
        showFooter
      >
        <div className={ConsumerAssetSubtypeViewStyles.container}>
          <AssetSubtypesSelectField
            className={ConsumerAssetSubtypeViewStyles.assetSubtypeSelector}
            fieldClassName={ConsumerAssetSubtypeViewStyles.assetSubtypeField}
            assetSubtype={selectedAssetSubtype}
            assetSubtypes={props.assetSubtypes || []}
            onChange={props.onChange}
            fieldUat={uatId(ConsumerAssetSubtypeViewUat.assetSubtypeSelect)}
            itemUat={uatId(ConsumerAssetSubtypeViewUat.assetSubtypeItem)}
            placeholder={intl.formatMessage(
              ConsumerAssetSubtypeViewMessages.selectAssetSubtypePlaceholder
            )}
          />
          <ConsumerAssetSubtypeError showIf={Boolean(props.error)} />
          <PrimaryButton
            cssContainerClasses={ConsumerAssetSubtypeViewStyles.buttonContainer}
            onClick={onSubmit}
            disabled={!hasAssetTypeSelected}
            loading={props.loading}
            uat={uatId(ConsumerAssetSubtypeViewUat.submitButton)}
          >
            <FormattedMessage
              {...ConsumerAssetSubtypeViewMessages.nextButton}
            />
          </PrimaryButton>
        </div>
      </ConsumerProfileAssetImageContainer>
    </>
  )
}
