export const formatToPhoneNumber = (phoneNumber: string): string => {
  const areaCode = phoneNumber.substring(0, 3)
  const exchangeCode = phoneNumber.substring(3, 6)
  const stationCode = phoneNumber.substring(6, 10)
  let formattedPhoneNumber = ''
  if (areaCode.length > 0) {
    formattedPhoneNumber = `(${areaCode}`
  }
  if (areaCode.length === 3) {
    formattedPhoneNumber = `(${areaCode}) `
  }
  if (exchangeCode.length > 0) {
    formattedPhoneNumber = `(${areaCode}) ${exchangeCode}`
  }
  if (exchangeCode.length === 3) {
    formattedPhoneNumber = `(${areaCode}) ${exchangeCode}-`
  }
  if (stationCode.length > 0) {
    formattedPhoneNumber = `(${areaCode}) ${exchangeCode}-${stationCode}`
  }
  return formattedPhoneNumber
}
