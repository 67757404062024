import { Epic } from 'redux-observable'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { catchError, filter, mergeMap, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { Action } from '../../../../../../../store/root.actions'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { ConsumerNetIncomeService } from '../../../../../../../api/bff/services/consumer-net-income.service'
import { ConsumerNetIncomeStepActions } from './consumer-net-income-step.actions'

const saveConsumerNetIncomeEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(ConsumerNetIncomeStepActions.saveConsumerNetIncome)),
    switchMap((action) =>
      new ConsumerNetIncomeService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .saveConsumerNetIncome(action.payload)
        .pipe(
          mergeMap(() =>
            of(ConsumerNetIncomeStepActions.saveConsumerNetIncomeSuccess())
          ),
          catchError((err: HttpError) =>
            of(ConsumerNetIncomeStepActions.saveConsumerNetIncomeFailure(err))
          )
        )
    )
  )

export const consumerNetIncomeStepEpics = [saveConsumerNetIncomeEpic]
