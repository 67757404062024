import clsx from 'clsx'
import { InputField } from '../../input-field.component'
import { EmbeddedIcon } from './components/embedded-icon/embedded-icon.component'
import { TextInputProps } from './text-input.props'
import { TextInputStyles } from './text-input.styles'

export const TextInput = (props: TextInputProps) => {
  return (
    <InputField
      label={props.label}
      name={props.name}
      error={props.error}
      errorMessage={props.errorMessage}
    >
      <div className={TextInputStyles.container}>
        <EmbeddedIcon icon={props.icon} end={props.embeddedIconEnd} />
        <input
          className={clsx(
            TextInputStyles.field(
              Boolean(props.icon),
              Boolean(props.fullWidth)
            ),
            props.error && TextInputStyles.error
          )}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          name={props.name}
          maxLength={props.maxLength}
          onChange={props.onChange}
          value={props.value}
          onKeyUp={props.onKeyUp}
          type="text"
          disabled={Boolean(props.disabled)}
          {...props.uat}
        />
      </div>
    </InputField>
  )
}
