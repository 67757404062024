export const DisclaimerMessages = {
  main: {
    id: 'MainLayout.Footer.Disclaimer.main',
    description: 'LendCare is ...'
  },
  creditCheckNotice: {
    id: 'MainLayout.Footer.Disclaimer.creditCheckNotice',
    description: 'soft-credit check...'
  },
  learnMore: {
    id: 'MainLayout.Footer.Disclaimer.learnMore',
    description: 'Learn more about'
  }
}
