import { ActionType, createAction } from 'typesafe-actions'
import {
  ConsumerWorkflowStep,
  ConsumerWorkflowSteps
} from '../../../../../../../domain/consumer/consumer-workflow/consumer-workflow-step.interface'

export enum ConsumerWorkflowStepsAction {
  SetActiveWorkflowStep = 'ConsumerWorkflowStepsAction/SetActiveWorkflowStep',
  SetWorkflow = 'ConsumerWorkflowStepsAction/SetWorkflow'
}

const setActiveWorkflowStep = createAction(
  ConsumerWorkflowStepsAction.SetActiveWorkflowStep,
  (action) => (workflowStep: ConsumerWorkflowStep) => action(workflowStep)
)

const setWorkflowSteps = createAction(
  ConsumerWorkflowStepsAction.SetWorkflow,
  (action) => (workflowSteps: ConsumerWorkflowSteps) => action(workflowSteps)
)

export const ConsumerWorkflowStepsActions = {
  setActiveWorkflowStep,
  setWorkflowSteps
}

export type ConsumerWorkflowStepsActionType = ActionType<
  typeof ConsumerWorkflowStepsActions
>
