export const ConsumerAddressViewMessages = {
  header: {
    id: 'Pages.ConsumerAddress.View.header',
    description: 'Consumer Address Page Header'
  },
  title: {
    id: 'Pages.ConsumerAddress.View.title',
    description: 'Consumer Address Page title'
  },
  autoFormSubtitle: {
    id: 'Pages.ConsumerAddress.Auto.paragraph',
    description: 'What is your address?'
  },
  manualFormSubtitle: {
    id: 'Pages.ConsumerAddress.Manual.paragraph',
    description: 'Enter you address below'
  }
}
