import { ConsumerNetIncomeStepState } from './consumer-net-income-step-state.interface'

export function createConsumerNetIncomeStepState(): ConsumerNetIncomeStepState {
  return {
    netIncome: null,
    error: null,
    loading: false,
    isWorkflowStepComplete: false
  }
}
