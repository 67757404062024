import React from 'react'
import { WebsiteLinkProps } from './website-link.props'
import { WebsiteLinkStyles } from './website-link.styles'
import { withShowIf } from '../../../helpers/with-show-if/with-show-if.component'
import { GlobeIcon } from '../../../../shared/icons/globe/globe.icon'

export const WebsiteLink = withShowIf((props: WebsiteLinkProps) => {
  return (
    <>
      <a
        {...props.uat}
        href={props.websiteLink}
        target="_blank"
        className={WebsiteLinkStyles.link(props.className)}
      >
        <GlobeIcon className={WebsiteLinkStyles.icon(props.cssLogoClasses)} />
        {props.text}
      </a>
    </>
  )
})
