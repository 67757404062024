import { PreQualifierProductLocalStorage } from './pre-qualifier-product-local-storage.enum'

export const setVendorIdToLocalStorage = (vendorId: string) => {
  localStorage.setItem(PreQualifierProductLocalStorage.VENDOR_ID, vendorId)
}

export const setProductIdToLocalStorage = (productId: string) => {
  localStorage.setItem(PreQualifierProductLocalStorage.PRODUCT_ID, productId)
}

export const getVendorIdFromLocalStorage = (): string => {
  return localStorage.getItem(PreQualifierProductLocalStorage.VENDOR_ID) || ''
}

export const getProductIdFromLocalStorage = (): string => {
  return localStorage.getItem(PreQualifierProductLocalStorage.PRODUCT_ID) || ''
}

export const clearVendorAndProductIdFromLocalStorage = () => {
  localStorage.removeItem(PreQualifierProductLocalStorage.VENDOR_ID)
  localStorage.removeItem(PreQualifierProductLocalStorage.PRODUCT_ID)
}

export const checkIfVendorAndProductIdExistInLocalStorage = (): boolean => {
  return (
    Boolean(localStorage.getItem(PreQualifierProductLocalStorage.VENDOR_ID)) &&
    Boolean(localStorage.getItem(PreQualifierProductLocalStorage.PRODUCT_ID))
  )
}
