import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../../../../../../shared/helpers/currency/currency-formatter'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { SettingsStore } from '../../../../../../../app/modules/settings/store/settings.store'
import { ConsumerNetIncomeSalarywMessages } from './consumer-net-income-salary.messages'
import { ConsumerNetIncomeSalaryProps } from './consumer-net-income-salary.props'
import { ConsumerNetIncomeSalaryStyles } from './consumer-net-income-salary.styles'
import { ConsumerNetIncomeSalaryUat } from './consumer-net-income-salary.uat'

export const ConsumerNetIncomeSalary = (
  props: ConsumerNetIncomeSalaryProps
) => {
  const locale = useSelector(SettingsStore.getLocaleSetting)

  const calculateBiWeekly = () => {
    return Math.round(calculateAnnually() / 26)
  }

  const calculateAnnually = () => {
    return props.netMonthlyIncome * 12
  }

  return (
    <div className={ConsumerNetIncomeSalaryStyles.root}>
      <Paragraph className={ConsumerNetIncomeSalaryStyles.header}>
        <FormattedMessage {...ConsumerNetIncomeSalarywMessages.header} />
      </Paragraph>

      <div className={ConsumerNetIncomeSalaryStyles.container}>
        <div className={ConsumerNetIncomeSalaryStyles.column}>
          <Paragraph
            className={ConsumerNetIncomeSalaryStyles.secondaryText}
            uat={uatId(ConsumerNetIncomeSalaryUat.biWeeklyValue)}
          >
            {formatCurrency(locale, calculateBiWeekly())}
          </Paragraph>
          <Paragraph
            className={ConsumerNetIncomeSalaryStyles.label}
            uat={uatId(ConsumerNetIncomeSalaryUat.biWeeklyLabel)}
          >
            <FormattedMessage
              {...ConsumerNetIncomeSalarywMessages.labelBiWeekly}
            />
          </Paragraph>
        </div>
        <div className={ConsumerNetIncomeSalaryStyles.column}>
          <Paragraph
            className={ConsumerNetIncomeSalaryStyles.secondaryText}
            uat={uatId(ConsumerNetIncomeSalaryUat.monthlyValue)}
          >
            {formatCurrency(locale, props.netMonthlyIncome)}
          </Paragraph>
          <Paragraph
            className={ConsumerNetIncomeSalaryStyles.label}
            uat={uatId(ConsumerNetIncomeSalaryUat.monthlyLabel)}
          >
            <FormattedMessage
              {...ConsumerNetIncomeSalarywMessages.labelMonthly}
            />
          </Paragraph>
        </div>
        <div className={ConsumerNetIncomeSalaryStyles.column}>
          <Paragraph
            className={ConsumerNetIncomeSalaryStyles.secondaryText}
            uat={uatId(ConsumerNetIncomeSalaryUat.annuallyLabel)}
          >
            {formatCurrency(locale, calculateAnnually())}
          </Paragraph>
          <Paragraph
            className={ConsumerNetIncomeSalaryStyles.label}
            uat={uatId(ConsumerNetIncomeSalaryUat.annuallyValue)}
          >
            <FormattedMessage
              {...ConsumerNetIncomeSalarywMessages.labelAnnually}
            />
          </Paragraph>
        </div>
      </div>
    </div>
  )
}
