import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerEditPhoneNumberState } from '../types/consumer-edit-phone-number-state.interface'

export enum ConsumerEditPhoneNumberAction {
  EditPhoneNumber = 'ConsumerEditPhoneNumber/EditPhoneNumber',
  EditPhoneNumberFailure = 'ConsumerEditPhoneNumber/EditPhoneNumberFailure',
  EditPhoneNumberSuccess = 'ConsumerEditPhoneNumber/EditPhoneNumberSuccess',
  ResetEditPhoneNumberState = 'ConsumerEditPhoneNumber/ResetEditPhoneNumberState'
}

const editPhoneNumber = createAction(
  ConsumerEditPhoneNumberAction.EditPhoneNumber,
  (action) =>
    (phoneNumber: NonNullable<ConsumerEditPhoneNumberState['phoneNumber']>) =>
      action(phoneNumber)
)

const editPhoneNumberSuccess = createAction(
  ConsumerEditPhoneNumberAction.EditPhoneNumberSuccess
)

const editPhoneNumberFailure = createAction(
  ConsumerEditPhoneNumberAction.EditPhoneNumberFailure,
  (action) => (error: NonNullable<ConsumerEditPhoneNumberState['error']>) =>
    action(error)
)

const resetEditPhoneNumberState = createAction(
  ConsumerEditPhoneNumberAction.ResetEditPhoneNumberState
)

export const ConsumerEditPhoneNumberActions = {
  editPhoneNumber,
  editPhoneNumberSuccess,
  editPhoneNumberFailure,
  resetEditPhoneNumberState
}

export type ConsumerEditPhoneNumberActionType = ActionType<
  typeof ConsumerEditPhoneNumberActions
>
