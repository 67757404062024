export const MerchantSelectionConfirmDialogViewMessages = {
  title: {
    id: 'Pages.MerchantSelection.MerchantSelectionConfirmDialogView.title',
    description: 'Merchant Selection Confirm Dialog Title'
  },
  cancelButtonLabel: {
    id: 'Pages.MerchantSelection.MerchantSelectionConfirmDialogView.cancelButtonLabel',
    description: 'Merchant Selection Confirm Dialog Cancel button label'
  },
  selectButtonLabel: {
    id: 'Pages.MerchantSelection.MerchantSelectionConfirmDialogView.selectButtonLabel',
    description: 'Merchant Selection Confirm Dialog Select button label'
  }
}
