import React from 'react'
import { LocalePicker } from './components/locale-picker/locale-picker.component'
import { Disclaimer } from './components/disclaimer/disclaimer.component'
import { FooterProps } from './footer.props'
import { FooterStyles } from './footer.styles'
import { withHideIf } from '../../../../../ui-library/helpers/with-hide-if/with-hide-if.component'
import { useSelector } from 'react-redux'
import { SettingsStore } from '../../../../../modules/app/modules/settings/store/settings.store'
import { LocaleHelper } from '../../../../helpers/locale-helper'
import { Language } from '../../../../types/language.enum'

export const Footer = withHideIf((props: FooterProps) => {
  const locale = useSelector(SettingsStore.getLocaleSetting)

  const changeLocale = () => {
    if (LocaleHelper.isEnglish(locale)) {
      SettingsStore.setLocaleSetting(Language.French)
    }
    if (LocaleHelper.isFrench(locale)) {
      SettingsStore.setLocaleSetting(Language.English)
    }
}
  return (
  <footer className={FooterStyles.footer}>
    <div className={FooterStyles.footerContent}>
      <LocalePicker changeLocale={changeLocale} />
      <Disclaimer locale={locale} alignCenter={props.centerAlignDisclaimer} />
    </div>
  </footer>
  )
})
