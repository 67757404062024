import { PaymentFrequency } from '../../../types/payment-frequency'
import { MessageDescriptor } from '../../../../../../../../../shared/types/message-descriptor'
import { OfferMessages } from '../offer.messages'

export function getPaymentTypeHeader(
  paymentFrequency: PaymentFrequency
): MessageDescriptor {
  switch (paymentFrequency) {
    case PaymentFrequency.Weekly:
      return OfferMessages.paymentWeeklyHeader
    case PaymentFrequency.BiWeekly:
      return OfferMessages.paymentBiWeeklyHeader
    default:
      return OfferMessages.paymentMonthlyHeader
  }
}
