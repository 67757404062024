import { PreQualifierAssetTypeSelectionState } from './pre-qualifier-asset-type-selection-state.interface'

export function createPreQualifierAssetTypeSelectionState(): PreQualifierAssetTypeSelectionState {
  return {
    assetTypes: [],
    fetchError: null,
    loading: false,
    selectedAssetType: null
  }
}
