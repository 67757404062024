import { ConsumerAutoAddressState } from './consumer-auto-address-state.interface'

export const createConsumerAutoAddressState = (): ConsumerAutoAddressState => {
  return {
    isWorkflowStepComplete: false,
    lookupAddresses: null,
    lookupAddressesFailure: null,
    lookupAddressesLoading: false,
    retrieveAddress: null,
    retrieveAddressFailure: null,
    retrieveAddressLoading: false,
    saveAddressLoading: false,
    saveAddressFailure: null
  }
}
