import { FormSchema } from '../../../../../../../../../../../../libraries/form-schema/form-schema'
import { ConsumerPhoneValidationSectionFormSchema } from './types/consumer-phone-validation-section-form-schema.interface'
import { NumberFieldSchema } from '../../../../../../../../../../../../libraries/form-schema/form-field-schema/number-field-schema'

export function generateConsumerPhoneValidationSectionFormSchema(): FormSchema<ConsumerPhoneValidationSectionFormSchema> {
  return new FormSchema<ConsumerPhoneValidationSectionFormSchema>({
    verificationCode: new NumberFieldSchema('verificationCode')
      .setMin(100000)
      .setMax(999999)
      .isRequired()
  })
}
