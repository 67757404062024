export const ConsumerDobViewMessages = {
  header: {
    id: 'Pages.ConsumerDob.View.header',
    description: 'Consumer Date Of Birth Page Header'
  },
  minimumAge: {
    id: 'Pages.ConsumerDob.View.minimumAge',
    description: 'make sure you’re at least 18'
  },
  name: {
    id: 'Pages.ConsumerDob.View.name',
    description: 'Sounds great {name}!'
  },
  born: {
    id: 'Pages.ConsumerDob.View.born',
    description: 'When were you born?'
  }
}
