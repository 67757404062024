import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { SelectField } from '../../../../../../ui-library/components/select-field/select-field.component'
import { AssetSubtypesSelectFieldMessages } from './asset-subtypes-select-field.messages'
import { AssetSubtypesSelectFieldProps } from './asset-subtypes-select-field.props'
import { AssetSubtype } from '../../../../../../domain/asset-subtype/asset-subtype.interface'
import { AssetSubtypesSelectFieldStyles } from './asset-subtypes-select-field.styles'

export const AssetSubtypesSelectField = (props: AssetSubtypesSelectFieldProps) => {
  const intl = useIntl()

  const [selectedAssetSubtype, setSelectedAssetSubtype] = useState<AssetSubtype['id']>(
    props.assetSubtype
  )

  useEffect(() => {
    setSelectedAssetSubtype(props.assetSubtype);
  }, [props.assetSubtype]);

  const onChange = (selectedAssetSubtypeId: string): void => {
    setSelectedAssetSubtype(selectedAssetSubtypeId)
    props.onChange(selectedAssetSubtypeId)
  }

  const getAssetSubtypes = () => props.assetSubtypes ?? []

  return (
    <div className={props.className || AssetSubtypesSelectFieldStyles.container}>
      <SelectField
        name="asset-subtypes"
        onChange={onChange}
        optionLabelKey={'name'}
        optionValueKey={'id'}
        value={selectedAssetSubtype}
        options={getAssetSubtypes()}
        placeholder={props.placeholder ?? intl.formatMessage(AssetSubtypesSelectFieldMessages.placeholder)}
        className={props.fieldClassName}
        uat={props.fieldUat}
        optionListItemUat={props.itemUat}
      />
    </div>
  )
}
