export const ConsumerEditPhoneNumberSectionFormStyles = {
  formContainer: (hasError: boolean) =>
    `mt-6 ${
      !hasError ? 'mb-6' : ''
    } flex flex-col w-full sm:flex-row sm:w-auto sm:justify-center`,
  errorBanner: 'mb-4',
  buttonContainer: 'flex mt-auto',
  primaryButton: 'text-xl sm:text-2xl mr-4',
  cancelButton: 'text-xl sm:text-2xl'
}
