import clsx from 'clsx'
import { ChangeEvent } from 'react'
import { isPositiveInteger } from '../../../../helpers/is-integer/is-integer.function'
import { InputField } from '../../input-field.component'
import { NumberInputProps } from './number-input.props'
import { NumberInputStyles } from './number-input.styles'

export const NumberInput = (props: NumberInputProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = event.target.value

    if (!isPositiveInteger(inputValue)) {
      return
    }

    props.onChange(inputValue)
  }

  return (
    <InputField
      label={props.label}
      name={props.name}
      containerClassName={props.containerClassName}
      error={props.error}
      errorMessage={props.errorMessage}
      labelClassName={props.labelClassName}
    >
      <input
        autoFocus={props.autoFocus}
        className={clsx(
          NumberInputStyles.field,
          props.error && NumberInputStyles.error,
          props.inputClassName
        )}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        name={props.name}
        onChange={onChange}
        value={props.value}
        type="tel"
      />
    </InputField>
  )
}
