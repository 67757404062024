import { ChangeEvent, useState } from 'react'
import { InputField } from '../../input-field.component'
import { PostalCodeInputProps } from './postal-code-input.props'
import { PostalCodeInputStyles } from './postal-code-input.styles'
import {
  postalCodeInputMask,
  removePostalCodeInputMaskFormat,
  stopCaretJumpingToEndOfLine
} from './types/postal-code-input.functions'

export const PostalCodeInput = (props: PostalCodeInputProps) => {
  const [postalCode, setPostalCode] = useState<string>(props.postalCode)

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = event.target.value.toUpperCase()
    const postalCodeValue: string = removePostalCodeInputMaskFormat(inputValue)

    const isUserInsertingNumberToField: boolean =
      inputValue.length > postalCode.length
    const { inputMask, moveCaretForwardBy } =
      postalCodeInputMask(postalCodeValue)

    if (isUserInsertingNumberToField) {
      stopCaretJumpingToEndOfLine(moveCaretForwardBy, event)
      setPostalCode(inputMask)
      props.onChange(postalCodeValue)
    } else {
      stopCaretJumpingToEndOfLine(0, event)
      setPostalCode(inputMask)
      props.onChange(postalCodeValue)
    }
  }

  return (
    <InputField label={props.label} name={props.name} fullWidth>
      <input
        className={PostalCodeInputStyles.field}
        name={props.name}
        maxLength={7}
        onChange={onChange}
        value={postalCode}
        type="text"
        {...props.uat}
      />
    </InputField>
  )
}
