import { Reducer } from 'redux'
import { createConsumerPhoneNumberStepState } from '../types/consumer-phone-number-step-state.functions'
import { ConsumerPhoneNumberStepState } from '../types/consumer-phone-number-step-state.interface'
import {
  ConsumerPhoneNumberStepAction,
  ConsumerPhoneNumberStepActionType
} from './consumer-phone-number-step.actions'

export const consumerPhoneNumberStepReducer: Reducer<
  ConsumerPhoneNumberStepState,
  ConsumerPhoneNumberStepActionType
> = (
  state: ConsumerPhoneNumberStepState = createConsumerPhoneNumberStepState(),
  action: ConsumerPhoneNumberStepActionType
): ConsumerPhoneNumberStepState => {
  switch (action.type) {
    case ConsumerPhoneNumberStepAction.SaveConsumerPhoneNumber:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ConsumerPhoneNumberStepAction.SaveConsumerPhoneNumberSuccess:
      return {
        ...state,
        loading: false,
        isWorkflowStepComplete: true
      }
    case ConsumerPhoneNumberStepAction.SaveConsumerPhoneNumberFailure:
      return {
        ...state,
        loading: false,
        isWorkflowStepComplete: false,
        error: action.payload
      }
    default:
      return state
  }
}
