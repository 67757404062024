import { Action } from 'redux'
import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { LookupAddress } from '../../../../../../../../../../../domain/lookup-address/lookup-address.interface'
import { RetrieveAddress } from '../../../../../../../../../../../domain/lookup-address/retrieve-address.interface'
import { HttpError } from '../../../../../../../../../../../shared/types/http-error.interface'
import { RootState } from '../../../../../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerAddressServiceFactory } from '../../../../../services/consumer-address-factory.service'
import { ConsumerAutoAddressActions } from './consumer-auto-address.actions'

const lookupAddress: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(ConsumerAutoAddressActions.lookupAddress)),
    switchMap((action) =>
      new ConsumerAddressServiceFactory()
        .create(state$.value)
        .lookupAddress(action.payload)
        .pipe(
          map((address: LookupAddress[]) =>
            ConsumerAutoAddressActions.lookupAddressSuccess(address)
          ),
          catchError((err: HttpError) =>
            of(ConsumerAutoAddressActions.lookupAddressFailure(err))
          )
        )
    )
  )

const retrieveAddress: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(ConsumerAutoAddressActions.retrieveAddress)),
    switchMap((action) =>
      new ConsumerAddressServiceFactory()
        .create(state$.value)
        .retrieveAddress(action.payload)
        .pipe(
          map((address: RetrieveAddress) =>
            ConsumerAutoAddressActions.retrieveAddressSuccess(address)
          ),
          catchError((err: HttpError) =>
            of(ConsumerAutoAddressActions.retrieveAddressFailure(err))
          )
        )
    )
  )

const saveAutoAddress: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(ConsumerAutoAddressActions.saveAutoAddress)),
    switchMap((action) =>
      new ConsumerAddressServiceFactory()
        .create(state$.value)
        .saveAddress(action.payload)
        .pipe(
          map(() => ConsumerAutoAddressActions.saveAutoAddressSuccess()),
          catchError((err: HttpError) =>
            of(ConsumerAutoAddressActions.saveAutoAddressFailure(err))
          )
        )
    )
  )

export const consumerAutoAddressEpics = [
  lookupAddress,
  retrieveAddress,
  saveAutoAddress
]
