import { AssetSubtypes } from '../../../domain/asset-subtype/asset-subtypes.interface'
import { Language } from '../../../shared/types/language.enum'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'

export class PreQualifierAssetSubtypeService extends BffService {
  public fetchAssetSubtypes(
    locale: Language,
  ): FetchResponse<AssetSubtypes> {
    return super.createAuthorizedRequest<AssetSubtypes>({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/asset-subtypes`,
      locale
    })
  }
}
