import { Language } from '../types/language.enum'

export class LocaleHelper {
  static isFrench(code?: string): boolean {
    return Boolean(code && code === Language.French)
  }

  static isEnglish(code?: string): boolean {
    return Boolean(code && code === Language.English)
  }

  static determineLocale(value: string | null): Language {
    const locale = value?.slice(0, 2).toLowerCase()
    if (LocaleHelper.isFrench(locale)) {
      return Language.French
    }
    return Language.English
  }
}
