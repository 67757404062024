import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerProfileAssetOverviewMessages } from './consumer-profile-asset-overview.messages'
import { ConsumerProfileAssetOverviewProps } from './consumer-profile-asset-overview.props'
import { ConsumerProfileAssetOverviewStyles } from './consumer-profile-asset-overview.styles'
import { ConsumerProfileAssetOverviewUat } from './consumer-profile-asset-overview.uat'


export const ConsumerProfileAssetOverview = (
  props: ConsumerProfileAssetOverviewProps
) => {
  return (
    <div className={ConsumerProfileAssetOverviewStyles.container}>
      <PrimaryButton
        onClick={props.onComplete}
        cssContainerClasses={ConsumerProfileAssetOverviewStyles.submitButton}
        uat={uatId(ConsumerProfileAssetOverviewUat.submitButton)}
      >
        <FormattedMessage
          {...ConsumerProfileAssetOverviewMessages.submitButton}
        />
      </PrimaryButton>
    </div>
  )
}
