import { FormattedMessage } from 'react-intl'
import { LocalePickerProps } from './locale-picker.props'
import { LocalePickerStyles } from './locale-picker.styles'
import { LocalePickerMessages } from './locale-picker.messages'
import { localePickerUat } from './locale-picker.uat'
import { uatId } from '../../../../../../../uat/uat-id.function'

export const LocalePicker = (props: LocalePickerProps) => (
  <div className={LocalePickerStyles.container}>
    <button
      onClick={props.changeLocale}
      className={LocalePickerStyles.button}
      {...uatId(localePickerUat.button)}
    >
      <FormattedMessage {...LocalePickerMessages.button} />
    </button>
  </div>
)
