import React from 'react'
import { useIntl } from 'react-intl'
import { ConsumerEmailErrorMessages } from './consumer-email-error.messages'
import { BannerTextType } from '../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { BannerText } from '../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { withShowIf } from '../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerEmailErrorStyles } from './consumer-email-error.styles'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { ConsumerEmailErrorUat } from './consumer-email-error.uat'

export const ConsumerEmailError = withShowIf(() => {
  const intl = useIntl()

  return (
    <BannerText
      uat={uatId(ConsumerEmailErrorUat.error)}
      cssContainerClasses={ConsumerEmailErrorStyles.banner}
      type={BannerTextType.DANGER}
      text={intl.formatMessage(ConsumerEmailErrorMessages.general)}
    />
  )
})
