import { Language } from '../../types/language.enum'
import { MinFractionDigits } from './min-fraction-digits'

export function formatCurrency(
  locale: Language,
  value: number,
  minFractionDigits: MinFractionDigits = 0
): string {
  return new Intl.NumberFormat(`${locale}-CA`, {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: minFractionDigits,
    maximumFractionDigits: 2
  })
    .format(value)
    .replace(/\s*CA\s*/, '')
}
