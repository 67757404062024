import { ActionType, createAction } from 'typesafe-actions'
import { ConsumerSession } from '../../../../../domain/consumer/consumer-session/consumer-session.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { ConsumerSessionState } from '../types/consumer-session-state.interface'

export enum ConsumerSessionAction {
  SetSessionToken = 'ConsumerSessionAction/SetSessionToken',
  GetSessionTokenFailure = 'ConsumerSessionAction/GetSessionTokenFailure',
  FetchConsumerSession = 'ConsumerSessionAction/FetchConsumerSession',
  SetIsLoading = 'ConsumerSessionAction/SetIsLoading',
  FetchConsumerSessionSuccess = 'ConsumerSessionAction/FetchConsumerSessionSuccess',
  FetchConsumerSessionFailure = 'ConsumerSessionAction/FetchConsumerSessionFailure'
}

const setSessionToken = createAction(
  ConsumerSessionAction.SetSessionToken,
  (action) => (sessionToken: ConsumerSessionState['sessionToken']) => {
    return action(sessionToken)
  }
)

const getSessionTokenFailure = createAction(
  ConsumerSessionAction.GetSessionTokenFailure,
  (action) => (error: ConsumerSessionState['getSessionTokenError']) => {
    return action(error)
  }
)

const fetchConsumerSession = createAction(
  ConsumerSessionAction.FetchConsumerSession
)

const fetchConsumerSessionSuccess = createAction(
  ConsumerSessionAction.FetchConsumerSessionSuccess,
  (action) => (consumerSession: ConsumerSession) => {
    return action(consumerSession)
  }
)

const fetchConsumerSessionFailure = createAction(
  ConsumerSessionAction.FetchConsumerSessionFailure,
  (action) => (error: HttpError) => action(error)
)

const setIsLoading = createAction(ConsumerSessionAction.SetIsLoading)

export const ConsumerSessionActions = {
  setSessionToken,
  fetchConsumerSession,
  fetchConsumerSessionSuccess,
  fetchConsumerSessionFailure,
  getSessionTokenFailure,
  setIsLoading
}

export type ConsumerSessionActionType = ActionType<
  typeof ConsumerSessionActions
>
