import { withShowIf } from '../../../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import React from 'react'
import { ConsumerPhoneValidationSectionErrorProp } from './consumer-phone-validation-section-error.prop'
import { ConsumerPhoneValidationErrorBadRequest } from './components/consumer-phone-validation-error-bad-request/consumer-phone-validation-error-bad-request.component'
import { ConsumerPhoneValidationErrorGeneral } from './components/consumer-phone-validation-error-general/consumer-phone-validation-error-general.component'
import { uatId } from '../../../../../../../../../../../../uat/uat-id.function'

export const ConsumerPhoneValidationStandardError = withShowIf(
  (props: ConsumerPhoneValidationSectionErrorProp): JSX.Element => {
    if(!props.errorMessage){
      return <ConsumerPhoneValidationErrorGeneral />
    }

    return (
      <ConsumerPhoneValidationErrorBadRequest
        uat={uatId(props.uatId)}
        error={props.errorMessage}
      />
    )
  }
)
