import { Reducer } from 'redux'
import {
  PreQualifierLoadingProductAction,
  PreQualifierLoadingProductActionType
} from './pre-qualifier-loading-product.actions'
import { createPreQualifierLoadingProductState } from '../types/pre-qualifier-loading-product-state.functions'
import { PreQualifierLoadingProductState } from '../types/pre-qualifier-loading-product-state.interface'

export const preQualifierLoadingProductReducer: Reducer<
  PreQualifierLoadingProductState,
  PreQualifierLoadingProductActionType
> = (
  state: PreQualifierLoadingProductState = createPreQualifierLoadingProductState(),
  action: PreQualifierLoadingProductActionType
): PreQualifierLoadingProductState => {
  switch (action.type) {
    case PreQualifierLoadingProductAction.FetchProductDetails:
      return {
        ...state,
        productSku: action.payload.sku,
        error: undefined,
        isLoading: true
      }
    case PreQualifierLoadingProductAction.FetchProductDetailsSuccess:
      return {
        ...state,
        productDetails: action.payload,
        error: undefined,
        isLoading: false
      }
    case PreQualifierLoadingProductAction.FetchProductDetailsFailure:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    default:
      return state
  }
}
