import { truncate } from './truncate.function'

export const truncateConsumerName = (name: string | null): string => {
  if (name === null) {
    return ''
  } else {
    const consumerNameCutOffPoint: number = 18
    return truncate(name, consumerNameCutOffPoint)
  }
}
