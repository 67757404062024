export const ConsumerSubmitFormMessages = {
  authorizedElectronicCommunicationConsentLabel: {
    id: 'Pages.ConsumerSubmit.Form.authorizedElectronicCommunicationConsentLabel',
    description: 'Submit consent label'
  },
  authorizedElectronicCommunicationConsentText: {
    id: 'Pages.ConsumerSubmit.Form.authorizedElectronicCommunicationConsentText',
    description: 'Submit consent text'
  },
  creditConsentLabel: {
    id: 'Pages.ConsumerSubmit.Form.creditConsentLabel',
    description: 'Submit credit consent label'
  },
  creditConsentText: {
    id: 'Pages.ConsumerSubmit.Form.creditConsentText',
    description: 'Submit credit consent text'
  },
  viewButton: {
    id: 'Pages.ConsumerSubmit.Form.viewButton',
    description: 'View consent link text'
  },
  submitButton: {
    id: 'Pages.ConsumerSubmit.Form.submitButton',
    description: 'Submit'
  },
  viewConsentDetails: {
    id: 'Pages.ConsumerSubmit.Form.viewConsentDetails',
    description: 'Submit'
  },
  consentLink: {
    id: 'Pages.ConsumerSubmit.Form.consentLink',
    description: 'Consent link'
  }
}
