import Pusher, { Channel } from 'pusher-js/with-encryption'

export class EventBusChannel {
  private channel: Channel
  constructor(name: string, pusher: Pusher) {
    this.channel = pusher.subscribe(name)
  }

  public bindToAll<T>(callback: (event: T, payload: any) => any): void {
    this.channel.bind_global(callback)
  }
}
