import { DEFAULT_VENDOR_ID } from '../../../../../domain/consumer/consumer-profile/types/consumer-profile-default-vendor-id'
import { ConsumerProfileState } from './consumer-profile-state.interface'

export const createConsumerProfileState = (): ConsumerProfileState => {
  return {
    consumerProfileId: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    assetSubtypeId: null,
    assetTypeId: null,
    dateOfBirth: null,
    email: null,
    address: null,
    netIncome: null,
    authorizedElectronicCommunication: false,
    creditConsent: false,
    preApprovalStatus: null,
    preApprovalAmount: null,
    referenceCode: null,
    isPreApprovalApplicationReassigned: false,
    canViewOfferPage: false,
    consumerProfileAsset: null,
    vendorId: DEFAULT_VENDOR_ID,
    vendor: null,
    refId: null,
    bannerUrl: null,
    preQualifierAssetTypeUiConfig: {
      assetTypeImageTitle: {en: '', fr: ''},
      assetTypeImageUrl: '',
    },
    postSubmissionConfig: {
      pages: []
    }
  }
}
