import React from 'react'
import { useIntl } from 'react-intl'
import { ConsumerNetIncomeErrorMessages } from './consumer-net-income-error.messages'
import { BannerTextType } from '../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { BannerText } from '../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { withShowIf } from '../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerNetIncomeErrorStyles } from './consumer-net-income-error.styles'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { ConsumerNetIncomeErrorUat } from './consumer-net-income-error.uat'

export const ConsumerNetIncomeError = withShowIf(() => {
  const intl = useIntl()

  return (
    <BannerText
      uat={uatId(ConsumerNetIncomeErrorUat.error)}
      cssContainerClasses={ConsumerNetIncomeErrorStyles.banner}
      type={BannerTextType.DANGER}
      text={intl.formatMessage(ConsumerNetIncomeErrorMessages.general)}
    />
  )
})
