import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerEditPhoneNumberSectionView } from '../../modules/consumer-edit-phone-number/components/consumer-edit-phone-number-section-view/consumer-edit-phone-number-section-view.component'
import { ConsumerEditPhoneNumberStore } from '../../modules/consumer-edit-phone-number/store/consumer-edit-phone-number.store'
import { ConsumerPhoneValidationStepStore } from '../../store/consumer-phone-validation-step.store'
import { ConsumerPhoneValidationSectionView } from './components/consumer-phone-validation-section-view/consumer-phone-validation-section-view.component'
import {
  isConsumerEditPhoneNumberSection,
  isConsumerPhoneValidationSection
} from './components/consumer-phone-validation-section-view/types/consumer-phone-validation-section-view.functions'
import { ConsumerPhoneValidationSection } from './components/consumer-phone-validation-section-view/types/consumer-phone-validation-section.enum'
import { ConsumerPhoneValidationViewMessage } from './consumer-phone-validation-view.message'
import { ConsumerPhoneValidationViewProps } from './consumer-phone-validation-view.props'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { useSelector } from 'react-redux'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { formatToPhoneNumber } from '../../../../../../../../ui-library/components/input-field/components/phone-input/types/format-to-phone-number.function'

export const ConsumerPhoneValidationView = (
  props: ConsumerPhoneValidationViewProps
) => {
  const intl = useIntl();
  const [currentSection, setCurrentSection] =
    useState<ConsumerPhoneValidationSection>(
      ConsumerPhoneValidationSection.PHONE_NUMBER_VALIDATION_SECTION
  );

  const phoneNumber: ConsumerProfileState['phoneNumber'] =
      useSelector(ConsumerProfileStore.getConsumerPhoneNumber) ?? ''

  const setSectionToEditPhoneNumber = () => {
    ConsumerPhoneValidationStepStore.resetPhoneValidationState()
    ConsumerPhoneValidationStepStore.resetResendVerificationCodeState()
    setCurrentSection(ConsumerPhoneValidationSection.EDIT_PHONE_NUMBER_SECTION)
  }
  const setSectionToPhoneValidation = () => {
    ConsumerEditPhoneNumberStore.resetEditPhoneNumberState()
    setCurrentSection(
      ConsumerPhoneValidationSection.PHONE_NUMBER_VALIDATION_SECTION
    )
  }

  return (
    <>
      <PrimaryHeader
        showBackIcon={props.step.rules.showBackButton}
        goBack={props.goBack}
      />

      <ConsumerProfileAssetImageContainer
        showIf={isConsumerPhoneValidationSection(currentSection)}
        titleMessage={intl.formatMessage(ConsumerPhoneValidationViewMessage.title)}
        subTitleMessage={intl.formatMessage(ConsumerPhoneValidationViewMessage.verifyPhoneSubTitle, {phoneNumber: formatToPhoneNumber(phoneNumber)})}
        noHorizontalPadding
        showFooter
      >
        <ConsumerPhoneValidationSectionView
          showIf={isConsumerPhoneValidationSection(currentSection)}
          setSectionToUpdatePhoneNumber={setSectionToEditPhoneNumber}
          onComplete={props.onComplete}
        />
      </ConsumerProfileAssetImageContainer>

      <ConsumerProfileAssetImageContainer
        showIf={isConsumerEditPhoneNumberSection(currentSection)}
        titleMessage={intl.formatMessage(ConsumerPhoneValidationViewMessage.editPhoneNumberTitle)}
        noHorizontalPadding
        showFooter
      >
        <ConsumerEditPhoneNumberSectionView
          showIf={isConsumerEditPhoneNumberSection(currentSection)}
          setSectionToPhoneValidation={setSectionToPhoneValidation}
        />
      </ConsumerProfileAssetImageContainer>

    </>
  )
}
