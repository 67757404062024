import React from 'react'
import clsx from 'clsx'
import { SpinnerIcon } from '../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { PrimaryButtonProps } from './primary-button.props'
import {
  primaryButtonContainerStyle,
  primaryButtonStyle
} from './primary-button.styles'

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const showButton: boolean = !props.loading ?? true
  return (
    <div
      className={primaryButtonContainerStyle(
        props.cssContainerClasses,
        props.fullWidth,
        props.minimumWidth
      )}
    >
      <button
        {...props.uat}
        onClick={props.onClick}
        className={clsx(
          primaryButtonStyle(showButton, props.disabled),
          props.cssButtonClasses
        )}
        disabled={props.disabled}
      >
        {props.children}
      </button>
      <SpinnerIcon showIf={!showButton} size={SpinnerIconSize.SMALL} />
    </div>
  )
}
