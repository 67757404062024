import { Marker } from 'react-map-gl'
import { MarkerIcon } from '../../../../shared/icons/marker/marker.icon'
import { MapLibraryMarkerProps } from './map-library-marker.props'

export const MapLibraryMarker = (props: MapLibraryMarkerProps) => {
  const onClick = () => props.setSelectedMarker(props.place)

  return (
    <Marker
      latitude={props.place.coordinates.latitude}
      longitude={props.place.coordinates.longitude}
      anchor={'bottom'}
      onClick={onClick}
    >
      <MarkerIcon />
    </Marker>
  )
}
