import { components } from 'react-select'
import { UatDataset } from '../../../../uat/types/uat-dataset.interface'
import { DropdownLibraryControlProps } from './dropdown-library-control.props'

export const DropdownLibraryControl =
  (uat?: UatDataset) => (props: DropdownLibraryControlProps) => {
    return (
      <components.Control
        {...props}
        innerProps={{ ...props.innerProps, ...uat } as any}
      >
        {props.children}
      </components.Control>
    )
  }
