import { PreQualifierAssetTypeImageTitle } from '../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type-ui-config/pre-qualifier-asset-type-image-title/pre-qualifier-asset-type-image-title.interface';
import { Language } from '../../types/language.enum';

export const getLocalizedAssetName = (locale: Language, assetTitle: PreQualifierAssetTypeImageTitle) => {
    if (locale === Language.English && assetTitle.en) {
        return assetTitle.en;
    }
    if (locale === Language.French && assetTitle.fr) {
        return assetTitle.fr;
    }
    return '';
}

export const isAssetTitleValid = (assetTitle: PreQualifierAssetTypeImageTitle | null) => {
    return Boolean(assetTitle && assetTitle.en !== '' && assetTitle.fr !== '');
}