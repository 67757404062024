import { Duration, intervalToDuration, parse } from 'date-fns'
import * as yup from 'yup'
import { ValidationError } from '../types/validation-error'
import { FormFieldSchema } from './form-field-schema'
import { DefaultSchemaOptions } from './types/form-field-schema-options/default-schema-options.interface'
import { FormFieldSchemaType } from './types/form-field-schema-type.enum'

export class DobFieldSchema extends FormFieldSchema {
  protected schema = yup.mixed()
  protected type: FormFieldSchemaType = FormFieldSchemaType.DOB

  private dobFormat: string = ''
  private youngestAge: number = 0
  private oldestYear: number = 0

  public getSchemaOptions(): DefaultSchemaOptions {
    return {
      name: this.name,
      type: this.type,
      required: this.required
    }
  }

  public setDobFormat(
    dobFormat: string,
    errorMessageId: string = 'invalid-dob-format'
  ): this {
    this.dobFormat = dobFormat
    return this.verifyDateFormat(errorMessageId)
  }

  public setOldestYear(
    year: number,
    errorMessageId: string = 'oldest-year-requirement'
  ): this {
    this.oldestYear = year
    return this.verifyOldestYear(errorMessageId)
  }

  public setYoungestAge(
    age: number,
    errorMessageId: string = 'youngest-age-requirement'
  ): this {
    this.youngestAge = age
    return this.verifyYoungestAge(errorMessageId)
  }

  private verifyDateFormat(errorMessageId: string): this {
    this.schema = this.schema.transform(
      (value: string, originalValue: string) => {
        const date: Date = parse(originalValue, this.dobFormat, new Date())
        return date
      }
    )

    this.schema = this.schema.test((value: Date): boolean | ValidationError => {
      const result = this.isDateInstanceValid(value)
      if (result) {
        return true
      }
      return new ValidationError(errorMessageId)
    })
    return this
  }

  private verifyYoungestAge(errorMessageId: string): this {
    this.schema = this.schema.test((value: Date): boolean | ValidationError => {
      if (this.isDobDateValid(value)) {
        const interval: Duration = intervalToDuration({
          start: value,
          end: new Date()
        })
        const isOldEnough = (interval.years ?? 0) >= this.youngestAge
        return isOldEnough ? true : new ValidationError(errorMessageId)
      }
      return new ValidationError(errorMessageId)
    })
    return this
  }

  private verifyOldestYear(errorMessageId: string): this {
    this.schema = this.schema.test((value: Date): boolean | ValidationError => {
      if (this.isDobDateValid(value)) {
        const isDobYearValid = this.oldestYear <= value.getFullYear()
        return isDobYearValid ? true : new ValidationError(errorMessageId)
      }
      return new ValidationError(errorMessageId)
    })
    return this
  }

  private isDobDateValid(date: Date): boolean {
    return (
      this.isDateInstanceValid(date) && this.inputDateDoesntExceedToday(date)
    )
  }

  private isDateInstanceValid(date: Date): boolean {
    return !isNaN(date.getTime())
  }

  private inputDateDoesntExceedToday(inputDate: Date): boolean {
    return new Date().getTime() >= inputDate.getTime()
  }
}
