import React from 'react'
import { AddressLinkProps } from './address-link.props'
import { AddressLinkStyles } from './address-link.styles'
import { withShowIf } from '../../../helpers/with-show-if/with-show-if.component'
import { PinIcon } from '../../../../shared/icons/pin/pin.icon'

export const AddressLink = withShowIf((props: AddressLinkProps) => {
  return (
    <>
      <a
        {...props.uat}
        target="_blank"
        href={`https://maps.google.com/maps?q=${props.address}`}
        className={AddressLinkStyles.link(props.className)}
      >
        <PinIcon className={AddressLinkStyles.icon(props.cssLogoClasses)} />
        {props.text}
      </a>
    </>
  )
})
