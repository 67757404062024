import { Modal } from '../../../../../../../../ui-library/components/modal/modal.component'
import { QuestionsDialogContent } from '../questions-dialog-content/questions-dialog-content.component'
import { SecurityQuestionsModalProps } from './security-questions-modal-props'

export const SecurityQuestionsModal = (props: SecurityQuestionsModalProps) => {
  return (
    <Modal open={props.isOpen}>
      <QuestionsDialogContent
        securityQuestions={props.securityQuestions}
        attemptsRemaining={props.attemptsRemaining}
        onSubmit={props.onSubmit}
        error={props.error}
        changeLocale={props.changeLocale}
        currentLocale={props.currentLocale}
        loading={props.loading}
      />
    </Modal>
  )
}
