export const ConsumerEditPhoneNumberSectionFormMessage = {
  phoneNumberLabel: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.Form.phoneNumberLabel',
    description: 'My new phone number is'
  },
  invalidPhoneNumber: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.Form.invalidPhoneNumber',
    description: 'Update Phone number validation error'
  },
  updatePhoneNumberAttemptsExhausted: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.Form.updatePhoneNumberAttemptsExhausted',
    description: 'No more phone number edits.'
  },
  errorGeneral: {
    id: 'Pages.ConsumerPhoneValidation.EditPhoneNumberSection.Form.errorGeneral',
    description: 'Catch all update Phone number error'
  }
}
