import { MessageDescriptor } from 'react-intl'
import { QuestionType } from '../../../../../../../../../domain/consumer/security-questions/types/questions/question-type.enum'
import { QuestionFieldType } from './question-field-type'

const getFieldMessage = (
  questionType: QuestionType,
  suffix: string,
  messages: Record<string, MessageDescriptor>
): MessageDescriptor => messages[`${questionType}${suffix}`]

const getFieldType = (questionType: QuestionType): QuestionFieldType => {
  if (questionType === QuestionType.Applicant4DigitCode) {
    return 'number'
  }

  return 'text'
}

export const QuestionsDialogFormViewFunctions = {
  getFieldMessage,
  getFieldType
}
