import { useSelector } from 'react-redux'
import { MainLayout } from '../../../../shared/components/main-layout/main-layout.component'
import { withShowIf } from '../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { SpinnerIcon } from '../../../../shared/icons/spinner/spinner.icon'
import { SpinnerIconSize } from '../../../../shared/icons/spinner/types/spinner-size.icon.enum'
import { PreQualifierLoadingProductStyles } from './pre-qualifier-loading-product.styles'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { PreQualifierLoadingProductStore } from './store/pre-qualifier-loading-product.store'
import { PreQualifierProductPathParams } from './types/pre-qualifier-product-path-params.interface'
import { PreQualifierLoadingProductErrorView } from './components/pre-qualifier-loading-product-error-view/pre-qualifier-loading-product-error-view.component'
import { PreQualifierAssetTypeSelectionStore } from '../pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.store'
import { ConsumerWorkflowStepsStore } from '../consumer-workflow/modules/consumer-workflow-steps/store/consumer-workflow-steps.store'
import { ConsumerProfileStore } from '../consumer-profile/store/consumer-profile.store'
import {
  setProductIdToLocalStorage,
  setVendorIdToLocalStorage
} from './types/pre-qualifier-product-local-storage.functions'

export const PreQualifierLoadingProduct = withShowIf(() => {
  const loading = useSelector(PreQualifierLoadingProductStore.isLoading)
  const productDetails = useSelector(
    PreQualifierLoadingProductStore.getProductDetails
  )
  const error = useSelector(PreQualifierLoadingProductStore.getError)
  const params = useParams<PreQualifierProductPathParams>()

  useEffect(() => {
    if (params.vendorId && params.sku) {
      ConsumerProfileStore.setVendorId(params.vendorId)
      setVendorIdToLocalStorage(params.vendorId)
      setProductIdToLocalStorage(params.sku)
      PreQualifierLoadingProductStore.fetchProductDetails(
        params.vendorId,
        params.sku
      )
    }
  }, [params])

  useEffect(() => {
    if (productDetails) {
      PreQualifierAssetTypeSelectionStore.setSelectedAssetType(
        productDetails.preQualifierAssetType
      )
      ConsumerWorkflowStepsStore.setWorkflowSteps(
        productDetails.preQualifierAssetType.workflow.steps
      )
      ConsumerWorkflowStepsStore.setActiveWorkflowStep(
        productDetails.preQualifierAssetType.workflow.steps[0]
      )
    }
  }, [productDetails])

  return (
    <MainLayout 
      showDefaultHeader 
      hideNavBar 
      hideHeader 
      centerAlignDisclaimer
    >
      <div className={PreQualifierLoadingProductStyles.loadingContainer}>
        <SpinnerIcon size={SpinnerIconSize.LARGE} showIf={loading} />
      </div>

      <PreQualifierLoadingProductErrorView showIf={Boolean(error)} />
    </MainLayout>
  )
})
