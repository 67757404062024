export const PreQualifierAssetTypeDetailsDialogStyles = {
  dialog: 'w-3/4 shadow-md bg-white rounded-md max-w-4xl',
  container: 'flex flex-col mx-8 pt-8 sm:flex-row',
  imageContainer: 'mb-4 sm:w-1/4 sm:h-32 sm:pr-4',
  image: 'object-contain h-full w-full rounded-md shadow-md',
  right: 'w-full sm:w-3/4',
  header: 'text-20px font-black mb-5',
  ratesContainer: 'flex flex-wrap flex-col sm:flex-row gap-2 mb-6',
  ratesItem: 'px-4 py-4 bg-gray50',
  ratesTitle: 'text-14px text-mainblue text-center mb-6',
  ratesValue: 'text-center text-16px font-bold',
  disclaimer: 'text-gray text-13px mb-4',
  buttonsContainer: 'flex flex-row justify-end py-3 px-3 bg-gray40',
  selectButtonContainer: 'w-24',
  closeButton:
    'text-black bg-white p-2 rounded-primaryButton border border-solid border-gray70 mr-2 w-24 h-auto'
}
