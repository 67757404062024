import ReactModal from 'react-modal'
import { useIsMobile } from '../../ui-library/hooks/use-is-mobile-hook/use-is-mobile-hook'
import { ModalLibraryProps } from './modal-library.props'
import {
  ModalLibraryClassNames,
  ModalLibraryDesktopStyles,
  ModalLibraryMobileStyles,
  SmallModalDesktopStyles,
} from './modal-library.styles'
import { useMemo } from 'react'

ReactModal.setAppElement('#root')
export const ModalLibrary = (props: ModalLibraryProps) => {
  const isMobile = useIsMobile();

  const modalStyle = useMemo(() => {
    const mobileStyle = props.mobileStyle ? props.mobileStyle : ModalLibraryMobileStyles;
    if (isMobile) {
      return mobileStyle;
    }
    if (props.smallModal) {
      return SmallModalDesktopStyles
    }
    return ModalLibraryDesktopStyles
  }, [props.mobileStyle]);
  
  return (
    <ReactModal
      style={modalStyle}
      className={props.className}
      bodyOpenClassName={ModalLibraryClassNames.body}
      isOpen={props.open}
      onRequestClose={props.onClose}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
      shouldCloseOnEsc={props.shouldCloseOnEsc}
    >
      {props.children}
    </ReactModal>
  )
}
