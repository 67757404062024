import { useCallback, useEffect, useState } from 'react'
import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component'
import { ConsumerAssetSubtypeView } from './components/consumer-asset-subtype-view/consumer-asset-subtype-view.component'
import { ConsumerAssetSubtypeStepProps } from './consumer-asset-subtype-step.props'
import { useSelector } from 'react-redux'
import { AssetSubtypeStore } from '../../../asset-subtype-selection/store/asset-subtype-selection.store'
import { Language } from '../../../../../../shared/types/language.enum'
import { SettingsStore } from '../../../../../app/modules/settings/store/settings.store'
import { AssetSubtype } from '../../../../../../domain/asset-subtype/asset-subtype.interface'
import { ConsumerProfileStore } from '../../../consumer-profile/store/consumer-profile.store'
import { ConsumerAssetSubtypeStore } from './store/consumer-asset-subtype-step.store'

export const ConsumerAssetSubtypeStep = (
  props: ConsumerAssetSubtypeStepProps
) => {
  const locale: Language = useSelector(SettingsStore.getLocaleSetting)
  const consumerProfileAssetSubtype = useSelector(
    ConsumerProfileStore.getConsumerAssetSubtype
  )
  const storedAssetSubtype = useSelector(
    ConsumerAssetSubtypeStore.getAssetSubtypeId
  )
  const [selectedAssetSubtype, setSelectedAssetSubtype] = useState<
    AssetSubtype['id']
  >(storedAssetSubtype?.toString() ?? '')

  const onComplete = useCallback(() => {
    props.onComplete(props.step)
  }, [props.onComplete, props.step])

  const goBack = useCallback(
    () => props.goBack(props.step),
    [props.step, props.goBack]
  )

  useEffect(() => {
    AssetSubtypeStore.fetchAssetSubtypes(locale)
  }, [locale])

  const saveAssetSubtype = () => {
    ConsumerAssetSubtypeStore.saveConsumerAssetSubtypeId(Number(selectedAssetSubtype))
  }

  useEffect(() => {
    if (consumerProfileAssetSubtype) {
      onComplete()
    }
  }, [consumerProfileAssetSubtype])

  return (
    <MainLayout step={props.step} hideFooter>
      <ConsumerAssetSubtypeView
        onComplete={onComplete}
        saveAssetSubtype={saveAssetSubtype}
        goBack={goBack}
        step={props.step}
        assetSubtypes={useSelector(AssetSubtypeStore.getAssetSubtypes)}
        assetSubtype={selectedAssetSubtype}
        onChange={setSelectedAssetSubtype}
        error={useSelector(ConsumerAssetSubtypeStore.getError)}
        loading={useSelector(ConsumerAssetSubtypeStore.isLoading)}
      />
    </MainLayout>
  )
}
