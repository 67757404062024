import { FetchMethod } from '../../fetch/types/fetch-method.enum';
import { FetchResponse } from '../../fetch/types/fetch-response.type';
import { BffService } from '../bff.service';

export class ConsumerAssetSubtypeService extends BffService {
    public saveConsumerAssetSubtype(assetSubtypeId: number): FetchResponse<null> {
        return super.createAuthorizedRequest({
            method: FetchMethod.PATCH,
            url: `${this.bffUrl}/v1/consumer-profile/asset-subtype/${assetSubtypeId}`,
        })
    }
}