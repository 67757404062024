import { consumerAddressStepEpics } from '../modules/consumer-address-step/store/consumer-address-step.epics'
import { consumerDobStepEpics } from '../modules/consumer-dob-step/store/consumer-dob-step.epics'
import { consumerEmailStepEpics } from '../modules/consumer-email-step/store/consumer-email-step.epics'
import { consumerPhoneNumberStepEpics } from '../modules/consumer-phone-number-step/store/consumer-phone-number-step.epics'
import { consumerPhoneValidationStepEpics } from '../modules/consumer-phone-validation/store/consumer-phone-validation-step.epics'
import { consumerWorkflowStepsEpics } from '../modules/consumer-workflow-steps/store/consumer-workflow-steps.epics'
import { consumerNetIncomeStepEpics } from '../modules/consumer-net-income-step/store/consumer-net-income-step.epics'
import { consumerSubmitStepEpics } from '../modules/consumer-submit-step/store/consumer-submit-step.epics'
import { consumerAssetSubtypeEpics } from '../modules/consumer-asset-subtype-step/store/consumer-asset-subtype-step.epics'

export const consumerWorkflowEpics = [
  ...consumerWorkflowStepsEpics,
  ...consumerPhoneNumberStepEpics,
  ...consumerPhoneValidationStepEpics,
  ...consumerDobStepEpics,
  ...consumerAssetSubtypeEpics,
  ...consumerEmailStepEpics,
  ...consumerAddressStepEpics,
  ...consumerNetIncomeStepEpics,
  ...consumerSubmitStepEpics
]
