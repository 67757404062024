const addressLookupItemStyle = {
  item: 'cursor-pointer px-3 py-1 hover:bg-white150 rounded truncate'
}

export const LookupAddressItemStyles = {
  item: (isFirstItem: boolean): string =>
    isFirstItem
      ? `${addressLookupItemStyle.item} pt-2`
      : addressLookupItemStyle.item
}
