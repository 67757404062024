import { FetchRequestQueryArrayType } from '../../fetch/types/fetch-request-query-array.type';
import { FetchRequestQueryType } from '../../fetch/types/fetch-request-query.type';

export function createMerchantQuery(
  assetSubtypeId: string | null,
  page?: number,
  merchantName?: string | null
): Record<string, FetchRequestQueryType | FetchRequestQueryArrayType> | undefined {
  let query: Record<string, FetchRequestQueryType | FetchRequestQueryArrayType> = {}

  if (!assetSubtypeId && !page) {
    return undefined
  }

  if (assetSubtypeId) {
    query = {...query, assetSubtypeId : assetSubtypeId }
  }

  if (page) {
    query = {...query, page : page }
  }

  if (merchantName) {
    query = {...query, merchantName : merchantName }
  }

  return query
}
