import { SecurityQuestionsState } from './security-questions-state.interface'

export function createSecurityQuestionsState(): SecurityQuestionsState {
  return {
    securityQuestions: null,
    attemptsRemaining: null,
    isLoading: false,
    submittingSecurityQuestions: false
  }
}
