import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { Language } from '../../../../../../../../../../../shared/types/language.enum'
import { RootStore } from '../../../../../../../../../../../store/root.store'
import { RootState } from '../../../../../../../../../../../store/types/root-state/root-state.interface'
import { ConsumerAddress } from '../../../../../../../../../../../domain/consumer/consumer-profile/consumer-address.interface'
import { ConsumerManualAddressActions } from './consumer-manual-address.actions'
import { ConsumerManualAddressState } from '../types/consumer-manual-address-state.interface'

const consumerManualAddressState = (
  rootState: RootState
): ConsumerManualAddressState =>
  rootState.consumerState.consumerWorkflowState.consumerAddressStepState.manual

const fetchProvinces = (locale: Language) => {
  RootStore.dispatch(ConsumerManualAddressActions.fetchProvinces(locale))
}

const getIsWorkflowStepComplete: Selector<
  RootState,
  ConsumerManualAddressState['isWorkflowStepComplete']
> = createSelector(
  consumerManualAddressState,
  (state: ConsumerManualAddressState) => state.isWorkflowStepComplete
)

const getProvinces: Selector<
  RootState,
  ConsumerManualAddressState['provinces']
> = createSelector(
  consumerManualAddressState,
  (state: ConsumerManualAddressState) => state.provinces
)

const getSaveAddressLoading: Selector<
  RootState,
  ConsumerManualAddressState['saveAddressLoading']
> = createSelector(
  consumerManualAddressState,
  (state: ConsumerManualAddressState) => state.saveAddressLoading
)

const getSaveAddressFailure: Selector<
  RootState,
  ConsumerManualAddressState['saveAddressFailure']
> = createSelector(
  consumerManualAddressState,
  (state: ConsumerManualAddressState) => state.saveAddressFailure
)

const saveAddress = (address: ConsumerAddress) => {
  RootStore.dispatch(ConsumerManualAddressActions.saveManualAddress(address))
}

const skipSaveAddress = () => {
  RootStore.dispatch(ConsumerManualAddressActions.saveManualAddressSuccess())
}

const reset = () =>
  RootStore.dispatch(
    ConsumerManualAddressActions.resetConsumerManualAddressState()
  )

const resetSaveAddressFailure = () =>
  RootStore.dispatch(ConsumerManualAddressActions.resetSaveAddressFailure())

export const ConsumerManualAddressStore = {
  fetchProvinces,
  getIsWorkflowStepComplete,
  getProvinces,
  getSaveAddressLoading,
  getSaveAddressFailure,
  saveAddress,
  skipSaveAddress,
  reset,
  resetSaveAddressFailure
}
