import { Reducer } from 'redux'
import { ConsumerAddressStepActionType } from '../modules/consumer-address-step/store/consumer-address-step.actions'
import { consumerAddressStepReducer } from '../modules/consumer-address-step/store/consumer-address-step.reducer'
import { ConsumerDobStepActionType } from '../modules/consumer-dob-step/store/consumer-dob-step.actions'
import { consumerDobStepReducer } from '../modules/consumer-dob-step/store/consumer-dob-step.reducer'
import { ConsumerEmailStepActionType } from '../modules/consumer-email-step/store/consumer-email-step.action'
import { consumerEmailStepReducer } from '../modules/consumer-email-step/store/consumer-email-step.reducer'
import { ConsumerNetIncomeStepActionType } from '../modules/consumer-net-income-step/store/consumer-net-income-step.actions'
import { consumerNetIncomeStepReducer } from '../modules/consumer-net-income-step/store/consumer-net-income-step.reducer'
import { ConsumerPhoneNumberStepActionType } from '../modules/consumer-phone-number-step/store/consumer-phone-number-step.actions'
import { consumerPhoneNumberStepReducer } from '../modules/consumer-phone-number-step/store/consumer-phone-number-step.reducer'
import { ConsumerPhoneValidationStepActionType } from '../modules/consumer-phone-validation/store/consumer-phone-validation-step.actions'
import { consumerPhoneValidationStepReducer } from '../modules/consumer-phone-validation/store/consumer-phone-validation-step.reducer'
import { ConsumerSubmitStepActionType } from '../modules/consumer-submit-step/store/consumer-submit-step.actions'
import { consumerSubmitStepReducer } from '../modules/consumer-submit-step/store/consumer-submit-step.reducer'
import { ConsumerWorkflowStepsActionType } from '../modules/consumer-workflow-steps/store/consumer-workflow-steps.actions'
import { consumerWorkflowStepsReducer } from '../modules/consumer-workflow-steps/store/consumer-workflow-steps.reducer'
import { createConsumerWorkflowState } from '../types/consumer-workflow-state/consumer-workflow-state.functions'
import { ConsumerWorkflowState } from '../types/consumer-workflow-state/consumer-workflow-state.interface'
import { ConsumerWorkflowActionType } from './consumer-workflow.actions'
import { consumerAssetSubtypeStepReducer } from '../modules/consumer-asset-subtype-step/store/consumer-asset-subtype-step.reducer'
import { ConsumerAssetSubtypeStepActionType } from '../modules/consumer-asset-subtype-step/store/consumer-asset-subtype-step.actions'

export const consumerWorkflowReducer: Reducer<
  ConsumerWorkflowState,
  ConsumerWorkflowActionType
> = (
  state: ConsumerWorkflowState = createConsumerWorkflowState(),
  action: ConsumerWorkflowActionType
): ConsumerWorkflowState => {
  return {
    ...state,
    consumerAddressStepState: consumerAddressStepReducer(
      state.consumerAddressStepState,
      action as ConsumerAddressStepActionType
    ),
    consumerWorkflowStepsState: consumerWorkflowStepsReducer(
      state.consumerWorkflowStepsState,
      action as ConsumerWorkflowStepsActionType
    ),
    consumerPhoneNumberStepState: consumerPhoneNumberStepReducer(
      state.consumerPhoneNumberStepState,
      action as ConsumerPhoneNumberStepActionType
    ),
    consumerPhoneValidationStepState: consumerPhoneValidationStepReducer(
      state.consumerPhoneValidationStepState,
      action as ConsumerPhoneValidationStepActionType
    ),
    consumerDobStepState: consumerDobStepReducer(
      state.consumerDobStepState,
      action as ConsumerDobStepActionType
    ),
    consumerAssetSubtypeStepState: consumerAssetSubtypeStepReducer(
      state.consumerAssetSubtypeStepState,
      action as ConsumerAssetSubtypeStepActionType
    ),
    consumerEmailStepState: consumerEmailStepReducer(
      state.consumerEmailStepState,
      action as ConsumerEmailStepActionType
    ),
    consumerNetIncomeStepState: consumerNetIncomeStepReducer(
      state.consumerNetIncomeStepState,
      action as ConsumerNetIncomeStepActionType
    ),
    consumerSubmitStepState: consumerSubmitStepReducer(
      state.consumerSubmitStepState,
      action as ConsumerSubmitStepActionType
    )
  }
}
