import { AssetSubtypes } from '../../../../domain/asset-subtype/asset-subtypes.interface';

export function isMerchantLocatorProductSelectionEnabled(): boolean {
  const selectionEnabled = process.env.REACT_APP_ENABLE_MERCHANT_LOCATOR_PRODUCT_SELECTION
  return Boolean(selectionEnabled && selectionEnabled.toLowerCase() === 'true')
}

export function showSelectAssetSubtypeTitle(
  selectedAssetSubtype: string | null,
  assetSubtypes: AssetSubtypes,
): boolean {
  return isMerchantLocatorProductSelectionEnabled() && !Boolean(selectedAssetSubtype) && Boolean(assetSubtypes.length)
}

export function showAssetSubtypesSelector(
  assetSubtypes: AssetSubtypes,
): boolean {
  return isMerchantLocatorProductSelectionEnabled() && Boolean(assetSubtypes.length)
}
