import { ConsumerEmailStepState } from './consumer-email-step-state.interface'

export function createConsumerEmailStepState(): ConsumerEmailStepState {
  return {
    email: null,
    error: null,
    loading: false,
    isWorkflowStepComplete: false
  }
}
