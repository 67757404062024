import { ConsumerSubmitStepState } from './consumer-submit-step-state.interface'

export function createConsumerSubmitStepState(): ConsumerSubmitStepState {
  return {
    loading: false,
    authorizedElectronicCommunication: false,
    creditConsent: false,
    error: null,
    isWorkflowStepComplete: false
  }
}
