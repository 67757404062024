import { ConsumerWorkflowDto } from './consumer-workflow.dto'
import { ConsumerWorkflowStepDtoMapper } from './consumer-workflow-step/consumer-workflow-step-dto-mapper'
import { ConsumerWorkflow } from '../../../../domain/consumer/consumer-workflow/consumer-workflow.interface'

export class ConsumerWorkflowDtoMapper {
  constructor(private data: ConsumerWorkflowDto) {}

  public toDomainObject(): ConsumerWorkflow {
    return {
      steps: this.data.steps.map((step) =>
        new ConsumerWorkflowStepDtoMapper(step).toDomainObject()
      )
    }
  }
}
