/* eslint-disable prefer-rest-params */
export const APP_ID = process.env.REACT_APP_INTERCOM_API_ID;

export const load = () => {
  (function() {
    const applicationWindow: any = window;
    const intercom = applicationWindow.Intercom;
    if (typeof intercom === 'function') {
      intercom('reattach_activator');
      intercom('update', applicationWindow.intercomSettings);
    } else {
      const doc = document;
      const intercomArgs: any = function() {
        intercomArgs.setArguments(arguments);
      };
      intercomArgs.q = [];
      intercomArgs.setArguments = function(args: any) {
        intercomArgs.q.push(args);
      };
      applicationWindow.Intercom = intercomArgs;
      const insertIntercomScript = function() {
        const intercomScript = doc.createElement('script');
        intercomScript.type = 'text/javascript';
        intercomScript.async = true;
        intercomScript.src = 'https://widget.intercom.io/widget/' + APP_ID;
        const documentScriptElement: any = doc.getElementsByTagName('script')[0];
        documentScriptElement.parentNode.insertBefore(intercomScript, documentScriptElement);
      };
      if (document.readyState === 'complete') {
        insertIntercomScript();
      } else if (applicationWindow.attachEvent) {
        applicationWindow.attachEvent('onload', insertIntercomScript);
      } else {
        applicationWindow.addEventListener('load', insertIntercomScript, false);
      }
    }
  })();
};

export const boot = (options = {}) => {
  const applicationWindow: any = window;

  applicationWindow &&
    applicationWindow.Intercom &&
    applicationWindow.Intercom('boot', {
      app_id: APP_ID,
      api_base: 'https://api-iam.intercom.io',
      ...options,
    });
};

export const update = () => {
  const applicationWindow: any = window;

  applicationWindow && applicationWindow.Intercom && applicationWindow.Intercom('update');
};

export const hideIntercom = (hideIntercom: boolean) => {
  const applicationWindow: any = window;

  if (Boolean(applicationWindow && applicationWindow.Intercom)) {
    if (hideIntercom) {
      applicationWindow.Intercom('hide');
    }
    applicationWindow.Intercom('update', {
      hide_default_launcher: hideIntercom,
    });
  }
};

export function initializeIntercom(): void {
  if (Boolean(process.env.REACT_APP_INTERCOM_API_ID)) {
    load();
    boot();
  }
}
