import { FetchRequestQueryArrayType } from './fetch-request-query-array.type'
import { FetchRequestQueryType } from './fetch-request-query.type'
import { FetchRequest } from './fetch-request.interface'

export function buildUrlWithQueryParams(
  url: string,
  urlForm: FetchRequest['query'] = {}
): string {
  const query = Object.keys(urlForm)
    .map((key: string): string => buildQueryParams(key, urlForm[key]))
    .join('&')

  return `${url}?${query}`
}

export function buildQueryParamsOutOfArray(
  key: string,
  values: FetchRequestQueryArrayType
): string {
  const query = values
    .map(
      (value) => buildEncodeURIComponent(key, value)
    )
    .join('&')

  return query
}

export function buildQueryParams(
  key: string,
  value: FetchRequestQueryType | FetchRequestQueryArrayType
) {
  if (Array.isArray(value)) {
    return buildQueryParamsOutOfArray(key, value)
  } else {
    return buildEncodeURIComponent(key, value)
  }
}

function buildEncodeURIComponent(key: string, value: FetchRequestQueryType) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
}
