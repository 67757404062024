import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { withShowIf } from '../../../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { SettingsStore } from '../../../../../../../../../../../app/modules/settings/store/settings.store'
import { SettingsState } from '../../../../../../../../../../../app/modules/settings/types/settings-state.interface'
import { ConsumerProfileStore } from '../../../../../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerManualAddressStore } from '../../store/consumer-manual-address.store'
import { ConsumerManualAddressForm } from '../consumer-manual-address-form/consumer-manual-address-form.component'
import { ConsumerManualAddressViewProps } from './consumer-manual-address-view.props'
import { ConsumerAddressViewError } from '../../../../components/consumer-address-view-error/consumer-address-view-error.component'
import { doesErrorMessageForInputValidationExist } from '../../../../../../../../../../../../shared/helpers/error-message-input-validation/error-message-input-validation.util'
import { InputValidationErrorCode } from '../../../../../../../../../../../../shared/validation/input-validation-error-code.enum'

export const ConsumerManualAddressView = withShowIf(
  (props: ConsumerManualAddressViewProps) => {
    const locale: SettingsState['locale'] = useSelector(
      SettingsStore.getLocaleSetting
    )

    const saveAddressFailure = useSelector(ConsumerManualAddressStore.getSaveAddressFailure)

    useEffect(() => {
      ConsumerManualAddressStore.fetchProvinces(locale)
    }, [locale])

    useEffect(() => {
      return () => {
        ConsumerManualAddressStore.reset()
      }
    }, [])

    const showNonPhysicalAddressError = useMemo(() => {
      return doesErrorMessageForInputValidationExist(
        saveAddressFailure,
        InputValidationErrorCode.NonPhysicalAddress,
      );
    }, [saveAddressFailure]);

    return (
      <>
        <ConsumerManualAddressForm
          onComplete={props.onComplete}
          switchToAutoAddressForm={props.switchToAutoAddressForm}
          onSubmit={ConsumerManualAddressStore.saveAddress}
          consumerAddress={useSelector(ConsumerProfileStore.getConsumerAddress)}
          provinces={useSelector(ConsumerManualAddressStore.getProvinces)}
          saveAddressLoading={useSelector(
            ConsumerManualAddressStore.getSaveAddressLoading
          )}
          isWorkflowStepComplete={useSelector(
            ConsumerManualAddressStore.getIsWorkflowStepComplete
          )}
          showNonPhysicalAddressError={showNonPhysicalAddressError}
        />

        <ConsumerAddressViewError showIf={showNonPhysicalAddressError}/>
      </>
    )
  }
)
