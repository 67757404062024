import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatCurrency } from '../../../../../../../../shared/helpers/currency/currency-formatter'
import { withShowIf } from '../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { SettingsStore } from '../../../../../../../app/modules/settings/store/settings.store'
import { ConsumerProfileAssetSummaryViewProps } from './consumer-profile-asset-summary-view.props'
import { ConsumerProfileAssetSummaryViewStyles } from './consumer-profile-asset-summary-view.styles'

export const ConsumerProfileAssetSummaryView = withShowIf((
  props: ConsumerProfileAssetSummaryViewProps
) => {
  const locale = useSelector(SettingsStore.getLocaleSetting)
  if (!props.consumerProfileAsset) {
    return null
  }

  const [productImage, setProductImage] = useState<string>(props.consumerProfileAsset.imageUrl || '');

  const productImageAltTag = useMemo(() => {
    if (props.consumerProfileAsset?.model) {
      return `${props.consumerProfileAsset.model} image`;
    }
    return '';
  }, [props.consumerProfileAsset]);

  const handleProductImageError = (): void => {
    if (props.vendorImage) {
      setProductImage(props.vendorImage);
    }
  }

  useEffect(() => {
    if (props.consumerProfileAsset?.imageUrl) {
      setProductImage(props.consumerProfileAsset.imageUrl);
    }
  }, [props.consumerProfileAsset]);
  
  return (
    <div className={ConsumerProfileAssetSummaryViewStyles.container}>
      <div className={ConsumerProfileAssetSummaryViewStyles.vendorContainer}>
        <img
          className={ConsumerProfileAssetSummaryViewStyles.vendorImage}
          src={'/brp_logo.png'}
        />
        <span
          className={ConsumerProfileAssetSummaryViewStyles.assetTypeDescription}
        >
          {props.consumerProfileAsset.model}
        </span>
      </div>
      <div className={ConsumerProfileAssetSummaryViewStyles.productContainer}>
        <img 
          className={ConsumerProfileAssetSummaryViewStyles.productImage}
          src={productImage} 
          onError={handleProductImageError} 
          alt={productImageAltTag} 
        />
        <div className={ConsumerProfileAssetSummaryViewStyles.msrpContainer}>
          <div>MSRP</div>
          <div
            className={ConsumerProfileAssetSummaryViewStyles.msrpValueContainer}
          >
            {formatCurrency(locale, props.consumerProfileAsset.msrp)}
          </div>
        </div>
      </div>
    </div>
  )
})
