export const ConsumerPostSubmissionApprovedReferenceCodeMessages = {
  referenceCodeLabel: {
    id: 'Pages.PostSubmission.Approved.ReferenceCode.referenceCodeLabel',
    description: 'Post Submission Approved Page Reference Code Label'
  },
  description: {
    id: 'Pages.PostSubmission.Approved.ReferenceCode.description',
    description: 'Post Submission Approved Page Reference Code Description'
  },
  note: {
    id: 'Pages.PostSubmission.Approved.ReferenceCode.note',
    description: 'Post Submission Approved Page Reference Code Note'
  },
  details: {
    id: 'Pages.PostSubmission.Approved.ReferenceCode.details',
    description: 'Post Submission Approved Page Reference Code Details',
  }
}
