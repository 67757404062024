export const AuthenticationFailureMessageBoxMessages = {
  message: {
    id: 'Pages.ConsumerAuthentication.Failure.message',
    description: 'failed to authenticate with auth code provided'
  },
  technicalAssistance: {
    id: 'Pages.ConsumerAuthentication.Failure.technicalAssistance',
    description: 'Auth failure technical assistance message'
  },
  lendCareEmail: {
    id: 'MainLayout.ContactInfoFooter.Footer.lendCareEmail',
    description: 'Merchant Selection Page Footer LendCare Email'
  },
  lendCarePhoneNumber: {
    id: 'MainLayout.ContactInfoFooter.Footer.lendCarePhoneNumber',
    description: 'Merchant Selection Page Footer LendCare Phone Number'
  }
}
