import { ModalLibrary } from '../../../libraries/modal/modal-library.component'
import { ModalProps } from './modal.props'

export const Modal = (props: ModalProps) => {
  return (
    <ModalLibrary className={props.className} open={props.open} smallModal={props.smallModal}>
      {props.children}
    </ModalLibrary>
  )
}
