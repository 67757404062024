import { SpinnerIconSize } from './types/spinner-size.icon.enum'
import styles from './spinner.icon.module.scss'

export const spinnerIconStyle = (
  size: SpinnerIconSize,
  cssClassesToAdd: string = ''
): string => {
  const cssClasses: string[] = [styles.spinIcon]

  cssClasses.push(cssClassesToAdd)
  cssClasses.push(spinnerSizeClass(size))

  return cssClasses.join(' ')
}

const spinnerSizeClass = (size: SpinnerIconSize): string => {
  switch (size) {
    case SpinnerIconSize.SMALL:
      return styles.spinIconSmall
    case SpinnerIconSize.LARGE:
      return styles.spinIconLarge
  }
}
