import { Reducer } from 'redux'
import { createConsumerEmailStepState } from '../types/consumer-email-step-state.function'
import { ConsumerEmailStepState } from '../types/consumer-email-step-state.interface'
import {
  ConsumerEmailStepActionType,
  ConsumerEmailAction
} from './consumer-email-step.action'

export const consumerEmailStepReducer: Reducer<
  ConsumerEmailStepState,
  ConsumerEmailStepActionType
> = (
  state: ConsumerEmailStepState = createConsumerEmailStepState(),
  action: ConsumerEmailStepActionType
): ConsumerEmailStepState => {
  switch (action.type) {
    case ConsumerEmailAction.SaveConsumerEmail:
      return {
        ...state,
        loading: true
      }
    case ConsumerEmailAction.SaveConsumerEmailSuccess:
      return {
        ...state,
        email: action.payload,
        isWorkflowStepComplete: true,
        loading: false,
        error: null
      }
    case ConsumerEmailAction.SaveConsumerEmailFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case ConsumerEmailAction.ResetConsumerEmailState:
      return createConsumerEmailStepState()
    default:
      return state
  }
}
