import { useCallback, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ConsumerRoute } from '../../../../../../../../domain/consumer/consumer-routes/consumer-route.enum'
import { MainLayout } from '../../../../../../../../shared/components/main-layout/main-layout.component'
import { addVendorIdToPath } from '../../../../../../../../shared/helpers/path-helper'
import { truncateConsumerName } from '../../../../../../../../shared/helpers/truncate/truncate-consumer-name.function'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { PostSubmissionApprovedStore } from '../../store/consumer-post-submission-approved.store'
import { ConsumerPostSubmissionApprovedState } from '../../types/consumer-post-submission-approved.state'
import { ConsumerPostSubmissionApprovedActionText } from '../consumer-post-submission-approved-action-text/consumer-post-submission-approved-action-text.component'
import { PostSubmissionApprovedReferenceCode } from '../consumer-post-submission-approved-reference-code/consumer-post-submission-approved-reference-code.component'
import { ConsumerPostSubmissionApprovedViewMessages } from './consumer-post-submission-approved-view.messages'
import { ConsumerPostSubmissionApprovedViewStyles } from './consumer-post-submission-approved-view.styles'
import { ConsumerPostSubmissionApprovedViewUat } from './consumer-post-submission-approved-view.uat'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ShowIfFragment } from '../../../../../../../../ui-library/components/show-if/show-if-fragment.component'
import { getPostSubmissionConfigApprovedRules } from '../../types/consumer-post-submission-config.function'

export const ConsumerPostSubmissionApprovedView = () => {
  const intl = useIntl()
  const history = useHistory()
  const vendorId = useSelector(ConsumerProfileStore.getVendorId)
  const applicantLink: ConsumerPostSubmissionApprovedState['applicantLink'] = 
    useSelector(PostSubmissionApprovedStore.getApplicantLink)

  const postSubmissionConfig = useSelector(ConsumerProfileStore.getPostSubmissionConfig)
  const postSubmissionConfigRules = getPostSubmissionConfigApprovedRules(postSubmissionConfig)

  useEffect(() => {
    window.scrollTo(0, 0)
    PostSubmissionApprovedStore.fetchApplicantLink()
  }, [])

  const firstName: ConsumerProfileState['firstName'] = useSelector(
    ConsumerProfileStore.getConsumerFirstName
  )

  const referenceCode: ConsumerProfileState['referenceCode'] = useSelector(
    ConsumerProfileStore.getReferenceCode
  )

  const selectMerchant = useCallback(
    () =>
      history.push(addVendorIdToPath(vendorId, ConsumerRoute.SelectMerchant)),
    []
  )
  const openApplicantLink = () => {
    if (applicantLink) {
      window.open(applicantLink, '_blank', 'noopener,noreferrer');
    }
  }

  const subtitleMessage = `${intl.formatMessage(ConsumerPostSubmissionApprovedViewMessages.approvedAmountMessageHeader, {name: truncateConsumerName(firstName)})} ${intl.formatMessage(ConsumerPostSubmissionApprovedViewMessages.approvedAmountMessage)}`

  return (
    <MainLayout showContactInfoFooter noHorizontalPadding centerAlignDisclaimer>
      <PrimaryHeader/>
      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(ConsumerPostSubmissionApprovedViewMessages.header)}
        titleClasses={ConsumerPostSubmissionApprovedViewStyles.title}
        subTitleMessage={subtitleMessage}
        subTitleUat={uatId(ConsumerPostSubmissionApprovedViewUat.approvedAmountMessage)}
      >
        <Paragraph
          className={
            ConsumerPostSubmissionApprovedViewStyles.approvedAmountMessage
          }
        >
          <FormattedMessage
            {...ConsumerPostSubmissionApprovedViewMessages.aboutMessage}
          />
        </Paragraph>
      </ConsumerProfileAssetImageContainer>

      <PostSubmissionApprovedReferenceCode referenceCode={referenceCode} />
      <ConsumerPostSubmissionApprovedActionText />

      <div
        className={
          ConsumerPostSubmissionApprovedViewStyles.selectMerchantOptionsContainer
        }
      >
        <ShowIfFragment showIf={postSubmissionConfigRules?.merchantLocatorLink}>
          <div
            className={
              ConsumerPostSubmissionApprovedViewStyles.selectMerchantContainer
            }
          >
            <div
              className={
                ConsumerPostSubmissionApprovedViewStyles.selectMerchantLabelContainer
              }
            >
              <Paragraph
                className={
                  ConsumerPostSubmissionApprovedViewStyles.selectMerchantLabel
                }
              >
                <FormattedMessage
                  {...ConsumerPostSubmissionApprovedViewMessages.getStartedLabel}
                />
              </Paragraph>
            </div>
            <div
              className={
                ConsumerPostSubmissionApprovedViewStyles.selectMerchantButtonContainer
              }
            >
              <PrimaryButton
                onClick={selectMerchant}
                uat={uatId(
                  ConsumerPostSubmissionApprovedViewUat.selectMerchantConfirmButton
                )}
              >
                <FormattedMessage
                  {...ConsumerPostSubmissionApprovedViewMessages.selectMerchantLabel}
                />
              </PrimaryButton>
            </div>
          </div>
        </ShowIfFragment>
        
        <ShowIfFragment showIf={postSubmissionConfigRules?.showApplicantPortalLink}>
          <div className={ConsumerPostSubmissionApprovedViewStyles.selectMerchantContainer}>
            <Paragraph
              className={ConsumerPostSubmissionApprovedViewStyles.selectMerchantLabel}
            >
              <FormattedMessage
                {...ConsumerPostSubmissionApprovedViewMessages.redirectApplicantPortalLabel}
              />
            </Paragraph>
            <div
              className={
                ConsumerPostSubmissionApprovedViewStyles.selectMerchantButtonContainer
              }
            >
              <PrimaryButton
                onClick={openApplicantLink}
                uat={uatId(
                  ConsumerPostSubmissionApprovedViewUat.redirectApplicantPortalButton
                )}
                disabled={!Boolean(applicantLink)}
              >
                <FormattedMessage
                  {...ConsumerPostSubmissionApprovedViewMessages.redirectApplicantPortalButton}
                />
              </PrimaryButton>
            </div>
          </div>
        </ShowIfFragment>
      </div>
    </MainLayout>
  )
}
