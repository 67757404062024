import { ParagraphProps } from './paragraph.props'
import { ParagraphStyles } from './paragraph.styles'

export const Paragraph = (props: ParagraphProps) => {
  const getClassName = () => {
    return props.className ? props.className : ParagraphStyles.paragraph
  }

  return (
    <p className={getClassName()} {...props.uat}>
      {props.children}
    </p>
  )
}
