import { Reducer } from 'redux'
import { createConsumerEditPhoneNumberState } from '../types/consumer-edit-phone-number-state.functions'
import { ConsumerEditPhoneNumberState } from '../types/consumer-edit-phone-number-state.interface'
import {
  ConsumerEditPhoneNumberAction,
  ConsumerEditPhoneNumberActionType
} from './consumer-edit-phone-number.actions'

export const consumerEditPhoneNumberReducer: Reducer<
  ConsumerEditPhoneNumberState,
  ConsumerEditPhoneNumberActionType
> = (
  state: ConsumerEditPhoneNumberState = createConsumerEditPhoneNumberState(),
  action: ConsumerEditPhoneNumberActionType
): ConsumerEditPhoneNumberState => {
  switch (action.type) {
    case ConsumerEditPhoneNumberAction.EditPhoneNumber:
      return {
        ...state,
        error: null,
        isEditSuccessful: false,
        loading: true,
        phoneNumber: action.payload
      }

    case ConsumerEditPhoneNumberAction.EditPhoneNumberFailure:
      return {
        ...state,
        error: action.payload,
        isEditSuccessful: false,
        loading: false
      }
    case ConsumerEditPhoneNumberAction.EditPhoneNumberSuccess:
      return {
        ...state,
        error: null,
        isEditSuccessful: true,
        loading: false
      }

    case ConsumerEditPhoneNumberAction.ResetEditPhoneNumberState:
      return {
        ...state,
        error: null,
        isEditSuccessful: false,
        loading: false,
        phoneNumber: null
      }

    default:
      return state
  }
}
