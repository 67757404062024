export const ConsumerManualAddressFormMessages = {
  streetLabel: {
    id: 'Pages.ConsumerAddress.Manual.streetLabel',
    description: 'Street'
  },
  aptLabel: {
    id: 'Pages.ConsumerAddress.Manual.aptLabel',
    description: 'Apt'
  },
  cityLabel: {
    id: 'Pages.ConsumerAddress.Manual.cityLabel',
    description: 'City'
  },
  postalLabel: {
    id: 'Pages.ConsumerAddress.Manual.postalLabel',
    description: 'Postal'
  },
  autoButton: {
    id: 'Pages.ConsumerAddress.Manual.autoButton',
    description: 'Back to auto-populating my address'
  },
  submitButton: {
    id: 'Pages.ConsumerAddress.Manual.submitButton',
    description: 'Next'
  }
}
