import React, { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { StringSchemaOptions } from '../../../../../../../../../../../../libraries/form-schema/form-field-schema/types/form-field-schema-options/string-schema-options.interface'
import { FormSchema } from '../../../../../../../../../../../../libraries/form-schema/form-schema'
import { uatId } from '../../../../../../../../../../../../uat/uat-id.function'
import { PostalCodeInput } from '../../../../../../../../../../../../ui-library/components/input-field/components/postal-code-input/postal-code-input.component'
import { TextInput } from '../../../../../../../../../../../../ui-library/components/input-field/components/text-input/text-input.component'
import { PrimaryButton } from '../../../../../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerProfileStore } from '../../../../../../../../../consumer-profile/store/consumer-profile.store'
import { Province } from '../../../../../../../../../../../../domain/province/province.interface'
import { ProvincesSelectField } from './components/provinces-select-field/provinces-select-field.component'
import { ConsumerManualAddressFormMessages } from './consumer-manual-address-form.messages'
import { ConsumerManualAddressFormProps } from './consumer-manual-address-form.props'
import { generateConsumerManualAddressFormSchema } from './consumer-manual-address-form.schema'
import { ConsumerManualAddressFormStyles } from './consumer-manual-address-form.styles'
import { ConsumerManualAddressFormUat } from './consumer-manual-address-form.uat'
import { ConsumerManualAddressFormSchema } from './types/consumer-manual-address-form-schema.interface'
import { buildConsumerAddressFromManualAddress } from './consumer-manual-address-form.functions'
import { ConsumerManualAddressStore } from '../../store/consumer-manual-address.store'

export const ConsumerManualAddressForm = (
  props: ConsumerManualAddressFormProps
) => {
  const intl = useIntl()

  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true)

  const [street, setStreet] = useState<string>(
    props.consumerAddress?.street ?? ''
  )
  const [unit, setUnit] = useState<string>(props.consumerAddress?.unit ?? '')
  const [city, setCity] = useState<string>(props.consumerAddress?.city ?? '')
  const [province, setProvince] = useState<Province['id']>(
    props.consumerAddress?.province ?? ''
  )
  const [postalCode, setPostalCode] = useState<string>(
    props.consumerAddress?.postalCode ?? ''
  )

  const formSchema: FormSchema<ConsumerManualAddressFormSchema> =
    generateConsumerManualAddressFormSchema()

  const onStreetChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStreet(event.target.value)

    if (props.showNonPhysicalAddressError) {
      ConsumerManualAddressStore.resetSaveAddressFailure()
    }
  }
  const onAptChange = (event: ChangeEvent<HTMLInputElement>) =>
    setUnit(event.target.value)
  const onCityChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCity(event.target.value)
  const onProvinceChange = (provideId: Province['id']) => setProvince(provideId)
  const onPostalChange = (postalCode: string) => setPostalCode(postalCode)

  useEffect(() => {
    if (props.isWorkflowStepComplete) {
      ConsumerProfileStore.setConsumerAddress({
        unit,
        street,
        city,
        province,
        postalCode
      })
      props.onComplete()
    }
  }, [
    props.isWorkflowStepComplete,
    unit,
    street,
    city,
    province,
    postalCode,
    props
  ])

  const onSubmit = () => {
    props.onSubmit(
      buildConsumerAddressFromManualAddress(
        unit,
        street,
        city,
        province,
        postalCode
      )
    )
  }

  useEffect(() => {
    const isFormValid: boolean = formSchema.isValidSync({
      apt: unit,
      street,
      city,
      postalCode,
      province
    })
    setDisableSubmitButton(!isFormValid)
  }, [street, unit, city, province, postalCode, formSchema])

  useEffect(() => {
    if (props.consumerAddress) {
      setUnit(props.consumerAddress.unit || '')
      setStreet(props.consumerAddress.street)
      setCity(props.consumerAddress.city)
      setPostalCode(props.consumerAddress.postalCode)
      setProvince(props.consumerAddress.province)
    }
  }, [props.consumerAddress])

  return (
    <div className={ConsumerManualAddressFormStyles.wrapper}>
      <div className={ConsumerManualAddressFormStyles.container}>
        <TextInput
          autoFocus
          label={intl.formatMessage(
            ConsumerManualAddressFormMessages.streetLabel
          )}
          name="street"
          value={street}
          onChange={onStreetChange}
          maxLength={
            formSchema.getSchemaOptions<StringSchemaOptions>('street').maxLength
          }
          uat={uatId(ConsumerManualAddressFormUat.street)}
        />

        <div className={ConsumerManualAddressFormStyles.horizontal}>
          <div className={ConsumerManualAddressFormStyles.apt}>
            <TextInput
              fullWidth
              label={intl.formatMessage(
                ConsumerManualAddressFormMessages.aptLabel
              )}
              maxLength={
                formSchema.getSchemaOptions<StringSchemaOptions>('apt')
                  .maxLength
              }
              name="apt"
              value={unit}
              onChange={onAptChange}
              uat={uatId(ConsumerManualAddressFormUat.apt)}
            />
          </div>

          <div className={ConsumerManualAddressFormStyles.city}>
            <TextInput
              fullWidth
              label={intl.formatMessage(
                ConsumerManualAddressFormMessages.cityLabel
              )}
              maxLength={
                formSchema.getSchemaOptions<StringSchemaOptions>('city')
                  .maxLength
              }
              name="city"
              value={city}
              onChange={onCityChange}
              uat={uatId(ConsumerManualAddressFormUat.city)}
            />
          </div>
        </div>

        <div className={ConsumerManualAddressFormStyles.horizontal}>
          <div className={ConsumerManualAddressFormStyles.province}>
            <ProvincesSelectField
              province={province}
              uat={ConsumerManualAddressFormUat.province}
              uatOptionList={ConsumerManualAddressFormUat.provinceOptionList}
              uatOptionListItem={
                ConsumerManualAddressFormUat.provinceOptionListItem
              }
              onChange={onProvinceChange}
              provinces={props.provinces}
            />
          </div>

          <div className={ConsumerManualAddressFormStyles.postal}>
            <PostalCodeInput
              label={intl.formatMessage(
                ConsumerManualAddressFormMessages.postalLabel
              )}
              name="postalCode"
              postalCode={postalCode}
              onChange={onPostalChange}
              uat={uatId(ConsumerManualAddressFormUat.postalCode)}
            />
          </div>
        </div>
      </div>

      <button
        {...uatId(ConsumerManualAddressFormUat.auto)}
        className={ConsumerManualAddressFormStyles.autoButton}
        onClick={props.switchToAutoAddressForm}
      >
        <FormattedMessage {...ConsumerManualAddressFormMessages.autoButton} />
      </button>

      <PrimaryButton
        cssContainerClasses={ConsumerManualAddressFormStyles.submitButton}
        disabled={disableSubmitButton}
        loading={props.saveAddressLoading}
        onClick={onSubmit}
        uat={uatId(ConsumerManualAddressFormUat.submitButton)}
      >
        <FormattedMessage {...ConsumerManualAddressFormMessages.submitButton} />
      </PrimaryButton>
    </div>
  )
}
