import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getConsumerAssetType } from './consumer-workflow.functions'
import { withShowIf } from '../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { PreQualifierAssetTypeSelectionStore } from '../pre-qualifier-asset-type-selection/store/pre-qualifier-asset-type-selection.store'
import { ConsumerProfileStore } from '../consumer-profile/store/consumer-profile.store'

export const ConsumerWorkflow = withShowIf(() => {
  const selectedAssetType = useSelector(
    PreQualifierAssetTypeSelectionStore.getSelectedAssetType
  )
  const assetTypes = useSelector(
    PreQualifierAssetTypeSelectionStore.getAssetTypes
  )
  const consumerAssetTypeId = useSelector(
    ConsumerProfileStore.getConsumerAssetType
  )

  useEffect(() => {
    if (!selectedAssetType) {
      const consumerAssetType = getConsumerAssetType(
        consumerAssetTypeId,
        assetTypes
      )
      if (consumerAssetType) {
        PreQualifierAssetTypeSelectionStore.setSelectedAssetType(
          consumerAssetType
        )
      }
    }
  }, [assetTypes, consumerAssetTypeId])

  return null
})
