import { withShowIf } from '../../helpers/with-show-if/with-show-if.component'
import { SecondaryButtonProps } from './secondary-button.props'
import {
  secondaryButtonContainerStyle,
  secondaryButtonStyles
} from './secondary-button.styles'

export const SecondaryButton = withShowIf((props: SecondaryButtonProps) => (
  <div
    className={secondaryButtonContainerStyle(
      props.cssContainerClasses,
      props.fullWidth,
      props.minimumWidth
    )}
  >
    <button
      {...props.uat}
      onClick={props.onClick}
      className={secondaryButtonStyles(props.isDisabled)}
      disabled={props.isDisabled}
    >
      {props.children}
    </button>
  </div>
))
