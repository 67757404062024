import { OfficeIconProp } from './office.icon.prop'

export const OfficeIcon = (props: OfficeIconProp) => (
  <svg
    {...props.uat}
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="31"
    viewBox="0 0 33 31"
    fill="none"
  >
    <path
      d="M23.9046 20.6667H26.8137V24.1111H23.9046V20.6667Z"
      fill="#1477CA"
    />
    <path
      d="M26.8137 13.7778H23.9046V17.2222H26.8137V13.7778Z"
      fill="#1477CA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0864 6.88889H32.6318V31H0.631836V0H18.0864V6.88889ZM10.8137 3.44444H15.1773V6.88889H10.8137V3.44444ZM15.1773 27.5556V24.1111H10.8137V27.5556H15.1773ZM15.1773 20.6667V17.2222H10.8137V20.6667H15.1773ZM15.1773 13.7778V10.3333H10.8137V13.7778H15.1773ZM29.7227 27.5556V10.3333H18.0864V13.7778H20.9955V17.2222H18.0864V20.6667H20.9955V24.1111H18.0864V27.5556H29.7227ZM3.54093 27.5556V24.1111H7.90456V27.5556H3.54093ZM3.54093 20.6667H7.90456V17.2222H3.54093V20.6667ZM7.90456 13.7778V10.3333H3.54093V13.7778H7.90456ZM3.54093 6.88889H7.90456V3.44444H3.54093V6.88889Z"
      fill="#1477CA"
    />
  </svg>
)
