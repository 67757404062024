import { ChangeEvent, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { StringSchemaOptions } from '../../../../../../../../libraries/form-schema/form-field-schema/types/form-field-schema-options/string-schema-options.interface'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { TextInput } from '../../../../../../../../ui-library/components/input-field/components/text-input/text-input.component'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { ConsumerEmailStepStore } from '../../store/consumer-email-step.store'
import { ConsumerEmailError } from '../consumer-email-error/consumer-email-error.component'
import { ConsumerEmailFormProps } from './consumer-email-form.props'
import { generateConsumerEmailFormSchema } from './consumer-email-form.schema'
import { ConsumerEmailFormStyles } from './consumer-email-form.styles'
import { ConsumerEmailFormUat } from './consumer-email-form.uat'
import { ConsumerEmailFormMessages } from './consumer-email.form.messages'

export const ConsumerEmailForm = (props: ConsumerEmailFormProps) => {
  const intl = useIntl()

  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [email, setEmail] = useState<string>(props.initialFormValue ?? '')
  const [disableSubmitButton, setDisableSubmitButton] = useState(true)

  const formSchema = generateConsumerEmailFormSchema()

  const saveConsumerEmailError = useSelector(ConsumerEmailStepStore.getError)

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setEmail(event.target.value)

  const submitForm = () => props.onSubmit(email)

  useEffect(() => {
    const disableButtonIfInvalidFormOrLoading: boolean =
      !isFormValid || props.loading
    setDisableSubmitButton(disableButtonIfInvalidFormOrLoading)
  }, [isFormValid, props.loading])

  useEffect(() => {
    setIsFormValid(formSchema.isValidSync({ email }))
  }, [email, formSchema])

  return (
    <div className={ConsumerEmailFormStyles.container}>
      <div className={ConsumerEmailFormStyles.emailField}>
        <TextInput
          autoFocus
          name={'email'}
          label={intl.formatMessage(ConsumerEmailFormMessages.emailLabel)}
          value={email}
          maxLength={
            formSchema.getSchemaOptions<StringSchemaOptions>('email').maxLength
          }
          uat={uatId(ConsumerEmailFormUat.input)}
          onChange={onEmailChange}
        />

        <ConsumerEmailError showIf={Boolean(saveConsumerEmailError)} />
      </div>

      <PrimaryButton
        onClick={submitForm}
        cssContainerClasses={ConsumerEmailFormStyles.submitButton}
        disabled={disableSubmitButton}
        loading={props.loading}
        uat={uatId(ConsumerEmailFormUat.submitButton)}
      >
        <FormattedMessage {...ConsumerEmailFormMessages.submitButton} />
      </PrimaryButton>
    </div>
  )
}
