import { Selector } from 'react-redux'
import { createSelector } from 'reselect'
import { PreQualifierProduct } from '../../../../../domain/pre-qualifier-product/pre-qualifier-product.interface'
import { HttpError } from '../../../../../shared/types/http-error.interface'
import { RootStore } from '../../../../../store/root.store'
import { RootState } from '../../../../../store/types/root-state/root-state.interface'
import { PreQualifierLoadingProductState } from '../types/pre-qualifier-loading-product-state.interface'
import { PreQualifierLoadingProductActions } from './pre-qualifier-loading-product.actions'

const getPreQualifierLoadingProductState = (
  rootState: RootState
): PreQualifierLoadingProductState =>
  rootState.consumerState.preQualifierLoadingProductState

const getProductSku: Selector<RootState, string> = createSelector(
  getPreQualifierLoadingProductState,
  (state: PreQualifierLoadingProductState) => state.productSku
)

const isLoading: Selector<RootState, boolean> = createSelector(
  getPreQualifierLoadingProductState,
  (state: PreQualifierLoadingProductState) => state.isLoading
)
const getError: Selector<RootState, HttpError | undefined> = createSelector(
  getPreQualifierLoadingProductState,
  (state: PreQualifierLoadingProductState) => state.error
)

const getProductDetails: Selector<RootState, PreQualifierProduct | undefined> =
  createSelector(
    getPreQualifierLoadingProductState,
    (state: PreQualifierLoadingProductState) => state.productDetails
  )

const fetchProductDetails = (vendorId: string, sku: string) =>
  RootStore.dispatch(
    PreQualifierLoadingProductActions.fetchProductDetails(vendorId, sku)
  )

export const PreQualifierLoadingProductStore = {
  getProductSku,
  fetchProductDetails,
  getProductDetails,
  isLoading,
  getError
}
