import { ConsumerAddress } from '../../../../../../../../../../../../domain/consumer/consumer-profile/consumer-address.interface'

export function buildConsumerAddressFromManualAddress(
  unit: string,
  street: string,
  city: string,
  province: string,
  postalCode: string
): ConsumerAddress {
  return {
    unit: unit || null,
    street,
    city,
    province,
    postalCode
  }
}
