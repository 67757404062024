import { ConsumerProfileSessionInfo } from '../../../../domain/consumer/consumer-profile/consumer-profile-session-token.interface'
import { ConsumerSessionTokenDto } from './consumer-session-token.dto'

export class ConsumerSessionTokenDtoMapper {
  constructor(private data: ConsumerSessionTokenDto) {}

  public toDomainObject(): ConsumerProfileSessionInfo {
    return {
      consumerProfileId: this.data.consumerProfileId,
      sessionToken: this.data.accessToken
    }
  }
}
