import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'

export class ConsumerDobService extends BffService {
  public saveConsumerDob(dateOfBirth: string): FetchResponse<null> {
    return super.createAuthorizedRequest({
      method: FetchMethod.PATCH,
      url: `${this.bffUrl}/v1/consumer-profile/date-of-birth`,
      body: {
        dateOfBirth: this.convertDateFormatToIso8610(dateOfBirth)
      }
    })
  }

  public convertDateFormatToIso8610 = (dateOfBirth: string): string => {
    const [month, day, year]: string[] = dateOfBirth.split('/')
    return [year, month, day].join('-')
  }
}
