import { Reducer } from 'redux'
import { createConsumerNetIncomeStepState } from '../types/consumer-net-income-step-state.functions'
import { ConsumerNetIncomeStepState } from '../types/consumer-net-income-step-state.interface'
import {
  ConsumerNetIncomeStepAction,
  ConsumerNetIncomeStepActionType
} from './consumer-net-income-step.actions'

export const consumerNetIncomeStepReducer: Reducer<
  ConsumerNetIncomeStepState,
  ConsumerNetIncomeStepActionType
> = (
  state: ConsumerNetIncomeStepState = createConsumerNetIncomeStepState(),
  action: ConsumerNetIncomeStepActionType
): ConsumerNetIncomeStepState => {
  switch (action.type) {
    case ConsumerNetIncomeStepAction.SaveConsumerNetIncome:
      return {
        ...state,
        error: null,
        loading: true
      }
    case ConsumerNetIncomeStepAction.SaveConsumerNetIncomeFailure:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case ConsumerNetIncomeStepAction.SaveConsumerNetIncomeSuccess:
      return {
        ...state,
        error: null,
        loading: false,
        isWorkflowStepComplete: true
      }
    case ConsumerNetIncomeStepAction.ResetConsumerNetIncomeState:
      return createConsumerNetIncomeStepState()
    default:
      return state
  }
}
