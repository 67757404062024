import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { ConsumerPostSubmissionApprovedActionTextMessages } from './consumer-post-submission-approved-action-text.messages'
import { ConsumerPostSubmissionApprovedActionTextStyles } from './consumer-post-submission-approved-action-text.styles'
import { ConsumerPostSubmissionApprovedStep } from './consumer-post-submission-approved-step/consumer-post-submission-approved-step.component'

export const ConsumerPostSubmissionApprovedActionText = () => {
  const intl = useIntl();
  return (
    <div className={ConsumerPostSubmissionApprovedActionTextStyles.container}>
      <Paragraph
        className={ConsumerPostSubmissionApprovedActionTextStyles.title}
      >
        <FormattedMessage 
          {...ConsumerPostSubmissionApprovedActionTextMessages.nextSteps}
        />
      </Paragraph>
      
      <ConsumerPostSubmissionApprovedStep 
        title={intl.formatMessage(ConsumerPostSubmissionApprovedActionTextMessages.step1Title)} 
        details={intl.formatMessage(ConsumerPostSubmissionApprovedActionTextMessages.step1Details)} 
      />

      <ConsumerPostSubmissionApprovedStep 
        title={intl.formatMessage(ConsumerPostSubmissionApprovedActionTextMessages.step2Title)} 
        details={intl.formatMessage(ConsumerPostSubmissionApprovedActionTextMessages.step2Details)} 
      />

      <ConsumerPostSubmissionApprovedStep 
        title={intl.formatMessage(ConsumerPostSubmissionApprovedActionTextMessages.step3Title)} 
        details={intl.formatMessage(ConsumerPostSubmissionApprovedActionTextMessages.step3Details)} 
      />
    </div>
  )
}
