import { useEffect, useState } from 'react'
import { useSelector, connect } from 'react-redux'
import { getLocale, getLocaleMessages, hasLocale } from './types/localization.functions'
import { SettingsStore } from '../../modules/settings/store/settings.store'
import { IntlProvider } from 'react-intl'
import { Language } from '../../../../shared/types/language.enum'
import { withRouter } from 'react-router'
import { ConsumerModule } from '../../../consumer/consumer.module'
import { ConsumerProfileStore } from '../../../consumer/modules/consumer-profile/store/consumer-profile.store'
import { getRefId } from './types/ref-id.functions'
import { getReferrer, setReferrerToSessionStorage } from './types/referrer.function'
import { getEventId, setEventIdToSessionStorage } from './types/event-id.functions'
import { getCampaignId, setCampaignIdToSessionStorage } from './types/campaign-id.functions'

const Initializer = () => {
  const locale: Language = useSelector(SettingsStore.getLocaleSetting)
  const [messages, setMessages] = useState<any>(getLocaleMessages(locale))

  useEffect(() => {
    if(!locale || hasLocale(window.location.search)){
      SettingsStore.setLocaleSetting(
        getLocale(window.location.search, window.navigator.language)
      )

    }
  }, [window.navigator.language, window.location.search, locale])

  useEffect(() => {
    setMessages(getLocaleMessages(locale))
  }, [locale])

  useEffect(() => {
    const url = window.location.search
    const refId = getRefId(url)
    if (refId) {
      ConsumerProfileStore.setRefId(refId)
    }

    setReferrerToSessionStorage(url);
    setEventIdToSessionStorage(url);
    setCampaignIdToSessionStorage(url)
    
    ConsumerProfileStore.setReferrer(
      getReferrer(url)
    );

    ConsumerProfileStore.setEventId(
      getEventId(url),
    );

    ConsumerProfileStore.setCampaignId(
      getCampaignId(url)
    )
  }, [])

  return (
    <IntlProvider locale={locale} messages={messages}>
      <ConsumerModule />
    </IntlProvider>
  )
}

export const InitializerComponent = withRouter(connect()(Initializer))
