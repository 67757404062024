import { Action } from 'redux'
import { Epic } from 'redux-observable'
import { catchError, filter, map, of, switchMap } from 'rxjs'
import { isActionOf } from 'typesafe-actions'
import { HttpError } from '../../../../../../../../../../../shared/types/http-error.interface'
import { RootState } from '../../../../../../../../../../../store/types/root-state/root-state.interface'
import { Provinces } from '../../../../../../../../../../../domain/province/provinces.interface'
import { ConsumerManualAddressActions } from './consumer-manual-address.actions'
import { ConsumerAddressServiceFactory } from '../../../../../services/consumer-address-factory.service'

const fetchProvinces: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(ConsumerManualAddressActions.fetchProvinces)),
    switchMap((action) =>
      new ConsumerAddressServiceFactory()
        .create(state$.value)
        .getProvinces(action.payload)
        .pipe(
          map((provinces: Provinces) =>
            ConsumerManualAddressActions.fetchProvincesSuccess(provinces)
          ),
          catchError((err: HttpError) =>
            of(ConsumerManualAddressActions.fetchProvincesFailure(err))
          )
        )
    )
  )

const saveManualAddress: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(ConsumerManualAddressActions.saveManualAddress)),
    switchMap((action) =>
      new ConsumerAddressServiceFactory()
        .create(state$.value)
        .saveAddress(action.payload)
        .pipe(
          map(() => ConsumerManualAddressActions.saveManualAddressSuccess()),
          catchError((err: HttpError) =>
            of(ConsumerManualAddressActions.saveManualAddressFailure(err))
          )
        )
    )
  )

export const consumerManualAddressEpics = [fetchProvinces, saveManualAddress]
