export const ConsumerPostSubmissionApprovedViewStyles = {
  container: 'lcpq-flex-1-0-auto flex flex-col',
  header:
    'mb-6 text-left text-center font-semibold text-green text-30px md:text-42px sm:block',
  headerContainer: 'w-full flex flex-row justify-center gap-x-2 sm:gap-x-10',
  headerIcon: 'w-12 h-12',
  title: 'text-green',
  approvedAmountHeaderMessage:
    'text-center mb-4 text-blue700 text-24px sm:mb-6 sm:text-30px sm:font-semibold',
  approvedAmountMessage:
    'text-center mb-4 sm:mb-6 text-xl font-regular text-goEasyGray900 sm:text-left',
  getStartedTitle: 'font-semibold text-24px',
  approvedContainer: 'w-full px-6 mb-8 mt-4 sm:w-3/4 sm:px-0 sm:mb-12',
  selectMerchantOptionsContainer: 'w-full max-w-6xl mb-2 mt-4 px-8 sm:px-16',
  selectMerchantContainer: 'flex sm:flex-row xs:flex-col align-center',
  selectMerchantLabelContainer: 'flex-2 flex items-center',
  selectMerchantLabel: 'font-semibold text-24px mr-6 sm:mb-0 xs:mb-3',
  selectMerchantButtonContainer: 'w-full flex flex-1 mb-5 justify-end',
}
