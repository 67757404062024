import { PreQualifierAssetTypes } from '../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-types.interface'
import { Language } from '../../../shared/types/language.enum'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'

export class PreQualifierAssetTypeService extends BffService {
  public fetchAssetTypes(
    locale: Language,
    vendorId: string,
    tags: string[] | null
  ): FetchResponse<PreQualifierAssetTypes> {
    return super.createAuthorizedRequest<PreQualifierAssetTypes>({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/asset-types/vendor/${vendorId}`,
      query: tags ? { tag: tags } : undefined,
      locale
    })
  }
}
