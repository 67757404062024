export const ConsumerPhoneNumberFormMessages = {
  phoneNumberLabel: {
    id: 'Pages.ConsumerPhoneNumber.Form.phoneNumberLabel',
    description: 'My Phone Number is'
  },
  submitButton: {
    id: 'Pages.ConsumerPhoneNumber.Form.submitButton',
    description: 'Submit Button'
  }
}
