import { Reducer } from 'redux'
import { createConsumerManualAddressState } from '../types/consumer-manual-address-state.functions'
import { ConsumerManualAddressState } from '../types/consumer-manual-address-state.interface'
import {
  ConsumerManualAddressAction,
  ConsumerManualAddressActionType
} from './consumer-manual-address.actions'

export const consumerManualAddressReducer: Reducer<
  ConsumerManualAddressState,
  ConsumerManualAddressActionType
> = (
  state: ConsumerManualAddressState = createConsumerManualAddressState(),
  action: ConsumerManualAddressActionType
): ConsumerManualAddressState => {
  switch (action.type) {
    case ConsumerManualAddressAction.SaveManualAddress:
      return {
        ...state,
        saveAddressLoading: true,
        saveAddressFailure: null
      }

    case ConsumerManualAddressAction.SaveManualAddressFailure:
      return {
        ...state,
        saveAddressLoading: false,
        saveAddressFailure: action.payload
      }

    case ConsumerManualAddressAction.SaveManualAddressSuccess:
      return {
        ...state,
        isWorkflowStepComplete: true,
        saveAddressLoading: false
      }

    case ConsumerManualAddressAction.FetchProvinces:
      return {
        ...state,
        provinces: null,
        provincesFailure: null
      }

    case ConsumerManualAddressAction.FetchProvincesSuccess:
      return {
        ...state,
        provinces: action.payload
      }

    case ConsumerManualAddressAction.FetchProvincesFailure:
      return {
        ...state,
        provincesFailure: action.payload
      }
    case ConsumerManualAddressAction.Reset:
      return createConsumerManualAddressState()
    case ConsumerManualAddressAction.ResetSaveAddressFailure:
      return {
        ...state,
        saveAddressFailure: null
      }
    default:
      return state
  }
}
