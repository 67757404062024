import { ConsumerWorkflowStep } from '../../../../../domain/consumer/consumer-workflow/consumer-workflow-step.interface'
import { ConsumerWorkflowStepDto } from './consumer-workflow-step.dto'

export class ConsumerWorkflowStepDtoMapper {
  constructor(private data: ConsumerWorkflowStepDto) {}

  public toDomainObject(): ConsumerWorkflowStep {
    return {
      order: this.data.order,
      type: this.data.type,
      name: this.data.name,
      state: this.data.state,
      rules: this.data.rules
    }
  }
}
