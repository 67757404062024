import { useIntl } from 'react-intl'
import { uatId } from '../../../../../../../../../../../../../../uat/uat-id.function'
import { BannerTextType } from '../../../../../../../../../../../../../../ui-library/components/banner-text/banner-text-type.enum'
import { BannerText } from '../../../../../../../../../../../../../../ui-library/components/banner-text/banner-text.component'
import { withShowIf } from '../../../../../../../../../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { ConsumerPhoneValidationResendCodeBannerInfoMessage } from './consumer-phone-validation-resend-code-banner-info.message'
import { ConsumerPhoneValidationResendCodeBannerInfoProps } from './consumer-phone-validation-resend-code-banner-info.props'
import { ConsumerPhoneValidationResendCodeBannerInfoUat } from './consumer-phone-validation-resend-code-banner-info.uat'

export const ConsumerPhoneValidationResendCodeBannerInfo = withShowIf(
  (props: ConsumerPhoneValidationResendCodeBannerInfoProps) => {
    const intl = useIntl()

    return (
      <BannerText
        cssContainerClasses={props.cssContainerClasses}
        uat={uatId(
          ConsumerPhoneValidationResendCodeBannerInfoUat.resendCodeBanner
        )}
        type={BannerTextType.INFO}
        boldText={intl.formatMessage(
          ConsumerPhoneValidationResendCodeBannerInfoMessage.message
        )}
      />
    )
  }
)
