import { useIntl } from 'react-intl'
import { ValidationErrorMessage } from '../../../../../../../../../../../../../../shared/components/validation-error-message/validation-error-message.component'
import { ConsumerPhoneValidationErrorBadRequestProps } from './consumer-phone-validation-error-bad-request.prop'
import { ConsumerPhoneValidationErrorBadRequestStyles } from './consumer-phone-validation-error-bad-request.styles'

export const ConsumerPhoneValidationErrorBadRequest = (
  props: ConsumerPhoneValidationErrorBadRequestProps
) => {
  const intl = useIntl()

  return (
    <ValidationErrorMessage
      cssContainerClasses={
        ConsumerPhoneValidationErrorBadRequestStyles.errorMessage
      }
      message={intl.formatMessage(props.error)}
      uat={props.uat}
    />
  )
}
