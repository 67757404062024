export const MainLayoutFooterMessages = {
  title: {
    id: 'MainLayout.ContactInfoFooter.Footer.title',
    description: 'Merchant Selection Page Footer title'
  },
  lendCareEmail: {
    id: 'MainLayout.ContactInfoFooter.Footer.lendCareEmail',
    description: 'Merchant Selection Page Footer LendCare Email'
  },
  lendCarePhoneNumber: {
    id: 'MainLayout.ContactInfoFooter.Footer.lendCarePhoneNumber',
    description: 'Merchant Selection Page Footer LendCare Phone Number'
  }
}
