import { EmailLink } from '../../../../../ui-library/components/link/email-link/email-link.component'
import { PhoneLink } from '../../../../../ui-library/components/link/phone-link/phone-link.component'
import { Paragraph } from '../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { contactInfoFooterProps } from './contact-info-footer.props'
import { ContactInfoFooterStyles } from './contact-info-footer.styles'

export const ContactInfoFooter = withShowIf((props: contactInfoFooterProps) => {
  return (
    <div className={ContactInfoFooterStyles.container}>
      <Paragraph className={ContactInfoFooterStyles.title} uat={props.uat}>
        {props.headerText}
      </Paragraph>
      <div className={ContactInfoFooterStyles.contactInfoContainer}>
        <EmailLink
          className={ContactInfoFooterStyles.email}
          cssLogoClasses={ContactInfoFooterStyles.icon}
          text={props.email}
          emailAddress={props.email}
        />

        <PhoneLink
          className={ContactInfoFooterStyles.phone}
          cssLogoClasses={ContactInfoFooterStyles.icon}
          text={props.phoneNumber}
          phoneNumber={props.phoneNumber}
        ></PhoneLink>
      </div>
    </div>
  )
})
