import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component'
import { PreQualifierLoadingProductStore } from '../../../pre-qualifier-loading-product/store/pre-qualifier-loading-product.store'
import { ConsumerReviewAssetView } from './components/consumer-review-asset-view/consumer-review-asset-view.component'
import { ConsumerReviewAssetStepProps } from './consumer-review-asset-step.props'

export const ConsumerReviewAssetStep = (
  props: ConsumerReviewAssetStepProps
) => {
  const onComplete = () => props.onComplete(props.step)
  const preQualifierProductDetails = useSelector(
    PreQualifierLoadingProductStore.getProductDetails
  )

  const goBack = useCallback(() => {
    props.goBack(props.step)
  }, [props.step, props.goBack])

  return (
    <MainLayout goBack={props.goBack} step={props.step} hideFooter>
      <ConsumerReviewAssetView
        onComplete={onComplete}
        goBack={goBack}
        step={props.step}
        productDetails={preQualifierProductDetails}
      />
    </MainLayout>
  )
}
