import { FormSchema } from '../../../libraries/form-schema/form-schema'
import { StringFieldSchema } from '../../../libraries/form-schema/form-field-schema/string-field-schema'
import { PhoneNumberFormSchema } from './phone-number-schema.interface'

export function generatePhoneNumberFormSchema(): FormSchema<PhoneNumberFormSchema> {
  return new FormSchema<PhoneNumberFormSchema>({
    phoneNumber: new StringFieldSchema('phoneNumber')
      .setMinLength(10)
      .setMaxLength(10)
      .isRequired()
  })
}
