import React, { useCallback } from 'react'
import { MainLayout } from '../../../../../../shared/components/main-layout/main-layout.component'
import { ConsumerNameView } from './components/consumer-name-view/consumer-name-view.component'
import { ConsumerNameStepProps } from './consumer-name-step.props'

export const ConsumerNameStep = (props: ConsumerNameStepProps) => {
  const onComplete = () => props.onComplete(props.step)

  const goBack = useCallback(() => {
    props.goBack(props.step)
  }, [props.step, props.goBack])

  return (
    <MainLayout hideFooter>
      <ConsumerNameView
        onComplete={onComplete}
        goBack={goBack}
        step={props.step}
      />
    </MainLayout>
  )
}
