import { Reducer } from 'redux'
import { ConsumerSessionState } from '../types/consumer-session-state.interface'
import {
  ConsumerSessionAction,
  ConsumerSessionActionType
} from './consumer-session.actions'
import { createConsumerSessionState } from '../types/consumer-session-state.functions'

export const consumerSessionReducer: Reducer<
  ConsumerSessionState,
  ConsumerSessionActionType
> = (
  state: ConsumerSessionState = createConsumerSessionState(),
  action: ConsumerSessionActionType
): ConsumerSessionState => {
  switch (action.type) {
    case ConsumerSessionAction.SetSessionToken:
      return {
        ...state,
        sessionToken: action.payload
      }
    case ConsumerSessionAction.GetSessionTokenFailure:
      return {
        ...state,
        getSessionTokenError: action.payload
      }
    case ConsumerSessionAction.FetchConsumerSession:
      return {
        ...state,
        fetchingConsumerSession: true,
        error: null
      }
    case ConsumerSessionAction.FetchConsumerSessionSuccess:
      return {
        ...state,
        fetchingConsumerSession: false
      }
    case ConsumerSessionAction.FetchConsumerSessionFailure:
      return {
        ...state,
        sessionToken: null,
        fetchingConsumerSession: false,
        error: action.payload
      }
    case ConsumerSessionAction.SetIsLoading:
      return {
        ...state,
        fetchingConsumerSession: true,
      }
    default:
      return state
  }
}
