export const ConsumerProductViewMessages = {
  header: {
    id: 'Pages.ConsumerProduct.View.header',
    description: 'Consumer product view header'
  },
  description: {
    id: 'Pages.ConsumerProduct.View.description',
    description: 'Consumer product view description'
  }
}
