import { FormattedMessage } from 'react-intl'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { PreQualifierAssetTypeSelectionErrorViewMessages } from './pre-qualifier-asset-type-selection-error-view.messages'
import { PreQualifierAssetTypeSelectionErrorViewStyles } from './pre-qualifier-asset-type-selection-error-view.styles'

export const PreQualifierAssetTypeSelectionErrorView = withShowIf(() => {
  return (
    <div className={PreQualifierAssetTypeSelectionErrorViewStyles.container}>
      <Paragraph>
        <FormattedMessage
          {...PreQualifierAssetTypeSelectionErrorViewMessages.errorMessage}
        />
      </Paragraph>
    </div>
  )
})
