export const OffersViewComponentStyles = {
  container: 'lcpq-flex-1-0-auto flex flex-col',
  header:
    'mb-6 my-auto text-left text-center font-semibold text-green text-20px sm:text-42px',
  headerContainer: 'w-full flex flex-row justify-center gap-x-2 sm:gap-x-10',
  title: 'text-blue300 text-24px my-6',
  approvedAmountHeaderMessage:
    'text-center mb-4 text-blue700 text-24px font-bold sm:mb-6 sm:text-30px sm:font-semibold',
  approvedAmountMessage:
    'text-center mb-4 text-blue700 text-16px font-bold sm:mb-6 sm:text-24px sm:font-semibold',
  selectMerchantContainer:
    'flex w-full flex-col mx-0 items-center md:flex-row md:justify-between py-8 md:mx-10',
  selectMerchantButtonContainer: 'flex flex-1 justify-center px-4 w-96',
  selectMerchantLabel:
    'flex-1 text-center mb-4 text-black text-24px sm:font-semibold',
  selectMerchantConfirmButton: 'mr-0 sm:mr-12',
  selectMerchantOptionsContainer: 'w-full flex flex-col px-6 md:w-3/4  md:px-0',
  approvedContainer: 'w-full px-6 mt-4 sm:w-3/4 sm:px-0',
}
