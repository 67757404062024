import { FormattedMessage } from 'react-intl'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { SelectedOfferOverlayMessages } from './selected-offer-overlay.messages'
import { SelectedOfferOverlayUat } from './selected-offer-overlay.uat'
import { SelectedOfferOverlayStyles } from './selected-offer-overlay.styles'

export const SelectedOfferOverlay = () => {
  return (
    <>
      <div
        className={SelectedOfferOverlayStyles.overlayTitle}
        {...uatId(SelectedOfferOverlayUat.title)}
      >
        <FormattedMessage {...SelectedOfferOverlayMessages.title} />
      </div>
      <div
        className={SelectedOfferOverlayStyles.overlaySelected}
      />
    </>
  )
}
