export const ConsumerNetIncomeFormStyles = {
  wrapper: 'flex flex-col w-full items-center',
  container: 'flex flex-col w-full items-center sm:w-328',
  secondaryText:
    'text-blue300 text-16px mb-5 font-bold sm:text-20px text-center lg:text-left sm:font-normal',
  sliderField: 'mb-6 w-full justify-middle',
  sliderContainer: 'flex justify-center',
  slider: 'w-full mt-6 sm:max-w-xs bg-transparent',
  submitButton: 'text-xl sm:text-2xl mt-auto',
  infoContainer: 'flex flex-col',
  moreInfo: 'self-end',
  moreInfoIcon: 'h-4 w-4 text-blue300 ml-2',
}
