export const ProvincesSelectFieldMessages = {
  placeholder: {
    id: 'Pages.ConsumerAddress.Manual.provincePlaceholder',
    description: 'Select a province'
  },
  label: {
    id: 'Pages.ConsumerAddress.Manual.provinceLabel',
    description: 'Province'
  }
}
