import { BooleanFieldSchema } from '../../../../../../../../libraries/form-schema/form-field-schema/boolean-field-schema'
import { FormSchema } from '../../../../../../../../libraries/form-schema/form-schema'
import { ConsumerSubmitFormSchema } from './types/consumer-submit-form-schema.interface'

export function generateConsumerSubmitFormSchema(): FormSchema<ConsumerSubmitFormSchema> {
  return new FormSchema<ConsumerSubmitFormSchema>({
    authorizedElectronicCommunicationConsent: new BooleanFieldSchema(
      'authorized-electronic-communication-consent'
    )
      .isRequired()
      .isTrue(),
    creditConsent: new BooleanFieldSchema('credit-consent')
      .isRequired()
      .isTrue()
  })
}
