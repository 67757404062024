import { Paragraph } from "../../../../../../../../../ui-library/components/paragraph/paragraph.component"
import { ConsumerPostSubmissionApprovedStepProps } from "./consumer-post-submission-approved-step.props"
import { ConsumerPostSubmissionApprovedStepStyles } from "./consumer-post-submission-approved-step.styles"

export const ConsumerPostSubmissionApprovedStep = (props: ConsumerPostSubmissionApprovedStepProps) => {
    return (
    <div className={ConsumerPostSubmissionApprovedStepStyles.stepContainer}>
        <Paragraph
          className={ConsumerPostSubmissionApprovedStepStyles.stepText}
        >
          {props.title}
        </Paragraph>
        <Paragraph
          className={ConsumerPostSubmissionApprovedStepStyles.stepDetails}
        >
            {props.details}
        </Paragraph>
      </div>
    )
}