import { Provinces } from '../../../domain/province/provinces.interface'
import { ConsumerAddress } from '../../../domain/consumer/consumer-profile/consumer-address.interface'
import { Language } from '../../../shared/types/language.enum'
import { FetchMethod } from '../../fetch/types/fetch-method.enum'
import { FetchResponse } from '../../fetch/types/fetch-response.type'
import { BffService } from '../bff.service'
import { RetrieveAddress } from '../../../domain/lookup-address/retrieve-address.interface'
import { LookupAddress } from '../../../domain/lookup-address/lookup-address.interface'

export class ConsumerAddressService extends BffService {
  public lookupAddress(address: string): FetchResponse<LookupAddress[]> {
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/address/find`,
      body: {
        query: address
      }
    })
  }

  public retrieveAddress(
    id: LookupAddress['id']
  ): FetchResponse<RetrieveAddress> {
    return super.createAuthorizedRequest({
      method: FetchMethod.POST,
      url: `${this.bffUrl}/v1/address/retrieve`,
      body: {
        id
      }
    })
  }

  public saveAddress(address: ConsumerAddress): FetchResponse<null> {
    return super.createAuthorizedRequest({
      method: FetchMethod.PATCH,
      url: `${this.bffUrl}/v1/consumer-profile/address`,
      body: {
        ...address
      }
    })
  }

  public getProvinces(locale: Language): FetchResponse<Provinces> {
    return super.createAuthorizedRequest({
      method: FetchMethod.GET,
      url: `${this.bffUrl}/v1/provinces`,
      locale
    })
  }
}
