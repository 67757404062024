import { DropdownOption } from '../../../libraries/dropdown-library/types/dropdown-option.interface'
import { SelectFieldOption } from './types/select-field-option.interface'

export const generateOptions = (
  valueKey: string,
  labelKey: string,
  options: SelectFieldOption[]
): DropdownOption[] => {
  return options.map((option) => {
    return {
      value: option[valueKey].toString(),
      label: option[labelKey].toString()
    }
  })
}

export const getSelectedOption = (
  options: DropdownOption[],
  value: DropdownOption['value'] | null
): DropdownOption | null => {
  return options.find((option) => option.value === value) ?? null
}
