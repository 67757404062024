import { Epic } from 'redux-observable'
import { RootState } from '../../../../../../../store/types/root-state/root-state.interface'
import { isActionOf } from 'typesafe-actions'
import { ConsumerAssetSubtypeStepActions } from './consumer-asset-subtype-step.actions'
import { catchError, filter, of, switchMap } from 'rxjs'
import { ConsumerAssetSubtypeService } from '../../../../../../../api/bff/services/consumer-asset-subtype.service'
import { ConsumerSessionStore } from '../../../../consumer-session/store/consumer-session.store'
import { HttpError } from '../../../../../../../shared/types/http-error.interface'
import { Action } from 'redux'

const saveConsumerAssetSubtypeIdEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf(ConsumerAssetSubtypeStepActions.saveConsumerAssetSubtypeId)
    ),
    switchMap((action) =>
      new ConsumerAssetSubtypeService(
        ConsumerSessionStore.getSessionToken(state$.value)
      )
        .saveConsumerAssetSubtype(Number(action.payload))
        .pipe(
          switchMap(() =>
            of(
              ConsumerAssetSubtypeStepActions.saveConsumerAssetSubtypeIdSuccess(
                action.payload
              )
            )
          ),
          catchError((error: HttpError) =>
            of(
              ConsumerAssetSubtypeStepActions.saveConsumerAssetSubtypeIdFailure(
                error
              )
            )
          )
        )
    )
  )

export const consumerAssetSubtypeEpics = [saveConsumerAssetSubtypeIdEpic]
